import { gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

const IMPORT_ADDITIONAL_GENERATION_RESULTS = gql`
    mutation importScheduleResult($input: JSON!) {
        importScheduleResult(importAdditionalGenerationInput: $input)
    }
`;

export function useImportAdditionalGenerationResult(scheduleGenerationProcessId: string) {
    const [importResult, { called }] = useMutationWithErrorHandling(
        IMPORT_ADDITIONAL_GENERATION_RESULTS,
    );

    return {
        importResult: (result: string) => {
            const json = JSON.parse(result);
            json.scheduleGenerationProcessId = scheduleGenerationProcessId;
            const input = JSON.stringify(json);
            importResult({ variables: { input } });
        },
        called,
    };
}
