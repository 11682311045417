import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useMutation, useLazyQuery, useApolloClient, useQuery,
} from '@apollo/client';

import { useUrlQuery } from '@common/hooks';

import { ScheduleGenerationPage } from './ScheduleGenerationPage';
import * as Interface from './ScheduleGenerationPageInterfaces';
import * as Queries from './scheduleQueries';
import { downloadJson } from './utils';
import {
    useBaseTimeIntervalInstanceQuery,
    useValidationForProcessQuery,
} from './graphql';
import { scheduleGenerationStore } from './store';
import { ValidationDates } from './ScheduleGenerationConflicts/scheduleViolationInterface';

interface Props {
    isSecret?: boolean;
}

export const ScheduleGenerationPageApollo = React.memo(({
    isSecret,
}: Props): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const {
        setUrlQuery,
        getUrlQuery,
    } = useUrlQuery();
    const { baseTimeIntervalInstance } = useBaseTimeIntervalInstanceQuery(id);
    useEffect(() => {
        scheduleGenerationStore.setBaseTimeIntervalInstance(baseTimeIntervalInstance);
    }, [baseTimeIntervalInstance]);

    const [filteredScheduleVariables, setFilteredScheduleVariables] = useState(
        {
            scheduleFilterInput: {
                baseTimeIntervalInstanceId: id,
            },
        },
    );

    const [
        getFilteredSchedule,
        {
            data: filteredSchedule,
            refetch: refetchFilteredSchedule,
            loading,
        },
    ] = useLazyQuery(Queries.GET_FILTERED_SCHEDULE, {
        fetchPolicy: 'cache-first',
        variables: filteredScheduleVariables,
    });

    const {
        data: dataWithPages,
        refetch: refetchPagesCount,
    } = useQuery(Queries.GET_PAGES_COUNT, {
        fetchPolicy: 'network-only',
        variables: filteredScheduleVariables,
    });

    const {
        validateScheduleDataLoading,
    } = useValidationForProcessQuery();

    const { data: durationList } = useQuery(Queries.GET_DURATION_LIST, {
        fetchPolicy: 'no-cache',
    });

    const [getStudentsAttendance, {
        data: studentsAttendance,
        loading: studentsAttendanceLoading,
        error: studentsAttendanceError,
    }] = useLazyQuery(
        Queries.GET_STUDENTS_ATTENDANCE, { fetchPolicy: 'network-only' },
    );

    const [checkSchedule] = useMutation(Queries.CHECK_SCHEDULE);

    function getLazyStudentsAttendance(fromDate: Date, toDate: Date) {
        getStudentsAttendance({
            variables: {
                studentsAttendanceInput: {
                    baseTimeIntervalInstanceId: baseTimeIntervalInstance?.id,
                    fromDate,
                    toDate,
                },
            },
        });
    }

    const client = useApolloClient();

    function loadFilteredSchedule(
        modules: Interface.ModuleTitleSuggestion[],
        meetings: Interface.Meeting[],
        rooms: Interface.RoomSuggestion[],
        teachers: Interface.TeacherSuggestion[],
        students: Interface.StudentSuggestion[],
        waves: { id: string, waveIndex: number }[],
        from: string,
        to: string,
        statuses: Interface.StatusType[],
        types: Interface.EventType[],
        roomTypes: Interface.EventType[],
    ) {
        const variables = {
            scheduleFilterInput: {
                baseTimeIntervalInstanceId: id,
                moduleIds: modules.map(getOptionId),
                eventPatterns: meetings.map(({ topic }) => topic),
                roomIds: rooms.map(getOptionId),
                teacherIds: teachers.map(getOptionId),
                studentIds: students.map(getOptionId),
                waveIndexes: waves.map((wave) => wave.waveIndex),
                from,
                to,
                statuses: statuses.map(item => item.status),
                paginationParameters: {
                    page: (Number.parseInt(getUrlQuery('page') ?? '1', 10)),
                    delimiter: (getUrlQuery('delimiter') ?? 'day'),
                    isPaginate: true,
                },
                types: types.map(getOptionId),
                roomTypes: roomTypes.map(getOptionId),
            },
        };
        setFilteredScheduleVariables(variables);
        getFilteredSchedule({ variables });
        const parameters: { [index: string]: any } = {
            modules: modules.length ? modules : undefined,
            meetings: meetings.length ? meetings : undefined,
            rooms: rooms.length ? rooms : undefined,
            teachers: teachers.length ? teachers : undefined,
            students: students.length ? students : undefined,
            waves: waves.length ? waves : undefined,
            from: from || undefined,
            to: to || undefined,
            statuses: statuses.length ? statuses : undefined,
            types: types.length ? types : undefined,
            roomTypes: roomTypes.length ? roomTypes : undefined,
        };
        setUrlQuery(parameters);
    }

    scheduleGenerationStore.setDurationList(durationList);

    return (
        <>
            <ScheduleGenerationPage
                baseTimeIntervalInstanceId={id}
                isSecret={isSecret}
                events={filteredSchedule?.filteredSchedule.events}
                pagesCount={dataWithPages?.countOfPageForFilteredSchedule}
                meetingsLoader={loading}
                validateScheduleDataLoading={validateScheduleDataLoading}
                studentsAttendance={studentsAttendance?.studentsAttendance}
                studentsAttendanceLoading={studentsAttendanceLoading}
                studentsAttendanceError={studentsAttendanceError}
                refetchPagesCount={() => refetchPagesCount()}
                loadFilteredSchedule={loadFilteredSchedule}
                refetchFilteredSchedule={() => refetchFilteredSchedule && refetchFilteredSchedule()}
                getLazyStudentsAttendance={getLazyStudentsAttendance}
                checkSchedule={async (dates: ValidationDates) => {
                    const response = await checkSchedule({
                        variables: {
                            scheduleScoreInput: {
                                baseTimeIntervalInstanceId: id,
                                fromDate: `${dates.startDay} 00:00:00`,
                                toDate: dates.endDay ? `${dates.endDay} 23:59:00` : null,
                            },
                        },
                    });

                    return response.data.checkSchedule;
                }}
                getConfig={async (forProcess = true) => {
                    if (forProcess) {
                        const process = scheduleGenerationStore.baseTimeIntervalInstance
                            ?.selectedProcess;
                        if (!process) {
                            downloadJson('config.json', { message: 'У вас не создано ни одного процесса в данном БВИ' });
                            return;
                        }
                        const { data: { getSchedulePlanningResultConfig } } = await client.query({
                            query: Queries.GET_CONFIG,
                            variables: { generationProcessId: process?.id },
                            fetchPolicy: 'no-cache',
                        });
                        downloadJson('config.json', getSchedulePlanningResultConfig);
                    } else {
                        const { data: { configForSplit } } = await client.query({
                            query: Queries.GET_CONFIG_FOR_SPLIT,
                            variables: { baseTimeIntervalInstanceId: id },
                            fetchPolicy: 'no-cache',
                        });
                        downloadJson('config.json', configForSplit);
                    }
                }}
            />
        </>
    );
});

function getOptionId(option: { id: string }) {
    return option.id;
}
