import React from 'react';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';
import {
    DaySchedule,
    WeekDays,
    WeekDay,
} from '../../EducationPeriodParametersAndDatesInterfaces';
import classes from './EducationPeriodParametersDaySchedules.module.scss';
import { WeekDayIntervals } from './WeekDayIntervals';

interface Props {
    daySchedules : DaySchedule[],
}

export const WEEK_DAYS: WeekDays = [
    ['Monday', []],
    ['Tuesday', []],
    ['Wednesday', []],
    ['Thursday', []],
    ['Friday', []],
    ['Saturday', []],
    ['Sunday', []],
];

function getWeekDayIndex(daySchedule: DaySchedule, weekDays: WeekDays) {
    return weekDays.findIndex(([name]) => name === daySchedule.weekDay);
}

export function getWeekDaysWithAdditionSchedule(daySchedules: DaySchedule[]) {
    const weekDays = cloneDeep(WEEK_DAYS) as WeekDay[];

    daySchedules.forEach((daySchedule: DaySchedule) => {
        const weekDayIndex = getWeekDayIndex(daySchedule, weekDays);

        if (weekDayIndex > -1) {
            weekDays[weekDayIndex][1] = daySchedule.workingIntervals;
        }
    });

    return weekDays;
}

export function getFormatedTime(date: string) {
    if (date === '') {
        return date;
    }

    const splittedTime = date.split(':');
    const minutes = splittedTime.length === 3 ? `:${splittedTime[1]}` : '';
    const hours = splittedTime[0];

    return `${hours}${minutes}`;
}

export function getFormatedDate(time: string) {
    if (!(/^[0-2]?[0-9]?[:]?[0-6]?[0-9]?$/.test(time)) || time === '') {
        return '';
    }

    return `${time}:00.000Z`;
}

export function EducationPeriodParametersDaySchedules({ daySchedules }: Props) {
    return (
        <div className={classes.educationPeriodParametersDaySchedules}>
            {
                getWeekDaysWithAdditionSchedule(daySchedules).map(
                    (weekDay: WeekDay) => (
                        <WeekDayIntervals weekDay={weekDay} key={nanoid()} />
                    ),
                )
            }
        </div>
    );
}
