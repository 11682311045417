import React, { useState } from 'react';

import { ArrowIcon } from '@common/svg/ArrowIcon';

import { Fork, ModuleSettings, Subspace } from '../Config/interfaces';
import { ModuleCards } from '../ModuleCard';
import { InfoCard } from './InfoCard';

import classes from './ChoicePoint.module.scss';
import { getModuleDeclension } from '.';

interface Props {
    fork: Fork;
    subspace: Subspace;
    moduleSettings?: ModuleSettings;
    order: number;
    isModuleSelectionTime: boolean;
}

export function DistributionPoint({
    fork,
    subspace,
    moduleSettings,
    order,
    isModuleSelectionTime,
}: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const { moduleSelectionCount } = fork.setting;
    const modules = fork.nextSlots.map(slot => ({ module: slot.module!, subspace }));
    return (
        <>
            <div
                className={classes.choicePoint}
                onClick={() => setIsOpen(!isOpen)}
            >
                <ArrowIcon
                    isOpen={isOpen}
                />
                <div className={classes.choicePoint__title}>
                    {`${order} распределение по модулям. Подпространство: ${subspace.name}, ${getModuleDeclension(modules.length)}`}
                </div>
            </div>
            {isOpen && (
                <>
                    {isModuleSelectionTime && (
                        <InfoCard hasArrow={false}>
                            <div>
                                {`В этом разделе вы будете автоматически 
                                зачислены на ${moduleSelectionCount} из ${modules.length} модулей. `}
                            </div>
                            <div>
                                Распределение будет производится
                                на основе ваших образовательных результатов или рейтинга.
                            </div>
                        </InfoCard>
                    )}
                    <ModuleCards
                        modules={modules}
                        moduleSettings={moduleSettings}
                    />
                </>
            )}
        </>
    );
}

// function getModuleDeclension(count: number) {
//     if (count === 1) {
//         return `${count} модулю`;
//     }
//     return `${count} модулям`;
// }
