import React, { createElement } from 'react';

import { ListUser_users_list } from '../Tables/apollo-types';
import {
    BaseTimeIntervalInstanceButton,
    ImportButtonApollo,
    ExportStudentsRatingButton,
    RoleButtonApollo,
    SkillsButton,
    StatusButtonAppolo,
    ImportStudentsRatingButton,
    ImportStudentsButton,
    ExportStudentsFormButton,
} from './Tools';

export enum Tool {
    STATUS_UPDATE,
    BASE_TIME_INTERVAL_INSTANCE_UPDATE,
    SKILL_UPDATE,
    USER_IMPORT,
    ROLE_UPDATE,
    STUDENT_IMPORT,
    GENERATE_SAMPLE_STUDENT_IMPORT,
    IMPORT_STUDENTS_RATING,
    GENERATE_SAMPLE_IMPORT_STUDENTS_RATING,
}

const toolMap = {
    [Tool.BASE_TIME_INTERVAL_INSTANCE_UPDATE]: BaseTimeIntervalInstanceButton,
    [Tool.STATUS_UPDATE]: StatusButtonAppolo,
    [Tool.SKILL_UPDATE]: SkillsButton,
    [Tool.USER_IMPORT]: ImportButtonApollo,
    [Tool.ROLE_UPDATE]: RoleButtonApollo,
    [Tool.STUDENT_IMPORT]: ImportStudentsButton,
    [Tool.GENERATE_SAMPLE_STUDENT_IMPORT]: ExportStudentsFormButton,
    [Tool.IMPORT_STUDENTS_RATING]: ImportStudentsRatingButton,
    [Tool.GENERATE_SAMPLE_IMPORT_STUDENTS_RATING]: ExportStudentsRatingButton,
};

interface Props {
    tools: Tool[];
    selectedUsers: ListUser_users_list[];
    refetchUserList: () => void;
}

export const ToolBar = ({ tools, ...rest }: Props) => (
    <>
        {
            tools.map(tool => createElement(
                toolMap[tool],
                {
                    key: tool,
                    ...rest,
                },
            ))
        }
    </>
);
