import React from 'react';
import {
    Fork,
    GroupSlot,
    ModuleSlot,
    SpaceBaseTimeInterval,
    Subspace, SubspaceType,
    SubspaceTypeLayout,
    UpdateSlotDiagramInput,
} from '../subSpaceTypes';
import { SlotAndModuleParameters } from '../SlotAndModuleParameters';
import { SubSpaceSlotDiagram } from '../SubSpaceSlotDiagram';

import { skills_skills, skillTypesData_skillTypes } from '../../graphql-query-types';

interface Props {
    subspaceTypeLayouts: SubspaceTypeLayout[];
    subspace: Subspace;
    subspaceType: SubspaceType;
    subSpaceSlots: ModuleSlot[];
    skillTypes: skillTypesData_skillTypes[];
    subSpaceGroupSlots: GroupSlot[];
    subSpaceForks: Fork[]
    allSkillsList: skills_skills[];

    updateSlotDiagram(updateSlotDiagramInput: UpdateSlotDiagramInput): void
}

export function SubSpaceSlotSettings({
    subspaceTypeLayouts,
    subspace,
    subSpaceSlots,
    subSpaceGroupSlots,
    subSpaceForks,
    subspaceType,
    skillTypes,
    allSkillsList,
    updateSlotDiagram,
}: Props): JSX.Element {
    const spaceBaseTimeIntervals: SpaceBaseTimeInterval[] = subspaceTypeLayouts
        .map(layout => ({
            id: layout.spaceBaseTimeInterval.id,
            order: layout.spaceBaseTimeInterval.order,
            minCreditCount: layout.spaceBaseTimeInterval.minCreditCount,
            maxCreditCount: layout.spaceBaseTimeInterval.maxCreditCount,
            subspaceTypeLayouts: layout.spaceBaseTimeInterval.subspaceTypeLayouts,
            space: layout.spaceBaseTimeInterval.space,
        }));
    return (
        <>
            <SubSpaceSlotDiagram
                spaceBaseTimeIntervals={spaceBaseTimeIntervals}
                updateSlotDiagram={updateSlotDiagram}
                subSpaceSlots={subSpaceSlots}
                subSpaceGroupSlots={subSpaceGroupSlots}
                subSpaceForks={subSpaceForks}
                subspace={subspace}
                subspaceType={subspaceType}
            />
            <SlotAndModuleParameters
                subspace={subspace}
                subSpaceSlots={subSpaceSlots}
                skillTypes={skillTypes}
                allSkillsList={allSkillsList}
                updateSlotDiagram={updateSlotDiagram}
            />
        </>
    );
}
