import React, { ChangeEvent } from 'react';
import { Parameter } from '../Parameter';
import classes from './WorkLoad.module.scss';

interface Props {
    maxHoursPerDay: number,
    updateMaxHoursPerDay(e: ChangeEvent<HTMLInputElement>): void,
    maxDaysPerWeek: number,
    updateMaxDaysPerWeek(e: ChangeEvent<HTMLInputElement>): void,
    maxHoursPerSpaceBaseTimeInterval: number,
    updateMaxHoursPerSpaceBaseTimeInterval(e: ChangeEvent<HTMLInputElement>): void,
    baseTimeIntervalTypeName: string,
}

export function WorkLoad({
    maxHoursPerDay,
    updateMaxHoursPerDay,
    maxDaysPerWeek,
    updateMaxDaysPerWeek,
    maxHoursPerSpaceBaseTimeInterval,
    updateMaxHoursPerSpaceBaseTimeInterval,
    baseTimeIntervalTypeName,
}: Props) {
    return (
        <div className={classes.workLoad}>
            <div className={classes.workLoad__title}>
                Занятость студента:
            </div>
            <Parameter
                value={maxHoursPerDay}
                title="max количество часов в день"
                onChange={updateMaxHoursPerDay}
            />
            <Parameter
                value={maxDaysPerWeek}
                title="max количество дней в неделю"
                onChange={updateMaxDaysPerWeek}
            />
            <Parameter
                value={maxHoursPerSpaceBaseTimeInterval}
                title={`max количество часов в ${baseTimeIntervalTypeName.toLowerCase()}`}
                onChange={updateMaxHoursPerSpaceBaseTimeInterval}
            />
        </div>
    );
}
