import React, { useState } from 'react';

import { ModuleTabs } from '../ModuleTabs';
import { ModuleTabsContent } from '../ModuleTabsContent';

import classes from './ModuleContent.module.scss';

import {
    Module as ModuleType,
} from '../../subSpaceTypes';
import { skills_skills, skillTypesData_skillTypes } from '../../../graphql-query-types';

interface Props {
    currentModule: ModuleType;
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
}

export function ModuleContent({
    currentModule,
    skillTypes,
    allSkillsList,
}: Props):JSX.Element {
    const [activeTabs, setActiveTabs] = useState('first');
    return (
        <div className={classes.moduleContent}>
            <ModuleTabs
                activeTabs={activeTabs}
                setActiveTabs={setActiveTabs}
            />
            <ModuleTabsContent
                activeTabs={activeTabs}
                currentModule={currentModule}
                skillTypes={skillTypes}
                allSkillsList={allSkillsList}
            />
        </div>
    );
}
