import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { orderBy } from 'lodash';

import { ArrowSvg } from '@admin/ScheduleGenerationPage/FiltrationComponents/ArrowSvg';
import { MultiAutosuggest } from '@common/MultiAutosuggest';
import {
    GetModuleFilters_module,
    GetModuleFilters_moduleWavesByBaseTimeIntervalInstanceId,
    GetModuleFilters_moduleWavesByBaseTimeIntervalInstanceId_students,
} from './graphql-types';
import { Switcher } from './Switcher';
import { EvaluationPointOption, StudentOption, WaveOption } from './ModuleStatisticsTypes';
import { SkillTypeSelect } from './SkillTypeSelect';
import { ModuleStatisticsTable } from './ModuleStatisticsTable';

import classes from './ModuleStatistics.module.scss';

interface Props {
    isDetailedModeOn: boolean;
    isDetailedModeEnabled: boolean;
    module: GetModuleFilters_module;
    waves: GetModuleFilters_moduleWavesByBaseTimeIntervalInstanceId[];
    students: GetModuleFilters_moduleWavesByBaseTimeIntervalInstanceId_students[];
    selectedEvaluationPoints: EvaluationPointOption[];
    selectedWaves: WaveOption[];
    selectedStudents: StudentOption[];
    onDetailedModeActivityToggle(): void;
    handleGoBack(): void;
    onEvaluationPointChange(options: EvaluationPointOption[]): void;
    onWaveChange(options: WaveOption[]): void;
    onStudentChange(options: StudentOption[]): void;
}

export function ModuleStatistics({
    isDetailedModeOn,
    isDetailedModeEnabled,
    module,
    waves,
    students,
    selectedEvaluationPoints,
    selectedWaves,
    selectedStudents,
    onDetailedModeActivityToggle,
    handleGoBack,
    onEvaluationPointChange,
    onWaveChange,
    onStudentChange,
}: Props) {
    const { id: moduleId, name, evaluationPoints: unOrderedEvaluationPoints } = module;
    const evaluationPoints = orderBy(unOrderedEvaluationPoints, 'order');
    const moduleLink = `/module/public/${moduleId}`;

    return (
        <div className={classes['module-statistics__container']}>
            <div className={classes['module-statistics__back']} onClick={handleGoBack}>
                <ArrowSvg />
                <p className={classes['module-statistics__back-title']}>
                    К списку модулей
                </p>
            </div>
            <Link
                className={classes['module-statistics__title']}
                to={moduleLink}
                target="_blank"
            >
                {name}
            </Link>
            <ModuleStatisticsTabs />
            <div className={classes['module-statistics__filters']}>
                <SkillTypeSelect />
                <div className={cn(classes['module-statistics__mode-switcher'],
                    {
                        [classes['module-statistics__mode-switcher_disabled']]: !isDetailedModeEnabled,
                    })}
                >
                    <Switcher
                        onClick={onDetailedModeActivityToggle}
                        checked={isDetailedModeOn}
                        label="Детализация по образовательным результатам"
                    />
                </div>
            </div>
            <div className={classes['module-statistics__filters']}>
                <div className={classes['module-statistics__filter']}>
                    <span className={classes['module-statistics__filter-label']}>
                        Точки оценки
                    </span>
                    <MultiAutosuggest
                        placeholder="Выберите точку оценки"
                        onChange={onEvaluationPointChange}
                        options={evaluationPoints}
                        selectedOptions={selectedEvaluationPoints}
                        formatOption={formatOption}
                        isSuggestableOnFocus
                    />
                </div>
                <div className={classes['module-statistics__filter']}>
                    <span className={classes['module-statistics__filter-label']}>
                        Потоки
                    </span>
                    <MultiAutosuggest
                        placeholder="Выберите поток"
                        onChange={onWaveChange}
                        options={waves}
                        selectedOptions={selectedWaves}
                        formatOption={formatWaveOption}
                        isSuggestableOnFocus
                    />
                </div>
                <div className={classes['module-statistics__filter']}>
                    <span className={classes['module-statistics__filter-label']}>
                        ФИО студента
                    </span>
                    <MultiAutosuggest
                        placeholder="Укажите Ф.И.О."
                        onChange={onStudentChange}
                        options={students}
                        selectedOptions={selectedStudents}
                        formatOption={formatStudentOption}
                        isSuggestableOnFocus
                    />
                </div>
            </div>
            <ModuleStatisticsTable
                isDetailedModeOn={isDetailedModeOn}
                moduleLink={moduleLink}
            />
        </div>
    );
}

function formatOption(option: EvaluationPointOption) {
    return option.topic!;
}

function formatWaveOption(option: WaveOption) {
    return `${option.index! + 1} поток`;
}

function formatStudentOption({ user }: StudentOption) {
    return `${user.lastName} ${user.firstName} ${user.patronymic}`;
}

function ModuleStatisticsTabs() {
    return (
        <div className={classes['module-statistics__tabs']}>
            <ul className={classes['module-statistics-tabs__list']}>
                <li className={classes['module-statistics-tabs__item']}>
                    <div className={cn(
                        classes['module-statistics-tab__link'],
                        classes['module-statistics-tab__link_selected'],
                    )}
                    >
                        Журнал оценок
                    </div>
                </li>
                <li className={classes['module-statistics-tabs__item']}>
                    <div className={cn(
                        classes['module-statistics-tab__link'],
                        classes['module-statistics-tab__link_disabled'],
                    )}
                    >
                        Общая статистика
                    </div>
                </li>
            </ul>
        </div>
    );
}
