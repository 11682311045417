import React, { useState } from 'react';
import cn from 'classnames';

import { ExclamationPoint } from '@common/svg';
import { ArrowIcon } from '@common/svg/ArrowIcon';

import classes from './ChoicePoint.module.scss';

interface Props {
    children?: React.ReactNode;
    hasExclamantion?: boolean;
    hasArrow?: boolean;
    title?: string;
}

export function InfoCard({
    children,
    title,
    hasExclamantion = false,
    hasArrow = true,
}: Props) {
    const [isShow, setIsShow] = useState(!hasArrow);
    return (
        <div
            className={cn(classes.infoCard, {
                [classes.infoCard__whithArrow]: hasArrow && !isShow,
            })}
            onClick={() => hasArrow && setIsShow(!isShow)}
        >
            <div className={classes.infocard__title}>
                {title}
            </div>
            {hasExclamantion && <ExclamationPoint className={classes.infoCard__alertIcon} />}
            {isShow && (
                <div className={classes.infoCard__text}>
                    {children}
                </div>
            )}
            {hasArrow && (
                <ArrowIcon
                    isOpen={isShow}
                    onClick={() => setIsShow(!isShow)}
                    className={classes.infoCard__arrow}
                />
            )}
        </div>
    );
}
