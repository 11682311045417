import { Input } from '@common/Input';
import React from 'react';
import classes from './RoomTitle.module.scss';

interface TitleProps {
    handleRoomTitle(event: React.ChangeEvent<HTMLInputElement>): void;
    title: number | string
}

export function RoomTitle({
    handleRoomTitle, title,
}: TitleProps): JSX.Element {
    return (
        <div className={classes.roomTitle}>
            <Input
                value={title}
                name="room"
                onChange={handleRoomTitle}
                placeholder="Введите номер помещения"
                label="Номер помещения"
            />
        </div>
    );
}
