import React from 'react';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { capitalize, map } from 'lodash';
import { observer } from 'mobx-react-lite';
import { SlotParametersListItem } from '../SlotParametersListItem';
import { SubspaceSkill } from '../subSpaceTypes';
import { skillTypesData_skillTypes } from '../../graphql-query-types';
import { filterSubspaceSpecializedSkillsBySkillType } from '../SlotParameters/utilities';
import Diagram from '../store/Diagram';
import classes from './SlotParametersList.module.scss';

interface Props {
    skillTypes: skillTypesData_skillTypes[]
    prerequisiteSkills: SubspaceSkill[]
    outputSkills: SubspaceSkill[]
    rootSkillTypes: skillTypesData_skillTypes[]
    prerequisiteAndOutputGroupNames: string[]
}

export const SlotParametersList = observer(({
    skillTypes,
    prerequisiteSkills,
    outputSkills,
    rootSkillTypes,
    prerequisiteAndOutputGroupNames,
}: Props): JSX.Element => {
    const [prerequisiteGroupName, outputGroupName] = prerequisiteAndOutputGroupNames;
    return (
        <div className={classes.slotParametersList}>
            <div className={classes.prerequisiteAndOutputGroups}>
                <div className={classes.prerequisiteGroup}>
                    <p className={classes.prerequisiteAndOutputGroups__name}>
                        {capitalize(prerequisiteGroupName)}
                    </p>
                    {map(rootSkillTypes, skillType => (

                        <SlotParametersListItem
                            skillType={skillType}
                            skillsList={filterSubspaceSpecializedSkillsBySkillType(
                                prerequisiteSkills,
                                skillType,
                                skillTypes,
                            )}
                        />
                    ))}

                </div>
                <div className={classes.prerequisiteGroup}>
                    <p className={classes.prerequisiteAndOutputGroups__name}>
                        {capitalize(outputGroupName)}
                    </p>
                    {map(rootSkillTypes, skillType => (
                        <SlotParametersListItem
                            skillType={skillType}
                            skillsList={filterSubspaceSpecializedSkillsBySkillType(
                                outputSkills,
                                skillType,
                                skillTypes,
                            )}
                        />
                    ))}
                </div>
            </div>
            <div className={classes.slotParameters__button}>
                <ActionButton
                    type="button"
                    actionType={ActionTypeOfButton.SECONDARY}
                    onClick={() => Diagram.setEditSlotParametersMode()}
                >
                    Редактировать параметры
                </ActionButton>
            </div>

        </div>
    );
});
