import React, { useState } from 'react';
import { SelectedModule } from '../SelectedModule';
import { SuitableModules } from '../SuitableModules';

import {
    Module as ModuleType,
    UpdateModuleInput,
    UpdateSlotDiagramInput,
} from '../subSpaceTypes';
import { skills_skills, skillTypesData_skillTypes } from '../../graphql-query-types';

interface Props {
    subspaceId: string
    slotId: string
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
    selectedModule: ModuleType;
    updateSlotModule: (item: UpdateModuleInput) => void;
    updateSlotDiagram(moduleSlotInput: UpdateSlotDiagramInput): void
}

export function Modules({
    subspaceId,
    slotId,
    skillTypes,
    allSkillsList,
    selectedModule,
    updateSlotModule,
    updateSlotDiagram,
}: Props): JSX.Element {
    const [moduleState, setModuleState] = useState(true);
    return (
        <>
            {selectedModule && moduleState
                ? (
                    <SelectedModule
                        subspaceId={subspaceId}
                        slotId={slotId}
                        skillTypes={skillTypes}
                        allSkillsList={allSkillsList}
                        selectedModule={selectedModule}
                        updateSlotModule={updateSlotModule}
                        setModuleState={setModuleState}
                        updateSlotDiagram={updateSlotDiagram}
                    />
                ) : (
                    <SuitableModules
                        subspaceId={subspaceId}
                        slotId={slotId}
                        skillTypes={skillTypes}
                        allSkillsList={allSkillsList}
                        updateSlotModule={updateSlotModule}
                        setModuleState={setModuleState}
                        updateSlotDiagram={updateSlotDiagram}
                    />
                )
            }
        </>
    );
}
