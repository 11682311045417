import React, { useState } from 'react';

import { TittleAndSettings } from './TittleAndSettings';

import { PrerequisiteAndOutputParams } from './PrerequisiteAndOutputParams';
import { EditPrerequisiteAndOutputParams } from './EditPrerequisiteAndOutputParams';

import classes from './SubSpaceOptions.module.scss';

import {
    Subspace,
    SubspaceType,
    SubspaceSkill,
    Space,
} from '../subSpaceTypes';

import {
    skills_skills,
    skillTypesData_skillTypes,
} from '../../graphql-query-types';

// import { array } from './mockParamsList';

interface Props {
    editState: boolean;
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
    space: Space;
    subspace: Subspace;
    subspaceType: SubspaceType;
    setEditState: (flag: boolean) => void;
    updateSubspace: (
        id: string,
        minStudentCount: number,
        maxStudentCount: number,
        prerequisiteSpecializedSkills?: SubspaceSkill[],
        outputSpecializedSkills?: SubspaceSkill[],
        description?: string | null,
    ) => void;
}

export function SubSpaceOptions({
    editState,
    skillTypes,
    allSkillsList,
    space,
    subspace,
    subspaceType,
    setEditState,
    updateSubspace,
}: Props): JSX.Element {
    const sortedSkillsArray: any = skillTypes
        .filter((skill: any) => skill.parentId === null);
    const [minStudentCount, setMinStudentCount] = useState<number>(subspace.minStudentCount);
    const [maxStudentCount, setMaxStudentCount] = useState<number>(subspace.maxStudentCount);
    const [
        description,
        setDescription,
    ] = useState<any>(subspace.description);
    const [
        prerequisiteSkills,
        setPrerequisiteSkills,
    ] = useState<any>(subspace.prerequisiteSpecializedSkills);
    const [
        outputSkills,
        setOutputSkills,
    ] = useState<any>(subspace.outputSpecializedSkills);
    const prerequisiteSkillsForBase = prerequisiteSkills.map((item: any) => (
        {
            skill: {
                id: item.skill.id,
                name: item.skill.name,
                typeId: item.skill.typeId,
            },
            level: item.level,
        }
    ));
    const outputSkillsForBase = outputSkills.map((item: any) => (
        {
            skill: {
                id: item.skill.id,
                name: item.skill.name,
                typeId: item.skill.typeId,
            },
            level: item.level,
        }
    ));
    const saveSubspaceParams = () => {
        updateSubspace(
            subspace.id,
            Number(minStudentCount),
            Number(maxStudentCount),
            prerequisiteSkillsForBase,
            outputSkillsForBase,
            description,
        );
        setEditState(!editState);
    };
    return (
        <div className={classes.subSpaceOptions}>
            <TittleAndSettings
                editState={editState}
                minStudentCount={minStudentCount}
                maxStudentCount={maxStudentCount}
                setMinStudentCount={setMinStudentCount}
                setMaxStudentCount={setMaxStudentCount}
                description={description}
                setDescription={setDescription}
                space={space}
                subspace={subspace}
                subspaceType={subspaceType}
            />
            {/* <ul className={classes.subSpaceOptions__commonParamsList}>
                <li className={classes.subSpaceOptions__commonParamsItem}>
                    <SubSpaceParamsList
                        paramsType="common"
                        skillsParamsType="prerequisite"
                        given={0}
                        allSkillsList={allSkillsList}
                        paramsList={array}
                        sortedSkillTypes={sortedSkillsArray}
                        notSortedSkillTypes={skillTypes}
                    />
                </li>
                <li className={classes.subSpaceOptions__commonParamsItem}>
                    <SubSpaceParamsList
                        paramsType="common"
                        skillsParamsType="output"
                        given={1}
                        allSkillsList={allSkillsList}
                        paramsList={array}
                        sortedSkillTypes={sortedSkillsArray}
                        notSortedSkillTypes={skillTypes}
                    />
                </li>
            </ul> */}
            {editState
                ? (
                    <EditPrerequisiteAndOutputParams
                        sortedSkillTypes={sortedSkillsArray}
                        notSortedSkillTypes={skillTypes}
                        profilePrerequisiteSkillsList={prerequisiteSkills}
                        profileOutputSkillsList={outputSkills}
                        allSkillsList={allSkillsList}
                        setPrerequisiteProfileSkills={setPrerequisiteSkills}
                        setOutputProfileSkills={setOutputSkills}
                        saveSubspaceParams={saveSubspaceParams}
                    />
                ) : (
                    <PrerequisiteAndOutputParams
                        editState={editState}
                        setEditState={setEditState}
                        subspace={subspace}
                        skillTypes={skillTypes}
                        sortedSkillTypes={sortedSkillsArray}
                        allSkillsList={allSkillsList}
                    />
                )
            }
        </div>
    );
}
