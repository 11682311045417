import React, { useState } from 'react';

import { Switcher } from '@common/Switcher';

import { CurrentProfileParams } from './CurrentProfileParams';

import classes from './ProfileParams.module.scss';

import {
    skills_skills,
    skillTypesData_skillTypes,
} from '../../../graphql-query-types';

interface Skill {
    id: string
    name: string
    fullName: string
    typeId: string
    maxLevel?: number
    description?: string
    children?: Skill[]
    dependencies?: Skill[]
}
interface SubspaceSkill {
    skill: Skill
    level: number
}

interface Props {
    skillsParamsType: string;
    sortedSkillTypes: skillTypesData_skillTypes[];
    notSortedSkillTypes: skillTypesData_skillTypes[];
    profileSkillsList: SubspaceSkill[];
    allSkillsList: skills_skills[];
    setProfileSkills: (arr: SubspaceSkill[]) => void;
    number: number;
}

export function ProfileParams({
    skillsParamsType,
    sortedSkillTypes,
    notSortedSkillTypes,
    profileSkillsList,
    allSkillsList,
    setProfileSkills,
    number,
}: Props): JSX.Element {
    const [checkSwitcher, setCheckSwitcher] = useState(false);
    return (
        <div className={classes.subSpaceProfileParams}>
            <div className={classes.subSpaceProfileParams__switcher}>
                <Switcher
                    label={`Добавить профильные ${skillsParamsType === 'prerequisite' ? 'входные' : 'выходные'} параметры подпространства`}
                    onClick={() => setCheckSwitcher(!checkSwitcher)}
                    checked={!checkSwitcher}
                />
            </div>
            {!checkSwitcher && (
                <ul className={classes.subSpaceProfileParams__list}>
                    {sortedSkillTypes.map((item: skillTypesData_skillTypes, index: number) => (
                        <li
                            key={item.id}
                            className={classes.subSpaceProfileParams__item}
                        >
                            <CurrentProfileParams
                                currentSkillType={item}
                                notSortedSkillTypes={notSortedSkillTypes}
                                forSug={index + number}
                                profileSkillsList={profileSkillsList}
                                allSkillsList={allSkillsList}
                                setProfileSkills={setProfileSkills}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
