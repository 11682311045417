import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { CheckBox, IconDeprecated } from '@common';
import { Meeting as MeetingComponent } from '@admin/NewModule/Meeting';
import { Assignment as AssignmentComponent } from '@admin/NewModule/Assignment';
import { BorderPlusIcon } from '@common/svg';
import { EventWithoutTest, moduleStore } from '@admin/NewModule/Store';
import { State } from './ActionButton';

import classes from './EventsList.module.scss';

interface EventWithoutTestProps {
    event: EventWithoutTest;
    canUp: boolean;
    canDown: boolean;
    canBeChosen: boolean;
    state: State;
    opened: string;

    setOpened(id: string): void;

    setState(state: State): void;

    setEventRef({
        ref, id,
    }: { ref: React.MutableRefObject<HTMLDivElement | null>, id: string }): void;
}

const EventWithoutTestComponent = observer(({
    event,
    canUp,
    canDown,
    canBeChosen,
    setEventRef,
    state,
    setState,
    opened,
    setOpened,
}: EventWithoutTestProps) => {
    const module = moduleStore.moduleModel;
    const { id, isChosen, isEditable } = event;

    const independentEvents = [
        ...module.eventsWithoutTest,
        ...module.evaluationPoints.filter(point => !point.previousEvent?.id),
    ];

    const getNextEventOrderWithoutTest = (eventOrder: number): number => {
        const futureEvents = independentEvents.filter(({ order }) => order > eventOrder);
        return futureEvents.reduce((acc, cur) => Math.min(acc, cur.order), Number.MAX_SAFE_INTEGER);
    };
    const getPreviousEventOrderWithoutTest = (eventOrder: number): number => {
        const prevEvents = independentEvents.filter(({ order }) => order < eventOrder);
        return prevEvents.reduce((acc, cur) => Math.max(acc, cur.order), Number.MIN_SAFE_INTEGER);
    };

    const eventRef = useRef<null | HTMLDivElement>(null);
    setEventRef({ ref: eventRef, id: event.id });

    const stateOfEditConnections = state === State.EditEventConnections;
    const stateOfChoseEventForEditConnections = state === State.ChooseEventForEditConnection;

    return (
        <div className={classes.eventsList__event} ref={eventRef}>
            <div className={classnames({
                [classes.eventsList__event_transparent]: !isChosen && !isEditable
                    && stateOfEditConnections,
            })}
            >
                {moduleStore.isMeeting(event) && (
                    <MeetingComponent
                        meeting={event}
                        opened={opened === event.id}
                        setOpened={setOpened}
                    />
                )}
                {moduleStore.isAssignment(event) && (
                    <AssignmentComponent
                        assignment={event}
                        opened={opened === event.id}
                        setOpened={setOpened}
                    />
                )}
            </div>
            {stateOfChoseEventForEditConnections && (
                <BorderPlusIcon
                    handler={() => {
                        module.choseEventForEditConnections(id);
                        setState(State.EditEventConnections);
                    }}
                    className={classes.eventsList__icon}
                />
            )}
            {stateOfEditConnections && !isEditable && canBeChosen && (
                <div className={classes.eventsList__icon}>
                    <CheckBox
                        id={id}
                        checked={isChosen}
                        handleChangeCheckBox={() => module.updateChosenStateOfEvent(
                            id, !isChosen,
                        )}
                    />
                </div>
            )}
            {stateOfEditConnections && isEditable && (
                <IconDeprecated
                    id="yellowCross"
                    click={() => {
                        module.cancelStateOfEditConnections();
                        setState(State.Start);
                    }}
                    className={classes.eventsList__icon}
                />
            )}
            {!stateOfChoseEventForEditConnections && !stateOfEditConnections && opened === '' && (
                <div className={classes.eventsList__arrows}>
                    {canUp && (
                        <IconDeprecated
                            id="yellowArrowUp"
                            click={() => module.changeOrder(
                                id, getPreviousEventOrderWithoutTest(event.order),
                            )}
                            className={classnames(classes.eventsList__icon, {
                                [classes.eventsList__wideIcon]: !canDown,
                            })}
                        />
                    )}
                    {canDown && (
                        <IconDeprecated
                            id="yellowArrowDown"
                            click={() => module.changeOrder(
                                id, getNextEventOrderWithoutTest(event.order),
                            )}
                            className={classnames(classes.eventsList__icon, {
                                [classes.eventsList__wideIcon]: !canUp,
                            })}
                        />
                    )}
                </div>
            )}
        </div>
    );
});

export { EventWithoutTestComponent as EventWithoutTest };
