import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';

import { Alert } from '@common/Alert';
import { Loader } from '@common/Loader';
import { NewModule as NewModuleComponent } from './NewModule';
import { moduleStore, dictionaryStore } from './Store';
import { GET_MODULES, GET_MODULE_DRAFTS } from './ModulesPage/ModulesPageApollo';

const MODULE_SKILL = `
    skill {
        id
        name
        fullName
        typeId
        maxLevel
    }
    level 
`;

const MODULE_EQUIPMENT = `
    equipment {
        id
        name
        count
    }
    count
`;

const MODULE_CONSUMABLE_EQUIPMENT = `
    consumableEquipment {
        id
        name
        unit
    }
    count
`;

export const MODULE_INFO = `
    id
    name
    description
    minStudentCount
    maxStudentCount
    maxWaveCount
    teachersRoles {
        id
        name
    }
    moduleTeachers {
        teacher {
            id
            user {
                firstName
                lastName
                patronymic
            }
        }
        teacherRoles {
            id
            name
        }
    }
    prerequisiteSkills {
        ${MODULE_SKILL}
    }
    outputSkills {
        ${MODULE_SKILL}
    }
    meetings {
        id
        topic
        format {
            id
            name
        }
        isOnline
        isOutside
        duration {
            id
            name
            academicHours
        }
        isManyWaves
        minStudentCount
        maxStudentCount
        equipmentPerMeeting {
            ${MODULE_EQUIPMENT}
        }
        equipmentPerStudent {
            ${MODULE_EQUIPMENT}
        }
        consumableEquipmentPerMeeting {
            ${MODULE_CONSUMABLE_EQUIPMENT}
        }
        consumableEquipmentPerStudent {
            ${MODULE_CONSUMABLE_EQUIPMENT}
        }
        prerequisiteSkills {
            ${MODULE_SKILL}
        }
        outputSkills {
            ${MODULE_SKILL}
        }
        meetingTeacherRoles {
            teacherRole {
                id
                name
            }
            count
        }
        isPreviousEventInRow
        isNextEventInRow
        minDaysToPreviousEvent
        maxDaysToPreviousEvent
        minDaysToNextEvent
        maxDaysToNextEvent
        order
        meetingIdForTeacherStaff
        dependentEvents {
            id
            type
        }
    }
    evaluationPoints {
        id
        topic
        description
        previousEvent {
            id
            type
        }
        duration {
            id
            name
            academicHours
        }
        evaluationPointSkills {
            ${MODULE_SKILL}
        }
        evaluationPointTeacherRoles {
            teacherRole {
                id
                name
            }
            count
        }
        order
    }
    assignments {
        id
        topic
        description
        duration {
            id
            name
            academicHours
        }
        prerequisiteSkills {
            ${MODULE_SKILL}
        }
        outputSkills {
            ${MODULE_SKILL}
        }
        isPreviousEventInRow
        isNextEventInRow
        minDaysToPreviousEvent
        maxDaysToPreviousEvent
        minDaysToNextEvent
        maxDaysToNextEvent
        order
        dependentEvents {
            id
            type
        }
    }
`;

const GET_OPTIONS = `
    moduleSettings{
        moduleSkillTypes {
            id
            isLevelIncreased
        }
        meetingSkillTypes {
            id
            isLevelIncreased
        }
    }

    equipmentRequestMessage {
        message
    }

    skillTypes {
        id
        name {
            nominativeSingular
            genitiveSingular
            nominativePlural
            genitivePlural
            english
        }
        properties {
            maxLevel
        }
        parentId
    }

    skills {
        id
        name
        fullName
        maxLevel
        typeId
    }

    teachers {
        id
        user {
            firstName
            lastName
            patronymic
        }
    }

    equipments (equipmentsInput: {}) {
        id
        name
        count
    }

    durations {
        id
        name
        academicHours
    }

    meetingFormats {
        id
        name
    }
    
    consumableEquipment {
        id
        name
        unit
    }
`;

const GET_MODULE_ID_INFO = gql`
    query GetModuleAndSettings ($id: String!) {
        module(id: $id) {
            ${MODULE_INFO}
        }
        ${GET_OPTIONS}
    }
`;

const GET_MODULE_DRAFT_ID_INFO = gql`
    query GetModuleAndSettings ($id: String!) {
        moduleDraft(id: $id) {
            ${MODULE_INFO}
        }
        ${GET_OPTIONS}
    }
`;

const GET_MODULE_INFO = gql`
    query GetModuleSettings {
        ${GET_OPTIONS}
    }
`;

const CREATE_MODULE = gql`
    mutation CreateModule($newModule: CreateModuleInput!) {
        createModule(createModuleInput: $newModule) {
            ${MODULE_INFO}
        }
    }
`;

const UPDATE_MODULE = gql`
    mutation UpdateModule($newModule: UpdateModuleInput!) {
        updateModule(updateModuleInput: $newModule) {
            ${MODULE_INFO}
        }
    }
`;

const CREATE_MODULE_DRAFT = gql`
    mutation CreateModuleDraft($newModule: CreateModuleDraftInput!) {
        createModuleDraft(createModuleDraftInput: $newModule) {
            ${MODULE_INFO}
        }
    }
`;

const UPDATE_MODULE_DRAFT = gql`
    mutation UpdateModuleDraft($newModule: UpdateModuleDraftInput!) {
        updateModuleDraft(updateModuleDraftInput: $newModule) {
            ${MODULE_INFO}
        }
    }
`;

interface Props {
    path: string;
    isDraft: boolean
}

const GetModuleInfo = ({ path, isDraft }: Props): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const apolloQuery = id ? useQuery(
        isDraft ? GET_MODULE_DRAFT_ID_INFO : GET_MODULE_ID_INFO, {
            fetchPolicy: 'no-cache',
            variables: { id },
        },
    ) : useQuery(GET_MODULE_INFO);

    useEffect(() => {
        moduleStore.restore();
        dictionaryStore.updateStaticOptions(apolloQuery.data);
        const module = apolloQuery.data?.module || apolloQuery.data?.moduleDraft;
        if (module) {
            moduleStore.moduleModel.updateModule(
                module,
                module.meetings,
                module.assignments,
                module.evaluationPoints,
            );
        }
    }, [apolloQuery.data]);
    const history = useHistory();

    const [createModule, {
        data: dataCreate, error: errorCreate, loading: loadingCreate,
    }] = useMutation(CREATE_MODULE, {
        refetchQueries: [
            { query: GET_MODULES },
            { query: GET_MODULE_DRAFTS },
        ],
    });
    const [updateModule, {
        data: dataUpdate, error: errorUpdate, loading: loadingUpdate,
    }] = useMutation(UPDATE_MODULE, {
        refetchQueries: [
            { query: GET_MODULES },
            { query: GET_MODULE_DRAFTS },
        ],
    });
    const [createModuleDraft, {
        data: dataCreateDraft, error: errorCreateDraft, loading: loadingCreateDraft,
    }] = useMutation(
        CREATE_MODULE_DRAFT, {
            refetchQueries: [
                { query: GET_MODULE_DRAFTS },
                { query: GET_MODULES },
            ],
        },
    );
    const [updateModuleDraft, {
        data: dataUpdateDraft, error: errorUpdateDraft, loading: loadingUpdateDraft,
    }] = useMutation(
        UPDATE_MODULE_DRAFT, {
            refetchQueries: [
                { query: GET_MODULE_DRAFTS },
                { query: GET_MODULES },
            ],
        },
    );
    if (apolloQuery.loading) return <Loader />;
    if (apolloQuery.error) return <>{`Error! ${apolloQuery.error.message}`}</>;
    if (dataCreateDraft || dataUpdateDraft) history.push(`${path}/drafts`);
    if (dataCreate || dataUpdate) history.push(`${path}/public`);

    const sendModule = (newModule: any) => (id && !isDraft
        ? updateModule(newModule) : createModule(newModule)).catch(() => {
    });
    const sendModuleDraft = (newModule: any) => (id
        ? updateModuleDraft(newModule) : createModuleDraft(newModule)).catch(() => {
    });
    const error = errorCreate ?? errorUpdate ?? errorCreateDraft ?? errorUpdateDraft;

    return (
        <>
            <NewModuleComponent
                createModule={
                    newModule => sendModule({ variables: { newModule } }).catch(() => {})
                }
                createModuleDraft={
                    newModule => sendModuleDraft({ variables: { newModule } }).catch(() => {})
                }
                disableButtons={loadingCreate || loadingCreateDraft
                    || loadingUpdate || loadingUpdateDraft}
            />
            {error && <Alert message={error.message} time={5000} />}
        </>
    );
};

export { GetModuleInfo as NewModule };
