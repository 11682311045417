import { gql, useLazyQuery, useMutation } from '@apollo/client';

export enum ButtonName {
    STARTSUBSECTIONSELECTION = 'Запустить выборность подпространств',
    STARTSUBSPACEDISTRIBUTION = 'Запустить предварительное распределение по подпространствам',
    STARTMODULESELECTION = 'Запустить выборность модулей',
    STARTMODULEDISTRIBUTION = 'Запустить предварительное распределение по модулям',
    ENDSUBSECTIONSELECTION = 'Завершить выборность подпространств',
    ENDMODULESELECTION = 'Завершить выборность модулей',
    LOADSUBSPACEDISTRIBUTION = 'Скачать предварительное распределение по подпространствам',
    LOADSTUDENTPRIORITY = 'Скачать приоритеты студентов',
    LOADMODULEDISTRIBUTION = 'Скачать предварительное распределение по модулям',
    APPROVESTUDENTSUBSPACEDISTRIBUTION = 'Подтвердить распределение студентов по подпространствам',
    APPROVESTUDENTMODULEDISTRIBUTION = 'Подтвердить распределение студентов по модулям',
}

enum HandlersName {
    startSubspaceSelectionIteration = 'startSubspaceSelectionIteration',
    finishSubspaceSelectionIteration = 'finishSubspaceSelectionIteration',
    distributeStudentsIntoSubspaces = 'distributeStudentsIntoSubspaces',
    startModuleSelectionIteration = 'startModuleSelectionIteration',
    finishModuleSelectionIteration = 'finishModuleSelectionIteration',
    distributeStudentsIntoModules = 'distributeStudentsIntoModules',
    exportStudentsModulePrioritiesCsv = 'exportStudentsModulePrioritiesCsv',
    exportStudentsSubspaceDistributionCsv = 'exportStudentsSubspaceDistributionCsv',
    exportStudentsModuleDistributionCsv = 'exportStudentsModuleDistributionCsv',
    approveStudentSubspaceDistributionResults = 'approveStudentSubspaceDistributionResults',
    approveStudentModuleDistributionResults = 'approveStudentModuleDistributionResults',
}

const getMutationGraphQlSchema = (heandlerName: HandlersName) => gql`
mutation ${heandlerName}($baseTimeIntervalInstanceId: String!) {
    ${heandlerName}(baseTimeIntervalInstanceId:$baseTimeIntervalInstanceId) {
        baseTimeIntervalInstance {
            selectionStatus
        }
    }
}
`;

const getMutationGraphQlSchemaApprove = (heandlerName: HandlersName) => gql`
mutation ${heandlerName}($baseTimeIntervalInstanceId: String!) {
    ${heandlerName}(baseTimeIntervalInstanceId:$baseTimeIntervalInstanceId)
}`;

const getQueryGraphQlSchema = (heandlerName: HandlersName) => gql`
query ${heandlerName}($baseTimeIntervalInstanceId: String!){
    ${heandlerName}(baseTimeIntervalInstanceId:$baseTimeIntervalInstanceId)
}`;

export const buttonHooks = (
    id: string,
    setFile: React.Dispatch<React.SetStateAction<{
        value: string;
        name: string;
    }>>,
) => {
    const [startSubsectionSelectionMutation, {
        error: startSubsectionSelectionError,
        loading: startSubsectionSelectionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.startSubspaceSelectionIteration),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [endSubsectionSelectionMutation, {
        error: endSubsectionSelectionError,
        loading: endSubsectionSelectionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.finishSubspaceSelectionIteration),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [startSubspaceDistributionMutation, {
        error: startSubspaceDistributionError,
        loading: startSubspaceDistributionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.distributeStudentsIntoSubspaces),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [startModuleSelectionMutation, {
        error: startModuleSelectionError,
        loading: startModuleSelectionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.startModuleSelectionIteration),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [endModuleSelectionMutation, {
        error: endModuleSelectionError,
        loading: endModuleSelectionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.finishModuleSelectionIteration),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [startModuleDistributionMutation, {
        error: startModuleDistributionError,
        loading: startModuleDistributionLoading,
    }] = useMutation(
        getMutationGraphQlSchema(HandlersName.distributeStudentsIntoModules),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [loadSubspaceDistribution, {
        error: loadSubspaceDistributionError,
        loading: loadSubspaceDistributionLoading,
    }] = useLazyQuery(
        getQueryGraphQlSchema(HandlersName.exportStudentsSubspaceDistributionCsv),
        {
            variables: { baseTimeIntervalInstanceId: id },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                setFile({
                    value: data
                        .exportStudentsSubspaceDistributionCsv,
                    name: 'Распределение студентов по подпространствам',
                });
            },
        },
    );

    const [loadStudentPriority, {
        error: loadStudentPriorityError,
        loading: loadStudentPriorityLoading,
    }] = useLazyQuery(
        getQueryGraphQlSchema(HandlersName.exportStudentsModulePrioritiesCsv),
        {
            variables: { baseTimeIntervalInstanceId: id },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                setFile({
                    value: data
                        .exportStudentsModulePrioritiesCsv,
                    name: 'Приоритеты студентов',
                });
            },
        },
    );

    const [
        loadModuleDistribution,
        {
            error: loadModuleDistributionError,
            loading: loadModuleDistributionLoading,
        },
    ] = useLazyQuery(
        getQueryGraphQlSchema(HandlersName.exportStudentsModuleDistributionCsv),
        {
            variables: { baseTimeIntervalInstanceId: id },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                setFile({
                    value: data
                        ?.exportStudentsModuleDistributionCsv,
                    name: 'Student-Module-Distibution',
                });
            },
        },
    );

    const [
        approveStudentSubspaceDistribution,
        {
            error: approveStudentSubspaceDistributionError,
            loading: approveStudentSubspaceDistributionLoading,
        },
    ] = useMutation(
        getMutationGraphQlSchemaApprove(HandlersName.approveStudentSubspaceDistributionResults),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    const [
        approveStudentModuleDistribution,
        {
            error: approveStudentModuleDistributionError,
            loading: approveStudentModuleDistributionLoading,
        },
    ] = useMutation(
        getMutationGraphQlSchemaApprove(HandlersName.approveStudentModuleDistributionResults),
        { variables: { baseTimeIntervalInstanceId: id } },
    );

    return {
        [ButtonName.STARTSUBSECTIONSELECTION]: startSubsectionSelectionMutation,
        [ButtonName.ENDSUBSECTIONSELECTION]: endSubsectionSelectionMutation,
        [ButtonName.STARTSUBSPACEDISTRIBUTION]: startSubspaceDistributionMutation,
        [ButtonName.STARTMODULESELECTION]: startModuleSelectionMutation,
        [ButtonName.ENDMODULESELECTION]: endModuleSelectionMutation,
        [ButtonName.STARTMODULEDISTRIBUTION]: startModuleDistributionMutation,
        [ButtonName.LOADSUBSPACEDISTRIBUTION]: async () => {
            await loadSubspaceDistribution();
        },
        [ButtonName.LOADMODULEDISTRIBUTION]: async () => {
            await loadModuleDistribution();
        },
        [ButtonName.LOADSTUDENTPRIORITY]: async () => {
            await loadStudentPriority();
        },
        [ButtonName.APPROVESTUDENTSUBSPACEDISTRIBUTION]: approveStudentSubspaceDistribution,
        [ButtonName.APPROVESTUDENTMODULEDISTRIBUTION]: approveStudentModuleDistribution,
        error: startModuleDistributionError
            || endModuleSelectionError
            || startModuleSelectionError
            || startSubspaceDistributionError
            || startSubsectionSelectionError
            || endSubsectionSelectionError
            || loadStudentPriorityError
            || loadSubspaceDistributionError
            || loadModuleDistributionError
            || approveStudentSubspaceDistributionError
            || approveStudentModuleDistributionError,
        loading: startSubsectionSelectionLoading
            || endSubsectionSelectionLoading
            || startSubspaceDistributionLoading
            || startModuleSelectionLoading
            || endModuleSelectionLoading
            || startModuleDistributionLoading
            || loadSubspaceDistributionLoading
            || loadStudentPriorityLoading
            || loadModuleDistributionLoading
            || approveStudentSubspaceDistributionLoading
            || approveStudentModuleDistributionLoading,
    };
};
