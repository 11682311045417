import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Alert } from '@common/Alert';
import { Loader } from '@common/Loader';
import { AddRoomButton } from './AddRoomButton';
import { RoomCreationContainer } from './RoomCreation';
import { RoomContainer } from './RoomsList';
import {
    AlertMessage,
    Building,
    RoomBackendData,
    RoomCreationEquipment,
    RoomFormSend,
    RoomMeetingFormat,
    TerritorialZone,
} from './RoomTypes';

import classes from './Rooms.module.scss';

interface RoomPageProps {
    buildings?: Building[];
    allRooms?: RoomBackendData[];
    territorialZoneRooms?: RoomBackendData[];
    meetingFormats?: RoomMeetingFormat[];
    storeEquipment?: RoomCreationEquipment[];
    alert: AlertMessage;
    territorialZones?: TerritorialZone[];
    isLoading: boolean;
    tabsState: [string, React.Dispatch<React.SetStateAction<string>>];
    createRoomHandler(createRoomData: RoomFormSend): void;
    editRoomHandler(editRoomData: RoomFormSend): void;
    deleteRoomHandler(id: string): void;
    getRoomsHandler: () => void;
}

export function RoomsPage({
    buildings,
    allRooms,
    territorialZoneRooms,
    meetingFormats,
    storeEquipment,
    alert,
    territorialZones = [],
    isLoading,
    tabsState,
    createRoomHandler,
    editRoomHandler,
    deleteRoomHandler,
    getRoomsHandler,
}: RoomPageProps): JSX.Element {
    const [newRoom, setNewRoom] = useState<boolean>(false);
    const [changeRoom, setChangeRoom] = useState<boolean>(false);
    const [roomID, setRoomID] = useState<string>('');
    const [rooms, setRooms] = useState(allRooms);
    const [activeTab, setActiveTab] = tabsState;
    useEffect(() => getRoomsHandler(), [activeTab]);

    useEffect(() => {
        if (activeTab === 'all') {
            setRooms(allRooms);
        } else {
            setRooms(territorialZoneRooms);
        }
    }, [activeTab, allRooms, territorialZoneRooms]);

    const addRoomButton = () => {
        setNewRoom((prevStateButton) => !prevStateButton);
    };

    const editRoomButton = (id: string) => {
        if (roomID !== id) {
            setChangeRoom(true);
            setRoomID(id);
        } else {
            setChangeRoom(!changeRoom);
        }
    };

    const tabs = [['all', 'Все'], ...territorialZones?.map(zone => ([zone.id, zone.name]))];

    return (
        <>
            <h2>Помещения университета:</h2>
            <div className={classes.tabs}>
                <ul className={classes.tabs__list}>
                    {tabs.map((tab) => (
                        <li
                            key={tab[0]}
                            className={classes.tabs__item}
                        >
                            <div
                                onClick={() => setActiveTab(tab[0])}
                                className={
                                    cn(classes.tabs__tab, {
                                        [classes.tabs__tab_selected]:
                                            tab[0] === activeTab,
                                    })
                                }
                            >
                                {tab[1]}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {(!newRoom && !changeRoom) && (<AddRoomButton addRoomButton={addRoomButton} />)}
            {isLoading && <Loader />}

            {
                (rooms && meetingFormats && buildings && storeEquipment) && (
                    <>
                        {
                            (newRoom) && (
                                <RoomCreationContainer
                                    buildings={buildings}
                                    meetingFormats={meetingFormats}
                                    storeEquipment={storeEquipment}
                                    createRoomHandler={createRoomHandler}
                                    editRoomHandler={editRoomHandler}
                                    addRoomButton={addRoomButton}
                                />
                            )
                        }
                        <div className={classes.list}>
                            {
                                (rooms.length > 0 && !newRoom) && (
                                    rooms.map((room: RoomBackendData) => (
                                        <RoomContainer
                                            roomID={roomID === room.id}
                                            roomInfo={room}
                                            buildings={buildings}
                                            meetingFormats={meetingFormats}
                                            storeEquipment={storeEquipment}
                                            deleteRoomHandler={deleteRoomHandler}
                                            editRoomHandler={editRoomHandler}
                                            createRoomHandler={createRoomHandler}
                                            addRoomButton={addRoomButton}
                                            editRoomButton={editRoomButton}
                                            key={room.id}
                                        />
                                    ))
                                )
                            }
                            {
                                alert.alert && (<Alert message={alert.message} time={2500} />)
                            }
                        </div>
                    </>
                )
            }
        </>
    );
}
