import React from 'react';
import cn from 'classnames';
import UserView from '@store/UserView';
import { InterfaceSection } from '../../graphql-query-types';
import classes from './ScheduleGenerationPage.module.scss';

export enum ViewMode {
    Common = 'common',
    Table = 'table',
    Statistics = 'statistics',
    AdditionalGeneration = 'additionalGeneration',
}

interface Props {
    viewMode: string;
    isSecret?: boolean;
    setViewMode: (type: ViewMode) => void;
}

export function ScheduleGenerationTabs({ viewMode, isSecret, setViewMode }: Props): JSX.Element {
    const tabs: { tab: ViewMode; name: string; isSecret: boolean; }[] = [];

    const addTabs = (
        tabType: any,
        tabName: string,
        isVisible: boolean,
    ) => {
        tabs.push({
            tab: tabType,
            name: tabName,
            isSecret: isVisible,
        });
    };

    const sections = UserView.user?.availableInterfaceSections;

    sections?.forEach(section => {
        switch (section) {
            case InterfaceSection.Schedule:
                addTabs(ViewMode.Common, 'Общий вид', false);
                break;
            case InterfaceSection.ScheduleTable:
                addTabs(ViewMode.Table, 'Табличный вид', false);
                break;
            case InterfaceSection.ScheduleAdditionalGeneration:
                addTabs(ViewMode.AdditionalGeneration, 'Догенерация', false);
                break;
            case InterfaceSection.ScheduleStatistics:
                addTabs(ViewMode.Statistics, 'Статистика', false);
                break;
            default:
                break;
        }
    });

    return (
        <ul className={classes.scheduleGenerationTabs}>
            {
                tabs
                    .filter(tab => isSecret || !tab.isSecret)
                    .map(({ tab, name }) => (
                        <li className={
                            cn(classes.scheduleGenerationTabs__item, {
                                [classes.scheduleGenerationTabs__item_active]: viewMode === tab,
                            })
                        }
                        >
                            <div
                                className={classes.scheduleGenerationTabs__text}
                                onClick={() => setViewMode(tab)}
                            >
                                {name}
                            </div>
                        </li>
                    ))
            }
        </ul>
    );
}
