import React from 'react';
import { useLazyQuery } from '@apollo/client';

import { scheduleGenerationStore } from '@admin/ScheduleGenerationPage/store';
import { OccupationDashboard } from './OccupationDashboard';
import {
    Occupation,
    OccupationData,
    OccupationInput,
    OccupationParams,
    TeacherOccupationRow,
    TeachersMap,
} from './OccupationDashboardInterfaces';
import { TEACHER_LOAD } from './OccupationDashboardQueries';

export const OccupationDashboardApollo = (): JSX.Element => {
    const [getTeacherLoadByDateRange,
        {
            data: occupationData,
            loading: occupationDataLoading,
            error: occupationError,
        },
    ] = useLazyQuery<OccupationData, OccupationInput>(TEACHER_LOAD, {
        fetchPolicy: 'no-cache',
    });

    function getOccupation(params: OccupationParams) {
        const baseTimeIntervalInstanceId = scheduleGenerationStore.baseTimeIntervalInstance?.id!;
        getTeacherLoadByDateRange({
            variables: {
                teacherLoadInput: {
                    ...params,
                    baseTimeIntervalInstanceId,
                },
            },
        });
    }
    return (
        <OccupationDashboard
            occupation={occupationData?.teacherLoad && createOccupation(occupationData)}
            getOccupation={getOccupation}
            loading={occupationDataLoading}
            occupationError={occupationError}
        />
    );
};

function createOccupation({ teacherLoad }: OccupationData): Occupation {
    const teachersMap: TeachersMap = {};
    teacherLoad.teachers.forEach((teacher) => {
        teachersMap[teacher.id] = teacher;
    });

    const occupationDashboardRows: TeacherOccupationRow[] = [];
    teacherLoad.modules.forEach(
        module => module.teachersRoles.forEach(
            teacherRole => teacherRole.teachers.forEach(
                teacher => occupationDashboardRows.push({
                    moduleId: module.id,
                    teacherRoleId: teacherRole.id,
                    timeslots: teachersMap[teacher.id].timeslots,
                    teacher: {
                        ...teachersMap[teacher.id].user,
                        id: teacher.id,
                    },
                }),
            ),
        ),
    );

    return {
        ...teacherLoad,
        teachersMap,
        occupationDashboardRows,
    };
}
