import { action, makeObservable, observable } from 'mobx';
import { skillTypesData_skillTypes } from '../graphql-query-types';

class SkillType {
    skillTypes: skillTypesData_skillTypes[] = [];

    constructor() {
        makeObservable(this, {
            skillTypes: observable,
            setSkillTypes: action,
        });
    }

    getSkillTypesWithoutParentId() {
        return this.skillTypes.filter((
            skill: skillTypesData_skillTypes,
        ) => skill.parentId === null);
    }

    setSkillTypes(skillTypes: skillTypesData_skillTypes[]): void {
        this.skillTypes = skillTypes;
    }
}

export default new SkillType();
