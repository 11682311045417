import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Icon } from '@admin/NewModule/CustomIcon/Icon';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { AvatarIcon } from '@common/svg';

import classes from './UserCard.module.scss';
import { UserCommonInfo } from './UserCommonInfo';
import { TeacherInfo } from './TeacherInfo';
import { AdminInfo } from './AdminInfo';
import { User } from './models';
import { userCardStore } from '../store/userCardStore';
import { StudentInfoApollo } from './StudentInfo/StudentInfoApollo';

export enum Roles {
    Teacher = 'Teacher',
    Student = 'Student',
    Admin = 'Admin',
}
interface Props {
    userData: User;
    edit: boolean;
    editUser(): void;
    loginUser(userId: string): void;
}

export const UserCard = observer(({
    userData, edit, editUser, loginUser,
}: Props): JSX.Element => {
    const {
        id,
        lastName,
        firstName,
        patronymic,
        birthday,
        email,
        roles = [],
        student,
        teacher,
    } = userData;

    const { pathname } = useLocation();
    const adminPath = (edit)
        ? pathname.split('/').slice(0, -3).join('/')
        : pathname.split('/').slice(0, -2).join('/');
    return (
        <>
            <div className={classes.userCard__navbar}>
                <Link to={adminPath} className={classes.userCard__link}>
                    <Icon id="backIcon" modifier="back" />
                    Пользователи
                </Link>

                <b> \ {lastName} {firstName} {patronymic}</b>
            </div>

            <div className={classes.userCard}>
                <div className={classes.userCard__flex}>
                    <AvatarIcon className={classes.userCard__photo} />

                    <div className={classes.userCard__name}>{`${lastName} ${firstName} ${patronymic} (${id.slice(0, 4).toLowerCase()})`}</div>

                    <div className={classes.userCard__login}>
                        {!localStorage.getItem('token') && (
                            <ActionButton
                                actionType={ActionTypeOfButton.SECONDARY}
                                onClick={() => loginUser(id)}
                            >
                                Залогиниться как этот пользователь
                            </ActionButton>
                        )}
                    </div>

                    <UserCommonInfo
                        birthday={birthday}
                        email={email}
                        roles={roles.filter(role => Object.keys(Roles).includes(role.name))}
                        edit={edit}
                    />

                    {
                        userCardStore.roles.find(role => role.name === 'Student')?.enabled && student && (
                            <StudentInfoApollo student={student} edit={edit} />
                        )
                    }

                    {
                        userCardStore.roles.find(role => role.name === 'Teacher')?.enabled && (
                            <TeacherInfo teacher={teacher!} edit={edit} />
                        )
                    }

                    {
                        userCardStore.roles.find(role => role.name === 'Admin')?.enabled && (
                            <AdminInfo id={id} edit={edit} />
                        )
                    }

                    {
                        userCardStore.roles.find(role => role.name === 'Admin')?.enabled === false && (
                            <h2 className={classes.userCard__title}>
                                Параметры роли Администратор
                                есть только у активных пользователей
                            </h2>
                        )
                    }
                </div>

                <div className={classes.userCard__button}>
                    {edit ? (
                        <Link to={pathname.split('/').slice(0, -1).join('/')}>
                            <ActionButton
                                actionType={ActionTypeOfButton.SECONDARY}
                                onClick={() => {
                                    editUser();
                                }}
                            >
                                Сохранить
                            </ActionButton>
                        </Link>
                    ) : (
                        <Link to={`${pathname}/edit`}>
                            <ActionButton actionType={ActionTypeOfButton.SECONDARY}>
                                Редактировать
                            </ActionButton>
                        </Link>
                    )}
                </div>
            </div>
        </>
    );
});
