import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { getISOWeek } from 'date-fns';

import { Parameter } from '@admin/EducationPeriodParametersAndDates/EducationPeriodParameters/EducationPeriodParametersEdit/Parameter';

import classes from './AvtorScheduleDownload.module.scss';

const getCurrentNumOfWeek = (): number => {
    const currentDate = new Date();
    const possibleBeginOfYear = new Date(currentDate.getFullYear(), 8, 1);
    const weekNumber = getISOWeek(currentDate) - getISOWeek(possibleBeginOfYear) + 1;
    if (weekNumber < 1) {
        const factBeginOfYear = new Date(currentDate.getFullYear() - 1, 8, 1);
        const numOfYearWeeks = getISOWeek(new Date(factBeginOfYear.getFullYear(), 11, 31));
        return getISOWeek(currentDate) - getISOWeek(factBeginOfYear) + 1 + numOfYearWeeks;
    }

    return weekNumber;
};

interface Props {
    avtorSchedule: { schedule: string; weekNumber: number; };

    getAvtorSchedule(weekNumber: number): void;
}

export const AvtorScheduleDownload = ({ avtorSchedule, getAvtorSchedule }: Props) => {
    const [weekNumber, setWeekNumber] = useState(getCurrentNumOfWeek);
    useEffect(() => {
        if (avtorSchedule !== undefined) {
            const blob = new Blob([avtorSchedule.schedule], {
                type: 'text/plain; charset=ISO-8859-1',
            });
            saveAs(blob, `raspisanie.${avtorSchedule.weekNumber}`);
        }
    }, [avtorSchedule]);
    return (
        <div className={classes.avtorScheduleDownload}>
            <div className={classes.avtorScheduleText}>
                <Parameter
                    onChange={({ target }) => {
                        const value = Number.isNaN(Number.parseInt(target.value, 10))
                            ? 0
                            : Number.parseInt(target.value, 10);
                        setWeekNumber(value);
                    }}
                    title="Номер недели для выгрузки расписания в систему Avtor"
                    value={weekNumber}
                />

                (сейчас неделя {getCurrentNumOfWeek()})
            </div>

            <div
                onClick={() => getAvtorSchedule(weekNumber)}
                style={{
                    border: '1px solid black',
                    padding: '10px 15px',
                    cursor: 'pointer',
                    width: '300px',
                    textAlign: 'center',
                }}
            >
                Скачать расписание на неделю {weekNumber}
            </div>
        </div>
    );
};
