import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import './Calendar.scss';

interface Props {
    start: Date|undefined|'';
    end: Date|undefined|'';
    onChange(update: any): void;
}
export const Calendar = ({
    start, end, onChange,
}: Props): JSX.Element => (
    <div className="calendar">
        <DatePicker
            onChange={onChange}
            startDate={start !== '' && start ? start : undefined}
            endDate={end !== '' && end ? end : undefined}
            placeholderText={(start && end) ? `${start} - ${end}` : 'Выбрать даты'}
            dateFormat="dd.MM.yyyy"
            selectsRange
            locale={ru}
            autoComplete="on"
            popperClassName="calendar__popperEducactionPeriod"
            wrapperClassName="calendar__wrapperEducactionPeriod"
            calendarStartDay={1}
        />
    </div>
);
