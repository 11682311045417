import React, { useState } from 'react';
import cn from 'classnames';
import { Switcher } from '@common';
import { BorderCrossIcon } from '@common/svg';

import { ZoneSelector } from '../ZoneSelector';

import classes from './EquipmentForm.module.scss';

interface Equipment {
    [index: string]: any;
    id: string;
    name: string;
    count: number;
    isMovable?: boolean;
    territorialZoneId?: string | null;
}

interface TerritorialZone {
    id: string;
    name: string;
}

interface EquipmentFormProps {
    equipmentIndex : number;
    equipment: Equipment;
    handleInputChange : (index : number, name : string, value : string) => void;
    handleDeleteInput : (index : number) => void;
    handleIsMovableChange: (index: number) => void;
    handleZoneSelectChange: (index: number, territorialZoneId: string|null) => void;
    warn : boolean;
    territorialZones: TerritorialZone[];
}

export function EquipmentForm({
    equipmentIndex,
    equipment,
    handleInputChange,
    handleDeleteInput,
    handleIsMovableChange,
    handleZoneSelectChange,
    warn,
    territorialZones,
}:EquipmentFormProps) {
    const [zoneCheck, setZoneCheck] = useState(!!equipment.territorialZoneId);
    // const [selectedZone, setSelectedZone] = useState<string | null>(null);
    const sortedTerritorialZones = territorialZones?.slice()
        .sort((a: TerritorialZone, b: TerritorialZone) => (a.name > b.name ? 1 : -1));
    if (!sortedTerritorialZones) {
        return <></>;
    }
    return (
        <div className={classes.equipmentForm}>
            <div className={classes.equipmentForm__content}>
                <input
                    className={`${classes.equipmentForm__name} ${warn && equipment.name === '' ? classes.equipmentForm__name_warn : ''}`}
                    type="text"
                    onChange={({ target }) => handleInputChange(
                        equipmentIndex,
                        target.name,
                        target.value,
                    )}
                    name="name"
                    value={equipment.name}
                    placeholder="Введите название оборудования"
                />
                <input
                    className={`${classes.equipmentForm__count} ${warn && !(equipment.count > 0) ? classes.equipmentForm__count_warn : ''}`}
                    type="number"
                    onChange={
                        (e) => handleInputChange(
                            equipmentIndex,
                            e.target.name,
                            e.target.value,
                        )
                    }
                    name="count"
                    value={equipment.count === -1 || NaN ? '' : equipment.count}
                    placeholder="Кол-во"
                />
                {equipmentIndex !== 0 && (
                    <BorderCrossIcon
                        handler={() => handleDeleteInput(equipmentIndex)}
                        size={22}
                    />
                )}
            </div>
            {warn && (
                <div className={classes.equipmentForm__warning}>
                    Заполните все поля
                </div>
            )}
            <div className={classes.equipmentForm__relocatableSwitcher}>
                <Switcher
                    label="Перемещаемое оборудование"
                    checked={equipment.isMovable}
                    onClick={() => handleIsMovableChange(equipmentIndex)}
                />
            </div>
            {equipment.isMovable && (
                <>
                    <div
                        className={cn(
                            classes.equipmentForm__zoneSwitcher,
                        )}
                    >
                        <Switcher
                            label="Это оборудование перемещаемо только в территориальной зоне"
                            checked={zoneCheck && equipment.isMovable}
                            onClick={() => setZoneCheck(!zoneCheck)}
                        />
                    </div>
                    <div className={cn(
                        classes.equipmentForm__zoneSelect,
                    )}
                    >
                        <ZoneSelector
                            sortedTerritorialZones={sortedTerritorialZones}
                            zoneCheck={zoneCheck}
                            setSelectedZone={
                                (id: string|null) => handleZoneSelectChange(
                                    equipmentIndex,
                                    id,
                                )
                            }
                            territorialZoneId={equipment.territorialZoneId}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
