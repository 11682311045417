import { FetchResult } from '@apollo/client';
import React, { useEffect } from 'react';
import {
    Space,
    DaySchedule,
    StudentWorkload,
    Mode,
} from '../EducationPeriodParametersAndDatesInterfaces';
import classes from './EducationPeriodParameters.module.scss';
import { EducationPeriodParametersEdit } from './EducationPeriodParametersEdit';
import { EducationPeriodParametersView } from './EducationPeriodParametersView';

type SetModeFunction = React.Dispatch<React.SetStateAction<Mode>>;

interface Props {
    setMode: SetModeFunction,
    mode: Mode,
    space:Space,
    updateParameters(
        id: string,
        daySchedules: DaySchedule[],
        studentWorkload: StudentWorkload,
    ) : Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>,
}

export function EducationPeriodParameters({
    space,
    mode,
    updateParameters,
    setMode,
}: Props) {
    const toggleMode = () => {
        setMode((oldMode: Mode) => (oldMode ? Mode.VIEW : Mode.EDIT));
    };

    useEffect(() => setMode(Mode.VIEW), [space]);

    return (
        <div className={classes.educationPeriodParameters}>
            <div className={classes.educationPeriodParameters__title}>
                Общие параметры учебного периода пространства {space.name}
            </div>
            {
                mode
                    ? (
                        <EducationPeriodParametersEdit
                            space={space}
                            update={updateParameters}
                            toggleMode={toggleMode}
                        />
                    )
                    : (
                        <EducationPeriodParametersView
                            space={space}
                            toggleMode={toggleMode}
                        />
                    )
            }
        </div>
    );
}
