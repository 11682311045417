import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useMutation } from '@apollo/client';

import { Alert } from '@common/Alert';
import { Confirm } from '@common/Confirm';
import { ButtonTemp } from '../../../ButtonTemp';
import { SwitcherTemp } from './SwitcherTemp';
import { EvaluationStudents } from './EvaluationStudents';
import { EvaluationMultipleStudents } from './EvaluationMultipleStudents';
import { EvaluationSearch } from './EvaluationStudents/EvaluationStudent/EvaluationSearch';
import { evaluationStore, StudentsTab, multipleEvaluationStore } from '../Store';
import { COMPLETE_EVALUATION_STUDENTS_BY_TEACHER, GET_EVALUATION_POINT_INSTANCES } from '../../../../../gql/evaluationPointInstance';
import { ApolloAlert, FilterType } from '../../../../../Interfaces';
import { accountStore } from '../../../../../../Store';
import { eventsStore, timeStore } from '../../../../../Store';

import classes from './EvaluationSection.module.scss';

interface CompeteEvaluationPoint {
    evaluationPointInstanceId: string;
    teacherId: string;
    status: boolean;
}

export const EvaluationSection = observer((): JSX.Element => {
    const [isEvaluateMultiple, setIsEvaluateMultiple] = useState<boolean>(false);
    const [modal, toggleModal] = useState<boolean>(false);
    const [alert, setAlert] = useState<ApolloAlert>({
        alert: false,
        message: '',
    });

    const [completeEvaluation] = useMutation<CompeteEvaluationPoint>(
        COMPLETE_EVALUATION_STUDENTS_BY_TEACHER,
        {
            onError: (error) => errorHandling(error.toString()),
            fetchPolicy: 'no-cache',
            refetchQueries: [
                {
                    query: GET_EVALUATION_POINT_INSTANCES,
                    variables: {
                        getEvaluationPointInstanceInput: {
                            teacherId: accountStore.teacherId,
                            fromDate: timeStore.getScheduleStart(),
                            toDate: timeStore.getScheduleEnd(),
                        },
                    },
                },
            ],
        },
    );

    function toggleEvaluateMultiple(): void {
        setIsEvaluateMultiple((prevIsEvaluateMultiple) => !prevIsEvaluateMultiple);
    }

    function errorHandling(error: string): void {
        setAlert({ alert: true, message: error.toString() });
        const clearId = setTimeout(() => setAlert({ alert: false, message: '' }), 2500);
        clearTimeout(clearId);
    }

    function createDescriptionText(): string {
        if (evaluationStore.notEvaluatedStudents.length) {
            return `Ещё не оценено студентов: ${evaluationStore.notEvaluatedStudents.length}`;
        }
        return '';
    }

    function sendEditEvaluation(): void {
        const queryInput = {
            evaluationPointInstanceId: !eventsStore.isAssignment(eventsStore.selectedEvent)
                && eventsStore.selectedEvent?.id,
            teacherId: accountStore.teacherId,
            status: true,
        };
        completeEvaluation({
            variables: { updateEvaluationPointInstanceTeacherStatusInput: queryInput },
        });
        toggleModal(false);
        multipleEvaluationStore.removeAllStudentsFromMultipleEvaluation();
    }

    function toggleModalWindow(): void {
        toggleModal((prevState) => !prevState);
    }

    function disableEvaluateMultiple(): void {
        setIsEvaluateMultiple(false);
    }

    useEffect(() => {
        disableEvaluateMultiple();
    }, [evaluationStore.selectedStudentsTab]);

    useEffect(() => {
        if (evaluationStore.notEvaluatedStudents.length < 2) {
            disableEvaluateMultiple();
        }
    }, [evaluationStore.notEvaluatedStudents]);

    return (
        <>
            <div className={classes.evaluationSection}>
                {evaluationStore.isTabSelected(StudentsTab.NOT_EVALUATED) && (
                    <>
                        <div className={classes.evaluationSection__headerWrapper}>
                            {
                                ((eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT
                                    && evaluationStore.notEvaluatedStudents.length > 1
                                    && !eventsStore.selectedEvent.isGraded))
                                && (
                                    <div className={classes.evaluationSection__switcher}>
                                        <SwitcherTemp onClick={toggleEvaluateMultiple} />
                                        <p className={classes.evaluationSection__switcherText}>
                                            Оценить нескольких
                                        </p>
                                    </div>
                                )
                            }

                            {!!evaluationStore.notEvaluatedStudents.length && (
                                <EvaluationSearch />
                            )}
                        </div>

                        {isEvaluateMultiple
                            ? <EvaluationMultipleStudents />
                            : <EvaluationStudents />}
                    </>
                )}

                {
                    (!evaluationStore.notEvaluatedStudents.length
                        && evaluationStore.isTabSelected(StudentsTab.NOT_EVALUATED))
                    && (
                        <div className={classes.evaluationSection__unEvaluated}>
                            Нет неоцененных студентов
                        </div>
                    )
                }

                {(evaluationStore.isTabSelected(StudentsTab.EVALUATED)
                    && !evaluationStore.evaluatedStudents.length)
                    && (
                        <div className={classes.evaluationSection__unEvaluated}>
                            Нет оцененных студентов
                        </div>
                    )}

                {evaluationStore.isTabSelected(StudentsTab.EVALUATED) && (
                    <>
                        {!!evaluationStore.evaluatedStudents.length && (
                            <EvaluationSearch />
                        )}
                        <EvaluationStudents />
                    </>
                )}
            </div>
            {
                (eventsStore.selectedEvent?.type === FilterType.EVALUATION_POINT
                    && !eventsStore.selectedEvent.isGraded)
                && (
                    <div className={classes.button__position}>
                        <ButtonTemp text="Завершить оценку" onClick={toggleModalWindow} />
                    </div>
                )
            }

            {
                modal && (
                    <Confirm
                        headerText="После завершения оценки вы не сможете больше оценивать студентов в этой точке"
                        descriptionText={`Вы действительно хотите завершить оценку? ${createDescriptionText()}`}
                        secondaryBtnText="Отменить"
                        primaryBtnText="Завершить"
                        onSecondaryBtnClick={toggleModalWindow}
                        onPrimaryBtnClick={sendEditEvaluation}
                        onOutClick={toggleModalWindow}
                    />
                )
            }

            {alert.alert && <Alert message={alert.message} time={2500} />}
        </>
    );
});
