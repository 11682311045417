import React, { useState } from 'react';

import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';
import { GradientText } from '@common/GradientText';
import { BorderPlusIcon } from '@common/svg';
import { ActionButton } from '@common/ActionButton';

import classes from './EquipmentForm.module.scss';

import { EquipmentFormInput } from './EquipmentFormInput';

interface Equipment {
    id: string;
    name: string;
    unit: string;
}

interface Props {
    createConsumableEquipment: (
        id: string,
        name: string,
        unit: string,
    ) => void;
}

export function EquipmentForm({ createConsumableEquipment }: Props): JSX.Element {
    const history = useHistory();
    const equipmentFormItem = {
        id: nanoid(),
        name: '',
        unit: '',
    };
    const [formList, setFormList] = useState([equipmentFormItem]);

    const addOneMoreForm = () => {
        const newEquipmentFormItem = {
            id: nanoid(),
            name: '',
            unit: '',
        };
        setFormList([...formList, newEquipmentFormItem]);
    };

    const deleteForm = (id: number) => {
        const equipmentForms = formList;
        equipmentForms.splice(id, 1);
        setFormList([...equipmentForms]);
    };

    const saveEquipments = () => {
        formList.forEach((item: Equipment) => {
            if (item.name) {
                const name = item.name[0].toUpperCase() + item.name.slice(1);
                createConsumableEquipment(item.id, name, item.unit);
            }
        });
        history.push('/consumable-equipment/list');
    };
    return (
        <div className={classes.equipmentForm}>
            <div className={classes.equipmentForm__container}>
                <h2>Добавить расходный материал</h2>
                <ul className={classes.equipmentForm__list}>
                    {formList.map((item: Equipment, index: number) => (
                        <li
                            className={classes.equipmentForm__item}
                            key={item.id}
                        >
                            <EquipmentFormInput
                                index={index}
                                deleteForm={deleteForm}
                                formList={formList}
                                setFormList={setFormList}
                            />
                        </li>
                    ))}
                </ul>
                <div
                    className={classes.equipmentForm__iconContainer}
                    onClick={() => addOneMoreForm()}
                >
                    <BorderPlusIcon />
                    <GradientText text="eще расходник" className={classes.equipmentForm__iconText} />
                </div>
                <div className={classes.equipmentForm__button}>
                    <ActionButton onClick={() => saveEquipments()}>
                        Сохранить
                    </ActionButton>
                </div>
            </div>
        </div>
    );
}
