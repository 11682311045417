import React, { useState, useEffect } from 'react';
import { useUrlQuery } from '@common/hooks';
import { format } from 'date-fns';
import { sortBy } from 'lodash';
import cn from 'classnames';

import { EventStatus } from '@admin/ScheduleGenerationPage/utils';
import { UpdateEventDateFunc } from '@admin/ScheduleGenerationPage/types';
import { IconDeprecated } from '@common';
import { Select } from '@admin/NewModule';

import classes from './Time.module.scss';

interface NewTime {
    startTime: string;
    endTime: string;
}

interface Props {
    startDate: string;
    endDate: string;
    status: string;

    getUpdateStartDateFunction: (type: string) => UpdateEventDateFunc;
    setNewTime(newTime: NewTime): void;
}

const getOnlyTime = (time: string) => format(new Date(time), 'HH.mm');
const getMinutes = (hours: number, minutes: number): number => hours * 60 + minutes;
const getNiceTime = (time: string): string => format(new Date(time), 'dd.MM.yyyy HH:mm');
const getDayFormat = (date: string): string => format(new Date(date), 'yyyy-MM-dd');
const div = (val: number, by: number) => (val - (val % by)) / by;
const getTime = (minutes: number): string => `${div(minutes, 60)}:${minutes % 60 >= 10 ? minutes % 60 : `0${minutes % 60}`}`;
const beginsOfPair = [
    getMinutes(8, 30),
    getMinutes(10, 15),
    getMinutes(12, 0),
    getMinutes(14, 15),
    getMinutes(16, 0),
    getMinutes(17, 45),
    getMinutes(19, 30),
];
const beginsOfTimeSlots = [
    getMinutes(9, 20),
    getMinutes(11, 5),
    getMinutes(12, 50),
    getMinutes(15, 5),
    getMinutes(16, 50),
    getMinutes(18, 30),
    getMinutes(20, 15),
    ...beginsOfPair,
];

export const Time = ({
    startDate,
    endDate,
    status,
    setNewTime,
    getUpdateStartDateFunction,
}: Props) => {
    const { getUrlQuery } = useUrlQuery();
    const difference = getMinutes(new Date(endDate).getHours(), new Date(endDate).getMinutes())
        - getMinutes(new Date(startDate).getHours(), new Date(startDate).getMinutes());
    const timeOptions = sortBy(beginsOfTimeSlots);
    const [opened, setOpened] = useState(false);
    const [newStartDate, setNewStartDate] = useState<string>(startDate);
    const [newEndDate, setNewEndDate] = useState<string>(endDate);

    const changeEndDate = () => {
        const calculatedEndDate = new Date(newStartDate);
        calculatedEndDate.setMinutes(calculatedEndDate.getMinutes() + difference);
        setNewEndDate(calculatedEndDate.toISOString());
    };

    useEffect(() => setNewStartDate(startDate), [startDate]);
    useEffect(() => setNewEndDate(endDate), [endDate]);
    useEffect(changeEndDate, [newStartDate]);

    return (
        <div className={classes.column}>
            <div className={classes.row}>
                <span>{getNiceTime(startDate)} — {getOnlyTime(endDate)}</span>
                {!opened && (
                    <div>
                        {status !== EventStatus.DELETED && (
                            <IconDeprecated
                                id="editPenBlack"
                                click={() => setOpened(true)}
                                className={classes.time__penIcon}
                            />
                        )}
                    </div>
                )}
            </div>

            {opened && (
                <div className={classes.row}>
                    <div style={{ width: 300 }}>
                        <div className={classes.editingDate}>
                            <input
                                value={getDayFormat(newStartDate)}
                                type="date"
                                onChange={({ target }) => {
                                    const newDate = new Date(target.value);
                                    newDate.setHours(
                                        new Date(newStartDate).getHours(),
                                        new Date(newStartDate).getMinutes(),
                                    );
                                    setNewStartDate(newDate.toISOString());
                                }}
                            />
                            <Select
                                options={timeOptions.map(item => ({
                                    id: item.toString(),
                                    name: getTime(item),
                                }))}
                                onChange={({ target }) => {
                                    const newDate = new Date(newStartDate);
                                    const minutes = Number(target.value);
                                    newDate.setHours(div(minutes, 60), minutes % 60);
                                    setNewStartDate(newDate.toISOString());
                                }}
                                value={getMinutes(
                                    new Date(newStartDate).getHours(),
                                    new Date(newStartDate).getMinutes(),
                                ).toString()}
                            />
                        </div>

                        <div className={cn(classes.editingDate, classes.editingDateEnd)}>
                            {getNiceTime(newEndDate)}
                        </div>
                    </div>

                    <IconDeprecated
                        id="check"
                        click={async () => {
                            await getUpdateStartDateFunction(getUrlQuery('detailedEventType')!)(newStartDate);
                            setNewTime({ startTime: newStartDate, endTime: newEndDate });
                            setOpened(false);
                        }}
                        className={classes.time__penIcon}
                    />
                </div>
            )}
        </div>
    );
};
