import React from 'react';

import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import { AuthRoute } from '@common/AuthRoute';
import { EquipmentTable } from '../EquipmentTable';
import { EquipmentForZones } from '../EquipmentForZones';
import { EquipmentForRooms } from '../EquipmentForRooms';

import { AllEquipment, Equipment } from '../equipmentTypes';
import { ViewType } from '../../../graphql-query-types';

interface Props {
    groupedConsumableEquipment: AllEquipment;
}

export function EquipmentContentRouter({
    groupedConsumableEquipment,
}: Props): JSX.Element {
    const { path } = useRouteMatch();
    const allEquipments = groupedConsumableEquipment.all.map((item: Equipment) => item);
    return (
        <Switch>
            {allEquipments.length > 0 ? (
                <>
                    <AuthRoute path={`${path}/all`} requiredUserTypes={[ViewType.Admin]}>
                        <EquipmentTable
                            equipmentArray={allEquipments}
                        />
                    </AuthRoute>
                    <AuthRoute path={`${path}/zones`} requiredUserTypes={[ViewType.Admin]}>
                        <EquipmentForZones
                            equipmentByTerritorialZone={
                                groupedConsumableEquipment.byTerritorialZone}
                        />
                    </AuthRoute>
                    <AuthRoute path={`${path}/rooms`} requiredUserTypes={[ViewType.Admin]}>
                        <EquipmentForRooms
                            equipmentByTerritorialZone={
                                groupedConsumableEquipment.byTerritorialZone}
                        />
                    </AuthRoute>
                    <AuthRoute path={`${path}`} requiredUserTypes={[ViewType.Admin]}>
                        <Redirect to={`${path}/all`} />
                    </AuthRoute>
                </>
            ) : (
                <AuthRoute path={`${path}`} requiredUserTypes={[ViewType.Admin]}>
                    <p>
                        В указанный период расходные материалы не требуются
                    </p>
                </AuthRoute>
            )}
        </Switch>
    );
}
