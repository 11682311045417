import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';

import colors from '../../store/ColorConstans';

export const GET_UNIVERSITY_CONFIG = gql`
    query GetUniversityConfig {
        universityConfig {
            mainGradient
            extraGradient
            firstColor
            secondColor
            thirdColor
        }
    }
`;

export interface UniversityConfig {
    mainGradient: string;
    extraGradient: string;
    firstColor: string;
    secondColor: string;
    thirdColor: string;
}

export function ReactHelmet() {
    const {
        data: universityConfigData,
    } = useQuery<{ universityConfig: UniversityConfig; }>(GET_UNIVERSITY_CONFIG, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    useEffect(() => {
        if (universityConfigData?.universityConfig) {
            colors.setColors(universityConfigData?.universityConfig);
        }
    }, [universityConfigData]);

    const defaultMainGradient = 'linear-gradient(0deg, rgba(51,51,51,1) 0%, rgba(122,122,122,1) 30%, rgba(195,195,195,1) 100%);';
    const defaultExtraGradient = 'linear-gradient(0deg, rgba(147,147,147,1) 0%, rgba(193,193,193,1) 39%, rgba(228,228,228,1) 100%);';

    return (
        <>
            <Helmet>
                {process.env.NODE_ENV === 'production' && (
                    <script type="text/javascript">
                        {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                        ym(87425716, "init", {
                            clickmap:true,
                            trackLinks:true,
                            accurateTrackBounce:true,
                            webvisor:true
                        });

                        ym(87439619, "init", {
                            clickmap:true,
                            trackLinks:true,
                            accurateTrackBounce:true,
                            webvisor:true
                        });
                        `}
                    </script>
                )}

                <style type="text/css">
                    {`
                        :root {
                            --main-gradient: ${universityConfigData?.universityConfig?.mainGradient ?? defaultMainGradient};
                            --extra-gradient: ${universityConfigData?.universityConfig?.extraGradient ?? defaultExtraGradient};
                        }
                    `}
                </style>
            </Helmet>
        </>
    );
}
