import React from 'react';
import { CreateBuildingInput } from '@admin/UniversityPage/types';
import { EditIcon } from '@common/svg';

import classes from './UniversityBuildingsListItem.module.scss';

interface Props {
    building?: CreateBuildingInput;

    handleSetEditedBuilding(building: CreateBuildingInput): void;
}

export function UniversityBuildingsListItem({
    building,
    handleSetEditedBuilding,
}: Props): JSX.Element {
    const { name, location } = building!;
    return (
        <div className={classes.listItem}>
            <button
                type="button"
                onClick={() => handleSetEditedBuilding(building!)}
                className={classes.listItem__button}
            >
                <EditIcon size={20} />
            </button>
            <div className={classes.address}>
                <div className={classes.address__buildingName}>{`${name}, `}</div>
                <div className={classes.address__buildingLocation}>
                    {`${location}`}
                </div>
            </div>
        </div>
    );
}
