import React from 'react';
import { FetchResult } from '@apollo/client';
import { map } from 'lodash';
import classes from './EquipmentGroups.module.scss';
import { EquipmentGroup } from './EquipmentGroup';

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

interface Equipment {
    [index: string]: any;
    id: string;
    name: string;
    count: number;
    isMovable?: boolean;
    territorialZoneId?: string | null;
}

interface TerritorialZone {
    id: string;
    name: string;
}

interface EquipmentGroupsProps {
    dialogView: string;
    setDialogView: React.Dispatch<React.SetStateAction<string>>;
    equipmentArray?: Equipment[];
    equipmentType?: string;
    del: (id: string) => void;
    upd: (equipments: Equipment) => ApolloPromise;
    territorialZones?: TerritorialZone[];
}

export function EquipmentGroups({
    dialogView,
    setDialogView,
    equipmentArray,
    equipmentType,
    del,
    upd,
    territorialZones = [],
}: EquipmentGroupsProps) {
    const sortEquipmentArray = (arr: Equipment[]): Equipment[] => [...arr].sort(
        (equipment1: Equipment, equipment2: Equipment): number => {
            if (equipment1.name > equipment2.name) {
                return 1;
            }
            if (equipment1.name < equipment2.name) {
                return -1;
            }
            return 0;
        },
    );
    const createGroups = (): { [index: string]: Equipment[] } => {
        if (!equipmentArray) {
            return {};
        }
        const sortedEquipmentArray = sortEquipmentArray(equipmentArray);
        const groups: { [index: string]: Equipment[] } = {};
        sortedEquipmentArray.forEach((element) => {
            const index = element.name.charAt(0).toUpperCase();
            if (!(index in groups)) {
                groups[index] = [];
            }
            groups[index].push(element);
        });
        return groups;
    };

    const groups = createGroups();
    const entries = Object.entries(groups);
    if (entries.length > 0) {
        return (
            <div className={classes.equipmentList}>
                <h2>
                    Список {equipmentType} оборудования:
                </h2>
                <div className={classes.equipmentList__cards}>
                    {
                        map(groups, (equipments, letter) => (
                            <EquipmentGroup
                                dialogView={dialogView}
                                setDialogView={setDialogView}
                                key={letter}
                                letter={letter}
                                equipments={equipments}
                                del={del}
                                upd={upd}
                                territorialZones={territorialZones}
                            />
                        ))
                    }
                </div>
            </div>
        );
    }
    return null;
}
