import React, { useState } from 'react';

import { ActionButton } from '@common/ActionButton';
import { Alert } from '@common/Alert';
import { BorderPlusIcon } from '@common/svg';

import { nanoid } from 'nanoid';

import { useHistory } from 'react-router';

import classes from './SkillAddPage.module.scss';

import { SkillAddWithoutNest } from './SkillAddWithoutNest';
import { SkillAddWithNestRoot } from './SkillAddWithNest/SkillAddWithNestRoot';
import { checkChild } from './SkillAddWithNest/SkillAddFunctions';

import NestedSkill from './store/NestedSkill';

import {
    skillTypesData_skillTypes,
} from '../../../graphql-query-types';

interface SkillFormData {
    id: string
    name: string
    description: string
    maxLevel: number
}

interface Skill {
    id: string;
    name: string;
    typeId: string;
    maxLevel?: number;
    description?: string;
    children?: Skill[];
    dependencies?: Skill[];
}

interface Props {
    skillType: skillTypesData_skillTypes;
    skillTypes: skillTypesData_skillTypes[];
    skillsList: Skill[];
    skillPath: string
    addSkill: (
        id: string,
        name: string,
        typeId: string,
        description?: string,
        maxLevel?: number,
    ) => void;
    addNestSkill: (
        id: string,
        name: string,
        typeId: string,
        children: Skill[],
        dependencies: string[],
    ) => void;
}

export const SkillAddPage = ({
    skillType,
    skillTypes,
    skillsList,
    skillPath = '/',
    addSkill,
    addNestSkill,
}: Props): JSX.Element => {
    const history = useHistory();
    const skillFormData = {
        id: nanoid(),
        name: '',
        description: '',
        maxLevel: 1,
    };

    const [formList, setFormList] = useState([skillFormData]);
    const [nameError, setNameError] = useState<string | null>(null);
    const [nameValidate, setNameValidate] = useState(false);
    const [error, setError] = useState(false);
    const existName = 'Одно или несколько имен уже существуют';
    const [nestSkillError, setNestSkillError] = useState('');
    const { hasChild } = checkChild(skillTypes, skillType.id);

    const addOneMoreForm = () => {
        const skillForm = {
            id: nanoid(),
            name: '',
            description: '',
            maxLevel: 1,
        };
        setFormList([...formList, skillForm]);
    };

    const removeForm = (id: number) => {
        const skillForms = formList;
        skillForms.splice(id, 1);
        setFormList([...skillForms]);
    };

    const changeInputData = (index: number, type: string, text: string) => {
        const skillForms = formList;
        if (type === 'name') {
            skillForms[index].name = text;
        }
        if (type === 'description') {
            skillForms[index].description = text;
        }
        if (type === 'maxLevel') {
            skillForms[index].maxLevel = Number(text);
        }
    };

    const submitSkill = () => {
        let voidFieldFlag = true;
        let existNameFlag = false;
        formList.forEach((item: SkillFormData) => {
            if (item.name.trim() === '') {
                voidFieldFlag = false;
            }
        });

        skillsList.forEach((skillFromBack: Skill) => {
            formList.forEach((skillFromForm: SkillFormData) => {
                // eslint-disable-next-line
                if (skillFromBack.name.trim() === skillFromForm.name.trim()) {
                    existNameFlag = true;
                    setError(true);
                } else {
                    setError(false);
                }
            });
        });

        if (voidFieldFlag && !existNameFlag) {
            formList.forEach((item: SkillFormData) => {
                const name = item.name.trim();
                const itemName = name[0].toUpperCase() + name.slice(1);
                addSkill(
                    item.id.trim(),
                    itemName,
                    skillType.id.trim(),
                    item.description.trim(),
                    Number(item.maxLevel),
                );
            });
            history.push(`${skillPath}/list`);
        } else {
            setNameError('Заполните все обязательные поля');
            setNameValidate(true);
        }
        setTimeout(() => setNameError(null), 1000);
    };

    const submitNestedSkill = () => {
        const { skills }: any = NestedSkill;
        if (NestedSkill.isEmptyNameValidator(NestedSkill.skills)) {
            if (NestedSkill.isUniqueValidator(skillsList, NestedSkill.skills)) {
                addNestSkill(
                    skills.id,
                    skills.name.trim(),
                    skills.typeId,
                    skills.children,
                    skills.dependencies,
                );
                NestedSkill.clear();
                history.push(`${skillPath}/list`);
            }
            setNestSkillError('Название образовательного результата должно быть уникальным');
        } else {
            setNestSkillError('Название образовательного результата обязательно для заполнения');
        }
    };

    return (
        <>
            <div className={classes.skillAdd__title}>
                Добавление {(skillType.name.genitiveSingular)?.toLowerCase()}:
            </div>
            <div className={classes.skillAdd}>
                <div className={classes.skillAdd__container}>
                    {!hasChild
                        ? (
                            <div className={classes.skillAdd__form}>
                                <ul className={classes.skillAdd__formList}>
                                    {formList.map((item, index) => (
                                        <li
                                            key={item.id}
                                            className={classes.skillAdd__formItem}
                                        >
                                            <SkillAddWithoutNest
                                                skillType={skillType}
                                                indexForm={index}
                                                formList={formList}
                                                removeForm={removeForm}
                                                changeInputData={changeInputData}
                                                nameValidate={nameValidate}
                                            />
                                        </li>
                                    ))}
                                </ul>
                                <div className={classes.skillAdd__addForm} onClick={addOneMoreForm}>
                                    <BorderPlusIcon />
                                    <div className={classes.skillAdd__addFormText}>
                                        {`${skillType.name.nominativeSingular}`}
                                    </div>
                                </div>
                            </div>
                        )
                        : (
                            <SkillAddWithNestRoot
                                skillType={skillType}
                                skillTypes={skillTypes}
                            />
                        )
                    }
                    <div className={classes.skillAdd__saveButton}>
                        <ActionButton
                            onClick={!hasChild ? submitSkill : submitNestedSkill}
                        >
                            Сохранить
                        </ActionButton>
                    </div>
                </div>
            </div>
            {(nameError) && (
                <Alert
                    message={error
                        ? existName
                        : nameError
                    }
                />
            )}
            {nestSkillError && (
                <Alert
                    message={nestSkillError}
                    onTimeout={() => setNestSkillError('')}
                />
            )}
        </>
    );
};
