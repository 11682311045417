import React, { useEffect } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { sortBy } from 'lodash';

import { AuthRoute } from '@common/AuthRoute';
import { Loader } from '@common/Loader';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Tabs } from '@common/Tabs';

import UserView from '../../store/UserView';
import SkillHierarchy from '../../store/SkillHierarchy';
import { ViewType } from '../../graphql-query-types';
import { RequestedBaseTimeIntervalInstance } from './Config/interfaces';
import { StudentModulesPage } from './StudentModulesPage';
import {
    GET_ALL_SKILLS,
    GET_FUTURE_BTI,
    GET_MODULE_ASSESSMENT_CONFIG,
    GET_PAST_OR_CURRENT_BTI,
    GET_STUDENT_INFO,
    GET_STUDENT_PRIORITIES,
    SET_STDENT_PRIORITIES,
} from './queries';
import ModuleAssessmentStore from './module-assessment-config-store';

import classes from './StudentModulesPage.module.scss';
import { GetModuleAssessmentConfig } from './graphql-query-types';

export const StudentModulesPageApollo = (): JSX.Element => {
    const { studentId } = UserView;
    const { path } = useRouteMatch();

    const { data: allSkills, loading: loadingSkills } = useQuery(GET_ALL_SKILLS, {
        fetchPolicy: 'cache-first',
    });

    const {
        data: assessmentConfigData,
    } = useQuery<GetModuleAssessmentConfig>(
        GET_MODULE_ASSESSMENT_CONFIG,
        { fetchPolicy: 'network-only' },
    );

    const { data, loading } = useQuery(GET_STUDENT_INFO, {
        fetchPolicy: 'no-cache',
        variables: { studentId },
        skip: !studentId,
    });

    const [getCurrentOrPastBTI, {
        loading: loadingCurrentBTI,
        data: curOrPastBtiData,
    }] = useLazyQuery(GET_PAST_OR_CURRENT_BTI, {
        fetchPolicy: 'no-cache',
    });

    const [getFutureBTI, {
        loading: loadingFutureBTI,
        data: futureBtiData,
    }] = useLazyQuery(GET_FUTURE_BTI, {
        fetchPolicy: 'no-cache',
    });

    const [getStudentPriorities, {
        loading: loadingStudentPriorities,
        data: studentPrioritiesData,
    }] = useLazyQuery(GET_STUDENT_PRIORITIES, {
        fetchPolicy: 'no-cache',
    });

    const [setStudentPriorities, {
        loading: loadingSetStudentPriorities,
    }] = useMutation(SET_STDENT_PRIORITIES, {
        fetchPolicy: 'no-cache',
    });
    if (allSkills) SkillHierarchy.setSkills(allSkills.skills);

    useEffect(() => {
        if (assessmentConfigData?.moduleAssessmentConfig) {
            ModuleAssessmentStore.setModuleAssessmentConfig(
                assessmentConfigData?.moduleAssessmentConfig,
            );
        }
    }, [assessmentConfigData]);

    if (loading) return <Loader />;
    const { student } = data;

    const { baseTimeIntervalInstances, activeBaseTimeIntervalInstance } = student;
    const BTIs = baseTimeIntervalInstances
        .map((item: RequestedBaseTimeIntervalInstance) => item.baseTimeIntervalInstance);
    const tabs: string[][] = sortBy(baseTimeIntervalInstances, 'baseTimeIntervalInstance.spaceBaseTimeInterval.order')
        ?.map((item: RequestedBaseTimeIntervalInstance): string[] => {
            const { baseTimeIntervalInstance } = item;
            const { spaceBaseTimeInterval } = baseTimeIntervalInstance;
            return [
                `${spaceBaseTimeInterval.order ?? ''} ${spaceBaseTimeInterval.space.baseTimeIntervalType.name ?? ''}`,
                `${baseTimeIntervalInstance.id}`,
                baseTimeIntervalInstance.selectionStatus,
            ];
        });
    const activeBTI = tabs.find(
        tab => tab[1] === activeBaseTimeIntervalInstance?.baseTimeIntervalInstance.id,
    );
    return data && (
        <>
            <h1 className={classes.studentModulesPage__title}>Модули</h1>
            <Tabs tabsOptions={tabs ?? []} />
            <Switch>
                <AuthRoute
                    path="/modules/:id"
                    requiredUserTypes={[ViewType.Student]}
                >
                    <StudentModulesPage
                        studentSubspacesIds={student.subspaces.map((item: any) => item.subspace.id)}
                        curOrPastBtiData={curOrPastBtiData?.student}
                        futureBtiData={{
                            ...futureBtiData
                                ?.baseTimeIntervalInstance.spaceBaseTimeInterval,
                            selectionPoint: futureBtiData
                                ?.baseTimeIntervalInstance.selectionPoint,
                        }}
                        loading={loadingCurrentBTI
                            || loadingFutureBTI
                            || loadingStudentPriorities
                            || loadingSetStudentPriorities
                            || loadingSkills}
                        studentPrioritiesData={studentPrioritiesData?.studentPriorities}
                        studentSchedule={data?.studentSchedule}
                        moduleSettings={data?.moduleSettings}
                        baseTimeIntervalInstances={BTIs}
                        getCurrentOrPastBTI={getCurrentOrPastBTI}
                        setStudentPriorities={setStudentPriorities}
                        getFutureBTI={getFutureBTI}
                        getStudentPriorities={getStudentPriorities}
                    />
                </AuthRoute>
                {activeBTI && tabs && (
                    <AuthRoute
                        path="/modules/"
                        requiredUserTypes={[ViewType.Student]}
                    >
                        <Redirect to={`${path}/${activeBTI[1] ?? tabs[0][1]}`} />
                    </AuthRoute>
                )}
            </Switch>
        </>
    );
};
