import sum from 'lodash/sum';
import { nanoid } from 'nanoid';

interface Interval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
}

interface Space {
    id: string;
    name: string;
    minCreditCount: number;
    maxCreditCount: number;
    timeMesureId: string;
    quantity: number;
    isDifferentIntervals: boolean;
    spaceBaseTimeIntervals: Interval[];
}

interface BaseTimeIntervalType {
    id: string;
    name: string;
    // minCreditCount: number;
    // maxCreditCount: number;
}

interface SpaceBaseTimeInterval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
    subspaceTypeLayouts: any;
}

interface SpaceFromBack {
    id: string;
    name: string;
    minCreditCount: number;
    maxCreditCount: number;
    baseTimeIntervalType: BaseTimeIntervalType;
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[];
}

interface TimeMesureTypes {
    id: string;
    name: string;
    minBaseTimeInterval: number;
    maxBaseTimeInterval: number;
}

export const createValidation = (
    newSpace: Space,
    tabOptions: string[][],
    localQuantity: number,
) => {
    const minCreditCountArray = newSpace.spaceBaseTimeIntervals.map((interval) => (
        interval.minCreditCount
    ));
    const maxCreditCountArray = newSpace.spaceBaseTimeIntervals.map((interval) => (
        interval.maxCreditCount
    ));

    const intervalsMinCreditCountSum = sum(minCreditCountArray);
    const intervalsMaxCreditCountSum = sum(maxCreditCountArray);

    if (newSpace.name === '') {
        return 'Имя пространства обязательное для заполнение поле';
    }
    if (newSpace.minCreditCount > newSpace.maxCreditCount
        || newSpace.spaceBaseTimeIntervals.find(interval => (
            interval.minCreditCount > interval.maxCreditCount
        ))
    ) {
        return 'Минимум не должен превышать максимальное значение';
    }
    if (intervalsMinCreditCountSum === 0 || intervalsMaxCreditCountSum === 0
        || newSpace.minCreditCount === 0 || newSpace.maxCreditCount === 0
    ) {
        return 'Минимальное и максимальное значения обязательные поля для заполнения';
    }
    if (newSpace.minCreditCount < 0 || newSpace.maxCreditCount < 0
        || newSpace.spaceBaseTimeIntervals.find(interval => (
            interval.maxCreditCount < 0 || interval.maxCreditCount < 0
        ))
    ) {
        return 'Минимум и макисмум должны быть неотрицательны';
    }
    if (tabOptions.find(tab => tab[0] === newSpace.name && tab[1] !== newSpace.id)) {
        return 'Пространство с таким именем уже существует';
    }
    if (intervalsMinCreditCountSum < newSpace.minCreditCount
        || intervalsMaxCreditCountSum > newSpace.maxCreditCount
    ) {
        return 'Сумма пространственных временных интервалов выходит за границы пространства';
    }
    if (!localQuantity || localQuantity < 0) {
        return 'Количество времененных промежутков должно быть определено';
    }
    return '';
};

export const generateDefaultSpace = (
    space: SpaceFromBack | undefined,
    timeMesureTypes: TimeMesureTypes[],
) => {
    if (space) {
        let isDifferentIntervals = false;
        const diff = space.spaceBaseTimeIntervals.find(interval => (
            interval.minCreditCount !== space.spaceBaseTimeIntervals[0].minCreditCount
            || interval.maxCreditCount !== space.spaceBaseTimeIntervals[0].maxCreditCount
        ));
        if (diff) {
            isDifferentIntervals = true;
        }
        const spaceBaseTimeIntervals: Interval[] = space.spaceBaseTimeIntervals.map(({
            id,
            order,
            minCreditCount,
            maxCreditCount,
        }) => (
            {
                id,
                minCreditCount,
                maxCreditCount,
                order,
            }
        ));
        return ({
            ...space,
            quantity: space.spaceBaseTimeIntervals.length,
            timeMesureId: space.baseTimeIntervalType.id,
            isDifferentIntervals,
            spaceBaseTimeIntervals,
        });
    }
    return ({
        id: nanoid(),
        name: '',
        minCreditCount: 0,
        maxCreditCount: 0,
        timeMesureId: timeMesureTypes[0].id,
        quantity: 1,
        isDifferentIntervals: false,
        spaceBaseTimeIntervals: [{
            id: nanoid(),
            minCreditCount: 0,
            maxCreditCount: 0,
            order: 1,
        }],
    });
};
