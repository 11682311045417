import React from 'react';

import classes from './EventsList.module.scss';

import { EventView } from '../EventView';
import EventItem from '../EventItem/EventItem';

import EventStore from '../store';

import {
    EventType,
    EvaluationPoint,
    ModuleSettings,
    AttendanceStudentSchedule,
} from '../Config/interfaces';

interface Props {
    sortedEvents: (EventType | EvaluationPoint)[];
    moduleSettings?: ModuleSettings;
    attendanceStudentSchedule: AttendanceStudentSchedule[] | undefined;
    checkEvent(event: any): boolean;
}

export function EventList({
    sortedEvents,
    moduleSettings,
    attendanceStudentSchedule,
    checkEvent,
}: Props): JSX.Element {
    return (
        <div className={classes.eventList}>
            {sortedEvents.map((event: EventType | EvaluationPoint) => (
                <div key={event.id}>
                    <EventItem
                        moduleEventId={EventStore.selectedEvent?.id}
                        event={event}
                        attendanceStudentSchedule={attendanceStudentSchedule}
                        checkEvent={checkEvent}

                    />
                    {event.id === EventStore.selectedEvent?.id && (
                        <div className={classes.eventList__eventItem_mobile}>
                            <EventView
                                sortedEvents={sortedEvents}
                                moduleSettings={moduleSettings}
                                attendanceStudentSchedule={attendanceStudentSchedule}
                                checkEvent={checkEvent}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
