import React, { SetStateAction, useEffect, useState } from 'react';

import cn from 'classnames';
import { nanoid } from 'nanoid';

import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Alert } from '@common/Alert';
import { BorderCrossIcon } from '@common/svg';
import { Suggest } from '@common';

import classes from './EducationSubspaceForm.module.scss';

interface User {
    id: string;
    name: string;
}

interface Subspace {
    id: string;
    name: string;
    ownerId: string;
    ownerFullName: string;
    typeId: string;
}

interface Props {
    index: number;
    users: User[];
    typeId: string;
    subspaces: Subspace[];
    setSubspaces: React.Dispatch<SetStateAction<Subspace[]>>;
    setActiveIndex(index: number): void;
    createSubspace: (
        id: string,
        name: string,
        ownerId: string,
        typeId: string,
    ) => void;
    removeSubspace: (
        id: string,
    ) => void;
}

const subspaceValidator = (subspace: Subspace, allSubspaces: Subspace[]) => {
    if (subspace.name.trim() === '') {
        return 'Имя подпространства обязательное для заполнения поле';
    }
    if (allSubspaces.find(({ id, name }) => subspace.name === name && subspace.id !== id)) {
        return 'Подпространство с таким именем уже существует';
    }
    if (subspace.ownerId === '-1') {
        return 'Подпространство обязательно должно иметь владельца';
    }
    return '';
};

export const EducationSubspaceEdit = ({
    index,
    users,
    typeId,
    subspaces,
    setSubspaces,
    setActiveIndex,
    createSubspace,
    removeSubspace,
}: Props) => {
    const defaultSubspace = (index > -1)
        ? { ...subspaces[index] }
        : {
            id: nanoid(),
            name: '',
            ownerId: '-1',
            ownerFullName: '',
            typeId,
        };

    const [subspace, setSubspace] = useState<Subspace>(defaultSubspace);

    const [subspaceOwner, setSubspaceOwner] = useState(
        users.find(user => user.id === defaultSubspace.ownerId)
        || undefined,
    );
    const [isOwnerChoosed, setIsOwnerChoosed] = useState(index > -1);
    const [error, setError] = useState('');

    const editSubspace = () => {
        createSubspace(
            subspace.id,
            subspace.name.trim(),
            subspace.ownerId,
            subspace.typeId,
        );
        setSubspaces([
            ...subspaces.slice(0, index),
            subspace,
            ...subspaces.slice(index + 1),
        ]);
    };

    const saveSubspace = () => {
        const message = subspaceValidator(subspace, subspaces);
        setError(message);
        if (message === '') {
            if (index > -1) {
                editSubspace();
            } else {
                createSubspace(
                    subspace.id,
                    subspace.name.trim(),
                    subspace.ownerId,
                    subspace.typeId,
                );
                setSubspaces([subspace, ...subspaces].sort(
                    (
                        a: Subspace,
                        b: Subspace,
                    ) => (
                        a.name > b.name ? 1 : -1
                    ),
                ));
            }
            setActiveIndex(-1);
        }
    };

    const closeSubspace = () => {
        setActiveIndex(-1);
    };

    const deleteSubspace = () => {
        if (index > -1) {
            removeSubspace(subspaces[index].id);
            setSubspaces(subspaces.filter((item) => item !== subspaces[index]));
        }
        setActiveIndex(-1);
    };

    useEffect(() => {
        setTimeout(() => {
            if (error !== '') {
                setError('');
            }
        }, 2000);
    });

    const chooseOwnerSelect = (userId: String | undefined) => {
        const user = users.find(({ id }) => id === userId);
        if (isOwnerChoosed) {
            setIsOwnerChoosed(false);
        }
        if (user) {
            setSubspaceOwner(
                user,
            );
            setSubspace({
                ...subspace,
                ownerId: user.id,
            });
            setIsOwnerChoosed(true);
        }
    };

    return (
        <div className={classes.educationSubspaceForm}>
            <div className={classes.educationSpaceEdit_container}>
                <div className={classes.educationSubspaceForm__head}>
                    Новое подпространство

                    <BorderCrossIcon
                        size={26}
                        handler={() => closeSubspace()}
                    />
                </div>

                <div className={classes.educationSubspaceEdit__formContainer}>
                    <label className={classes.educationSubspaceForm__label}>
                        Название подпространства

                        <input
                            className={classes.educationSubspaceForm__input}
                            value={subspace.name}
                            onChange={({
                                currentTarget,
                            }) => setSubspace({ ...subspace, name: currentTarget.value })}
                        />
                    </label>

                    <label className={classes.educationSubspaceForm__label}>
                        Администратор подпространства

                        <Suggest
                            options={users}
                            onChange={value => chooseOwnerSelect(value?.id)}
                            value={subspaceOwner}
                            placeholder="Преподаватель"
                        />
                    </label>
                </div>
            </div>

            <div className={classes.educationSubspaceForm__ButtonContainer}>
                <ActionButton
                    className={cn(classes.educationButton, {
                        [classes.educationButton__bgButton]: true,
                        [classes.educationButton_width_fix]: true,
                    })}
                    onClick={() => saveSubspace()}
                >
                    Сохранить подпространство
                </ActionButton>

                <ActionButton
                    actionType={ActionTypeOfButton.SECONDARY}
                    className={cn(classes.educationButton, {
                        [classes.educationButton_width_fix]: true,
                    })}
                    onClick={() => deleteSubspace()}
                >
                    Удалить подпространство

                </ActionButton>
            </div>

            {error !== '' && <Alert message={error} />}
        </div>
    );
};
