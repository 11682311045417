import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { Subspace, SubspaceType } from '../subSpaceTypes';
import { skills_skills, skillTypesData_skillTypes } from '../../graphql-query-types';
import { SubSpaceSlotSettings } from './SubSpaceSlotSettings';

const UPDATE_SLOT_DIAGRAM = gql`
    mutation updateSlotDiagram($updateSlotDiagramInput: UpdateSlotDiagramInput!) {
        updateSlotDiagram(updateSlotDiagramInput: $updateSlotDiagramInput) {
            slots {
                id 
            }
        }
    }
`;

interface Props {
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
    subspace: Subspace;
    subspaceType: SubspaceType;
}

const SLOT_QUERY = gql`
query getSlots($subspaceId: String!) {
  subspace(id: $subspaceId) {
    slotDiagram {
      slots {
        id
        spaceBaseTimeIntervalId
        row
        column
        nextSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
          prerequisiteSkills {
            skill {
              id
              name
              fullName
              typeId
            }
            level
          }
          outputSkills {
            skill {
              id
              name
              fullName
              typeId
            }
            level
          }
        }
        nextForks {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
        nextGroupSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
        module {
          id
          name
        }
        prerequisiteSkills {
          skill {
            id
            name
            fullName
            typeId
          }
          level
        }
        outputSkills {
          skill {
            id
            name
            fullName
            typeId
          }
          level
        }
      }
      forks {
        id
        spaceBaseTimeIntervalId
        setting {
          distributionType
          moduleSelectionCount
          overbooking
          studentPickingType
          transitionalEmptyModule
          transitionalUnderfilledModule
          distributionModule {
            id
            name
          }
          studentPriorityCount
        }
        row
        column
        nextSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
          prerequisiteSkills {
            skill {
              id
              name
              fullName
              typeId
            }
            level
          }
          outputSkills {
            skill {
              id
              name
              fullName
              typeId
            }
            level
          }
        }
        nextForks {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
        nextGroupSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
      }
      groupSlots {
        id
        spaceBaseTimeIntervalId
        row
        column
        nextSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
          prerequisiteSkills {
            skill {
              id
              name
              fullName
              typeId
            }
            level
          }
          outputSkills {
            skill {
              id
              name
              fullName
              typeId
            }
            level
          }
        }
        nextForks {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
        nextGroupSlots {
          id
          spaceBaseTimeIntervalId
          row
          column
        }
      }
    }
  }
}
`;

export function SubSpaceSlotSettingsApollo({
    skillTypes,
    allSkillsList,
    subspace,
    subspaceType,
}: Props): JSX.Element {
    const { params }: any = useRouteMatch();
    const [updateSlotDiagram] = useMutation(UPDATE_SLOT_DIAGRAM, {
        refetchQueries: [{ query: SLOT_QUERY, variables: params }],
    });

    return (
        <SubSpaceSlotSettings
            allSkillsList={allSkillsList}
            subspaceTypeLayouts={subspace.type.subspaceTypeLayouts || []}
            subspace={subspace}
            subspaceType={subspaceType}
            subSpaceSlots={subspace.slotDiagram.slots || []}
            subSpaceGroupSlots={subspace.slotDiagram.groupSlots || []}
            subSpaceForks={subspace.slotDiagram.forks || []}
            skillTypes={skillTypes}
            updateSlotDiagram={(
                updateSlotDiagramInput,
            ) => updateSlotDiagram(
                {
                    variables: { updateSlotDiagramInput },
                },
            )
            }
        />
    );
}
