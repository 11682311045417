import React from 'react';
import { useMutation } from '@apollo/client';

import { Loader, LoaderSizes } from '@common/Loader';
import { Alert } from '@common/Alert';
import { ImportButton } from './ImportButton';
import { importUsers } from '../apollo-types';
import { ToolBarButtonProps } from '../interface';
import { IMPORT_USERS } from '../gql';

export const ImportButtonApollo = ({ refetchUserList }: ToolBarButtonProps) => {
    const [
        updateUsers,
        { loading, error },
    ] = useMutation<importUsers>(
        IMPORT_USERS,
        {
            onCompleted: () => refetchUserList(),
            // eslint-disable-next-line no-console
            onError: (err) => console.error(err.graphQLErrors?.[0].message),
        },
    );

    if (loading) return (<Loader size={LoaderSizes.default} />);

    function onChange() {
        updateUsers();
    }

    return (
        <>
            <ImportButton onClick={onChange} />
            {error?.graphQLErrors?.[0].message && (
                <Alert message={error?.graphQLErrors?.[0].message} time={5000} />
            )}
        </>
    );
};
