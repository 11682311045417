import React, { useEffect, useState } from 'react';
import { ApolloQueryResult, FetchResult, OperationVariables } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import {
    Space,
    UpdateSpaceBaseTimeIntervalParameters,
    CreateSpaceBaseTimeIntervalParameters,
    SpaceEducationPeriod,
    Mode,
} from '../EducationPeriodParametersAndDatesInterfaces';
import { EducationPeriodDatesView } from './EducationPeriodDatesView';
import { EducationPeriodDatesEdit } from './EducationPeriodDatesEdit';
import classes from './EducationPeriodDates.module.scss';

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

type SetModeFunction = React.Dispatch<React.SetStateAction<Mode>>;

interface Props {
    mode: Mode,
    space: Space,
    isSecret: boolean,
    setMode: SetModeFunction,
    updatePeriod(
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: UpdateSpaceBaseTimeIntervalParameters[],
    ): ApolloPromise,
    createPeriod(
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: CreateSpaceBaseTimeIntervalParameters[],
    ): ApolloPromise,
    refetchGetSpaces: (variables?:
    Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{
        spaces: Space[];
    }>>
}

export function EducationPeriodDates({
    mode,
    space,
    isSecret,
    setMode,
    updatePeriod,
    createPeriod,
    refetchGetSpaces,
}: Props) {
    const [
        periodsCopy,
        setPeriodsCopy,
    ] = useState<(SpaceEducationPeriod | undefined)[]>(
        cloneDeep(space.spaceEducationPeriods));
    const [
        currentPeriod,
        setCurrentPeriod,
    ] = useState<SpaceEducationPeriod | undefined>(
        periodsCopy[0],
    );
    useEffect(
        () => {
            setMode(Mode.VIEW);
            const temporarySpacePeriods = cloneDeep(space.spaceEducationPeriods);
            const lastPeriod = temporarySpacePeriods.pop();
            if (lastPeriod) {
                temporarySpacePeriods.unshift(lastPeriod);
            }
            setPeriodsCopy(temporarySpacePeriods);
            setCurrentPeriod(temporarySpacePeriods[0]);
        },
        [space],
    );
    const goForward = () => {
        const temporaryPeriods = [...periodsCopy];
        temporaryPeriods.push(temporaryPeriods.shift());
        setPeriodsCopy(temporaryPeriods);
        setCurrentPeriod(temporaryPeriods[0]);
    };
    const goBackward = () => {
        const temporaryPeriods = [...periodsCopy];
        temporaryPeriods.unshift(temporaryPeriods.pop());
        setPeriodsCopy(temporaryPeriods);
        setCurrentPeriod(temporaryPeriods[0]);
    };

    if (!currentPeriod) {
        setMode(Mode.CREATE);
    }
    return (
        <div className={classes.educationPeriodDates}>
            <div className={classes.educationPeriodDates__title}>
                Даты учебного периода пространства {space.name}
            </div>
            {
                ((mode > 0) || (!currentPeriod))
                    ? (
                        <EducationPeriodDatesEdit
                            space={space}
                            period={currentPeriod}
                            setCurrentPeriod={setCurrentPeriod}
                            setMode={setMode}
                            updatePeriod={updatePeriod}
                            createPeriod={createPeriod}
                            mode={mode}
                        />
                    )
                    : (
                        <>
                            <EducationPeriodDatesView
                                space={space}
                                currentPeriod={currentPeriod as SpaceEducationPeriod}
                                isSecret={isSecret}
                                goForward={goForward}
                                goBackward={goBackward}
                                refetchGetSpaces={refetchGetSpaces}
                            />
                            <div className={classes.educationPeriodDates__buttonPanel}>
                                <ActionButton
                                    actionType={ActionTypeOfButton.SECONDARY}
                                    className={classes.educationPeriodDates__createButton}
                                    onClick={() => setMode(Mode.CREATE)}
                                >
                                    Создать будущий учебный период
                                </ActionButton>
                                <ActionButton
                                    actionType={ActionTypeOfButton.SECONDARY}
                                    className={classes.educationPeriodDates__editButton}
                                    onClick={() => setMode(Mode.EDIT)}
                                >
                                    Редактировать этот учебный период
                                </ActionButton>
                            </div>
                        </>
                    )
            }
        </div>
    );
}
