import React, { ChangeEvent } from 'react';
import classes from './PeriodTitleEdit.module.scss';

interface Props {
    onChange(e: ChangeEvent<HTMLInputElement>): void,
    value?: string,
}

export function PeriodTitleEdit({
    onChange,
    value,
}: Props) {
    return (
        <div className={classes.periodTitleEdit}>
            <div className={classes.periodTitleEdit__title}>
                Название учебного периода
            </div>
            <div className={classes.periodTitleEdit__box}>
                <input
                    type="text"
                    className={classes.periodTitleEdit__input}
                    onChange={onChange}
                    value={value}
                    placeholder="Введите название"
                />
            </div>
        </div>
    );
}
