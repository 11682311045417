import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    ModuleSlot,
    Subspace,
    SubspaceSkill,
    UpdateSlotDiagramInput,
} from '../subSpaceTypes';
import { skills_skills, skillTypesData_skillTypes } from '../../graphql-query-types';
import { SlotAndModuleParameters } from './SlotAndModuleParameters';

const GET_SUBSPACE_PREREQUISITE_SPECIALIZED_SKILLS = gql`
    query subspacePrerequisiteSpecializedSkills($subspaceId: String!){
        subspacePrerequisiteSpecializedSkills(subspaceId: $subspaceId ){
            skill {
                id
                name
                fullName
                typeId
            }
            level
        }
    }
`;

const GET_SUBSPACE_OUTPUT_SPECIALIZED_SKILLS = gql`
    query subspaceOutputSpecializedSkills($subspaceId: String!){
        subspaceOutputSpecializedSkills(subspaceId: $subspaceId ){
            skill {
                id
                name
                fullName
                typeId
            }
            level
        }
    }
`;

const UPDATE_MODULE_SLOT_SKILLS = gql`
    mutation updateModuleSlotSkills($updateModuleSlotSkillInput: UpdateModuleSlotSkillInput!) {
        updateModuleSlotSkills(updateModuleSlotSkillInput: $updateModuleSlotSkillInput) {
            id
            prerequisiteSkills {
                skill {
                    id
                    typeId
                    name
                    fullName
                }
                level
            }
            outputSkills {
                skill {
                    id
                    typeId
                    name
                    fullName
                }
                level
            }
        }
    }
`;

interface Props {
    subspace: Subspace;
    subSpaceSlots?: ModuleSlot[];
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];

    updateSlotDiagram(updateSlotDiagramInput: UpdateSlotDiagramInput): void
}

export const SlotAndModuleParametersApollo = ({
    subspace,
    subSpaceSlots,
    skillTypes,
    allSkillsList,
    updateSlotDiagram,
}: Props): JSX.Element => {
    const {
        data: prerequisiteSpecializedSkills,
        loading: prerequisiteSpecializedSkillsLoading,
        error: prerequisiteSpecializedSkillsError,
    } = useQuery<{ subspacePrerequisiteSpecializedSkills: SubspaceSkill[] }>(
        GET_SUBSPACE_PREREQUISITE_SPECIALIZED_SKILLS,
        {
            fetchPolicy: 'cache-and-network',
            variables: { subspaceId: subspace.id },
        },
    );

    const {
        data: outputSpecializedSkills,
        loading: outputSpecializedSkillsLoading,
        error: outputSpecializedSkillsError,
    } = useQuery<{ subspaceOutputSpecializedSkills: SubspaceSkill[] }>(
        GET_SUBSPACE_OUTPUT_SPECIALIZED_SKILLS,
        {
            fetchPolicy: 'cache-and-network',
            variables: { subspaceId: subspace.id },
        },
    );

    const [updateModuleSlotSkills] = useMutation(UPDATE_MODULE_SLOT_SKILLS);

    return (
        <SlotAndModuleParameters
            subspaceId={subspace.id}
            allSkillsList={allSkillsList}
            subSpaceSlots={subSpaceSlots}
            skillTypes={skillTypes}
            prerequisiteSpecializedSkills={
                prerequisiteSpecializedSkills?.subspacePrerequisiteSpecializedSkills
            }
            outputSpecializedSkills={
                outputSpecializedSkills?.subspaceOutputSpecializedSkills
            }
            prerequisiteSpecializedSkillsLoading={prerequisiteSpecializedSkillsLoading}
            outputSpecializedSkillsLoading={outputSpecializedSkillsLoading}
            prerequisiteSpecializedSkillsError={prerequisiteSpecializedSkillsError}
            outputSpecializedSkillsError={outputSpecializedSkillsError}
            updateModuleSlotSkills={(
                moduleSlotSkillsInput,
            ) => updateModuleSlotSkills(
                {
                    variables: { updateModuleSlotSkillInput: moduleSlotSkillsInput },
                },
            )
            }
            updateSlotDiagram={updateSlotDiagram}
        />
    );
};
