import React from 'react';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import {
    Space,
    SpaceEducationPeriod,
} from '../../EducationPeriodParametersAndDatesInterfaces';
import classes from './EducationPeriodDatesView.module.scss';
import { Navigation } from './Navigation';
import { Intervals } from './Intervals';

interface Props {
    space: Space,
    currentPeriod: SpaceEducationPeriod,
    isSecret: boolean;
    goBackward(): void,
    goForward(): void,
    refetchGetSpaces: (variables?:
    Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{
        spaces: Space[];
    }>>
}

export function EducationPeriodDatesView({
    space,
    currentPeriod,
    isSecret,
    goBackward,
    goForward,
    refetchGetSpaces,
}: Props) {
    return (
        <div className={classes.educationPeriodDatesView}>
            <Navigation
                currentPeriodName={currentPeriod.name}
                goBackward={goBackward}
                goForward={goForward}
                space={space}
            />

            <div className={classes.educationPeriodDatesView__content}>
                <div className={classes.educationPeriodDatesView__intervals}>
                    <Intervals
                        isSecret={isSecret}
                        space={space}
                        currentPeriod={currentPeriod}
                        refetchGetSpaces={refetchGetSpaces}
                    />
                </div>
            </div>
        </div>
    );
}
