import React from 'react';
import cn from 'classnames';

import classes from './SVG.module.scss';

interface Props {
    isOpen: boolean;
    size?: number;
    className?: string;
    onClick?: () => void;
}

export function WhiteArrowIcon({
    isOpen,
    size = 16,
    className = '',
    onClick = () => { },
}: Props) {
    return (
        <div
            className={cn(classes.arrow, {
                [classes.arrow_reverse]: isOpen,
                [className]: className,
            })
            }
            style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }}
            onClick={onClick}
        >
            <svg width={size} height={size} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.57101 0.733849L15.7637 7.92898C16.0787 8.24469 16.0787 8.75621 15.7637 9.07272C15.4488 9.38844 14.9373 9.38844 14.6224 9.07272L8.00037 2.4483L1.37834 9.07192C1.06342 9.38764 0.551907 9.38764 0.23619 9.07192C-0.0787304 8.75621 -0.0787304 8.2439 0.23619 7.92818L7.42886 0.733051C7.74052 0.422184 8.26008 0.422184 8.57101 0.733849Z" fill="white" />
            </svg>

        </div>
    );
}
