import React, { useState } from 'react';

import { ActionButton } from '@common/ActionButton';
import { Loader, LoaderSizes } from '@common/Loader';
import { FetchResult } from '@apollo/client';

import classes from './PublishEvents.module.scss';

interface IProps {
    loading?: boolean;
    error?: any;
    title: string;
    onButtonClick: (
        (from: Date, to: Date, types: string[]) => Promise<FetchResult<any,
        Record<string, any>, Record<string, any>>>
    ) | ((fromDate: Date, toDate: Date, types: string[]) => void)
}

interface EventTypes {
    [index: string]: boolean,
}

export const PublishEvents = ({
    loading,
    error,
    title,
    onButtonClick,
}: IProps) => {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [dateError, setError] = useState<string | undefined>(undefined);
    const [eventTypes, setEventTypes] = useState<EventTypes>(
        {
            meeting: false,
            assignment: false,
            evaluationPoint: false,
        },
    );
    const handleCheckBoxChange = (name: string) => () => setEventTypes(
        (oldEventTypes: EventTypes) => (
            { ...oldEventTypes, [name]: !oldEventTypes[name] }
        ),
    );
    async function sendData() {
        const fromDate = new Date(`${from} 00:00:01`);
        const toDate = new Date(`${to} 23:59:59`);
        if (!Number.isNaN(fromDate.getTime()) && !Number.isNaN(toDate.getTime())) {
            if (fromDate <= toDate) {
                setError(undefined);
                await onButtonClick(fromDate, toDate, getTrueKeys(eventTypes));
            } else {
                setError('Дата начала временного интервала не может превышать дату конца временного интервала');
            }
        } else {
            setError('Пожалуйста, укажите обе границы временного интервала');
        }
    }

    return (
        <>
            <div className={classes.row}>
                {
                    title === 'Опубликовать' && (
                        <div className={classes.filters__conflictTypes}>
                            <div
                                className={classes.filters__itemName}
                            >
                                Типы событий:
                            </div>
                            <div className={classes.filters__checkbox}>
                                <input
                                    type="checkbox"
                                    id="meeting"
                                    name="meeting"
                                    value="meeting"
                                    checked={eventTypes.meeting}
                                    onChange={handleCheckBoxChange('meeting')}
                                />
                                <div onClick={handleCheckBoxChange('meeting')}>Встречи</div>
                            </div>
                            <div className={classes.filters__checkbox}>
                                <input
                                    type="checkbox"
                                    id="assignment"
                                    name="assignment"
                                    value="assignment"
                                    checked={eventTypes.assignment}
                                    onChange={handleCheckBoxChange('assignment')}
                                />
                                <div onClick={handleCheckBoxChange('assignment')}>Самостоятельные работы</div>
                            </div>
                            <div className={classes.filters__checkbox}>
                                <input
                                    type="checkbox"
                                    id="evaluationPoint"
                                    name="evaluationPoint"
                                    value="evaluationPoint"
                                    checked={eventTypes.evaluationPoint}
                                    onChange={handleCheckBoxChange('evaluationPoint')}
                                />
                                <div onClick={handleCheckBoxChange('evaluationPoint')}>Точки оценки</div>
                            </div>
                        </div>
                    )
                }
                <div>
                    <input
                        type="date"
                        value={from}
                        onChange={({ target }) => setFrom(target.value)}
                    />
                    <input
                        type="date"
                        value={to}
                        onChange={({ target }) => setTo(target.value)}
                    />
                    {loading ? (
                        <Loader size={LoaderSizes.default} />
                    ) : (
                        <ActionButton
                            onClick={async () => {
                                sendData();
                            }}
                        >
                            {title}
                        </ActionButton>
                    )}
                </div>
            </div>
            {
                dateError && (
                    <span className={classes.error}>{dateError}</span>
                )
            }
            {
                error && (
                    <span className={classes.error}>{error}</span>
                )
            }
        </>
    );
};

function getTrueKeys(obj: { [index: string]: boolean }): string[] {
    return Object.entries(obj).filter(
        ([, value]) => value,
    ).map(
        ([key]) => key,
    );
}
