import React from 'react';
import { FetchResult } from '@apollo/client';

import { Dialog } from '@common/Dialog';
import { BorderCrossIcon } from '@common/svg';

import { EquipmentGroupDialog } from './EquipmentGroupDialog';
import classes from './EquipmentGroups.module.scss';

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

interface Element {
    [index: string]: any;
    id: string;
    name: string;
    count: number;
    isMovable?: boolean;
    territorialZoneId?: string | null;
}

interface TerritorialZone {
    id: string;
    name: string;
}

interface Props {
    dialogView: string;
    setDialogView: React.Dispatch<React.SetStateAction<string>>;
    element: Element;
    upd: (equipments: Element) => ApolloPromise;
    del : (id:string) => void;
    territorialZones?: TerritorialZone[];
}

export function EquipmentGroupItem({
    dialogView,
    setDialogView,
    element,
    upd,
    del,
    territorialZones = [],
}: Props): JSX.Element {
    const handleClick = (id : string) => {
        del(id);
    };
    return (
        <div
            className={classes.equipmentList__cardItem}
            key={element.id}
        >
            <div className={classes.equipmentList__cardTextContainer}>
                <div
                    className={
                        classes.equipmentList__cardItemName
                    }
                    onClick={() => setDialogView(element.id)}
                >
                    {element.name}
                </div>
                <div className={classes.equipmentList__cardItemCount}>
                    {element.count}
                </div>
            </div>
            <button
                type="button"
                className={classes.equipmentList__cardItemDelete}
                onClick={() => handleClick(element.id)}
            >
                <BorderCrossIcon size={15} />
            </button>
            {dialogView === element.id && (
                <Dialog
                    id="portal-root"
                    dom={(
                        <EquipmentGroupDialog
                            setDialogView={setDialogView}
                            element={element}
                            upd={upd}
                            territorialZones={territorialZones}
                        />
                    )}
                />
            )}
        </div>
    );
}
