import React from 'react';
import { ArrowIcon } from '@common/svg/ArrowIcon';
import { WhiteArrowIcon } from '@common/svg/WhiteArrowIcon';
import cn from 'classnames';

import { PointIcon } from '@common/svg';

import classes from './ModuleCardHeader.module.scss';
import store from '../../store';

interface Props {
    moduleName: string;
    contentMode: boolean;
    slot?: {
        slotId: string;
        priority: number;
    };
    isStudentModuleAssessmentActive?: boolean;
    setContentMode: (bool: boolean) => void;
}

export function ModuleCardHeader({
    moduleName,
    contentMode,
    slot,
    isStudentModuleAssessmentActive,
    setContentMode,
}: Props): JSX.Element {
    return (
        <div className={classes.moduleCardHeader__container}>
            {!contentMode && (slot?.priority! > 0) && (
                <div className={classes.moduleCardHeader__priority}>
                    {slot?.priority}
                </div>
            )}
            <div
                className={cn(classes.moduleCardHeader,
                    {
                        [classes.moduleCardHeader_open]: contentMode,
                    })
                }
                onClick={() => {
                    setContentMode(!contentMode);
                    store.setSelectSlotId(contentMode ? '' : slot?.slotId);
                }}
            >
                <div className={classes.moduleCardHeader__content}>
                    <div className={classes.moduleCardHeader__title}>
                        {moduleName}
                    </div>
                </div>
                {isStudentModuleAssessmentActive && (
                    <PointIcon
                        className={classes.moduleCardHeader__point}
                    />
                )}
                {!contentMode ? (
                    <ArrowIcon
                        isOpen={contentMode}
                        className={classes.eventView_arrow}
                    />
                ) : (
                    <WhiteArrowIcon
                        isOpen={!contentMode}
                        className={classes.eventView_arrow}
                    />
                )}
            </div>
        </div>
    );
}
