import { gql, useQuery } from '@apollo/client';

const GET_SCHEDULE_PERIOD_SCORE = gql`
    query getScheduleGenerationStatus($baseTimeIntervalInstanceId: String!) {
        scheduleGenerationStatus(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId)
    }
`;

export function useSchedulePeriodScoreQuery(baseTimeIntervalInstanceId: string) {
    const { data, refetch } = useQuery(GET_SCHEDULE_PERIOD_SCORE, {
        fetchPolicy: 'cache-and-network',
        variables: { baseTimeIntervalInstanceId },
        // eslint-disable-next-line no-console
        onError: console.error,
    });

    return { data, refetch };
}
