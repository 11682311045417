import React, { FunctionComponent, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { nanoid } from 'nanoid';
import { EquipmentPage, Equipment } from './EquipmentPage';
import { Alert } from '../../common/Alert';

// interface CreateEquipment {
//     id: string;
//     name: string;
//     count: number;
//     isMovable: Boolean;
// }

const ADD_EQUIPMENT = gql`
mutation createEq($equipment: CreateEquipmentInput!) {
    createEquipment(creatEquipmentInput: $equipment) {
        id
    }
}
`;

const UPDATE_EQUIPMENT = gql`
mutation updateEquipment($updateEquipment: UpdateEquipmentInput!) {
    updateEquipment(updateEquipmentInput: $updateEquipment) {
        id
    }
}
`;

const DELETE_EQUIPMENT = gql`
mutation RemoveEquipment($id: String!) {
    removeEquipment(id: $id) {
        status
    }
}
`;

const GET_ALL_EQUIPMENTS = gql`
query getAllEquipment {
equipments(
    equipmentsInput: {}
) {
    id
    name
    count
    isMovable
    territorialZoneId
}
}
`;

const GET_STATIONARY_EQUIPMENTS = gql`
query getStationaryEquipment {
    equipments(
        equipmentsInput: {
            status: STATIONARY
        }
    ) {
        id
        name
        count
        isMovable
        territorialZoneId
    }
}
`;

const GET_MOVABLE_EQUIPMENTS = gql`
query getMovableEquipment {
    equipments(
        equipmentsInput: {
            status: MOVABLE
        }
    ) {
        id
        name
        count
        isMovable
        territorialZoneId
    }
}
`;

const GET_TERRITORIAL_ZONE = gql`
    query territorialZones {
        territorialZones {
            id
            name
        }
    }
`;

export const EquipmentPageContainer: FunctionComponent<{}> = () => {
    const [equipmentErrorAlerts, setEquipmentErrorAlerts] = useState<JSX.Element[] | []>([]);
    const [dialogView, setDialogView] = useState('-1');
    const [isAddFormClearable, setIsAddFormClearable] = useState(false);
    const [add] = useMutation(ADD_EQUIPMENT, {
        refetchQueries: [{ query: GET_ALL_EQUIPMENTS }],
        onError: (error) => {
            setIsAddFormClearable(false);
            setEquipmentErrorAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message={error.message}
            />)]);
        },
        onCompleted: () => {
            setIsAddFormClearable(true);
            setEquipmentErrorAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message="Оборудование добавлено"
            />)]);
        },
    });

    const [upd] = useMutation(UPDATE_EQUIPMENT, {
        refetchQueries: [{ query: GET_ALL_EQUIPMENTS }],
        onError: (error) => {
            // setDialogView(true);
            setEquipmentErrorAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message={error.message}
            />)]);
        },
        onCompleted: () => {
            setDialogView('-1');
        },
    });
    const [del] = useMutation(DELETE_EQUIPMENT, {
        refetchQueries: [{ query: GET_ALL_EQUIPMENTS }],
        onError: (error) => {
            setEquipmentErrorAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message={error.message}
            />)]);
        },
    });

    const { data: allEquipments } = useQuery(GET_ALL_EQUIPMENTS, {
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            setEquipmentErrorAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message={error.message}
            />)]);
        },
    });
    const { data: stationaryEquipments } = useQuery(
        GET_STATIONARY_EQUIPMENTS, {
            fetchPolicy: 'cache-and-network',
            onError: (error) => {
                setEquipmentErrorAlerts((arr) => [...arr, (<Alert
                    key={nanoid()}
                    message={error.message}
                />)]);
            },
        },
    );
    const { data: movableEquipments } = useQuery(
        GET_MOVABLE_EQUIPMENTS, {
            fetchPolicy: 'cache-and-network',
            onError: (error) => {
                setEquipmentErrorAlerts((arr) => [...arr, (<Alert
                    key={nanoid()}
                    message={error.message}
                />)]);
            },
        },
    );

    const { data: territorialZones } = useQuery(
        GET_TERRITORIAL_ZONE, {
            fetchPolicy: 'cache-and-network',
        },
    );
    return (
        <>
            <EquipmentPage
                dialogView={dialogView}
                setDialogView={setDialogView}
                isAddFormClearable={isAddFormClearable}
                setIsAddFormClearable={setIsAddFormClearable}
                add={(item: Equipment) => add({ variables: { equipment: item } }).catch(() => { })}
                upd={(item: Equipment) => upd({
                    variables: { updateEquipment: item },
                }).catch(() => { })}
                del={(id: string) => del({
                    variables: { id },
                }).catch(() => { })}
                equipmentArrayProps={allEquipments?.equipments}
                stationaryEquipments={stationaryEquipments?.equipments}
                movableEquipments={movableEquipments?.equipments}
                territorialZones={territorialZones?.territorialZones}
            />
            {equipmentErrorAlerts}
        </>
    );
};
