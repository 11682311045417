import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { ActionButton } from '@common/ActionButton';
import { BorderCrossIcon } from '@common/svg';
import { Alert } from '@common/Alert';

import NestedSkill from '@admin/SkillPage/SkillAddPage/store/NestedSkill';
import { SkillAddWithNestRoot } from '../../SkillAddPage/SkillAddWithNest/SkillAddWithNestRoot';

import classes from './SKillCardWithNestEdit.module.scss';

import { skillTypesData_skillTypes } from '../../../../graphql-query-types';

interface IProps {
    size?: string
    skillType: skillTypesData_skillTypes;
    skillTypes: skillTypesData_skillTypes[];
    skillsList: any;
    closePortal(): void;
    updateNestSkill: (
        id: string,
        name: string,
        typeId: string,
        children: any,
        dependencies: string[],
    ) => void;
}

export const SKillCardWithNestEdit = observer(({
    size,
    skillType,
    skillTypes,
    skillsList,
    closePortal,
    updateNestSkill,
}: IProps) => {
    const [error, setError] = useState('');
    const submitNestedSkill = () => {
        const { skills }: any = NestedSkill;
        if (NestedSkill.isEmptyNameValidator(NestedSkill.skills)) {
            if (NestedSkill.isUniqueValidator(skillsList, NestedSkill.skills)) {
                updateNestSkill(
                    skills.id, skills.name, skills.typeId, skills.children, skills.dependencies,
                );
                NestedSkill.clear();
                closePortal();
            } else {
                setError('Все имена должны быть уникальны!');
            }
        } else {
            setError('Перед сохранением надо заполнитбь все обязательные поля');
        }
    };

    let littlePortal = true;
    if (size !== undefined) {
        littlePortal = false;
    }

    const closeModalWindow = () => {
        NestedSkill.clear();
        closePortal();
    };

    return (
        <div className={classes['skill-add-portal-container']}>
            <div
                className={littlePortal
                    ? classes['skill-add-portal']
                    : classes['skill-add-portal-little']
                }
            >
                <div
                    className={littlePortal
                        ? classes['skill-add-portal__wrapper']
                        : classes['skill-add-portal__wrapper-little']
                    }
                >
                    <div className={classes['skill-add-portal__container']}>
                        <div className={classes['skill-add-portal__title']}>
                            Редактирование
                        </div>
                        <div className={classes['skill-add-portal__contant']}>
                            <SkillAddWithNestRoot
                                skillType={skillType}
                                skillTypes={skillTypes}
                            />
                        </div>
                        <div className={classes['skill-add-portal__button']}>
                            <ActionButton
                                className={classes.newModule__buttons_margin_right}
                                onClick={submitNestedSkill}
                            >
                                Сохранить
                            </ActionButton>
                        </div>
                    </div>
                    <BorderCrossIcon
                        handler={closeModalWindow}
                        size={30}
                        className={classes.blackCrossAddSkillEditPortal}
                    />
                </div>
            </div>

            {error && <Alert message={error} onTimeout={() => setError('')} />}
        </div>
    );
});
