import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import classes from './EventView.module.scss';

import { SkillsTabs } from '../SkillsTabs';

import { EventType, EvaluationPoint, ModuleSettings, AttendanceStudentSchedule } from '../Config/interfaces';

import EventItem from '../store';

enum EventTypeEnum {
    Meeting = 'Встреча',
    Assignments = 'Самостоятельная работа',
    EvaluationPoints = 'Точка оценки',
}

interface Props {
    sortedEvents: (EventType | EvaluationPoint)[];
    moduleSettings?: ModuleSettings;
    attendanceStudentSchedule: AttendanceStudentSchedule[] | undefined;
    checkEvent(event: any): boolean;
}

export const EventView = observer(({
    sortedEvents, moduleSettings, attendanceStudentSchedule, checkEvent,
}: Props): JSX.Element => {
    const [selectedModule, setSelectedModule] = useState<EventType | EvaluationPoint | undefined>();
    const moduleFromStore = EventItem.selectedEvent;

    useEffect(() => {
        const currentModuleEvent = sortedEvents.find(item => item.id === moduleFromStore?.id);
        setSelectedModule(currentModuleEvent);
    });
    const attendance = attendanceStudentSchedule
        ?.find(item => item.meetingId === selectedModule?.id);

    return (
        <div className={cn(classes.eventView, {
            [classes.eventView_verticalCenter]: !selectedModule,
        })}
        >
            {!selectedModule && (
                <div className={classes.eventView__plug}>
                    Выберите событие для просмотра деталей
                </div>
            )}
            {(selectedModule?.eventName) && (
                <div className={classes.eventView__content}>
                    <div className={classes.eventView__title}>
                        <div className={classes.eventTypeName}>
                            {(selectedModule.eventName === 'meetings') && (`${EventTypeEnum.Meeting} ${selectedModule.eventOrder} `)}
                            {(selectedModule.eventName === 'assignments') && (`${EventTypeEnum.Assignments} ${selectedModule.eventOrder}: `)}
                            {(selectedModule.eventName === 'evaluationPoints') && (`${EventTypeEnum.EvaluationPoints} ${selectedModule.eventOrder}: `)}
                        </div>
                        {checkEvent(selectedModule)
                            && (
                                <div className={classes.eventView__attendance}>
                                    <p className={classes.eventTypeName}>
                                        {!attendance?.attendance ? '(не посещена)' : '(посещена)'}
                                    </p>
                                    {!attendance?.attendance
                                        && (<div className={classes.eventView__attendanceSign} />)}
                                </div>
                            )
                        }
                    </div>
                    <div className={classes.eventName}>
                        {selectedModule.topic}
                    </div>
                    <div className={classes.eventTypeName}>
                        {`Продолжительность ${Number(selectedModule.duration?.academicHours) * 45} мин`}
                    </div>
                    {(
                        selectedModule.eventName === 'meetings'
                        || selectedModule.eventName === 'assignments'
                    )
                        && (
                            <>
                                <SkillsTabs
                                    meetingOrAssignmentEvent={selectedModule as EventType}
                                    type="prerequisite"
                                    moduleSettings={moduleSettings}
                                    title="Образовательные результаты для попадания"
                                />
                                <SkillsTabs
                                    meetingOrAssignmentEvent={selectedModule as EventType}
                                    type="output"
                                    moduleSettings={moduleSettings}
                                    title="Образовательные результаты после прохождения"
                                />
                            </>
                        )}
                    {
                        selectedModule.eventName === 'evaluationPoints'
                        && (
                            <SkillsTabs
                                title="Образовательные результаты, которые оцениваются в этой точке"
                                evaluationPointEvent={selectedModule as EvaluationPoint}
                                moduleSettings={moduleSettings}
                            />
                        )}
                </div>
            )}
        </div>
    );
});
