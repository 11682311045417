import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import { EducationSpace } from './EducationSpace';

interface BaseTimeIntervalType {
    id: string;
    name: string;
}

interface SpaceBaseTimeInterval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
    subspaceTypeLayouts: any;
}

interface SpaceFromBack {
    id: string;
    name: string;
    minCreditCount: number;
    maxCreditCount: number;
    baseTimeIntervalType: BaseTimeIntervalType;
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[];
}

interface Interval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
}

interface Props {
    space: SpaceFromBack | undefined;
    path: string;
    tabOptions: string[][];
    refetch: any;
    isEdit: string;
    updateSpace?: (
        id: string,
        name: string,
        minCreditCount: number,
        maxCreditCount: number,
        isDifferentSpaceBaseTimeIntervals: boolean,
        baseTimeIntervalTypeId: string,
        spaceBaseTimeIntervals: Interval[],
    ) => void;
    removeSpace: (
        id: string,
    ) => void;

    setTabOptions(tabOptions: string[][]): void;

    setIsEdit(value: string): void;
}

const GET_BASE_TIME_INTERVAL_TYPES = gql`
query {
    baseTimeIntervalTypes {
        id
        name
    }
}
`;

const CREATE_SPACE = gql`
mutation createSpace(
        $id: String!
        $name: String!
        $minCreditCount: Int!
        $maxCreditCount: Int!
        $isDifferentSpaceBaseTimeIntervals: Boolean!
        $baseTimeIntervalTypeId: String!
        $spaceBaseTimeIntervals: [CreateSpaceBaseTimeInterval!]!
    ) {
        createSpace(
            createSpaceInput: {
                id: $id
                name: $name
                minCreditCount: $minCreditCount
                maxCreditCount: $maxCreditCount
                isDifferentSpaceBaseTimeIntervals: $isDifferentSpaceBaseTimeIntervals
                baseTimeIntervalTypeId: $baseTimeIntervalTypeId
                spaceBaseTimeIntervals: $spaceBaseTimeIntervals
            }
        ) {
            id
        }
    }
`;

export const EducationSpaceApollo = ({
    space,
    path,
    tabOptions,
    setTabOptions,
    refetch,
    isEdit,
    setIsEdit,
    removeSpace,
    updateSpace,
}: Props) => {
    const [creatSpaceInput] = useMutation(CREATE_SPACE, {
        onCompleted: () => refetch(),
    });

    const {
        error,
        loading,
        data,
    } = useQuery(GET_BASE_TIME_INTERVAL_TYPES);

    if (loading) return <></>;
    if (error) return <>`Error! ${error.message}`</>;
    return (
        <>
            <EducationSpace
                defaultSpace={space}
                path={path}
                tabOptions={tabOptions}
                setTabOptions={setTabOptions}
                timeMesureTypes={data.baseTimeIntervalTypes}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                createSpace={(
                    id,
                    name,
                    minCreditCount,
                    maxCreditCount,
                    isDifferentSpaceBaseTimeIntervals,
                    baseTimeIntervalTypeId,
                    spaceBaseTimeIntervals,
                ) => creatSpaceInput({
                    variables: {
                        id,
                        name,
                        minCreditCount,
                        maxCreditCount,
                        isDifferentSpaceBaseTimeIntervals,
                        baseTimeIntervalTypeId,
                        spaceBaseTimeIntervals,
                    },
                })}
                updateSpace={updateSpace}
                removeSpace={removeSpace}
            />
        </>
    );
};
