import React, { useEffect, useState } from 'react';

import { BorderPlusDisabledIcon, BorderPlusIcon } from '@common/svg';

import { EducationSpace } from '../EducationSpace';
import { EducationSpaceInfo } from '../EducationSpaceInfo';
import { EducationSubspaceTypeForm } from './EducationSubspaceTypeForm';
import { EducationSubspaceFormApollo } from './EducationSubspaceForm/EducationSubspaceFormApollo';
import classes from '../EducationSpacePage.module.scss';

import { Space } from '../../../subSpaceAdmin/subSpaceTypes';

interface SpaceBaseTimeInterval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
}

interface SubspaceTypeLayoutType {
    id: string;
    spaceBaseTimeInterval: SpaceBaseTimeInterval;
    minCreditCount: number;
    maxCreditCount: number;
}

interface SubspaceTypeLayout {
    id: string;
    spaceBaseTimeIntervalId: string;
    minCreditCount: number;
    maxCreditCount: number;
}

interface SubspaceType {
    id: string;
    name: string;
    isSelective: boolean;
    isDescriptionEnabled: boolean;
    subspaceTypeLayouts: SubspaceTypeLayout[];
    subspaces: { id: string; }[];
}

interface SubspaceTypeFromBack {
    id: string;
    name: string;
    isSelective: boolean;
    isDescriptionEnabled: boolean;
    subspaceTypeLayouts: SubspaceTypeLayoutType[];
    subspaces: { id: string; }[];
}

interface IntervalTypes {
    id: string;
    name: string;
}

interface Interval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
}

interface Props {
    space: Space;
    defaultSubspaceTypes: SubspaceTypeFromBack[];
    spaceBaseTimeIntervals: IntervalTypes[];
    educationSpacePath: string;
    tabOptions: string[][];
    isEdit: string;
    setIsEdit(value: string): void;
    setTabOptions(tabOptions: string[][]): void;
    spaceRefetch: any;
    createSubspaceType: (
        id: string,
        name: string,
        isSelective: boolean,
        isDescriptionEnabled: boolean,
        subspaceTypeLayout: SubspaceTypeLayout[],
    ) => void;
    updateSubspaceType: (
        id: string,
        name: string,
        isSelective: boolean,
        isDescriptionEnabled: boolean,
        subspaceTypeLayout: SubspaceTypeLayout[],
    ) => void;
    removeSubspaceType: (
        id: string,
    ) => void;
    updateSpace: (
        id: string,
        name: string,
        minCreditCount: number,
        maxCreditCount: number,
        isDifferentSpaceBaseTimeIntervals: boolean,
        baseTimeIntervalTypeId: string,
        spaceBaseTimeIntervals: Interval[],
    ) => void;
    removeSpace: (
        id: string,
    ) => void;
    routerPath: string;
    hasSubspaces: boolean;
    refetchSubspaceTypes: any;
}

const fullDefaultSubspaceTypes = (subspaceTypes: SubspaceTypeFromBack[]) => {
    const defaultSubspaceType: SubspaceType[] = [];
    let i = 0;

    while (i < subspaceTypes.length) {
        let j = 0;
        defaultSubspaceType.push({
            id: subspaceTypes[i].id,
            name: subspaceTypes[i].name,
            isSelective: subspaceTypes[i].isSelective,
            isDescriptionEnabled: subspaceTypes[i].isDescriptionEnabled,
            subspaceTypeLayouts: [],
            subspaces: subspaceTypes[i].subspaces,
        });
        while (j < subspaceTypes[i].subspaceTypeLayouts.length) {
            defaultSubspaceType[i].subspaceTypeLayouts.push({
                id: subspaceTypes[i].subspaceTypeLayouts[j].id,
                minCreditCount: subspaceTypes[i].subspaceTypeLayouts[j].minCreditCount,
                maxCreditCount: subspaceTypes[i].subspaceTypeLayouts[j].maxCreditCount,
                spaceBaseTimeIntervalId:
                    subspaceTypes[i].subspaceTypeLayouts[j].spaceBaseTimeInterval.id,
            });
            j += 1;
        }
        i += 1;
    }

    return defaultSubspaceType;
};

export const EducationSubspaceType = ({
    space,
    defaultSubspaceTypes,
    spaceBaseTimeIntervals,
    educationSpacePath,
    tabOptions,
    isEdit,
    spaceRefetch,
    hasSubspaces,
    refetchSubspaceTypes,
    routerPath,
    setIsEdit,
    setTabOptions,
    createSubspaceType,
    updateSubspaceType,
    removeSubspaceType,
    removeSpace,
    updateSpace,
}: Props) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [subspaceTypes, setSubspaceTypes] = useState<SubspaceType[]>(
        fullDefaultSubspaceTypes(defaultSubspaceTypes),
    );

    useEffect(() => {
        setSubspaceTypes(fullDefaultSubspaceTypes(defaultSubspaceTypes));
    }, [defaultSubspaceTypes]);

    return (
        <>
            {
                isEdit
                    ? (
                        <EducationSpace
                            space={space}
                            path={educationSpacePath}
                            tabOptions={tabOptions}
                            setTabOptions={setTabOptions}
                            refetch={spaceRefetch}
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                            removeSpace={removeSpace}
                            updateSpace={updateSpace}
                        />
                    ) : (
                        <EducationSpaceInfo
                            space={space}
                            hasSubspaces={hasSubspaces}
                            setIsEdit={setIsEdit}
                        />
                    )
            }
            {
                subspaceTypes.map((subspaceType, index) => (
                    (activeIndex === index)
                        ? (
                            <EducationSubspaceTypeForm
                                index={index}
                                setActiveIndex={setActiveIndex}
                                subspaceTypes={subspaceTypes}
                                spaceBTI={spaceBaseTimeIntervals}
                                createSubspaceType={createSubspaceType}
                                updateSubspaceType={updateSubspaceType}
                                removeSubspaceType={removeSubspaceType}
                                key={subspaceType.id}
                            />
                        ) : (
                            <EducationSubspaceFormApollo
                                subspaceTypeName={subspaceType.name}
                                subspaceTypeId={subspaceType.id}
                                subspaceType={subspaceType}
                                typeIndex={index}
                                setActiveIndexType={setActiveIndex}
                                space={space}
                                refetchSubspaceTypes={refetchSubspaceTypes}
                                routerPath={routerPath}
                                key={subspaceType.id}
                            />
                        )
                ))
            }

            {
                subspaceTypes.length === activeIndex && (
                    <EducationSubspaceTypeForm
                        index={-1}
                        setActiveIndex={setActiveIndex}
                        subspaceTypes={subspaceTypes}
                        spaceBTI={spaceBaseTimeIntervals}
                        createSubspaceType={createSubspaceType}
                        updateSubspaceType={updateSubspaceType}
                        removeSubspaceType={removeSubspaceType}
                    />
                )
            }

            <div
                className={classes.educationSubspace__IconAddSubspaceContainer}
                onClick={() => setActiveIndex(subspaceTypes.length)}
            >
                {activeIndex >= 0 ? <BorderPlusDisabledIcon /> : <BorderPlusIcon />}

                <div className={classes.educationSubspace__textAddSubspace}>
                    Добавить новый тип подпространтсва
                </div>
            </div>
        </>
    );
};
