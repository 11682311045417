import { ApolloError, useMutation } from '@apollo/client';
import { EventTeacher } from '@admin/ScheduleGenerationPage/types';
import { GET_EVENT_DATA, UPDATE_EVALUATION_POINT_INSTANCE } from '../../scheduleQueries';

export const useUpdateEvaluationPointTeacherMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
) => {
    const [
        updateEvaluationPointInstance,
    ] = useMutation(UPDATE_EVALUATION_POINT_INSTANCE, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        updateEvaluationPointTeacher: (
            currentTeacherId: string,
            eventTeachers: EventTeacher[],
        ) => {
            const teachers = eventTeachers
                .map((item: EventTeacher) => ({
                    teacherId: item.newTeacherId,
                    teacherRoleId: item.teacherRole,
                }));
            updateEvaluationPointInstance({
                variables: {
                    updateEvaluationPointInstanceInput: {
                        id,
                        teachers,
                    },
                },
            });
        },
    };
};
