import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react';

import { Alert } from '@common/Alert';
import { EDIT_STUDENT_MEETING_ATTENDANCE, GET_MEETING_INSTANCES_BY_TEACHER } from '../../../../../gql/meetingInstance';
import { ApolloAlert, StudentsAttendance } from '../../../../../Interfaces';
import { eventsStore, timeStore } from '../../../../../Store';

import classes from './AttendanceMutation.module.scss';
import { ButtonTemp } from '../../../ButtonTemp';

interface AttendanceMutationProps {
    editStudentMode: boolean;
    studentsAttended: StudentsAttendance[];
    toggleAttendanceCheckMode(): void;
}

export const AttendanceMutation = observer(({
    editStudentMode,
    studentsAttended,
    toggleAttendanceCheckMode,
}: AttendanceMutationProps): JSX.Element => {
    const [alert, setAlert] = useState<ApolloAlert>({
        alert: false,
        message: '',
    });

    const errorHandling = (error: string) => {
        setAlert({ alert: true, message: error.toString() });
        const clearId = setTimeout(() => setAlert({ alert: false, message: '' }), 2500);
        clearTimeout(clearId);
    };

    const [editAttendance] = useMutation<StudentsAttendance>(EDIT_STUDENT_MEETING_ATTENDANCE, {
        onError: (error) => errorHandling(error.toString()),
        refetchQueries: [
            {
                query: GET_MEETING_INSTANCES_BY_TEACHER,
                variables: {
                    getMeetingInstanceInput: {
                        // teacherId: accountStore.teacherId,
                        fromDate: timeStore.getScheduleStart(),
                        toDate: timeStore.getScheduleEnd(),
                    },
                },
            },
        ],
    });

    function updateStudentsAttendance() {
        toggleAttendanceCheckMode();
        if (!editStudentMode) {
            const data = {
                meetingInstanceId: !eventsStore.isAssignment(eventsStore.selectedEvent)
                    && eventsStore.selectedEvent?.id,
                updateStudentAttendances: studentsAttended,
            };
            editAttendance({ variables: { data } });
        }
    }
    return (
        <>
            <ButtonTemp
                text={editStudentMode ? 'Редактировать' : 'Сохранить'}
                onClick={updateStudentsAttendance}
                className={classes.attendanceButton}
            />
            {alert.alert && <Alert message={alert.message} time={2500} />}
        </>
    );
});
