import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { ArrowIcon } from '@common/svg/ArrowIcon';

import { Fork, ModuleSettings, Subspace } from '../Config/interfaces';
import { ModuleCards } from '../ModuleCard';
import { InfoCard } from './InfoCard';
import store from '../store';

import classes from './ChoicePoint.module.scss';

interface Props {
    fork: Fork;
    subspace: Subspace;
    moduleSettings?: ModuleSettings;
    order: number;
    isModuleSelectionTime: boolean;
}

export const ElectivePoint = observer(({
    fork,
    subspace,
    moduleSettings,
    order,
    isModuleSelectionTime,
}: Props) => {
    // const { studentPriorityCount } = fork.setting;
    const [isOpen, setIsOpen] = useState(false);
    const modules = fork.nextSlots.map(slot => ({
        module: slot.module,
        slotId: slot.id,
        isOpen: slot.id === store.selectSlotId,
    }));

    const slots = store.getFork(fork.id ?? '')?.slots;
    const sortModules = slots
        ?.map(({ slotId }) => modules?.find(module => module.slotId === slotId)!);
    store.addFork({
        forkId: fork.id,
        slots: fork.nextSlots.map(slot => ({ slotId: slot.id, priority: 0 })),
    });
    store.updateForkPriorityCount({
        forkId: fork.id,
        priorityCount: fork.setting.studentPriorityCount,
    });
    store.updateForksOnStudentPriorities();
    return (
        <>
            <div
                className={classes.choicePoint}
                onClick={() => setIsOpen(!isOpen)}
            >
                <ArrowIcon
                    isOpen={isOpen}
                />
                <div className={classes.choicePoint__title}>
                    {`${order}-й выбор модулей. Подпространство: ${subspace.name}, ${getModuleDeclension(sortModules?.length ?? 0)}`}
                </div>
            </div>
            {isOpen && (
                <>
                    {isModuleSelectionTime && (
                        <>
                            <InfoCard
                                title="Для прохождения опроса Вам необходимо:"
                            >
                                <h2 style={{ marginBottom: '5px', fontSize: '14px' }}>1 ВЫБРАТЬ ЭЛЕКТИВНЫЙ КУРС </h2>
                                <div style={{ marginBottom: '5px' }}><b><i>Первый шаг.</i></b>{' '}Внимательно прочитать описание для каждой дисциплины, ознакомиться с кратким содержанием.</div>
                                <div style={{ marginBottom: '5px' }}><b><i>Второй шаг.</i></b>{' '}Из 28 предложенных элективных дисциплин выбрать 3 наиболее интересные для Вас и расставить их в приоритете от 1 до 3 (1 –  та дисциплина, которую Вы хотели бы изучать в первую очередь; 2 – дисциплина, которую Вы хотели бы изучать во вторую очередь и 3 – дисциплина, которую Вы хотели бы изучать в третью очередь). </div>
                                <div style={{ marginBottom: '5px' }}>
                                    Важно отметить, что Вы выбираете любые 3 элективные дисциплины,
                                    независимо от того, будут они преподаваться в РХТУ им.
                                    Д.И.Менделеева или это будут дисциплины
                                    с платформы Открытое Образование
                                </div>
                                <div style={{ marginBottom: '5px' }}>
                                    После распределения
                                    Вам придет информация-подтверждение
                                    о зачислении на тот или иной курс.
                                </div>
                                <div style={{ marginBottom: '5px' }}><i><b>Третий шаг:</b>{' '}Если Вы выбрали курсы с платформы Открытое Образование, то далее, порядок действий следующий: </i></div>
                                <ol>
                                    <li><i>Заходите на сайт https://openedu.ru и регистрируетесь, используя свою почту от университета. (Обязательно запишите логин и пароль, которые вы себе придумали! Нельзя использовать такие логины как Зайка1999, Darth_Vader и пр. Лучше использовать свою фамилию и цифры)</i></li>
                                    <li><i>Указываете в опросе свой логин. </i></li>
                                    <li>
                                        <i>
                                            На этом все, дальше делаем все мы,
                                            а вы ждете от нас оповещения на почту,
                                            когда можно приступить к курсам.
                                        </i>
                                    </li>
                                </ol>
                                <div style={{ marginBottom: '5px' }}>
                                    <i>
                                        *После прохождения курсов,
                                        вам предстоит пройти экзамен
                                        на получение сертификата о пройденном курсе,
                                        также дистанционно. После этого пройденный Вами курс
                                        будет перезачтён в ваш учебный
                                        план автоматически. Сертификаты Вы также получите.
                                    </i>
                                </div>
                                <div style={{ marginBottom: '5px' }}>
                                    <i>
                                        {`ВАЖНО! Ничего оплачивать на платформе открытого образования не нужно!
                                        Когда мы вас зачислим на курс, оплата от вас требоваться не будет.`}
                                    </i>
                                </div>
                                <h2 style={{ marginBottom: '5px', fontSize: '14px' }}>2 ВЫБРАТЬ НАПРАВЛЕНИЕ ПО ИНФОРМАТИКЕ:</h2>
                                <div style={{ marginBottom: '5px' }}>
                                    <b>Для этого нужно:</b> Ознакомиться с содержанием направлений
                                    по информатике и расставить их также
                                    в приоритете от 1 до 3 (логическая последовательность такая же:
                                    1 – наиболее интересная для
                                    Вас дисциплина, 2 – чуть менее интересная для
                                    Вас дисциплина, 3 – дисциплина, которую
                                    Вы хотели бы изучать в третью очередь)
                                </div>
                                <div style={{ marginBottom: '5px' }}>
                                    Таким образом, результатом Вашего опроса
                                    должны стать выбранные Вами 3 элективные дисциплины,
                                    расставленные согласно приоритету в изучении,
                                    и 3 направления по информатике,
                                    для которых цифрами 1-2-3
                                    также обозначена очередность их изучения.
                                </div>
                            </InfoCard>
                            <div className={classes.choicePoint__RemainingPriorities_text}>
                                {`Осталось расставить приоритетов: ${store.getRemainingPriorities(fork.id)}`}
                            </div>
                        </>
                    )}
                    <ModuleCards
                        forkId={fork.id}
                        isElectiveFork={isModuleSelectionTime}
                        modules={sortModules}
                        moduleSettings={moduleSettings}
                    />
                </>
            )}
        </>
    );
});

export function getModuleDeclension(count: number) {
    if (count === 1) {
        return `${count} модуль`;
    }
    if (count < 5 ?? count > 1) {
        return `${count} модуля`;
    }
    return `${count} модулей`;
}
