import React from 'react';
import { observer } from 'mobx-react';
import { Select } from '@common/Select';

import { assignmentsStore, FilterValue } from '../../store/AssignmentsStore';
import classes from './Filter.module.scss';

const options = [{
    id: 'Выполненые',
    value: FilterValue.true,
}, {
    id: 'Невыполненые',
    value: FilterValue.false,
}, {
    id: 'Все',
    value: FilterValue.undefined,
}];

export const Filter = observer(() => (
    <div className={classes.Filter}>
        <Select
            options={options}
            onChange={option => assignmentsStore.setCompleted(option.value)}
            selectedOption={options.find(
                option => option.value === String(assignmentsStore.isCompleted),
            )!}
        />
    </div>
));
