import React, { Fragment, useState } from 'react';
import { EditIcon } from '@common/svg';
import { Confirm } from '@common/Confirm';

import { format } from 'date-fns';
import { RoomBackendData } from '../RoomTypes';
import classes from '../Rooms.module.scss';

interface RoomProps {
    roomData: RoomBackendData;
    editMode: boolean;
    deleteRoomHandler(id: string): void;
    editRoomData(): void;
    closeEditRoom(): void;
}

export const Room = ({
    roomData,
    editMode,
    deleteRoomHandler,
    editRoomData,
    closeEditRoom,
}: RoomProps) => {
    const {
        title,
        capacity,
        location,
        id,
        meetingFormats,
        availableIntervals = [],
        isAvailable,
    }: RoomBackendData = roomData;

    const [modal, toggleModal] = useState<boolean>(false);

    const hasAvailableIntervalsTitle = availableIntervals?.length > 0 ? ' ' : ' помещение недоступно';
    const isAvailableRoomTitle = isAvailable ? ' помещение доступно' : hasAvailableIntervalsTitle;

    function toggleModalWindow() {
        toggleModal(!modal);
    }

    function deleteRoomButton() {
        deleteRoomHandler(id);
        if (editMode) {
            closeEditRoom();
        }
    }

    return (
        <>
            <header className={classes.header}>
                <h4 className={classes.title}>{title}</h4>

                <div className={classes.wrap}>
                    <h4 className={classes.title}>{`${capacity} человек`}</h4>
                    <EditIcon
                        handler={() => {
                            editRoomData();
                        }}
                    />
                </div>
            </header>

            <main className={classes.main}>
                <section className={classes.section}>
                    <h4 className={classes.subtitle}>
                        Форматы встреч:
                    </h4>
                    {
                        meetingFormats.map((meeting, index) => (
                            <Fragment key={meeting.id}>
                                {
                                    `${meeting.name}${index === meetingFormats.length - 1 ? '' : ', '}`
                                }
                            </Fragment>
                        ))
                    }
                </section>

                <section className={classes.section}>
                    {
                        !location
                            ? <a href="/">Выберите корпус</a>
                            : (
                                <>
                                    <h4 className={classes.subtitle}>
                                        Расположение:
                                    </h4>
                                    {location.name} {location.location}
                                </>
                            )
                    }
                </section>
                <section className={classes.section}>
                    <h4 className={classes.subtitle}>Доступное время: </h4> {isAvailableRoomTitle}

                    {!isAvailable && (
                        <>
                            {
                                availableIntervals?.map(item => (
                                    <Fragment key={item.id}>
                                        {format(new Date(item.from), 'dd.MM.yyyy HH:mm')}
                                        {format(new Date(item.to), '— dd.MM.yyyy HH:mm')}
                                    </Fragment>
                                ))
                            }
                        </>
                    )}
                </section>

                {
                    modal && (
                        <Confirm
                            headerText="Вы действительно хотите удалить помещение?"
                            descriptionText="Выберите вариант ответа"
                            secondaryBtnText="Отменить"
                            primaryBtnText="Удалить"
                            onSecondaryBtnClick={toggleModalWindow}
                            onPrimaryBtnClick={deleteRoomButton}
                            onOutClick={toggleModalWindow}
                        />
                    )
                }
            </main>
        </>
    );
};
