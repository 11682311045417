import React from 'react';

import { CurrentParamsList } from './CurrentParamsList';

import classes from './ParamsList.module.scss';

import {
    SubspaceSkill,
} from '../../subSpaceTypes';

import {
    skills_skills,
    skillTypesData_skillTypes,
} from '../../../graphql-query-types';

interface Props {
    paramsType: string;
    skillsParamsType: string;
    paramsList: SubspaceSkill[] | null;
    given: number;
    sortedSkillTypes: skillTypesData_skillTypes[];
    notSortedSkillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
}

export function ParamsList({
    paramsType,
    skillsParamsType,
    paramsList,
    given,
    sortedSkillTypes,
    notSortedSkillTypes,
    allSkillsList,
}: Props): JSX.Element {
    return (
        <div className={classes.subSpaceCommonParams}>
            <div className={classes.subSpaceCommonParams__container}>
                <div className={classes.subSpaceCommonParams__tittle}>
                    {paramsType === 'common' ? 'Общие' : 'Профильные'} {skillsParamsType === 'prerequisite' ? 'входные' : 'выходные'} параметры подпространства:
                    {given === 0 && (
                        <div className={classes.subSpaceCommonParams__tittleWithValue}>
                            Не задано
                        </div>
                    )}
                </div>
                {given > 0 && (
                    <ul className={classes.subSpaceCommonParams__list}>
                        {sortedSkillTypes.map((skillType: skillTypesData_skillTypes) => (
                            <li
                                key={skillType.id}
                                className={classes.subSpaceCommonParams__item}
                            >
                                <CurrentParamsList
                                    allSkillsList={allSkillsList}
                                    skillsParamsType={skillsParamsType}
                                    paramsList={paramsList}
                                    skillType={skillType}
                                    sortedSkillTypes={sortedSkillTypes}
                                    notSortedSkillTypes={notSortedSkillTypes}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}
