import React from 'react';

import { ModuleCard } from '@common/ModuleCard';
import styles from './ModulesList.module.scss';

import { SkillType, Module as ModuleType } from '../../../../subSpaceAdmin/subSpaceTypes';
import { skills_skills } from '../../../../graphql-query-types';

interface Props {
    path: string;
    modules: ModuleType[];
    skillTypes: SkillType[];
    skills: skills_skills[];

    removeModule(id: string): void;
}

export const ModulesList = ({
    modules, removeModule, path, skillTypes, skills,
}: Props) => (
    <div className={styles.modulesList}>
        {modules.map(module => (
            <div className={styles.modulesList__item} key={module.id}>
                <ModuleCard
                    skillTypes={skillTypes}
                    currentModule={module}
                    allSkillsList={skills}
                    path={path}
                    handleRemoveModule={() => removeModule(module.id)}
                />
            </div>
        ))}
    </div>
);
