import { moduleStore } from './moduleStore';
import { Meeting } from './Meeting';
import { EvaluationPoint } from './EvaluationPoint';
import { Assignment } from './Assignment';
import { EventType, TeacherRole } from './moduleStoreTypes';
import { Event, EventWithoutTest } from './ModuleModel';

interface Error {
    valid: boolean;
    error: string;
}

export class Validator {
    static getError = (
        errorMessage: string, existError: string, separator = ', ',
    ): string => (existError ? [existError, errorMessage].join(separator) : errorMessage);

    static validateModule(): Error {
        const module = moduleStore.moduleModel;
        let { error, valid } = this.validateOptions();

        const sortedEvents = [...module.events].sort((a, b) => a.order - b.order);
        sortedEvents.forEach(event => {
            let eventError: Error;
            if (moduleStore.isMeeting(event)) {
                eventError = this.validateMeeting(event);
            } else if (moduleStore.isAssignment(event)) {
                eventError = this.validateAssignment(event);
            } else {
                eventError = this.validateEvaluationPoint(event);
            }
            if (!eventError.valid) {
                [error, valid] = [this.getError(eventError.error, error, '; '), false];
            }
        });

        const totalError = this.validateTotalResult();
        if (!totalError.valid) {
            [error, valid] = [this.getError(totalError.error, error, '; '), false];
        }

        return { valid, error };
    }

    static validateOptions(): Error {
        let optionsError = '';
        let validOptions = true;
        const { options } = moduleStore.moduleModel;
        const moduleErrors: Error[] = [{
            valid: !!options.name,
            error: 'не указано название модуля',
        }, {
            valid: (options.minStudentCount ?? 0) > 0,
            error: 'минимальное кол-во студентов не может быть меньше 1',
        }, {
            valid: (options.maxStudentCount ?? 0) > 0,
            error: 'максимальное кол-во студентов не может быть меньше 1',
        }, {
            valid: (options.minStudentCount ?? 0) <= (options.maxStudentCount ?? 0),
            error: 'минимальное кол-во студентов не может быть больше максимального',
        }, {
            valid: options.maxWaveCount > 0,
            error: 'максимальное кол-во потоков не может быть меньше 1',
        }];
        moduleErrors.forEach(({ valid, error }) => {
            if (!valid) {
                [optionsError, validOptions] = [this.getError(error, optionsError), false];
            }
        });
        if (!validOptions) {
            return { error: `Информация о модуле: ${optionsError}`, valid: false };
        }
        return { error: '', valid: true };
    }

    static validateMeeting(meeting: Meeting): Error {
        let validMeeting = true;
        let meetingError = '';
        const messageErrors: Error[] = [
            this.validateDuration(meeting),
            this.validateTopic(meeting),
            this.validateEventTeacherRoles(meeting, []),
            this.validateEventIntervals(meeting),
            this.validatePreviousEvent(meeting),
            this.validateNextEvent(meeting),
            {
                valid: !!meeting.format?.id,
                error: 'не указан формат встречи',
            }, {
                valid: (meeting.isManyWaves && (meeting.maxStudentCount ?? 0) > 0)
                    || !meeting.isManyWaves,
                error: 'максимальное кол-во студентов не может быть меньше 1',
            }, {
                valid: (meeting.minStudentCount ?? 0) <= (meeting.maxStudentCount ?? 0),
                error: 'минимальное кол-во студентов не может быть больше максимального',
            }, {
                valid: !!meeting.meetingTeacherRoles?.length,
                error: 'Не указаны преподаватели',
            },
        ];
        messageErrors.forEach(({ valid, error }) => {
            if (!valid) {
                [meetingError, validMeeting] = [this.getError(error, meetingError), false];
            }
        });
        if (!validMeeting) {
            return { error: `Встреча ${meeting.meetingNumber}: ${meetingError}`, valid: false };
        }
        return { error: '', valid: true };
    }

    static validateAssignment(assignment: Assignment): Error {
        let validAssignment = true;
        let assignmentError = '';
        const messageErrors = [
            this.validateDuration(assignment),
            this.validateTopic(assignment),
            this.validateEventIntervals(assignment),
            this.validatePreviousEvent(assignment),
            this.validateNextEvent(assignment),
        ];
        messageErrors.forEach(({ valid, error }) => {
            if (!valid) {
                [assignmentError, validAssignment] = [this.getError(error, assignmentError), false];
            }
        });
        if (!validAssignment) {
            return {
                error: `Самостоятельная работа ${assignment.assignmentNumber}: ${assignmentError}`,
                valid: false,
            };
        }
        return { error: '', valid: true };
    }

    static validateEvaluationPoint(point: EvaluationPoint): Error {
        let validPoint = true;
        let pointError = '';
        const pointErrors: Error[] = [
            this.validateTopic(point),
            this.validateDuration(point),
            this.validateEventTeacherRoles(point, []),
            {
                valid: !!point.previousEvent?.id,
                error: 'К точке оценки не привязаны встречи и самостоятельные работы',
            }, {
                valid: !!point.evaluationPointTeacherRoles?.length,
                error: 'Не указаны преподаватели',
            },
            {
                valid: !!point.evaluationPointSkills?.length,
                error: 'не указаны образовательные результаты',
            },
        ];
        pointErrors.forEach(({ valid, error }) => {
            if (!valid) {
                [pointError, validPoint] = [this.getError(error, pointError), false];
            }
        });
        if (!validPoint) {
            return {
                error: `Точка оценки ${point.evaluationPointNumber}: ${pointError}`, valid: false,
            };
        }
        return { error: '', valid: true };
    }

    static validateEventTeacherRoles(
        event: Meeting | EvaluationPoint, anotherRoles: TeacherRole[],
    ): Error {
        const { moduleTeachers } = moduleStore.moduleModel.options;
        const eventTeacherRoles = [
            ...(moduleStore.isMeeting(event)
                ? event.meetingTeacherRoles
                : event.evaluationPointTeacherRoles),
            ...anotherRoles,
        ];
        const selectRolesIds = eventTeacherRoles.map(({ teacherRole: { id } }) => id);
        const maxSelectedTeacherRoles = moduleTeachers
            .filter(({ teacherRoles }) => {
                const rolesThatSelectedInMeeting = teacherRoles
                    .filter(({ id }) => selectRolesIds.indexOf(id) > -1);
                return rolesThatSelectedInMeeting.length > 0;
            })
            .length;
        const selectedTeacherRoles = eventTeacherRoles.reduce((acc, cur) => acc + cur.count!, 0);
        if (selectedTeacherRoles > maxSelectedTeacherRoles) {
            return {
                valid: false,
                error: 'Требуется больше преподавателей с определенными ролями, чем их доступно в модуле',
            };
        }

        const hasRoleWhithZeroCount = eventTeacherRoles.some(({ count }) => count === 0);
        if (hasRoleWhithZeroCount) {
            return {
                valid: false,
                error: 'указана роль преподаватель с количеством 0',
            };
        }

        return { valid: true, error: '' };
    }

    private static validateDuration(event: Event): Error {
        return {
            valid: !!event.duration?.id,
            error: 'не указана продолжительность',
        };
    }

    private static validateTopic(event: Event): Error {
        return {
            valid: !!event.topic,
            error: 'не указано название(тема)',
        };
    }

    private static validateTotalResult(): Error {
        let { error, valid } = this.validateTotalSkills();
        const connectionsError = this.validateTotalConnections();
        if (!connectionsError.valid) {
            [error, valid] = [this.getError(connectionsError.error, error), false];
        }

        return { valid, error };
    }

    private static validateTotalSkills(): Error {
        const typeMap = new Map();
        typeMap.set(EventType.Assignment, 'Самостоятельная работа');
        typeMap.set(EventType.Meeting, 'Встреча');
        const { eventsWithoutTest, evaluationPointList } = moduleStore.moduleModel;
        if (eventsWithoutTest.length > 0) {
            const notEvaluatedEventSkills = eventsWithoutTest.flatMap(
                event => evaluationPointList.getNotEvaluatedSkills(event).map(skill => ({
                    ...skill,
                    topic: `${typeMap.get(event.type)} ${moduleStore.isAssignment(event) ? event.assignmentNumber : event.meetingNumber}: ${event.topic ?? ''}`,
                })),
            );
            if (notEvaluatedEventSkills.length > 0) {
                return { valid: false, error: `Образовательные результаты в событиях не оценены: ${notEvaluatedEventSkills.map(skill => `${skill.skill.fullName} на событии ${skill.topic}`).join(', ')}` };
            }
        }
        const notEvaluatedModuleSkills = evaluationPointList.getNotEvaluatedModuleSkills();
        if (notEvaluatedModuleSkills.length) {
            return { valid: false, error: `Образовательные результаты модуля не оценены: ${notEvaluatedModuleSkills.map(skill => skill.skill.fullName).join(', ')}` };
        }
        return { valid: true, error: '' };
    }

    private static validateTotalConnections(): Error {
        let valid = true;
        const { eventsWithoutTest } = moduleStore.moduleModel;
        eventsWithoutTest.forEach(event => {
            event.dependentEvents.forEach(({ id }) => {
                const dependentEvent = moduleStore.moduleModel.getEventWithoutTest(id)!;
                const connectionError = this.validateInterval(event, dependentEvent);
                if (!connectionError.valid) valid = false;
            });
        });
        if (!valid) {
            return { error: 'Не все связи консистентны', valid: false };
        }
        return { error: '', valid: true };
    }

    private static validateEventIntervals(event: EventWithoutTest): Error {
        if (!event) return { valid: true, error: '' };
        const {
            isPreviousEventInRow,
            isNextEventInRow,
            maxDaysToPreviousEvent,
            minDaysToPreviousEvent,
            maxDaysToNextEvent,
            minDaysToNextEvent,
        } = event;
        const { eventsWithoutTest } = moduleStore.moduleModel;
        const numOfMainEvents = eventsWithoutTest.reduce((acc, { dependentEvents }) => {
            const isMainEvent = dependentEvents.some(({ id }) => id === event.id);
            return acc + (isMainEvent ? 1 : 0);
        }, 0);

        if (isPreviousEventInRow && numOfMainEvents > 1) {
            return {
                valid: false,
                error: 'Временные интервалы в указанных встречах различны. Необходимо отменить параметр "Предыдущая встреча идет встык с текущей" в карточке встречи, если у этого события несколько связей',
            };
        }
        if (isNextEventInRow && event.dependentEvents.length > 1) {
            return {
                valid: false,
                error: 'Временные интервалы в указанных встречах различны. Необходимо отменить параметр "Следующая встреча идет встык с текущей" в карточке встречи, если у этого события несколько связей',
            };
        }
        if (maxDaysToNextEvent !== undefined
            && minDaysToNextEvent !== undefined
            && maxDaysToNextEvent < minDaysToNextEvent
            && !isNextEventInRow) {
            return {
                valid: false,
                error: 'Минимальное количество рабочих дней до следующей встречи должно быть меньше максимального',
            };
        }
        if (maxDaysToPreviousEvent !== undefined
            && minDaysToPreviousEvent !== undefined
            && maxDaysToPreviousEvent < minDaysToPreviousEvent
            && !isPreviousEventInRow) {
            return {
                valid: false,
                error: 'Максимальное количество рабочих дней от предыдущей встречи должно быть больше минимального',
            };
        }

        return { valid: true, error: '' };
    }

    static validateInterval(mainEvent: EventWithoutTest, dependentEvent: EventWithoutTest): Error {
        if (!mainEvent || !dependentEvent) return { valid: true, error: '' };
        const mainError = this.validateEventIntervals(mainEvent);
        const dependentError = this.validateEventIntervals(dependentEvent);
        if (!mainError.valid || !dependentError.valid) {
            return { valid: false, error: `${mainError.error} ${dependentError.error}` };
        }

        const { isNextEventInRow } = mainEvent;
        const { isPreviousEventInRow } = dependentEvent;
        if (isNextEventInRow !== isPreviousEventInRow) {
            return {
                valid: false,
                error: 'Параметр "встреча встык" имеет противоположное значение в связанных встречах. Сделайте данный параметр одинаковым',
            };
        }

        return { valid: true, error: '' };
    }

    static validatePreviousEvent(event: EventWithoutTest): Error {
        if (!event) return { valid: true, error: '' };
        const {
            isPreviousEventInRow,
            maxDaysToPreviousEvent,
            minDaysToPreviousEvent,
        } = event;
        const { eventsWithoutTest } = moduleStore.moduleModel;
        const numOfMainEvents = eventsWithoutTest.reduce((acc, { dependentEvents }) => {
            const isMainEvent = dependentEvents.some(({ id }) => id === event.id);
            return acc + (isMainEvent ? 1 : 0);
        }, 0);
        if ((isPreviousEventInRow
            || ((maxDaysToPreviousEvent ?? minDaysToPreviousEvent ?? null) !== null)
        ) && numOfMainEvents < 1) {
            return {
                valid: false,
                error: 'Укажите связи между встречами для работы параметра "Предыдущая встреча идет встык с текущей"',
            };
        }
        return { valid: true, error: '' };
    }

    static validateNextEvent(event: EventWithoutTest): Error {
        if (!event) return { valid: true, error: '' };
        const {
            isNextEventInRow,
            maxDaysToNextEvent,
            minDaysToNextEvent,
        } = event;

        if ((isNextEventInRow
            || ((maxDaysToNextEvent ?? minDaysToNextEvent ?? null) !== null)
        ) && event.dependentEvents.length < 1) {
            return {
                valid: false,
                error: 'Укажите связи между встречами для работы параметра "Следующая встреча идет встык с текущей"',
            };
        }
        return { valid: true, error: '' };
    }
}
