import React, { useEffect } from 'react';

import cn from 'classnames';
import classes from './ZoneSelector.module.scss';

interface TerritorialZone {
    id: string;
    name: string;
}

interface Props {
    sortedTerritorialZones: TerritorialZone[];
    zoneCheck: boolean;
    setSelectedZone: (id: string | null) => void;
    territorialZoneId?: string|null,
}

export function ZoneSelector({
    sortedTerritorialZones,
    zoneCheck,
    setSelectedZone,
    territorialZoneId = '',
}: Props): JSX.Element {
    useEffect(
        () => {
            if (territorialZoneId) {
                setSelectedZone(territorialZoneId);
            }
        },
        [],
    );
    return (
        <div className={classes.zoneSelector}>
            <label
                className={cn(classes.zoneSelector__label, {
                    [classes.zoneSelector__label_disabled]: !zoneCheck,
                })}
            >
                Территориальная зона:
            </label>
            <select
                className={classes.zoneSelector__select}
                disabled={!zoneCheck}
                onChange={({ currentTarget }) => setSelectedZone(currentTarget.value)}
                defaultValue={territorialZoneId || 'null'}
            >
                <option value="null" disabled className={classes.zoneSelector__defaultOption}>
                    Выберите территориальную зону
                </option>
                {sortedTerritorialZones.map((item: TerritorialZone) => (
                    <option value={item.id} key={item.id}>{item.name}</option>
                ))}
            </select>
        </div>
    );
}
