import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import colors from '../../store/ColorConstans';

import classes from './SVG.module.scss';

interface Props {
    isOpen: boolean;
    firstColor?: string;
    secondColor?: string;
    thirdColor?: string;
    size?: number;
    className?: string;
    onClick?: () => void;
}

export const ArrowIcon = observer(({
    isOpen,
    size = 16,
    className = '',
    firstColor = colors.firstColor,
    secondColor = colors.secondColor,
    thirdColor = colors.thirdColor,
    onClick = () => { },
}: Props) => (
    <div
        className={cn(classes.arrow, {
            [classes.arrow_reverse]: isOpen,
            [className]: className,
        })
        }
        style={{ height: `${size}px`, width: `${size}px`, cursor: 'pointer' }}
        onClick={onClick}
    >
        <svg width={size} height={size} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.57101 9.1685L15.7637 1.97337C16.0787 1.65765 16.0787 1.14614 15.7637 0.829623C15.4488 0.513905 14.9373 0.513905 14.6224 0.829623L8.00037 7.45404L1.37834 0.83042C1.06342 0.514704 0.551907 0.514704 0.23619 0.83042C-0.0787304 1.14614 -0.0787304 1.65845 0.23619 1.97417L7.42886 9.16929C7.74052 9.48016 8.26008 9.48016 8.57101 9.1685Z" fill="url(#paint0_linear_1556:7256)" />
            <defs>
                <linearGradient
                    id="paint0_linear_1556:7256"
                    x1="7.08858"
                    y1="-0.0439975"
                    x2="9.35558"
                    y2="10.5039"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.130208" stopColor={firstColor} />
                    <stop offset="0.734375" stopColor={secondColor} />
                    <stop offset="0.932292" stopColor={thirdColor} />
                </linearGradient>
            </defs>
        </svg>
    </div>
));
