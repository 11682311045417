import React, { ChangeEvent, useState } from 'react';
import { TerritorialZone, UpdateTerritorialZoneInput } from '@admin/UniversityPage/types';
import {
    findIndex, map, range, remove,
} from 'lodash';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Confirm } from '@common/Confirm';
import { UniversityZonesFormGroup } from '../UniversityZonesFormGroup';
import { addEmptyZones, sortZones } from '../UniversityZones/utilities';
import { transformZonesToCreateZones } from '../UniversityZones/utilities/utilities';
import classes from './UniversityZonesForm.module.scss';

interface Props {
    territorialZones: TerritorialZone[];

    handleSetEditZonesMode(): void;

    updateTerritorialZones(zones: UpdateTerritorialZoneInput[]): void;
}

export function UniversityZonesForm({
    territorialZones,
    handleSetEditZonesMode,
    updateTerritorialZones,
}: Props): JSX.Element {
    const initialStateZones: UpdateTerritorialZoneInput[] = territorialZones.length > 0
        ? transformZonesToCreateZones(territorialZones)
        : [
            {
                id: 'D1OkRwM6cmcdt32q7wV8s',
                name: '',
                travelTimeBetweenZones: [
                    { id: 'D1OkRwM6cmcdt32q7wV8s', travelTime: 0 },
                ],
                isOptimalRoomCapacityForMeetingsEnabled: true,
                availableIntervals: [{
                    id: '1',
                    from: new Date(),
                    to: new Date(),
                }],
            },
        ];

    const [selectValue, setSelectValue] = useState<number>(
        initialStateZones.length,
    );

    const [
        stateZones,
        setStateZones,
    ] = useState<UpdateTerritorialZoneInput[]>(sortZones(initialStateZones));

    const [showWarning, setShowWarning] = useState(false);

    const handleSetShowWarning = (): void => {
        setShowWarning(!showWarning);
    };

    const handleRemoveZone = (id: string): void => {
        const newZones = remove(
            stateZones,
            (zone: UpdateTerritorialZoneInput) => zone.id !== id && {
                ...zone,
                travelTimeBetweenZones: remove(
                    zone.travelTimeBetweenZones,
                    (travelTimeZone) => travelTimeZone.id === id && travelTimeZone,
                ),
            },
        );

        setStateZones(sortZones(newZones));
        setSelectValue(selectValue - 1);
    };

    const handleSubmit = (event: React.SyntheticEvent): void => {
        event.preventDefault();
        updateTerritorialZones(stateZones);
        handleSetEditZonesMode();
    };

    const handleSetSelectValue = ({
        target,
    }: ChangeEvent<HTMLSelectElement>): void => {
        const { value } = target;
        const intSelectValue = Number(value);

        if (stateZones.length < intSelectValue) {
            setSelectValue(intSelectValue);
            const stateZonesCopy = stateZones;
            addEmptyZones(stateZonesCopy, intSelectValue - stateZones.length);
            setStateZones(sortZones(stateZonesCopy));
        }

        if (stateZones.length > intSelectValue) {
            handleSetShowWarning();
        }
    };

    const handleInputChange = ({
        target,
    }: React.ChangeEvent<HTMLInputElement>): void => {
        const { id, name, value } = target;

        if (name === 'name') {
            setStateZones(
                map(stateZones, (zone) => (zone.id === id ? { ...zone, name: value } : zone)),
            );
        }

        if (name === 'travelTime') {
            const travelTime = Number(value);
            const [zoneFromId, zoneToId] = id.split('&&');

            setStateZones(
                map(stateZones, (zone, zoneIndex) => {
                    if (zoneFromId === zone.id) {
                        const newTravelTimeBetweenZones = map(
                            zone.travelTimeBetweenZones,
                            (timeZone) => {
                                if (timeZone.id === zoneToId) {
                                    return { ...timeZone, travelTime };
                                }

                                return timeZone;
                            },
                        );

                        return {
                            ...zone,
                            travelTimeBetweenZones: [...newTravelTimeBetweenZones],
                        };
                    }

                    const indexOfZoneWithZoneToId = findIndex(stateZones, { id: zoneFromId });

                    if (zoneToId === zone.id && zoneIndex > indexOfZoneWithZoneToId) {
                        const newTravelTimeBetweenZones = map(
                            zone.travelTimeBetweenZones,
                            (timeZone) => {
                                if (timeZone.id === zoneFromId) {
                                    return {
                                        ...timeZone,
                                        travelTime,
                                    };
                                }

                                return timeZone;
                            },
                        );

                        return {
                            ...zone,
                            travelTimeBetweenZones: [...newTravelTimeBetweenZones],
                        };
                    }

                    return zone;
                }),
            );
        }
    };

    const onSwicherChange = (id: string) => {
        setStateZones(map(stateZones, (zone) => (zone.id === id
            ? ({
                ...zone,
                isOptimalRoomCapacityForMeetingsEnabled:
                    !zone.isOptimalRoomCapacityForMeetingsEnabled,
            })
            : zone)));
    };

    const onDateChange = (id: string, intervals: any) => {
        setStateZones(map(stateZones, (zone) => (zone.id === id
            ? ({
                ...zone,
                availableIntervals: intervals,
            })
            : zone)));
    };

    return (
        <>
            {showWarning && (
                <Confirm
                    headerText="Предупреждение"
                    descriptionText="Для удаления зоны нажмите крестик"
                    primaryBtnText="Ок"
                    onPrimaryBtnClick={handleSetShowWarning}
                    onSecondaryBtnClick={handleSetShowWarning}
                    onOutClick={handleSetShowWarning}
                />
            )}

            <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.formGroupSelect}>
                    <label
                        className={classes.formGroupSelect__label}
                        htmlFor="select"
                    >
                        Количество территориальных зон в вашем университете:
                        <select
                            id="select"
                            className={classes.formGroupSelect__select}
                            name="selectValue"
                            value={selectValue}
                            onChange={handleSetSelectValue}
                        >
                            {map(range(1, 21), (element, index) => (
                                <option key={index + element}>{element}</option>
                            ))}
                        </select>
                    </label>
                </div>

                {map(stateZones, (zone, index) => (
                    <UniversityZonesFormGroup
                        territorialZonesLength={territorialZones.length}
                        serialNumber={Number(index) + 1}
                        zones={stateZones}
                        zone={zone}
                        handleInputChange={handleInputChange}
                        handleRemoveZone={handleRemoveZone}
                        onSwicherChange={onSwicherChange}
                        onDateChange={onDateChange}
                        key={zone.id}
                    />
                ))}
                <div className={classes.form__buttonsBlock}>
                    <ActionButton
                        type="button"
                        actionType={ActionTypeOfButton.SECONDARY}
                        onClick={handleSetEditZonesMode}
                    >
                        Отменить
                    </ActionButton>
                    <ActionButton
                        type="submit"
                    >Сохранить
                    </ActionButton>
                </div>

            </form>
        </>
    );
}
