import { ApolloError, useMutation } from '@apollo/client';
import { UpdateWavesInput } from '@admin/ScheduleGenerationPage/types';
import { GET_EVENT_DATA, UPDATE_MEETING_WAVES } from '../../scheduleQueries';

export const useUpdateMeetingWavesMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
) => {
    const [updateWavesMutation] = useMutation(UPDATE_MEETING_WAVES, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        updateWaves: (updateMeetingInstanceWavesInput: UpdateWavesInput) => updateWavesMutation({
            variables: {
                updateMeetingInstanceWavesInput,
            },
        }),
    };
};
