import React from 'react';

import { ToolButton } from '../../ToolButton';

interface Props {
    onClick: any;
    disable?: boolean;
}

export const ImportButton = ({ onClick, disable = false }: Props): JSX.Element => (
    <ToolButton
        icon="import"
        onClick={onClick}
        disable={disable}
    />
);
