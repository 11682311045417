import { ApolloError, useMutation } from '@apollo/client';
import { GET_EVENT_DATA, UPDATE_MEETING_DATE } from '../../scheduleQueries';

export const useUpdateMeetingDateMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
) => {
    const [
        updateMeetingInstanceDate,
    ] = useMutation(UPDATE_MEETING_DATE, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        updateMeetingDate: (startDate: string) => updateMeetingInstanceDate({
            variables: {
                updateMeetingInstanceDateInput: {
                    startDate,
                    meetingInstanceId: id,
                },
            },
        }),
    };
};
