import React, { ChangeEvent } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';

import { Switcher } from '@admin/ModuleStatistics/Switcher';

import {
    BaseTimeIntervalType,
    SpaceBaseTimeInterval,
    ModuleAssessment as IModuleAssessment,
} from '@admin/EducationPeriodParametersAndDates/EducationPeriodParametersAndDatesInterfaces';
import classes from './ModuleAssessment.module.scss';

interface Props {
    spaceBaseTimeInterval: SpaceBaseTimeInterval;
    baseTimeIntervalType: BaseTimeIntervalType;
    moduleAssessment?: IModuleAssessment | null;
    onModuleAssessmentChange(moduleAssessment?: IModuleAssessment): void;
}

export function ModuleAssessment({
    spaceBaseTimeInterval,
    baseTimeIntervalType,
    moduleAssessment,
    onModuleAssessmentChange,
}: Props) {
    function onModuleAssessmentToggle() {
        if (moduleAssessment?.id !== undefined) {
            onModuleAssessmentChange();
        } else {
            onModuleAssessmentChange({
                ...moduleAssessment,
                isDynamicStartDate: false,
                id: nanoid(),
            });
        }
    }

    function onDynamicStartDateToggle() {
        onModuleAssessmentChange({
            ...moduleAssessment,
            isDynamicStartDate: !moduleAssessment?.isDynamicStartDate,
            startDate: undefined,
            id: moduleAssessment?.id!,
        });
    }

    function onStartDateChange({ target }: ChangeEvent<HTMLInputElement>) {
        const startDate = target.value
            ? `${format(new Date(target.value), 'yyyy-MM-dd')} 00:00`
            : undefined;
        onModuleAssessmentChange({
            ...moduleAssessment!,
            startDate: moduleAssessment!.isDynamicStartDate ? undefined : startDate,
        });
    }

    function onEndDateChange({ target }: ChangeEvent<HTMLInputElement>) {
        const endDate = target.value
            ? `${format(new Date(target.value), 'yyyy-MM-dd')} 23:59`
            : undefined;
        onModuleAssessmentChange({
            ...moduleAssessment!,
            endDate,
        });
    }

    const startDate = moduleAssessment?.startDate && format(new Date(moduleAssessment!.startDate!), 'yyyy-MM-dd');
    const endDate = moduleAssessment?.endDate && format(new Date(moduleAssessment!.endDate!), 'yyyy-MM-dd');

    return (
        <div className={classes['module-assessment']}>
            <div className={classes['module-assessment__title']}>
                Задайте параметры студенческой оценки преподавателей:
            </div>
            <div className={classes['module-assessment__switcher']}>
                <Switcher
                    label={`Проводить СОП для ${spaceBaseTimeInterval.order} ${baseTimeIntervalType.name}`}
                    onClick={onModuleAssessmentToggle}
                    checked={moduleAssessment?.id !== undefined}
                />
            </div>
            <div className={cn(classes['module-assessment__settings'], {
                [classes['module-assessment__settings_disabled']]: !moduleAssessment?.id,
            })}
            >
                <div className={classes['module-assessment__switcher']}>
                    <Switcher
                        label="Дата начала СОП задается автоматически"
                        onClick={onDynamicStartDateToggle}
                        checked={!!moduleAssessment?.isDynamicStartDate}
                    />
                </div>
                <p>Укажите даты проведения СОП:</p>
                <div className={classes['module-assessment__date-container']}>
                    <label className={cn(classes['module-assessment__start-date'], {
                        [classes['module-assessment__start-date_disabled']]: moduleAssessment?.isDynamicStartDate,
                    })}
                    >
                        дата начала:
                        <input
                            className={classes['module-assessment__calendar']}
                            key={moduleAssessment?.startDate}
                            type="date"
                            defaultValue={startDate}
                            onChange={onStartDateChange}
                        />
                    </label>
                    <label className={classes['module-assessment__start-date']}>
                        дата окончания:
                        <input
                            className={classes['module-assessment__calendar']}
                            key={Number(moduleAssessment?.id !== undefined)}
                            type="date"
                            data-date-format="DD-MMMM-YY"
                            defaultValue={endDate}
                            onChange={onEndDateChange}
                        />
                    </label>
                </div>
            </div>
        </div>
    );
}
