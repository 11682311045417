import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { IconDeprecated } from '@common';
import { DaySchedule } from '../../../../EducationPeriodParametersAndDatesInterfaces';
import { getWeekDaysWithAdditionSchedule } from '../../../EducationPeriodParametersDaySchedules';
import classes from './TimePicker.module.scss';
import { DaysNavigation } from './DaysNavigation/DaysNavigation';
import { WorkingIntervals } from './WorkingIntervals';

interface Props {
    daySchedules: DaySchedule[],
    update(daySchedules: DaySchedule[]):void,
}

function getCurrentDaySchedule(daySchedules: DaySchedule[], currentDay: string) {
    return daySchedules.find((daySchedule) => daySchedule.weekDay === currentDay)
        || { weekDay: currentDay, workingIntervals: [] };
}

export function TimePicker({ update, daySchedules: daySchedulesProps }:Props) {
    const [daySchedules, setDaySchedules] = useState<DaySchedule[]>(cloneDeep(daySchedulesProps));
    const [currentDay, setCurrentDay] = useState<string>('Monday');
    const weekDays = getWeekDaysWithAdditionSchedule(daySchedules);

    return (
        <div className={classes.timePicker}>
            <div className={classes.timePicker__daysNavigation}>
                <DaysNavigation
                    currentDay={currentDay}
                    setCurrentDay={setCurrentDay}
                    weekDays={weekDays}
                />
            </div>
            <div className={classes.timePicker__workIntervals}>
                <WorkingIntervals
                    daySchedule={getCurrentDaySchedule(daySchedules, currentDay)}
                    daySchedules={daySchedules}
                    setDaySchedules={setDaySchedules}
                />
                <IconDeprecated
                    id="check"
                    className={classes.timePicker__saveButton}
                    click={() => update(daySchedules)}
                />
            </div>
        </div>
    );
}
