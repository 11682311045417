import { makeAutoObservable } from 'mobx';

/* eslint import/no-cycle: "off" */
import { Meeting } from './Meeting';
import { MeetingListModel } from './MeetingListModel';
import { EvaluationPoint } from './EvaluationPoint';
import { EvaluationPointListModel } from './EvaluationPointListModel';
import { Options, defaultOptions } from './Options';
import {
    getRefactorOptionsFromApollo,
    getRefactorMeetingsFromApollo,
    getRefactorEvaluationPointsFromApollo,
    getRefactorAssignmentsFromApollo,
} from './refactorModule';
import { ModuleSkill, PointSkill, DependentEvent } from './moduleStoreTypes';
import { AssignmentListModel } from './AssignmentListModel';
import { Assignment } from './Assignment';

export type Event = Meeting | Assignment | EvaluationPoint;
export type EventWithoutTest = Meeting | Assignment;

export class ModuleModel {
    options = new Options();

    meetingList = new MeetingListModel();

    evaluationPointList = new EvaluationPointListModel();

    assignmentList = new AssignmentListModel();

    constructor() {
        makeAutoObservable(this);
    }

    public get meetings(): Meeting[] {
        return this.meetingList.meetings;
    }

    public get assignments(): Assignment[] {
        return this.assignmentList.assignments;
    }

    public get evaluationPoints(): EvaluationPoint[] {
        return this.evaluationPointList.evaluationPoints;
    }

    public get events(): (Meeting | Assignment | EvaluationPoint)[] {
        return [...this.meetings, ...this.assignments, ...this.evaluationPoints];
    }

    public get eventsWithoutTest(): (Meeting | Assignment)[] {
        return [...this.meetings, ...this.assignments];
    }

    getEvent = (eventId: string): Event | undefined => this.events.find(({ id }) => eventId === id);

    getEventWithoutTest = (eventId: string): EventWithoutTest | undefined => this.eventsWithoutTest
        .find(({ id }) => eventId === id);

    getMeeting = (id: string): Meeting | undefined => this.meetingList.getMeeting(id);

    updateMeeting = (id: string, meeting: Partial<Meeting>): void => {
        this.meetingList.updateMeeting(id, meeting);
    };

    addMeeting = (): void => {
        this.meetingList.addMeeting();
    };

    removeMeeting = (id: string): void => {
        this.meetingList.removeMeeting(id);
        this.saveValidAllEvaluationPointsSkills();
    };

    getAssignment = (id: string): Assignment | undefined => this.assignmentList.getAssignment(id);

    updateAssignment = (id: string, assignment: Partial<Assignment>): void => {
        this.assignmentList.updateAssignment(id, assignment);
    };

    addAssignment = (): void => {
        this.assignmentList.addAssignment();
    };

    removeAssignment = (id: string): void => {
        this.assignmentList.removeAssignment(id);
        this.saveValidAllEvaluationPointsSkills();
    };

    getEvaluationPoint = (id: string): EvaluationPoint | undefined => this.evaluationPointList
        .getEvaluationPoint(id);

    updateEvaluationPoint = (id: string, evaluationPoint: Partial<EvaluationPoint>): void => {
        this.evaluationPointList.updateEvaluationPoint(id, evaluationPoint);
    };

    addEvaluationPoint = (): void => {
        this.evaluationPointList.addEvaluationPoint();
    };

    removeEvaluationPoint = (id: string): void => {
        this.evaluationPointList.removeEvaluationPoint(id);
    };

    restore = (): void => this.updateModule(defaultOptions, [], [], []);

    updateModule = (
        options: Partial<Options>,
        meetings: Meeting[],
        assignments: Assignment[],
        evaluationPoints: EvaluationPoint[],
    ): void => {
        this.updateOptions(getRefactorOptionsFromApollo(options));
        this.meetingList.updateAllMeetings(getRefactorMeetingsFromApollo(meetings));
        this.assignmentList.updateAllAssignments(getRefactorAssignmentsFromApollo(assignments));
        this.evaluationPointList.updateALLEvaluationPoints(
            getRefactorEvaluationPointsFromApollo(evaluationPoints),
        );
    };

    updateOptions = (data: Partial<Options>): void => {
        Object.keys(data).forEach(key => {
            // @ts-ignore
            this.options[key] = data[key];
        });
    };

    updateMeetingRequiredSkills = ({ meetingID, newSkills, typeID }: {
        meetingID: string,
        newSkills: ModuleSkill[],
        typeID: string,
    }): void => {
        const meeting = this.getMeeting(meetingID);
        meeting?.updatePrerequisitesSkills(newSkills, typeID);
    };

    updateMeetingOutputSkills = ({ meetingID, newSkills, typeID }: {
        meetingID: string,
        newSkills: ModuleSkill[],
        typeID: string,
    }): void => {
        const meeting = this.getMeeting(meetingID);
        meeting?.updateOutputSkills(newSkills, typeID);
        this.saveValidAllEvaluationPointsSkills();
    };

    updateAssignmentRequiredSkills = ({ assignmentID, newSkills, typeID }: {
        assignmentID: string,
        newSkills: ModuleSkill[],
        typeID: string,
    }): void => {
        const assignment = this.getAssignment(assignmentID);
        assignment?.updatePrerequisitesSkills(newSkills, typeID);
    };

    updateAssignmentOutputSkills = ({ assignmentID, newSkills, typeID }: {
        assignmentID: string,
        newSkills: ModuleSkill[],
        typeID: string,
    }): void => {
        const assignment = this.getAssignment(assignmentID);
        assignment?.updateOutputSkills(newSkills, typeID);
        this.saveValidAllEvaluationPointsSkills();
    };

    saveValidAllEvaluationPointsSkills(): void {
        this.evaluationPointList.saveValidAllEvaluationPointsSkills();
    }

    getSkillsSuggestForEvaluationPoint = (pointId: string, typeId: string): PointSkill[] => this
        .evaluationPointList.getSkillsSuggestForEvaluationPoint(pointId, typeId);

    getPointSkillsForPoint = (pointId: string): PointSkill[] => this.evaluationPointList
        .getPointSkillsForPoint(pointId);

    changeOrder = (eventId: string, newOrder: number): void => {
        const { events } = this;
        const event = events.find(({ id }) => id === eventId);
        if (!event || event.order === newOrder) return;
        const minOrder = Math.min(event.order, newOrder);
        const maxOrder = Math.max(event.order, newOrder);
        const otherEvents = events.filter(({ order }) => order >= minOrder && order <= maxOrder);
        otherEvents.forEach(({ order }, index) => {
            otherEvents[index].order = order + Math.sign(event.order - newOrder);
        });
        event.order = newOrder;
    };

    choseEventForEditConnections = (eventId: string): void => {
        const editableEvent = this.eventsWithoutTest.find(({ id }) => eventId === id);
        Object.assign(editableEvent, { isEditable: true });
        this.eventsWithoutTest.forEach(event => {
            const isChosen = editableEvent?.dependentEvents.some(({ id }) => id === event.id);
            Object.assign(event, { isChosen });
        });
    };

    updateChosenStateOfEvent = (eventId: string, chosen: boolean): void => {
        const event = this.eventsWithoutTest.find(({ id }) => id === eventId);
        if (!event) return;
        event.isChosen = chosen;
    };

    updateDependentEventsInEditState = (): void => {
        const { eventsWithoutTest } = this;
        const editableEvent = eventsWithoutTest.find(({ isEditable }) => isEditable);
        if (!editableEvent) return;
        const chosenEvents = eventsWithoutTest.filter(({ isChosen }) => isChosen);
        editableEvent.dependentEvents = chosenEvents.map(({ id, type }) => ({ id, type }));
        editableEvent.isEditable = false;
        eventsWithoutTest.forEach((event, index) => {
            eventsWithoutTest[index].isChosen = false;
        });
        this.saveValidAllEvaluationPointsSkills();
    };

    cancelStateOfEditConnections = (): void => {
        const editableEvent = this.eventsWithoutTest.find(({ isEditable }) => isEditable);
        if (!editableEvent) return;
        editableEvent.isEditable = false;
        this.eventsWithoutTest.forEach((event, index) => {
            this.eventsWithoutTest[index].isChosen = false;
        });
    };

    updateDependentEvents = (): void => {
        this.eventsWithoutTest.forEach((event, index) => {
            this.eventsWithoutTest[index].dependentEvents = this.removeNonexistentEvents(
                event.dependentEvents,
            );
        });
    };

    private removeNonexistentEvents = (dependentEvents: DependentEvent[]): DependentEvent[] => {
        const correctIds = dependentEvents.filter(({ id }) => {
            const isExist = this.events.some(event => event.id === id);
            return isExist;
        });
        return correctIds;
    };

    updateOrderOfDependentEvaluationPoints = (eventId: string): void => {
        const event = this.getEvent(eventId);
        if (!event) return;
        const dependentPoints = this.evaluationPoints
            .filter(({ previousEvent }) => previousEvent?.id === eventId);
        dependentPoints.forEach((point, index) => {
            this.changeOrder(point.id, event.order + index + 1);
        });
    };

    getEventsOneLevelHigher = (eventId: string) => this.eventsWithoutTest
        .filter(({ dependentEvents }) => dependentEvents.some(({ id }) => id === eventId));

    hasLoopingEventDependencies = (eventId: string, dependentEventIds?: string[]): boolean => {
        const eventsOneLevelHigher = this.getEventsOneLevelHigher(eventId);
        if (!eventsOneLevelHigher.length) return false;

        let isCollision = false;
        eventsOneLevelHigher.forEach(event => {
            const eventHappened = dependentEventIds?.some(id => id === event.id);
            if (eventHappened) isCollision = true;
        });
        if (isCollision) return true;

        const results = eventsOneLevelHigher.map(({ id }) => this.hasLoopingEventDependencies(
            id, [...(dependentEventIds ?? []), id],
        ));
        const isCollisionLower = results.reduce((acc, cur) => (cur || acc), false);

        return isCollisionLower;
    };
}
