import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import './Calendar.scss';

interface Props {
    start: null | Date;
    end: null | Date;
    customTimeInput?: JSX.Element;
    customInput?: JSX.Element;
    showTimeInput?: boolean;
    shouldCloseOnSelect?: boolean;
    modifier?: string;
    onChange(update: any): void;
}

export function Calendar({
    start,
    end,
    customTimeInput,
    showTimeInput,
    shouldCloseOnSelect,
    modifier,
    customInput,
    onChange,
}: Props): JSX.Element {
    return (
        <div className="calendar">
            <DatePicker
                selectsRange
                startDate={start}
                endDate={end}
                onChange={onChange}
                isClearable
                placeholderText={(start && end) ? `${start} - ${end}` : 'Выбрать даты'}
                dateFormat="dd.MM.yyyy"
                locale={ru}
                className={`calendar__input calendar__input_${modifier}`}
                autoComplete="on"
                showYearDropdown
                dropdownMode="scroll"
                customTimeInput={customTimeInput}
                showTimeInput={showTimeInput}
                shouldCloseOnSelect={shouldCloseOnSelect}
                timeInputLabel="Время:"
                customInput={customInput}
                calendarStartDay={1}
                popperClassName="calendar__popperRoom"
                wrapperClassName="calendar__wrapperRoom"
            />
        </div>
    );
}
