import React, { useState } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { ApolloQueryResult, FetchResult, OperationVariables } from '@apollo/client';

import { Tabs } from '@common/Tabs';
import { AuthRoute } from '@common/AuthRoute';
import { AvtorScheduleDownload } from '@admin/EducationPeriodParametersAndDates/AvtorScheduleDownload';
import {
    CreateSpaceBaseTimeIntervalParameters,
    DaySchedule,
    Mode,
    Space,
    StudentWorkload,
    UpdateSpaceBaseTimeIntervalParameters,
} from './EducationPeriodParametersAndDatesInterfaces';
import { EducationPeriodParameters } from './EducationPeriodParameters';
import { EducationPeriodDates } from './EducationPeriodDates';
import { ViewType } from '../../graphql-query-types';

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

type SetModeFunction = React.Dispatch<React.SetStateAction<Mode>>;

interface Props {
    datesMode: Mode,
    spaces: Space[],
    parametersMode: Mode,
    avtorSchedule: { schedule: string; weekNumber: number; },
    isSecret: boolean;
    setDatesMode: SetModeFunction,
    setParametersMode: SetModeFunction,

    updateParameters(
        id: string,
        daySchedules: DaySchedule[],
        studentWorkload: StudentWorkload,
    ): ApolloPromise,

    updatePeriod(
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: UpdateSpaceBaseTimeIntervalParameters[],
    ): ApolloPromise,

    createPeriod(
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: CreateSpaceBaseTimeIntervalParameters[],
    ): ApolloPromise,

    getAvtorSchedule(weekNumber: number): void,
    refetchGetSpaces: (variables?:
    Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{
        spaces: Space[];
    }>>
}

function generateTabsOptions(spaces: Space[]) {
    return spaces.map((space) => [space.name, space.id]);
}

export function EducationPeriodParametersAndDates({
    spaces,
    datesMode,
    avtorSchedule,
    parametersMode,
    isSecret,
    updatePeriod,
    createPeriod,
    setDatesMode,
    updateParameters,
    getAvtorSchedule,
    setParametersMode,
    refetchGetSpaces,
}: Props) {
    const [tabsOptions] = useState(() => generateTabsOptions(spaces));
    const { path } = useRouteMatch();

    return (
        <div>
            <Tabs tabsOptions={tabsOptions} />
            <Switch>
                {
                    tabsOptions.map(([name, id], index) => (
                        <AuthRoute
                            exact
                            path={`${path}/${id}`}
                            key={`${name}`}
                            requiredUserTypes={[ViewType.Admin]}
                        >
                            <>
                                <EducationPeriodParameters
                                    space={spaces[index]}
                                    mode={parametersMode}
                                    setMode={setParametersMode}
                                    updateParameters={updateParameters}
                                />
                                <AvtorScheduleDownload
                                    avtorSchedule={avtorSchedule}
                                    getAvtorSchedule={getAvtorSchedule}
                                />
                                <EducationPeriodDates
                                    mode={datesMode}
                                    space={spaces[index]}
                                    isSecret={isSecret}
                                    setMode={setDatesMode}
                                    updatePeriod={updatePeriod}
                                    createPeriod={createPeriod}
                                    refetchGetSpaces={refetchGetSpaces}
                                />
                            </>
                        </AuthRoute>
                    ))}
                <AuthRoute
                    exact
                    path={`${path}`}
                    requiredUserTypes={[ViewType.Admin]}
                >
                    <Redirect to={`${path}/${tabsOptions[0][1]}`} />
                </AuthRoute>
            </Switch>

        </div>
    );
}
