import React, { useState } from 'react';
import { ActionButton } from '@common/ActionButton';
import {
    capitalize, compact, filter, map,
} from 'lodash';
import { observer } from 'mobx-react-lite';
import { Alert } from '@common/Alert';
import { skillTypesData_skillTypes } from '../../graphql-query-types';
import { SlotParametersFormField } from '../SlotParametersFormField';
import {
    filterSelectedSkillBySkillTypeName,
    filterSpecializedSkillsBySelectedSkill,
    filterSubspaceSpecializedSkillsBySkillType,
} from '../SlotParameters/utilities';
import Diagram from '../store/Diagram';
import classes from './SlotParametersForm.module.scss';

import {
    // SkillType,
    SpecializedSkillsInput,
    SubspaceSkill,
    UpdateModuleSlotSkillInput,
    UpdateSubspaceSkillInput,
} from '../subSpaceTypes';

interface Props {
    slotPrerequisiteSkills: SubspaceSkill[]
    slotOutputSkills: SubspaceSkill[]
    skillTypes: skillTypesData_skillTypes[]
    rootSkillTypes: skillTypesData_skillTypes[]
    prerequisiteSpecializedSkills: SubspaceSkill[]
    outputSpecializedSkills: SubspaceSkill[]
    prerequisiteAndOutputGroupNames: string[]

    updateModuleSlotSkills(moduleSlotSkills: UpdateModuleSlotSkillInput): void;
}

export const SlotParametersForm = observer(({
    slotOutputSkills,
    slotPrerequisiteSkills,
    skillTypes,
    rootSkillTypes,
    prerequisiteSpecializedSkills,
    outputSpecializedSkills,
    prerequisiteAndOutputGroupNames,
    updateModuleSlotSkills,
}: Props): JSX.Element => {
    const { slotSettings } = Diagram;

    const [
        mutablePrerequisiteSpecializedSkills,
        setMutablePrerequisiteSpecializedSkills,
    ] = useState<SubspaceSkill[]>(prerequisiteSpecializedSkills);

    const [
        mutableOutputSpecializedSkills,
        setMutableOutputSpecializedSkills,
    ] = useState<SubspaceSkill[]>(outputSpecializedSkills);

    const [prerequisiteGroupName, outputGroupName] = prerequisiteAndOutputGroupNames;

    const [
        prerequisiteSkillInput,
        setPrerequisiteSkillInput,
    ] = useState<any>({});

    const [
        outputSkillInput,
        setOutputSkillInput,
    ] = useState<any>({});

    const [prerequisiteSkills, setPrerequisiteSkills] = useState<SubspaceSkill[]>(
        slotPrerequisiteSkills,
    );

    const [outputSkills, setOutputSkills] = useState<SubspaceSkill[]>(
        slotOutputSkills,
    );

    const [showWarning, setShowWarning] = useState<boolean>(false);

    const [
        selectedPrerequisiteSkills,
        setSelectedPrerequisiteSkills,
    ] = useState<SpecializedSkillsInput>(
        filterSelectedSkillBySkillTypeName(
            slotPrerequisiteSkills, skillTypes,
        ),
    );

    const [
        selectedOutputSkills,
        setSelectedOutputSkills,
    ] = useState<SpecializedSkillsInput>(
        filterSelectedSkillBySkillTypeName(
            slotOutputSkills, skillTypes,
        ),
    );

    const handleSetSkillInput = (
        inputValue: string,
        specializedSkillsType: 'prerequisite' | 'output',
        englishSkillName: string,
    ): void => {
        if (specializedSkillsType === 'prerequisite') {
            setPrerequisiteSkillInput({
                [englishSkillName]: inputValue,
            });
        }

        if (specializedSkillsType === 'output') {
            setOutputSkillInput({
                [englishSkillName]: inputValue,
            });
        }
    };

    const handleSetSelectedSkills = (
        inputValue: string,
        specializedSkillsType: 'prerequisite' | 'output',
        englishSkillName: string,
    ): void => {
        setShowWarning(false);

        if (specializedSkillsType === 'prerequisite') {
            setSelectedPrerequisiteSkills({
                ...selectedPrerequisiteSkills,
                [englishSkillName]: [...selectedPrerequisiteSkills[englishSkillName], inputValue],
            });

            setPrerequisiteSkills([
                ...prerequisiteSkills,
                ...compact(
                    map(
                        prerequisiteSpecializedSkills,
                        skill => {
                            const skillName = skill.skill.name === skill.skill.fullName
                                ? skill.skill.name
                                : skill.skill.fullName;
                            if (skill.level) {
                                if ([skillName, skill.level].join(' ') === inputValue) {
                                    setMutablePrerequisiteSpecializedSkills(
                                        filterSpecializedSkillsBySelectedSkill(
                                            mutablePrerequisiteSpecializedSkills,
                                            skill,
                                        ),
                                    );
                                    return skill;
                                }
                                return null;
                            }
                            if (skillName === inputValue) {
                                setMutablePrerequisiteSpecializedSkills(
                                    filterSpecializedSkillsBySelectedSkill(
                                        mutablePrerequisiteSpecializedSkills,
                                        skill,
                                    ),
                                );
                                return skill;
                            }
                            return null;
                        },
                    ),
                )]);
            setPrerequisiteSkillInput({ ...prerequisiteSkillInput, [englishSkillName]: '' });
        }
        if (specializedSkillsType === 'output') {
            setSelectedOutputSkills({
                ...selectedOutputSkills,
                [englishSkillName]: [...selectedOutputSkills[englishSkillName], inputValue],
            });

            setOutputSkills([
                ...outputSkills,
                ...compact(map(
                    outputSpecializedSkills,
                    skill => {
                        const skillName = skill.skill.name === skill.skill.fullName
                            ? skill.skill.name
                            : skill.skill.fullName;
                        if (skill.level) {
                            if ([skillName, skill.level].join(' ') === inputValue) {
                                setMutableOutputSpecializedSkills(
                                    filterSpecializedSkillsBySelectedSkill(
                                        mutableOutputSpecializedSkills,
                                        skill,
                                    ),
                                );
                                return skill;
                            }
                            return null;
                        }
                        if (skillName === inputValue) {
                            setMutableOutputSpecializedSkills(
                                filterSpecializedSkillsBySelectedSkill(
                                    mutableOutputSpecializedSkills,
                                    skill,
                                ),
                            );
                            return skill;
                        }
                        return null;
                    },
                ))]);
            setOutputSkillInput({ ...outputSkillInput, [englishSkillName]: '' });
        }
    };

    const handleRemoveSelectedSkill = (
        suggestSkillName: string,
        specializedSkillsType: 'prerequisite' | 'output',
        englishSkillName: string,
    ): void => {
        if (specializedSkillsType === 'prerequisite') {
            setSelectedPrerequisiteSkills({
                ...selectedPrerequisiteSkills,
                [englishSkillName]: filter(
                    selectedPrerequisiteSkills[englishSkillName],
                    skill => skill !== suggestSkillName,
                ),
            });

            let removedSkill: SubspaceSkill | undefined;
            if (prerequisiteSkills) {
                setPrerequisiteSkills(compact(
                    map(
                        prerequisiteSkills,
                        skill => {
                            const skillName = skill.skill.name === skill.skill.fullName
                                ? skill.skill.name
                                : skill.skill.fullName;
                            if (skill.level) {
                                if ([skillName, skill.level].join(' ') !== suggestSkillName) {
                                    return skill;
                                }
                                removedSkill = skill;
                                return null;
                            }
                            if (skillName !== suggestSkillName) {
                                return skill;
                            }
                            removedSkill = skill;
                            return null;
                        },
                    ),
                ));
            }

            if (removedSkill) {
                setMutablePrerequisiteSpecializedSkills(compact([
                    ...map(
                        [...prerequisiteSpecializedSkills],
                        skill => {
                            const skillName = skill.skill.name === skill.skill.fullName
                                ? skill.skill.name
                                : skill.skill.fullName;
                            if (skill.level) {
                                if ([skillName, skill.level].join(' ') !== suggestSkillName) {
                                    return skill;
                                }
                                return null;
                            }
                            if (skillName !== suggestSkillName) {
                                return skill;
                            }
                            return null;
                        },
                    ),
                    removedSkill,
                ]));
            }
        }

        if (specializedSkillsType === 'output') {
            setSelectedOutputSkills({
                ...selectedOutputSkills,
                [englishSkillName]: filter(
                    selectedOutputSkills[englishSkillName],
                    skill => skill !== suggestSkillName,
                ),
            });

            let removedSkill: SubspaceSkill | undefined;

            if (outputSkills) {
                setOutputSkills(compact(
                    map(
                        outputSkills,
                        skill => {
                            const skillName = skill.skill.name === skill.skill.fullName
                                ? skill.skill.name
                                : skill.skill.fullName;
                            if (skill.level) {
                                if ([skillName, skill.level].join(' ') !== suggestSkillName) {
                                    return skill;
                                }
                                removedSkill = skill;
                                return null;
                            }
                            if (skillName !== suggestSkillName) {
                                return skill;
                            }
                            removedSkill = skill;
                            return null;
                        },
                    ),
                ));
            }

            setMutableOutputSpecializedSkills(compact([
                ...map(
                    outputSpecializedSkills,
                    skill => {
                        const skillName = skill.skill.name === skill.skill.fullName
                            ? skill.skill.name
                            : skill.skill.fullName;
                        if (skill.level) {
                            if ([skillName, skill.level].join(' ') !== suggestSkillName) {
                                return skill;
                            }
                            return null;
                        }
                        if (skillName !== suggestSkillName) {
                            return skill;
                        }
                        return null;
                    },
                ),
                removedSkill,
            ]));
        }
    };

    const handleSubmit = (event: React.SyntheticEvent): void => {
        event.preventDefault();

        const prerequisiteSkillsInput: UpdateSubspaceSkillInput[] = prerequisiteSkills.map(
            skill => ({
                skill: {
                    id: skill.skill.id,
                    name: skill.skill.name,
                    typeId: skill.skill.typeId,
                },
                level: skill.level,
            }),
        );

        const outputSkillsInput: UpdateSubspaceSkillInput[] = outputSkills.map(
            skill => ({
                skill: {
                    id: skill.skill.id,
                    name: skill.skill.name,
                    typeId: skill.skill.typeId,
                },
                level: skill.level,
            }),
        );

        if (slotSettings.id) {
            updateModuleSlotSkills({
                slotId: slotSettings.id,
                prerequisiteSkills: prerequisiteSkillsInput,
                outputSkills: outputSkillsInput,
            });

            Diagram.updateSlotSkills(
                slotSettings.id,
                prerequisiteSkills,
                outputSkills,
            );
        }

        Diagram.setShowParametersSlotMode();

        setShowWarning(true);
    };

    return (
        <>
            <form className={classes.slotParametersForm} onSubmit={handleSubmit}>
                <div className={classes.prerequisiteAndOutputGroups}>
                    <div className={classes.prerequisiteGroup}>
                        <p className={
                            classes.prerequisiteAndOutputGroups__name
                        }
                        >
                            {capitalize(prerequisiteGroupName)}
                        </p>
                        {map(rootSkillTypes, (skillType, index) => (
                            <SlotParametersFormField
                                skillInput={prerequisiteSkillInput}
                                handleSetSkillInput={handleSetSkillInput}
                                englishSkillName={skillType.name.english.toLowerCase()}
                                handleRemoveSelectedSkill={handleRemoveSelectedSkill}
                                handleSetSelectedSkills={handleSetSelectedSkills}
                                specializedSkillsType="prerequisite"
                                selectedSkills={selectedPrerequisiteSkills}
                                suggestionSkillsList={filterSubspaceSpecializedSkillsBySkillType(
                                    mutablePrerequisiteSpecializedSkills,
                                    skillType,
                                    skillTypes,
                                )
                                }
                                specializedName={prerequisiteGroupName}
                                skillType={skillType}
                                index={index}
                                key={`prerequisite${skillType.id}${index}`}
                                skillsForSelect={filterSubspaceSpecializedSkillsBySkillType(
                                    prerequisiteSkills,
                                    skillType,
                                    skillTypes,
                                )}
                            />
                        ))}
                    </div>
                    <div className={classes.prerequisiteGroup}>
                        <p className={classes.prerequisiteAndOutputGroups__name}>{capitalize(
                            outputGroupName,
                        )}
                        </p>
                        {map(rootSkillTypes, (skillType, index) => (
                            <SlotParametersFormField
                                skillInput={outputSkillInput}
                                handleSetSkillInput={handleSetSkillInput}
                                englishSkillName={skillType.name.english.toLowerCase()}
                                handleRemoveSelectedSkill={handleRemoveSelectedSkill}
                                handleSetSelectedSkills={handleSetSelectedSkills}
                                specializedSkillsType="output"
                                selectedSkills={selectedOutputSkills}
                                suggestionSkillsList={filterSubspaceSpecializedSkillsBySkillType(
                                    mutableOutputSpecializedSkills,
                                    skillType,
                                    skillTypes,
                                )
                                }
                                specializedName={outputGroupName}
                                skillType={skillType}
                                index={index + 1000}
                                key={`output${skillType.id}${index}`}
                                skillsForSelect={filterSubspaceSpecializedSkillsBySkillType(
                                    outputSkills,
                                    skillType,
                                    skillTypes,
                                )}
                            />
                        ))}
                    </div>
                </div>
                <div className={classes.slotParameters__button}>
                    <ActionButton type="submit">
                        Сохранить параметры
                    </ActionButton>
                </div>

            </form>
            {showWarning && <Alert message="Необходимо добавить выходные параметры" />}
        </>
    );
});
