import React, { useEffect, useState } from 'react';
import { range, sortBy } from 'lodash';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { StudentTargetName, useSendMetriks } from '@common/hooks';

import {
    EvaluationPoint,
    EventType,
    Module,
    ModuleSettings,
    StudentSchedule,
} from '../Config/interfaces';
import store from '../store';
import { EventList } from '../EventsList';
import { EventView } from '../EventView';
import UserView from '../../../store/UserView';
import SkillHierarchy from '../../../store/SkillHierarchy';
import { ModuleCardInfo } from '../ModuleCard/ModuleCardInfo';
import { ModuleCardHeader } from '../ModuleCard/ModuleCardHeader';
import { ModuleSkills } from '../ModuleCard/ModulesSkills/ModuleSkills';
import { ViewType } from '../../../graphql-query-types';
import { ModuleAssessmentTab } from '../ModuleCard/ModuleCardAssessment';

import classes from './ModuleContent.module.scss';

interface Props {
    module: Module;
    studentSchedule?: StudentSchedule;
    moduleSettings?: ModuleSettings;
    isElectiveFork: boolean;
    forkId?: string;
    slotId?: string;
    isOpen?: boolean;
    isStudentModuleAssessmentActive?: boolean;
    studentModuleId?: string;
}

enum Tabs {
    ASSESSMENT = 'Оценка модуля и преподавателей',
    DESCRIPTION = 'Описание',
    CONTENT = 'Содержание',
    SKILLS = 'Образовательные результаты',
    TEACHERS = 'Преподаватели',
}

export const ModuleContent = observer(({
    module,
    studentSchedule,
    moduleSettings,
    isElectiveFork,
    forkId,
    slotId,
    isOpen,
    isStudentModuleAssessmentActive,
    studentModuleId,
}: Props): JSX.Element => {
    const priorities = range(1, (store.getForkPriorityCount(forkId) ?? 0) + 1);
    const slot = store.getSlot(forkId, slotId);
    const { prerequisiteSkills, outputSkills } = module;
    const [contentMode, setContentMode] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState(
        isStudentModuleAssessmentActive
            ? Tabs.ASSESSMENT
            : Tabs.DESCRIPTION,
    );

    const { sendYandexMetrika } = useSendMetriks([ViewType.Student]);

    const sortedEvents = [
        ...(updateEvents(
            sortBy(
                module.meetings,
                meeting => {
                    const instance = studentSchedule?.meetingInstances
                        .find(meetingInstance => meetingInstance.meeting.id === meeting.id);
                    return Number(
                        !(instance && new Date(Number(instance.startDate)) < new Date()),
                    ) * 1000 + meeting.order;
                },
            ), 'meetings',
        )),
        // ...(updateEvents(sortBy(module.assignments, 'order'), 'assignments')),
        // ...(updateEvents(sortBy(module.evaluationPoints, 'order'), 'evaluationPoints')),
    ];

    useEffect(() => {
        store.sortForkSlotsByPriorities();
        setContentMode(isOpen ?? contentMode);
    }, [isOpen]);

    const attendanceStudentSchedule = studentSchedule?.meetingInstances
        .flatMap((instance) => instance
            .meetingInstanceToStudents.filter(item => item.student.id === UserView.studentId)
            .map(current => ({
                meetingId: instance.meeting.id,
                attendance: current.attendance,
            })));

    function checkEvent(event: any): boolean {
        if (studentSchedule) {
            let entryEvent: { endDate: string }[] = [];
            if (event.eventName === 'meetings') {
                entryEvent = studentSchedule.meetingInstances
                    .filter(item => item.meeting.id === event.id);
            }
            if (event.eventName === 'assignments') {
                entryEvent = studentSchedule.assignmentInstances
                    .filter(item => item.assignment.id === event.id);
            }
            if (event.eventName === 'evaluationPoints') {
                entryEvent = studentSchedule.evaluationPointInstances
                    .filter(item => item.evaluationPoint.id === event.id);
            }
            return (Number(entryEvent[0]?.endDate) < Date.now());
        }
        return false;
    }

    const tabs = isStudentModuleAssessmentActive
        ? Object.entries(Tabs)
        : Object.entries(Tabs).filter((tab) => tab[1] !== Tabs.ASSESSMENT);

    return (
        <>
            <ModuleCardHeader
                slot={slot}
                moduleName={module.name}
                contentMode={contentMode}
                setContentMode={setContentMode}
                isStudentModuleAssessmentActive={isStudentModuleAssessmentActive}
            />
            {contentMode && (
                <div className={classes.moduleContent}>
                    <div className={classes.tabs}>
                        <ul className={classes.tabs__list}>
                            {tabs.map((tab) => (
                                <li
                                    key={tab[1]}
                                    className={classes.tabs__item}
                                >
                                    <div
                                        onClick={() => {
                                            setActiveTab(tab[1]);
                                            sendYandexMetrika(StudentTargetName.modules, {
                                                moduleTab: tab[1],
                                            });
                                        }}
                                        className={
                                            cn(classes.tabs__tab, {
                                                [classes.tabs__tab_selected]:
                                                    tab[1] === activeTab,
                                            })
                                        }
                                    >
                                        {tab[1]}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {Tabs.DESCRIPTION === activeTab && (
                        <ModuleCardInfo
                            module={module}
                            studentSchedule={studentSchedule}
                        />
                    )}
                    {Tabs.CONTENT === activeTab && (
                        <div className={classes.moduleContent__listOfEvents}>
                            <EventList
                                sortedEvents={sortedEvents}
                                moduleSettings={moduleSettings}
                                attendanceStudentSchedule={attendanceStudentSchedule}
                                checkEvent={checkEvent}
                            />
                            <div className={classes.eventView_hideInMobile}>
                                <EventView
                                    sortedEvents={sortedEvents}
                                    moduleSettings={moduleSettings}
                                    attendanceStudentSchedule={attendanceStudentSchedule}
                                    checkEvent={checkEvent}
                                />
                            </div>
                        </div>
                    )}
                    {Tabs.SKILLS === activeTab && (
                        <div className={classes.moduleContent__skills}>
                            <ModuleSkills
                                title="Для попадания"
                                skillsTree={SkillHierarchy.filterBySkillIds(prerequisiteSkills
                                    .map(({ skill }) => skill.id))}
                                skills={prerequisiteSkills}
                            />
                            <ModuleSkills
                                title="После прохождения"
                                skillsTree={SkillHierarchy.filterBySkillIds(outputSkills
                                    .map(({ skill }) => skill.id))}
                                skills={outputSkills}
                            />
                        </div>
                    )}
                    {Tabs.TEACHERS === activeTab && (
                        <div className={classes.moduleContent__teacher_list}>
                            {module.moduleTeachers.map(({ teacher, teacherRoles }) => (
                                <div className={classes.moduleContent__teacher}>
                                    {`${teacher.user.lastName} ${teacher.user.firstName} ${teacher.user.patronymic}`}
                                    <div className={classes.moduleContent__teacher_roles}>
                                        {teacherRoles.map(({ name }) => `${name}, `)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {Tabs.ASSESSMENT === activeTab && (
                        <ModuleAssessmentTab
                            moduleId={module.id}
                            studentModuleId={studentModuleId}
                        />
                    )}
                    {isElectiveFork && (
                        <div className={classes.moduleContent__priorities}>
                            <div className={classes.moduleContent__priorities_title}>
                                ПРИОРИТЕТ МОДУЛЯ:
                            </div>
                            <div className={classes.moduleContent__priorities_list}>
                                {priorities.map(priority => {
                                    const activePriority = classes
                                        .moduleContent__priorities_priorityActive;
                                    const disablePriority = classes
                                        .moduleContent__priorities_priorityDisable;
                                    const isSelected = slot?.priority === priority;
                                    const isDisable = store
                                        .isPrioritySelected(forkId, slotId, priority);
                                    return (
                                        <div
                                            key={priority}
                                            className={cn(
                                                classes.moduleContent__priorities_priority,
                                                {
                                                    [activePriority]: isSelected,
                                                    [disablePriority]: isDisable,
                                                },
                                            )
                                            }
                                            onClick={() => {
                                                if (!isDisable) {
                                                    store.setPriority(forkId, slotId, priority);
                                                }
                                                if (isSelected) {
                                                    store.setPriority(forkId, slotId, 0);
                                                }
                                            }}
                                        >
                                            {priority}
                                        </div>
                                    );
                                })
                                }
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
});

function updateEvents(events: EventType[] | EvaluationPoint[], type: string) {
    const updatedEvents = events.map((event: EventType | EvaluationPoint, index: number) => ({
        ...event,
        eventName: type,
        eventOrder: Number(index + 1),
    }));
    return updatedEvents;
}
