/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setAdminRoles
// ====================================================

export interface setAdminRoles_updateAdminRoles {
  __typename: "User";
  id: string;
}

export interface setAdminRoles {
  updateAdminRoles: setAdminRoles_updateAdminRoles;
}

export interface setAdminRolesVariables {
  adminRoles: UpdateRolesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTeacherWorkload
// ====================================================

export interface updateTeacherWorkload_updateTeacherWorkload {
  __typename: "Teacher";
  id: string;
}

export interface updateTeacherWorkload {
  updateTeacherWorkload: updateTeacherWorkload_updateTeacherWorkload | null;
}

export interface updateTeacherWorkloadVariables {
  updateWorkload: UpdateTeacherWorkloadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setRoles
// ====================================================

export interface setRoles_updateRoleTypes_teacher_workingHours {
  __typename: "WorkingHours";
  id: string;
}

export interface setRoles_updateRoleTypes_teacher {
  __typename: "Teacher";
  id: string;
  workingHours: setRoles_updateRoleTypes_teacher_workingHours[] | null;
}

export interface setRoles_updateRoleTypes {
  __typename: "User";
  id: string;
  teacherId: string | null;
  teacher: setRoles_updateRoleTypes_teacher | null;
}

export interface setRoles {
  updateRoleTypes: setRoles_updateRoleTypes;
}

export interface setRolesVariables {
  setList: UpdateRoleTypesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateStudentWaves
// ====================================================

export interface updateStudentWaves_updateStudentWaves_module {
  __typename: "Module";
  id: string;
}

export interface updateStudentWaves_updateStudentWaves {
  __typename: "Wave";
  id: string;
  module: updateStudentWaves_updateStudentWaves_module;
}

export interface updateStudentWaves {
  updateStudentWaves: updateStudentWaves_updateStudentWaves[];
}

export interface updateStudentWavesVariables {
  updateStudentWaveInput: UpdateStudentWaveInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTrajectoryDraft
// ====================================================

export interface updateTrajectoryDraft_updateTrajectoryDraft {
  __typename: "Trajectory";
  id: string;
}

export interface updateTrajectoryDraft {
  updateTrajectoryDraft: updateTrajectoryDraft_updateTrajectoryDraft;
}

export interface updateTrajectoryDraftVariables {
  updateTrajectoryInput: UpdateTrajectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTrajectory
// ====================================================

export interface updateTrajectory_updateTrajectory {
  __typename: "Trajectory";
  id: string;
}

export interface updateTrajectory {
  updateTrajectory: updateTrajectory_updateTrajectory;
}

export interface updateTrajectoryVariables {
  updateTrajectoryInput: UpdateTrajectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeTrajectory
// ====================================================

export interface removeTrajectory_removeTrajectory {
  __typename: "Trajectory";
  id: string;
}

export interface removeTrajectory {
  removeTrajectory: removeTrajectory_removeTrajectory;
}

export interface removeTrajectoryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: publishTrajectories
// ====================================================

export interface publishTrajectories_publishTrajectories {
  __typename: "Trajectory";
  id: string;
}

export interface publishTrajectories {
  publishTrajectories: publishTrajectories_publishTrajectories[];
}

export interface publishTrajectoriesVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCurrentUser
// ====================================================

export interface getCurrentUser_getCurrentUser_roles {
  __typename: "RoleSwitch";
  roleId: string;
  name: string;
  text: string;
  enabled: boolean;
}

export interface getCurrentUser_getCurrentUser_student {
  __typename: "Student";
  notifications: StudentNotification[];
}

export interface getCurrentUser_getCurrentUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  roles: getCurrentUser_getCurrentUser_roles[];
  availableInterfaceSections: InterfaceSection[];
  student: getCurrentUser_getCurrentUser_student | null;
  studentId: string | null;
  teacherId: string | null;
  acceptedAgreement: boolean;
}

export interface getCurrentUser {
  getCurrentUser: getCurrentUser_getCurrentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: skillTypesData
// ====================================================

export interface skillTypesData_skillTypes_name {
  __typename: "SkillTypeName";
  english: string;
  nominativeSingular: string;
  nominativePlural: string;
  genitiveSingular: string;
  genitivePlural: string;
}

export interface skillTypesData_skillTypes_properties {
  __typename: "SkillTypeProperties";
  maxLevel: number | null;
  description: boolean | null;
}

export interface skillTypesData_skillTypes {
  __typename: "SkillType";
  id: string;
  name: skillTypesData_skillTypes_name;
  parentId: string | null;
  properties: skillTypesData_skillTypes_properties | null;
  possibleDependencies: string[] | null;
}

export interface skillTypesData {
  skillTypes: skillTypesData_skillTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUserById
// ====================================================

export interface getUserById_user_roles {
  __typename: "RoleSwitch";
  name: string;
  text: string;
  enabled: boolean;
}

export interface getUserById_user_teacher_nonWorkingHours {
  __typename: "NonWorkingHours";
  id: string;
  reason: string;
  from: string;
  to: string;
}

export interface getUserById_user_teacher_workingHours {
  __typename: "WorkingHours";
  id: string;
  to: string;
  from: string;
  day: Day;
  weekType: WeekType;
}

export interface getUserById_user_teacher {
  __typename: "Teacher";
  id: string;
  status: string;
  nonWorkingHours: getUserById_user_teacher_nonWorkingHours[] | null;
  workingHours: getUserById_user_teacher_workingHours[] | null;
}

export interface getUserById_user_student_targetSkills_skill_parent_parent_parent_parent {
  __typename: "Skill";
  id: string;
}

export interface getUserById_user_student_targetSkills_skill_parent_parent_parent_children {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
}

export interface getUserById_user_student_targetSkills_skill_parent_parent_parent {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
  parent: getUserById_user_student_targetSkills_skill_parent_parent_parent_parent | null;
  children: getUserById_user_student_targetSkills_skill_parent_parent_parent_children[] | null;
}

export interface getUserById_user_student_targetSkills_skill_parent_parent_children {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
}

export interface getUserById_user_student_targetSkills_skill_parent_parent {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
  parent: getUserById_user_student_targetSkills_skill_parent_parent_parent | null;
  children: getUserById_user_student_targetSkills_skill_parent_parent_children[] | null;
}

export interface getUserById_user_student_targetSkills_skill_parent_children {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
}

export interface getUserById_user_student_targetSkills_skill_parent {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
  parent: getUserById_user_student_targetSkills_skill_parent_parent | null;
  children: getUserById_user_student_targetSkills_skill_parent_children[] | null;
}

export interface getUserById_user_student_targetSkills_skill_children {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
}

export interface getUserById_user_student_targetSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
  parent: getUserById_user_student_targetSkills_skill_parent | null;
  children: getUserById_user_student_targetSkills_skill_children[] | null;
}

export interface getUserById_user_student_targetSkills {
  __typename: "TargetStudentSkill";
  level: number | null;
  skill: getUserById_user_student_targetSkills_skill;
}

export interface getUserById_user_student_space_baseTimeIntervalType {
  __typename: "BaseTimeIntervalType";
  name: string;
}

export interface getUserById_user_student_space {
  __typename: "Space";
  name: string;
  baseTimeIntervalType: getUserById_user_student_space_baseTimeIntervalType;
}

export interface getUserById_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval_space_baseTimeIntervalType {
  __typename: "BaseTimeIntervalType";
  name: string;
}

export interface getUserById_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval_space {
  __typename: "Space";
  id: string;
  name: string;
  baseTimeIntervalType: getUserById_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval_space_baseTimeIntervalType;
}

export interface getUserById_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval {
  __typename: "SpaceBaseTimeInterval";
  order: number;
  space: getUserById_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval_space;
}

export interface getUserById_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  spaceBaseTimeInterval: getUserById_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance_spaceBaseTimeInterval;
}

export interface getUserById_user_student_baseTimeIntervalInstances {
  __typename: "StudentBaseTimeIntervalInstance";
  baseTimeIntervalInstance: getUserById_user_student_baseTimeIntervalInstances_baseTimeIntervalInstance;
}

export interface getUserById_user_student_maxSkills_student {
  __typename: "Student";
  id: string;
}

export interface getUserById_user_student_maxSkills_skill_parent_parent_parent_parent {
  __typename: "Skill";
  id: string;
}

export interface getUserById_user_student_maxSkills_skill_parent_parent_parent_children {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
}

export interface getUserById_user_student_maxSkills_skill_parent_parent_parent {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
  parent: getUserById_user_student_maxSkills_skill_parent_parent_parent_parent | null;
  children: getUserById_user_student_maxSkills_skill_parent_parent_parent_children[] | null;
}

export interface getUserById_user_student_maxSkills_skill_parent_parent_children {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
}

export interface getUserById_user_student_maxSkills_skill_parent_parent {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
  parent: getUserById_user_student_maxSkills_skill_parent_parent_parent | null;
  children: getUserById_user_student_maxSkills_skill_parent_parent_children[] | null;
}

export interface getUserById_user_student_maxSkills_skill_parent_children {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
}

export interface getUserById_user_student_maxSkills_skill_parent {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
  parent: getUserById_user_student_maxSkills_skill_parent_parent | null;
  children: getUserById_user_student_maxSkills_skill_parent_children[] | null;
}

export interface getUserById_user_student_maxSkills_skill_children {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
}

export interface getUserById_user_student_maxSkills_skill {
  __typename: "Skill";
  id: string;
  name: string;
  fullName: string;
  typeId: string;
  maxLevel: number | null;
  description: string | null;
  parent: getUserById_user_student_maxSkills_skill_parent | null;
  children: getUserById_user_student_maxSkills_skill_children[] | null;
}

export interface getUserById_user_student_maxSkills {
  __typename: "StudentSkill";
  id: string;
  isCredited: boolean;
  level: number | null;
  student: getUserById_user_student_maxSkills_student;
  skill: getUserById_user_student_maxSkills_skill;
}

export interface getUserById_user_student_activeWaves_module_waves {
  __typename: "Wave";
  id: string;
  index: number | null;
}

export interface getUserById_user_student_activeWaves_module {
  __typename: "Module";
  id: string;
  name: string | null;
  waves: getUserById_user_student_activeWaves_module_waves[];
}

export interface getUserById_user_student_activeWaves {
  __typename: "Wave";
  id: string;
  index: number | null;
  module: getUserById_user_student_activeWaves_module;
}

export interface getUserById_user_student {
  __typename: "Student";
  id: string;
  timeInterval: string;
  status: string;
  targetSkills: getUserById_user_student_targetSkills[];
  space: getUserById_user_student_space | null;
  baseTimeIntervalInstances: getUserById_user_student_baseTimeIntervalInstances[];
  maxSkills: getUserById_user_student_maxSkills[];
  activeWaves: getUserById_user_student_activeWaves[];
}

export interface getUserById_user {
  __typename: "User";
  id: string;
  lastName: string;
  firstName: string;
  patronymic: string | null;
  birthday: string | null;
  email: string | null;
  status: string;
  teacherId: string | null;
  studentId: string | null;
  roles: getUserById_user_roles[];
  isAdmin: boolean | null;
  teacher: getUserById_user_teacher | null;
  student: getUserById_user_student | null;
}

export interface getUserById {
  user: getUserById_user;
}

export interface getUserByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAdminRoles
// ====================================================

export interface getAdminRoles_adminRoles {
  __typename: "RoleSwitch";
  roleId: string;
  name: string;
  text: string;
  enabled: boolean;
}

export interface getAdminRoles {
  adminRoles: (getAdminRoles_adminRoles | null)[];
}

export interface getAdminRolesVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: spaces
// ====================================================

export interface spaces_spaces_spaceBaseTimeIntervals_baseTimeIntervalInstances {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  endDate: string;
  startDate: string;
}

export interface spaces_spaces_spaceBaseTimeIntervals_subspaceTypeLayouts_subspaceType_subspaces {
  __typename: "Subspace";
  id: string;
  name: string;
  description: string | null;
}

export interface spaces_spaces_spaceBaseTimeIntervals_subspaceTypeLayouts_subspaceType {
  __typename: "SubspaceType";
  id: string;
  name: string;
  isDescriptionEnabled: boolean;
  isSelective: boolean;
  subspaces: spaces_spaces_spaceBaseTimeIntervals_subspaceTypeLayouts_subspaceType_subspaces[];
}

export interface spaces_spaces_spaceBaseTimeIntervals_subspaceTypeLayouts {
  __typename: "SubspaceTypeLayout";
  subspaceType: spaces_spaces_spaceBaseTimeIntervals_subspaceTypeLayouts_subspaceType;
}

export interface spaces_spaces_spaceBaseTimeIntervals {
  __typename: "SpaceBaseTimeInterval";
  id: string;
  order: number;
  baseTimeIntervalInstances: spaces_spaces_spaceBaseTimeIntervals_baseTimeIntervalInstances[];
  subspaceTypeLayouts: spaces_spaces_spaceBaseTimeIntervals_subspaceTypeLayouts[];
}

export interface spaces_spaces_baseTimeIntervalType {
  __typename: "BaseTimeIntervalType";
  id: string;
  name: string;
}

export interface spaces_spaces {
  __typename: "Space";
  id: string;
  name: string;
  spaceBaseTimeIntervals: spaces_spaces_spaceBaseTimeIntervals[];
  baseTimeIntervalType: spaces_spaces_baseTimeIntervalType;
}

export interface spaces {
  spaces: spaces_spaces[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: subspaceTypes
// ====================================================

export interface subspaceTypes_subspaceTypes_subspaces_type {
  __typename: "SubspaceType";
  name: string;
}

export interface subspaceTypes_subspaceTypes_subspaces {
  __typename: "Subspace";
  id: string;
  name: string;
  description: string | null;
  type: subspaceTypes_subspaceTypes_subspaces_type;
}

export interface subspaceTypes_subspaceTypes {
  __typename: "SubspaceType";
  id: string;
  name: string;
  isDescriptionEnabled: boolean;
  isSelective: boolean;
  subspaces: subspaceTypes_subspaceTypes_subspaces[];
}

export interface subspaceTypes {
  subspaceTypes: subspaceTypes_subspaceTypes[];
}

export interface subspaceTypesVariables {
  subspaceTypesInput: SubspaceTypesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: trajectories
// ====================================================

export interface trajectories_trajectories_subspaces_type {
  __typename: "SubspaceType";
  name: string;
  isSelective: boolean;
}

export interface trajectories_trajectories_subspaces {
  __typename: "Subspace";
  id: string;
  name: string;
  description: string | null;
  type: trajectories_trajectories_subspaces_type;
}

export interface trajectories_trajectories {
  __typename: "Trajectory";
  id: string;
  name: string;
  status: TrajectoryStatus;
  description: string | null;
  subspaces: trajectories_trajectories_subspaces[];
}

export interface trajectories {
  trajectories: trajectories_trajectories[];
}

export interface trajectoriesVariables {
  spaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Day {
  friday = "friday",
  monday = "monday",
  saturday = "saturday",
  sunday = "sunday",
  thursday = "thursday",
  tuesday = "tuesday",
  wednesday = "wednesday",
}

export enum InterfaceSection {
  ActiveModules = "ActiveModules",
  ConflictsReport = "ConflictsReport",
  ConsumableEquipment = "ConsumableEquipment",
  EducationalPeriod = "EducationalPeriod",
  Equipment = "Equipment",
  ExternalModules = "ExternalModules",
  MainParameters = "MainParameters",
  MeetingFormats = "MeetingFormats",
  ModuleDrafts = "ModuleDrafts",
  ModuleStatistics = "ModuleStatistics",
  Modules = "Modules",
  OnlineEducation = "OnlineEducation",
  RoomLoad = "RoomLoad",
  Rooms = "Rooms",
  Schedule = "Schedule",
  ScheduleAdditionalGeneration = "ScheduleAdditionalGeneration",
  ScheduleStatistics = "ScheduleStatistics",
  ScheduleTable = "ScheduleTable",
  Skills = "Skills",
  Space = "Space",
  StudentAttendance = "StudentAttendance",
  TeacherLoad = "TeacherLoad",
  TeacherRoleConflicts = "TeacherRoleConflicts",
  Trajectories = "Trajectories",
  University = "University",
  Users = "Users",
}

export enum StudentNotification {
  moduleAssessment = "moduleAssessment",
  moduleSelection = "moduleSelection",
  subspaceSelection = "subspaceSelection",
}

export enum TrajectoryStatus {
  active = "active",
  inactive = "inactive",
}

export enum UserRoleType {
  admin = "admin",
  student = "student",
  teacher = "teacher",
}

export enum WeekType {
  even = "even",
  every = "every",
  odd = "odd",
}

export interface SubspaceTypesInput {
  spaceId?: string | null;
}

export interface UpdateNonWorkingHoursInput {
  id: string;
  reason: string;
  from: string;
  to: string;
}

export interface UpdateRoleInput {
  id: string;
  enabled: boolean;
}

export interface UpdateRoleTypesInput {
  userId: string;
  roleTypes: UserRoleType[];
}

export interface UpdateRolesInput {
  userId: string;
  roles: UpdateRoleInput[];
}

export interface UpdateStudentWaveInput {
  studentId: string;
  newWaveIds: string[];
}

export interface UpdateTeacherWorkloadInput {
  teacherId: string;
  workingHours: UpdateWorkingHoursInput[];
  nonWorkingHours: UpdateNonWorkingHoursInput[];
}

export interface UpdateTrajectoryInput {
  id: string;
  spaceId: string;
  name: string;
  description?: string | null;
  subspaceIds?: string[] | null;
}

export interface UpdateWorkingHoursInput {
  id: string;
  from: string;
  to: string;
  day: Day;
  weekType: WeekType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
