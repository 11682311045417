import React from 'react';
import { ViewType } from '../graphql-query-types';
import { TeacherProfile } from './TeacherProfile';
import { StudentProfile } from './StudentProfile';
import { User } from './profileModels';
import UserView from '../store/UserView';
import classes from './Profile.module.scss';

interface Props {
    userData: User;
}

export function Profile({ userData }: Props) {
    const { teacher, student } = userData;
    const { userViewType } = UserView;

    // const isAdmin = (userViewType?.name === ViewType.Admin);
    const isTeacher = (userViewType?.name === ViewType.Teacher);
    const isStudent = (userViewType?.name === ViewType.Student);

    return (
        <>
            <div className={classes.Profile__title}>Профиль</div>

            {
                isTeacher && (
                    <div className={classes.Profile}>
                        <TeacherProfile teacherData={teacher!} />
                    </div>
                )
            }

            {
                isStudent && <StudentProfile studentData={student!} />
            }
        </>
    );
}
