import React from 'react';
import { upperFirst } from 'lodash';
import { observer } from 'mobx-react-lite';
import { skillTypesData_skillTypes as skillTypesInterface } from 'src/graphql-query-types';

import classes from './SkillsTabs.module.scss';

import { filterSkillTypesForEventView } from './services';
import { SkillTab } from '../SkillTab';
import SkillType from '../../../store/SkillType';

import {
    EvaluationPoint,
    EventType,
    ModuleSettings,
} from '../Config/interfaces';
import { CollapseExpand } from '../CollapseExpand';

interface Props {
    meetingOrAssignmentEvent?: EventType;
    evaluationPointEvent?: EvaluationPoint;
    type?: string;
    moduleSettings?: ModuleSettings;
    title: string;
}

export const SkillsTabs = observer(({
    meetingOrAssignmentEvent,
    evaluationPointEvent,
    type,
    moduleSettings,
    title,
}: Props): JSX.Element | null => {
    const { skillTypes: allSkillTypes } = SkillType;
    const skillTypes = allSkillTypes.filter(
        skillType => evaluationPointEvent
            || moduleSettings?.meetingSkillTypes.some(({ id }) => id === skillType.id),
    ) ?? [];

    const eventSkillTypes: skillTypesInterface[] = filterSkillTypesForEventView(skillTypes);

    const structure = new Map<string, string[]>();

    allSkillTypes.forEach(({ parentId, id }) => {
        if (parentId !== null) {
            if (structure.has(id)) {
                structure.delete(id);
                structure.set(parentId, [id, ...structure.get(id)!]);
            } else {
                const target = Array.from(structure.entries()).find(
                    ([, value]) => value.find(typeId => typeId === parentId),
                );
                if (target) {
                    structure.set(target[0], [...target[1], id]);
                } else {
                    structure.set(parentId, [id]);
                }
            }
        }
    });

    const coreSkillTypes = skillTypes.filter(item => (
        item.parentId === null && !structure.has(item.id)
    ));

    function currentSkill() {
        if (evaluationPointEvent?.evaluationPointSkills) {
            return evaluationPointEvent?.evaluationPointSkills;
        }
        if (type === 'prerequisite') {
            return meetingOrAssignmentEvent?.prerequisiteSkills || [];
        }
        if (type === 'output') {
            return meetingOrAssignmentEvent?.outputSkills || [];
        }
        return [];
    }

    function name(skillType: skillTypesInterface) {
        if (coreSkillTypes.find(({ id }) => skillType.id === id)) {
            return currentSkill().filter(skill => skill.skill.typeId === skillType.id);
        }
        if (structure.has(skillType.id)) {
            return currentSkill().filter(skill => (
                Array.from(structure.get(skillType.id)!).includes(skill.skill.typeId)
            ));
        }
        return [];
    }
    const list = eventSkillTypes
        .map(skillType => name(skillType))
        .filter(skillType => skillType);

    return list.some((listItem) => listItem.length !== 0) ? (
        <>
            <span className={classes.skillsTabs__title}>
                {title}
            </span>
            <div className={classes.skillsTabs}>
                <>
                    {skillTypes && (
                        <div className={classes.skillsTabs__tabs}>
                            {eventSkillTypes.map(skillType => (
                                name(skillType).length !== 0 && (
                                    <div className={classes.skillsTabs__content} key={skillType.id}>
                                        <CollapseExpand
                                            buttonName={upperFirst(skillType.name.genitiveSingular)}
                                        >
                                            <SkillTab
                                                skills={name(skillType)}
                                            />
                                        </CollapseExpand>
                                    </div>
                                )
                            ))}
                        </div>
                    )}
                </>
            </div>
        </>
    ) : null;
});
