import React from 'react';

import { IconDeprecated } from '@common/IconDeprecated';

import classes from './PopUp.module.scss';

interface Props {
    closePortal(): void
    text?: string
}

export function PopUp({
    closePortal,
    text,
}: Props): JSX.Element {
    const ref = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        const closeOfBackground = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                closePortal();
            }
        };
        document.addEventListener('click', closeOfBackground);
        return () => document.removeEventListener('click', closeOfBackground);
    }, []);
    return (
        <div className={classes.popUp}>
            <div className={classes.popUp__wrapper} ref={ref}>
                <div className={classes.popUp__container}>
                    <>{text}</>
                </div>
                <IconDeprecated id="Cross" className={classes.blackCrossAddSkillPopUp} click={closePortal} />
            </div>
        </div>
    );
}
