import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';

import UserView from '@store/UserView';
import { InterfaceSection } from '../../../graphql-query-types';

import { OccupationDashboard } from './OccupationDashboard';
import { RoleConflictsDashboard } from './RoleConflictsDashboard';
import { ConflictsReport } from './ConflictsReport';
import { Tabs } from './Tabs';
import { Attendance } from './Attendance';
import { RoomOccupationDashboard } from './RoomOccupationDashboard';
import classes from './Statistics.module.scss';

interface Props {
    studentsAttendanceLoading: boolean,
    studentsAttendanceError: ApolloError | undefined,
    getLazyStudentsAttendance: (fromDate: Date, toDate: Date) => void,
}

enum Tab {
    STUDENT_ATTENDANCE = 'Cтатистика о посещаемости',
    TEACHER_LOAD = 'Загруженность преподавателей',
    TEACHER_ROLE_CONFLICTS = 'Конфликты ролей преподавателей',
    ROOM_LOAD = 'Загруженность помещений',
    CONFLICTS_REPORT = 'Отчёт по конфликтам',
}

export function Statistics({
    studentsAttendanceLoading,
    studentsAttendanceError,
    getLazyStudentsAttendance,
}: Props): JSX.Element {
    const sections = UserView.user?.availableInterfaceSections;
    const tabs = sections?.reduce(
        (result, section) => {
            switch (section) {
                case InterfaceSection.StudentAttendance:
                    result.push(Tab.STUDENT_ATTENDANCE);
                    break;
                case InterfaceSection.TeacherLoad:
                    result.push(Tab.TEACHER_LOAD);
                    break;
                case InterfaceSection.TeacherRoleConflicts:
                    result.push(Tab.TEACHER_ROLE_CONFLICTS);
                    break;
                case InterfaceSection.RoomLoad:
                    result.push(Tab.ROOM_LOAD);
                    break;
                case InterfaceSection.ConflictsReport:
                    result.push(Tab.CONFLICTS_REPORT);
                    break;
                default:
                    break;
            }
            return result;
        },
        [] as Tab[],
    ) ?? [];
    const [activeTab, setActiveTab] = useState<Tab>(Tab.STUDENT_ATTENDANCE);
    return (
        <div className={classes.statistics}>
            <Tabs<Tab>
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            {
                activeTab === Tab.STUDENT_ATTENDANCE
                && (
                    <Attendance
                        studentsAttendanceLoading={studentsAttendanceLoading}
                        studentsAttendanceError={studentsAttendanceError}
                        getLazyStudentsAttendance={getLazyStudentsAttendance}
                    />
                )
            }
            {
                activeTab === Tab.TEACHER_LOAD
                && (
                    <>
                        <h3 className={classes.statistics__title}>
                            Отчет о занятости преподавателей:
                        </h3>
                        <OccupationDashboard />
                    </>
                )
            }
            {
                activeTab === Tab.TEACHER_ROLE_CONFLICTS
                && (
                    <>
                        <h3 className={classes.statistics__title}>
                            Отчет о конфликтах ролей преподавателей:
                        </h3>
                        <RoleConflictsDashboard />
                    </>
                )
            }
            {
                activeTab === Tab.CONFLICTS_REPORT
                && (
                    <>
                        <h3 className={classes.statistics__title}>
                            Отчет по конфликтам:
                        </h3>
                        <ConflictsReport />
                    </>
                )
            }
            {
                activeTab === Tab.ROOM_LOAD
                && (
                    <>
                        <h3 className={classes.statistics__title}>
                            Отчет о занятости помещений:
                        </h3>
                        <RoomOccupationDashboard />
                    </>
                )
            }
        </div>
    );
}
