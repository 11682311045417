import React, { useState } from 'react';

import { ModuleTabs } from '../ModuleTabs';
import { ModuleTabsContent } from '../ModuleTabsContent';

import { Module as ModuleType, SkillType } from '../../../subSpaceAdmin/subSpaceTypes';

import { skills_skills } from '../../../graphql-query-types';
import classes from './ModuleContent.module.scss';

interface Props {
    currentModule: ModuleType;
    skillTypes: SkillType[];
    allSkillsList: skills_skills[];
}

export function ModuleContent({
    currentModule,
    skillTypes,
    allSkillsList,
}: Props): JSX.Element {
    const [activeTabs, setActiveTabs] = useState('first');
    return (
        <div className={classes.moduleContent}>
            <ModuleTabs
                activeTabs={activeTabs}
                setActiveTabs={setActiveTabs}
            />
            <ModuleTabsContent
                activeTabs={activeTabs}
                currentModule={currentModule}
                skillTypes={skillTypes}
                allSkillsList={allSkillsList}
            />
        </div>
    );
}
