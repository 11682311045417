import React, { useState } from 'react';
import { groupBy } from 'lodash';

import cn from 'classnames';

import classes from './CurrentParamsList.module.scss';

import {
    SubspaceSkill,
} from '../../subSpaceTypes';

import {
    skills_skills,
    skillTypesData_skillTypes,
} from '../../../graphql-query-types';

interface Props {
    skillsParamsType: string;
    paramsList: SubspaceSkill[] | null;
    skillType: skillTypesData_skillTypes;
    sortedSkillTypes: skillTypesData_skillTypes[];
    notSortedSkillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
}

export function CurrentParamsList({
    skillType,
    allSkillsList,
    skillsParamsType,
    paramsList,
    sortedSkillTypes,
    notSortedSkillTypes,
}: Props): JSX.Element {
    const [height, setHeight] = useState(true);

    const getFinalSkillTypeId = (typeId: string): string => {
        const skillTypeGroups = groupBy(notSortedSkillTypes, 'parentId');
        let finalSkillTypeId = typeId;
        while (skillTypeGroups[finalSkillTypeId]) {
            const [child] = skillTypeGroups[finalSkillTypeId];
            finalSkillTypeId = child.id;
        }

        return finalSkillTypeId;
    };

    function isParentSkill(parent: any, child: any): boolean {
        return !!parent.children?.filter((item: any) => item.id !== parent.id)
            .some((item: any) => item.id === child.id);
    }

    function findParentSkill(skill: any): any {
        return allSkillsList.find((item: any) => isParentSkill(item, skill));
    }

    function formatSkillName(skill: any): string {
        let { name } = skill;
        let parent = findParentSkill(skill);

        while (parent) {
            name = `${name}. ${parent.name}`;
            parent = findParentSkill(parent);
        }

        return name;
    }

    const currenSkillTypeParams: any = [];
    paramsList!.forEach((item: any) => {
        if (skillType.id === item.skill.typeId) {
            currenSkillTypeParams.push({ name: item.skill.name, level: item.level });
        }
        if (getFinalSkillTypeId(skillType.id) > String(sortedSkillTypes.length)
            && getFinalSkillTypeId(skillType.id) <= item.skill.typeId) {
            currenSkillTypeParams.push({ name: formatSkillName(item.skill), level: item?.level });
        }
    });
    return (
        <div className={classes.subSpaceCurrentParams}>
            <div
                onClick={currenSkillTypeParams.length > 0
                    ? () => setHeight(!height)
                    : () => null
                }
                className={classes.subSpaceCurrentParams__tittle}
            >
                <div className={cn(classes.subSpaceCurrentParams__triangle, {
                    [classes.subSpaceCurrentParams__staticTriangle]:
                        currenSkillTypeParams.length <= 0,
                    [classes.subSpaceCurrentParams__staticTriangle_withContent]:
                    currenSkillTypeParams.length > 0,
                    [classes.subSpaceCurrentParams__triangle_active]: !height,
                })}
                />
                {skillsParamsType === 'prerequisite' ? 'Входные' : 'Выходные'} {skillType.name.nominativePlural?.toLocaleLowerCase()} подпространства:
            </div>
            <ul className={cn(classes.subSpaceCurrentParams__skillsList, {
                [classes.subSpaceCurrentParams__skillsList_height_null]: height,
            })}
            >
                {currenSkillTypeParams.map((item: any, index: number) => (
                    <li
                        // eslint-disable-next-line
                        key={index}
                        className={classes.subSpaceCurrentParams__skillsItem}
                    >
                        <div className={classes.subSpaceCurrentParams__paramName}>
                            {item.name}
                        </div>
                        <div className={classes.subSpaceCurrentParams__paramLevel}>
                            {item.level}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
