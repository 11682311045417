import React, { ChangeEvent, useState } from 'react';
import { Input, InputTypes } from '@common/Input';
import { UpdateTerritorialZoneInput } from '@admin/UniversityPage/types';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';
import { Calendar } from '@admin/RoomsPage/RoomForm/RoomItems/RoomAvailable/Calendar';
import { PeriodChooser } from '@admin/RoomsPage/RoomForm/RoomItems/RoomAvailable/Calendar/PeriodChooser';
import { findIndex, map, trimStart } from 'lodash';
import { BorderCrossIcon, BorderPlusIcon } from '@common/svg';
import { Switcher } from '@common';
import { Confirm } from '@common/Confirm';
import classes from './UniversityZonesFormGroup.module.scss';

enum DefaultTime {
    fromTime = '00:00',
    toTime = '23:59',
}

interface Props {
    territorialZonesLength: number;
    serialNumber: number;
    zones: UpdateTerritorialZoneInput[];
    zone: UpdateTerritorialZoneInput;

    handleInputChange(event: ChangeEvent<HTMLInputElement>): void;
    onSwicherChange: (id: string) => void;
    handleRemoveZone(id: string): void;
    onDateChange: (id: string, intervals: any) => void;

}

export function UniversityZonesFormGroup({
    territorialZonesLength,
    serialNumber,
    zones,
    zone,
    onDateChange,
    handleInputChange,
    onSwicherChange,
    handleRemoveZone,
}: Props): JSX.Element {
    const [showConfirm, setShowConfirm] = useState(false);

    const {
        id,
        name: intervalName,
        travelTimeBetweenZones,
        availableIntervals,
        isOptimalRoomCapacityForMeetingsEnabled,
    } = zone;

    const handleSetShowConfirm = (): void => {
        setShowConfirm(!showConfirm);
    };

    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [inputValues, setInputValues] = useState({
        fromTime: DefaultTime.fromTime,
        toTime: DefaultTime.toTime,
    });

    const hasStartDate = startDate ?? false;

    const onChangeCalendar = (update: []) => setDateRange(update);

    const { to, from } = formatTimeToNumber(inputValues.toTime, inputValues.fromTime);

    const dateFrom = new Date(startDate)
        .setHours(from.hours, from.minuts);

    const dateTo = new Date(endDate)
        .setHours(to.hours, to.minuts);

    const handleAddAvalebleTime = () => {
        if (startDate && endDate) {
            onDateChange(id, [...availableIntervals || [], {
                id: nanoid(),
                from: new Date(dateFrom),
                to: new Date(dateTo),
            }]);
        }
        setDateRange([]);
        setInputValues({
            fromTime: DefaultTime.fromTime,
            toTime: DefaultTime.toTime,
        });
    };

    const handleRemoveAvalableTime = (intervalId: string) => {
        const newAvalebleTimes = availableIntervals?.filter(item => item.id !== intervalId);
        onDateChange(id, newAvalebleTimes);
    };

    function formatTimeToNumber(timeTo: string, timeFrom: string) {
        return ({
            to: {
                hours: Number(timeTo.slice(0, 2)),
                minuts: Number(timeTo.slice(3, 5)),
            },
            from: {
                hours: Number(timeFrom.slice(0, 2)),
                minuts: Number(timeFrom.slice(3, 5)),
            },
        });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    return (
        <>
            {showConfirm && (
                <Confirm
                    headerText="Внимание"
                    descriptionText="Вы действительно хотите удалить корпус?"
                    secondaryBtnText="Нет"
                    primaryBtnText="Да"
                    onSecondaryBtnClick={handleSetShowConfirm}
                    onOutClick={handleSetShowConfirm}
                    onPrimaryBtnClick={() => handleRemoveZone(id)}
                />
            )}

            <div className={classes.formGroup}>
                <div className={classes.formGroup__name}>
                    <Input
                        id={id}
                        placeholder="Введите название"
                        name="name"
                        value={trimStart(intervalName)}
                        onChange={handleInputChange}
                        label={`Территориальная зона ${serialNumber}:`}
                        inline
                    />
                    {
                        territorialZonesLength > 1
                        && (
                            <BorderCrossIcon
                                size={25}
                                className={classes.formGroup__removeBtn}
                                handler={handleSetShowConfirm}
                            />
                        )
                    }

                </div>

                {map(travelTimeBetweenZones, (travelTimeZone, index) => {
                    const currentTimeZone = findIndex(zones, {
                        id: travelTimeZone.id,
                    });
                    return (
                        <div
                            key={`${id}$${travelTimeZone.id}`}
                            className={classes.formGroup__timeTravel}
                        >
                            <Input
                                id={`${id}&&${travelTimeZone.id}`}
                                type={InputTypes.Number}
                                placeholder="Кол-во"
                                name="travelTime"
                                value={travelTimeZone.travelTime === 0
                                    ? undefined
                                    : travelTimeZone.travelTime.toString()
                                }
                                labelModifier="formGroup__label"
                                onChange={handleInputChange}
                                label={index === 0
                                    ? 'Время перемещения внутри зоны:'
                                    : `Удаленность от территориальной зоны ${currentTimeZone + 1
                                    }:`}
                            />
                            <p className={classes.formGroup__minutes}>мин</p>
                        </div>
                    );
                })}
                <div>
                    <p>Даты доступности:</p>
                    <>
                        <div className={classes.available__calendar}>
                            <Calendar
                                start={startDate}
                                end={endDate}
                                onChange={onChangeCalendar}
                                modifier="placeholder"
                                customInput={(
                                    <button
                                        className={classes.calendar__button}
                                        aria-label="button"
                                        type="button"
                                        onClick={() => { }}
                                    >
                                        {hasStartDate ? (`${format(dateFrom, 'dd.MM.yyyy')} 
                                ${endDate ? `${format(dateTo, '— dd.MM.yyyy')}` : ''} `) : 'Выбрать даты'}
                                    </button>
                                )}
                                customTimeInput={(
                                    <PeriodChooser
                                        fromTime={inputValues.fromTime ?? ''}
                                        toTime={inputValues.toTime ?? ''}
                                        onChangeFromTime={handleChange}
                                        onChangeToTime={handleChange}
                                    />
                                )}
                            />
                            <div className={classes.available__addButton}>
                                <BorderPlusIcon handler={handleAddAvalebleTime} size={20} />
                            </div>
                        </div>
                        <ul className={classes.available_list}>
                            {
                                availableIntervals?.map((item) => (
                                    <li
                                        className={classes.available_listBlock}
                                        key={item.id}
                                    >
                                        <div className={classes.available_nameGroup}>
                                            <div className={classes.available_name}>
                                                <p>
                                                    {`${format(new Date(item.from), 'dd.MM.yyyy')}
                                    ${format(new Date(item.to), '— dd.MM.yyyy')}`}
                                                </p>
                                            </div>
                                        </div>

                                        <button
                                            type="button"
                                            className={classes.available_btn}
                                            onClick={() => handleRemoveAvalableTime(item.id)}
                                        >
                                            <BorderCrossIcon size={20} />
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>
                    </>
                </div>
                <Switcher
                    label={isOptimalRoomCapacityForMeetingsEnabled ? 'оффлайн' : 'онлайн'}
                    checked={isOptimalRoomCapacityForMeetingsEnabled}
                    onClick={() => onSwicherChange(id)}
                />
            </div>
        </>
    );
}
