import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert } from '@common/Alert';
import { EducationSpacePage } from './EducationSpacePage';

interface Props {
    routerPath: string;
}

const GET_SPACES = gql`
  query {
        spaces {
            id
            name
            minCreditCount
            maxCreditCount
            isDifferentSpaceBaseTimeIntervals
            spaceBaseTimeIntervals {
                id
                order
                minCreditCount
                maxCreditCount
            }
        }
    }
`;

const DELETE_SPACE = gql`
    mutation removeSpace($id: String!){
        removeSpace(id: $id) {
            status
        }
    }
`;

export const EducationSpacePageApollo = ({ routerPath }: Props) => {
    const [removeSpaceError, setRemoveSpaceError] = useState(undefined);
    const [removeSpace, { error: gotError }] = useMutation(DELETE_SPACE, {
        refetchQueries: [{ query: GET_SPACES }],
        onError: (spaceError: any) => {
            setRemoveSpaceError(spaceError.message);
        },
    });

    const {
        error,
        loading,
        data,
        refetch,
    } = useQuery(GET_SPACES);

    if (loading) return <></>;
    if (error) return <>`Error! ${error.message}`</>;

    return (
        <>
            <EducationSpacePage
                refetch={refetch}
                spaces={data.spaces}
                removeSpace={(
                    id,
                ) => removeSpace({
                    variables: {
                        id,
                    },
                })}
                routerPath={routerPath}
            />

            {
                gotError
                    && removeSpaceError
                    && <Alert message={gotError.message} time={3000} />
            }
        </>
    );
};
