import React, {
    SyntheticEvent,
    useEffect,
    useState,
} from 'react';

import { Student, StudentsAttendance } from '../../../../Interfaces';
import { FilterStudents } from './FilterStudents';
import { StudentWithAttendance } from './StudentWithAttendance';
import { AttendanceHeader } from './AttendanceHeader';
import { AttendanceMutation } from './AttendanceMutation/AttendanceMutation';
import { AllStudentsToggler } from './AllStudentsToggler';

import classes from './Attendance.module.scss';

interface Suggestion {
    filteredSuggestions: Student[];
}

interface Props {
    userList: Student[]
}

export function Attendance({
    userList,
}: Props): JSX.Element {
    const [students, setStudents] = useState<Student[]>(userList.sort((a, b) => {
        if (a.student.user.lastName[0] > b.student.user.lastName[0]) {
            return 1;
        }
        if (a.student.user.lastName[0] < b.student.user.lastName[0]) {
            return -1;
        }
        return 0;
    }));
    const [isAllStudentsToggled, setIsAllStudentsToggled] = useState<boolean>(false);
    const [isAnyStudentToggled, setIsAnyStudentToggled] = useState<boolean>(false);
    const [editStudentMode, setEditStudentMode] = useState<boolean>(false);
    const [studentsAttended, setStudentsAttended] = useState<StudentsAttendance[]>([]);
    const [search, setSearch] = useState<string>('');

    const [suggestion, setSuggestion] = useState<Suggestion>({
        filteredSuggestions: students,
    });

    const handleSuggestion = (foundSimilarities: Student[]) => {
        setStudents(foundSimilarities);
        setSuggestion({ ...suggestion, filteredSuggestions: foundSimilarities });
    };

    const toggleAttendStudent = ({ currentTarget }: SyntheticEvent<HTMLDivElement>) => {
        const visitedStudents = students
            .map((student) => {
                if (student.student.user.id === currentTarget.id && student.attendance === false) {
                    setIsAnyStudentToggled(true);
                    return { ...student, attendance: true };
                }
                if (student.student.user.id === currentTarget.id && student.attendance === true) {
                    setIsAnyStudentToggled(true);
                    return { ...student, attendance: false };
                }
                if (currentTarget.id === 'All') {
                    if (!isAllStudentsToggled) {
                        setIsAllStudentsToggled(true);
                        return { ...student, attendance: true };
                    }
                    setIsAnyStudentToggled(false);
                    setIsAllStudentsToggled(false);
                    return { ...student, attendance: false };
                }
                return { ...student };
            });
        setStudents(visitedStudents);
        setSuggestion({ filteredSuggestions: visitedStudents });
    };

    const sortStudentsByAttendance = (studentList: Student[]) => {
        const sortedByAttendance = [...studentList]
            .sort((a, b) => (Number(b.attendance) - Number(a.attendance)));
        setStudents([...sortedByAttendance]);
        setSuggestion({ filteredSuggestions: sortedByAttendance });
    };

    const toggleAttendanceCheckMode = () => {
        setEditStudentMode((prevEditStudentMode) => !prevEditStudentMode);
        setSearch('');
    };

    useEffect(() => {
        const toggledAllStudents = students.every((student) => student.attendance === true);
        const isAtLeastOneStudentToggled = students.some((student) => student.attendance === true);

        if (toggledAllStudents) {
            setIsAllStudentsToggled(true);
            setIsAnyStudentToggled(true);
        }
        if (!toggledAllStudents) {
            setIsAllStudentsToggled(false);
        }
        if (isAtLeastOneStudentToggled) {
            setIsAnyStudentToggled(true);
        }
        if (!isAtLeastOneStudentToggled) {
            setIsAnyStudentToggled(false);
            setEditStudentMode(false);
        }

        const attendedStudents = students.map(({ student: {
            id,
        }, attendance }) => ({ studentId: id, attendance }));
        setStudentsAttended(attendedStudents);
    }, [students]);

    useEffect(() => {
        const isAttendance = userList.find((student) => student.attendance);
        if (isAttendance) {
            setEditStudentMode(true);
        }
    }, [userList]);

    useEffect(() => {
        sortStudentsByAttendance(userList);
    }, [userList]);

    return (
        <>
            <AttendanceHeader editStudentMode={editStudentMode} />

            <div className={classes.attendanceSection}>
                <div className={classes.schedulePage_eventsSection_search}>
                    <FilterStudents
                        students={students}
                        handleSuggestion={handleSuggestion}
                        search={search}
                        setSearch={setSearch}
                    />
                    <div className={classes.attendance__allStudentsToggler}>
                        <AllStudentsToggler
                            toggleAttendStudent={toggleAttendStudent}
                            notAllStudentsToggled={isAnyStudentToggled}
                            toggleAllStudents={isAllStudentsToggled}
                            editStudentMode={editStudentMode}
                        />
                    </div>
                </div>
                {
                    (suggestion.filteredSuggestions.map((student) => (
                        <StudentWithAttendance
                            key={student.student.user.id}
                            student={student}
                            toggleAttendStudent={toggleAttendStudent}
                            editStudentMode={editStudentMode}
                        />
                    )))
                }
                <div className={classes.attendance__buttonContainer}>
                    <AttendanceMutation
                        editStudentMode={editStudentMode}
                        studentsAttended={studentsAttended}
                        toggleAttendanceCheckMode={toggleAttendanceCheckMode}
                    />
                </div>
            </div>
        </>
    );
}
