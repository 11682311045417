import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import {
    GenerationProcessStatus,
} from '@admin/ScheduleGenerationPage/store/BaseTimeIntervalInstanceModel';

export enum ScheduleGenerationProcessStatus {
    Creation = 'creation',
    Correction = 'correction',
    Preparing = 'preparing',
    Generation = 'generation',
    GenerationReady = 'generationReady',
    Finalize = 'finalize',
    Generated = 'generated',
}

export interface ScheduleGenerationProcess {
    id: string;
    name: string;
    from: Date;
    to: Date;
    status: GenerationProcessStatus;
    validationErrors?: object;
    scheduleGeneratorErrors?: object;
}

export const GET_SCHEDULE_PERIODS = gql`
    query getSchedulePeriods($baseTimeIntervalInstanceId: String!) {
        scheduleGenerationProcesses(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId) {
            id
            name
            from
            to
            status
        }
    }
`;

export const GET_SCHEDULE_PERIOD = gql`
    query getSchedulePeriod($scheduleGenerationProcessId: String!) {
        scheduleGenerationProcess(id: $scheduleGenerationProcessId) {
            id
            name
            from
            to
            status
            validationErrors
            scheduleGeneratorErrors
        }
    }
`;

export function useScheduleGenerationProcesses(
    baseTimeIntervalInstanceId: string,
) {
    const [getProcesses, { data, loading }] = useLazyQuery(GET_SCHEDULE_PERIODS, {
        variables: { baseTimeIntervalInstanceId },
    });

    const scheduleGenerationProcesses = orderBy(data?.scheduleGenerationProcesses ?? [], 'from');

    return {
        loading,
        scheduleGenerationProcesses,
        getProcesses,
    };
}

export function useScheduleGenerationProcessQuery(
    scheduleGenerationProcessId: string,
) {
    const { data = {}, loading, refetch } = useQuery(GET_SCHEDULE_PERIOD, {
        variables: { scheduleGenerationProcessId },
    });

    return {
        loading,
        refetch,
        scheduleGenerationProcess: data?.scheduleGenerationProcess,
    };
}
