import React from 'react';
import { ModuleMainCharacters } from '@common/ModuleCard/ModuleMainCharacters';
import { ModuleEvents } from '../ModuleEvents';
import { ModuleSkillsList } from '../ModuleSkillsList';

import classes from './ModuleTabsContent.module.scss';

import { SkillType, Module as ModuleType } from '../../../subSpaceAdmin/subSpaceTypes';
import { skills_skills } from '../../../graphql-query-types';

interface Props {
    activeTabs: string;
    currentModule: ModuleType;
    skillTypes: SkillType[];
    allSkillsList: skills_skills[];
}

export function ModuleTabsContent({
    activeTabs,
    currentModule,
    skillTypes,
    allSkillsList,
}: Props):JSX.Element {
    return (
        <div className={classes.moduleTabsContent}>
            {activeTabs === 'first' && (
                <ModuleMainCharacters
                    currentModule={currentModule}
                />
            )}
            {activeTabs === 'second' && (
                <ModuleSkillsList
                    currentModule={currentModule}
                    skillTypes={skillTypes}
                    allSkillsList={allSkillsList}
                />
            )}
            {activeTabs === 'third' && (
                <ModuleEvents
                    currentModule={currentModule}
                    skillTypes={skillTypes}
                    allSkillsList={allSkillsList}
                />
            )}
        </div>
    );
}
