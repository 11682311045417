import React, { useState } from 'react';
import cn from 'classnames';
import { IconDeprecated } from '@common';
import classes from './CollapseExpand.module.scss';

interface Props {
    buttonName?: string,
    children: any,
}

export function CollapseExpand({
    buttonName,
    children,
}: Props) {
    const [collapsingState, setCollapsingState] = useState(false);

    return (
        children ? (
            <>
                <div
                    className={classes.collapseExpand__buttonAndTitle}
                    onClick={() => setCollapsingState(!collapsingState)}
                >
                    <svg
                        className={cn(classes.collapseExpand__icon, {
                            [classes.collapseExpand__icon_turnOver]: collapsingState,
                        })}
                    >
                        <IconDeprecated
                            id="arrowToUp"
                        />
                    </svg>
                    <span className={classes.collapseExpand__name}>{buttonName}</span>
                </div>
                <div className={cn(classes.collapseExpand__content, {
                    [classes.collapseExpand__content_show]: collapsingState,
                })}
                >
                    {children}
                </div>
            </>
        ) : null
    );
}
