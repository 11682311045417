import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { format } from 'date-fns';

import { ActionButton } from '@common/ActionButton';
import { Alert } from '@common/Alert';
import { MultiAutosuggest } from '@common/MultiAutosuggest';
import { scheduleGenerationStore } from '@admin/ScheduleGenerationPage/store';

import { CalendarComponent } from './CalendarComponent';
import classes from './Filters.module.scss';
import {
    GET_SUGGESTED_MODULES,
    GET_SUGGESTED_TEACHERS,
    GET_TEACHER_ROLES,
} from '../OccupationDashboardQueries';
import { OccupationParams } from '../OccupationDashboardInterfaces';

interface Props {
    getOccupation(params: OccupationParams): void;
    setTUniversityMeetingsVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    isTUniversityMeetingsVisible: boolean;
}

interface Suggestion {
    id: string;
    name: string;
}

interface TeacherSuggestion {
    id: string,
    firstName: string,
    lastName: string,
    patronymic: string,
}

interface TeacherRoleSuggestionResponse {
    teacherRoles: Suggestion[];
}

interface TeacherSuggestionResponse {
    modulesTeachers: {
        id: string;
        user: {
            lastName: string;
            firstName: string;
            patronymic: string;
        };
    }[];
}

interface ModuleTitleSuggestionResponse {
    modulesByFilters: Suggestion[];
}

export function Filters({
    getOccupation,
    isTUniversityMeetingsVisible,
    setTUniversityMeetingsVisibility,
}: Props): JSX.Element {
    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [startDate, endDate] = dateRange;
    const [filtersErrorAlerts, setFiltersErrorAlerts] = useState<JSX.Element[] | []>([]);
    const [modules, setModules] = useState<Suggestion[]>([]);
    const [teachers, setTeachers] = useState<TeacherSuggestion[]>([]);
    const [teacherRoles, setTeacherRoles] = useState<Suggestion[]>([]);

    const showDate = () => {
        if (!(dateRange[0] && dateRange[1])) {
            setFiltersErrorAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message="Нужно задать начальную и конечную дату"
            />)]);
        } else {
            getOccupation({
                fromDate: format(startDate, 'yyyy-MM-dd'),
                toDate: format(endDate, 'yyyy-MM-dd'),
                moduleIds: modules.length ? modules.map(({ id }) => id) : undefined,
                teacherIds: teachers.length ? teachers.map(({ id }) => id) : undefined,
                teacherRoleIds: teacherRoles.length ? teacherRoles.map(({ id }) => id) : undefined,
            });
        }
    };

    function formatModulesSendindValue(value: string) {
        return {
            namePattern: value.trim(),
            baseTimeIntervalInstanceId: scheduleGenerationStore.baseTimeIntervalInstance?.id!,
        };
    }

    const formatTeachersSendindValue = (value: string) => ({
        namePattern: value.trim(),
        baseTimeIntervalInstanceId: scheduleGenerationStore.baseTimeIntervalInstance?.id!,
        moduleIds: modules.length ? modules.map(({ id }) => id) : undefined,
        teacherRoleIds: teacherRoles.length ? teacherRoles.map(({ id }) => id) : undefined,
    });

    const formatTeacherRolesSendindValue = (value: string) => ({
        namePattern: value.trim(),
        baseTimeIntervalInstanceId: scheduleGenerationStore.baseTimeIntervalInstance?.id!,
        moduleIds: modules.length ? modules.map(({ id }) => id) : undefined,
        teacherIds: teachers.length ? teachers.map(({ id }) => id) : undefined,
    });

    function teacherTransform(
        response: TeacherSuggestionResponse,
    ): TeacherSuggestion[] {
        return response.modulesTeachers.map((teacher) => ({
            id: teacher.id,
            ...teacher.user,
        }));
    }

    function handleChangeCheckBox() {
        setTUniversityMeetingsVisibility(!isTUniversityMeetingsVisible);
    }

    return (
        <>
            <div className={classes.filters}>
                <CalendarComponent
                    startDate={startDate}
                    endDate={endDate}
                    onChange={setDateRange}
                />
                <div className={classes.filters__item}>
                    <div
                        className={classes.filters__itemName}
                    >
                        Модули:
                    </div>
                    <MultiAutosuggest
                        placeholder="Выбрать модуль"
                        query={GET_SUGGESTED_MODULES}
                        formatOption={titleFormat}
                        transformResponse={moduleTransform}
                        onChange={setModules}
                        selectedOptions={modules}
                        formatSendingValue={formatModulesSendindValue}
                    />
                </div>
                <div className={classes.filters__item}>
                    <div
                        className={classes.filters__itemName}
                    >
                        Роли преподавателей:
                    </div>
                    <MultiAutosuggest
                        placeholder="Выбрать роль"
                        query={GET_TEACHER_ROLES}
                        formatOption={titleFormat}
                        transformResponse={teacherRoleTransform}
                        onChange={setTeacherRoles}
                        selectedOptions={teacherRoles}
                        formatSendingValue={formatTeacherRolesSendindValue}
                    />
                </div>
                <div className={classes.filters__item}>
                    <div
                        className={classes.filters__itemName}
                    >
                        Преподаватели:
                    </div>
                    <MultiAutosuggest
                        placeholder="Выбрать преподавателя"
                        query={GET_SUGGESTED_TEACHERS}
                        formatOption={teacherFormat}
                        transformResponse={teacherTransform}
                        onChange={setTeachers}
                        selectedOptions={teachers}
                        formatSendingValue={formatTeachersSendindValue}
                    />
                </div>
            </div>
            <div className={classes.filters__checkbox}>
                <input
                    onChange={handleChangeCheckBox}
                    type="checkbox"
                    id="room-load-report"
                    checked={isTUniversityMeetingsVisible}
                />
                <label htmlFor="room-load-report">
                    Показывать встречи Т-университета
                </label>
            </div>
            <ActionButton
                className={classes.filter__submitButton}
                onClick={showDate}
            >
                Применить
            </ActionButton>
            {filtersErrorAlerts}
        </>
    );
}

function moduleTransform(response: ModuleTitleSuggestionResponse): Suggestion[] {
    return response.modulesByFilters;
}

function teacherRoleTransform(response: TeacherRoleSuggestionResponse): Suggestion[] {
    return response.teacherRoles;
}

function titleFormat(item: Suggestion): string {
    return `${item.name}`;
}

function teacherFormat(item: TeacherSuggestion): string {
    return `${item.lastName} ${item.firstName} ${item.patronymic} (${item.id.slice(0, 4).toLowerCase()})`;
}
