import React from 'react';
import cn from 'classnames';
import {
    WeekDays,
    WeekDay,
    WeekDaysRussian,
} from '../../../../../EducationPeriodParametersAndDatesInterfaces';
import classes from './DaysNavigation.module.scss';

interface Props {
    weekDays: WeekDays,
    currentDay: string,
    setCurrentDay(name:string):void,
}

export function DaysNavigation({ weekDays, currentDay, setCurrentDay }: Props) {
    const getDayButton = (weekDay: WeekDay) => (
        <div
            className={
                cn(
                    classes.daysNavigation__dayButton,
                    {
                        [classes.daysNavigation__dayButton_holiday]: weekDay[1].length === 0,
                        [classes.daysNavigation__dayButton_current]: weekDay[0] === currentDay,
                    },
                )
            }
            onClick={() => setCurrentDay(weekDay[0])}
            key={weekDay[0] + weekDay[1]}
        >
            {WeekDaysRussian[weekDay[0]]}
        </div>
    );
    return (
        <div className={classes.daysNavigation}>
            <div className={classes.daysNavigation__title}>
                День
            </div>
            {
                weekDays.map(getDayButton)
            }
        </div>
    );
}
