import React from 'react';
import { useLazyQuery } from '@apollo/client';

import { scheduleGenerationStore } from '@admin/ScheduleGenerationPage/store';
import { OccupationDashboard } from './RoleConflictsDashboard';
import {
    Conflicts,
    ConflictsData,
    ConflictsInput,
    TeacherRolesConflict,
} from './RoleConflictsDashboardInterfaces';

import {
    TEACHER_LOAD,
} from './RoleConflictsDashboardQueries';

export const RoleConflictsDashboardApollo = (): JSX.Element => {
    const [getTeacherLoadByDateRange,
        {
            data: occupationData,
            loading: occupationDataLoading,
            error: occupationError,
        },
    ] = useLazyQuery<ConflictsData, ConflictsInput>(TEACHER_LOAD, {
        fetchPolicy: 'no-cache',
    });

    function getOccupation(
        fromDate: string,
        toDate: string,
        conflictTypes: string[],
        scaleType: string,
    ) {
        const baseTimeIntervalInstanceId = scheduleGenerationStore.baseTimeIntervalInstance?.id!;
        getTeacherLoadByDateRange({
            variables: {
                teacherRoleConflictsInput: {
                    fromDate,
                    toDate,
                    conflictTypes,
                    scaleType,
                    baseTimeIntervalInstanceId,
                },
            },
        });
    }
    return (
        <OccupationDashboard
            occupation={createConflicts(occupationData)}
            getOccupation={getOccupation}
            loading={occupationDataLoading}
            error={occupationError}
        />
    );
};

function createConflicts(
    conflictsData: ConflictsData | undefined,
): Conflicts | undefined {
    const conflicts: Conflicts | undefined = conflictsData && {
        ...conflictsData?.teacherRoleConflicts,
        teacherRolesConflictsMap: {},
        teacherRolesConflictsList: [],
    };
    // conflicts?.modules.forEach(
    //     (teacher) => {
    //         conflicts.teachersMap[teacher.id] = teacher;
    //     },
    // );
    if (conflicts) {
        conflicts.teacherRolesConflictsList = getTeacherRolesConflictsList(conflicts);
    }
    return conflicts;
}

function getTeacherRolesConflictsList(
    conflicts: Conflicts | undefined,
) {
    const teacherTimeSlotsList: TeacherRolesConflict[] = [];
    conflicts?.modules.forEach(
        module => module.teachersRoles.forEach(
            teacherRole => {
                const teacherRolesConflictCopy = conflicts.teacherRolesConflicts.find(
                    teacherRolesConflict => teacherRolesConflict.id === teacherRole.id,
                );
                if (teacherRolesConflictCopy) {
                    teacherTimeSlotsList.push(teacherRolesConflictCopy);
                }
            },
        ),
    );
    return teacherTimeSlotsList;
}
