import React, { useState } from 'react';

import { EventType, moduleStore } from '@admin/NewModule/Store';
import { Event as EventComponent } from './Event';
import { SkillType, Module as ModuleType } from '../../../subSpaceAdmin/subSpaceTypes';
import { skills_skills } from '../../../graphql-query-types';

import classes from './ModuleEvents.module.scss';

interface Props {
    currentModule: ModuleType;
    skillTypes: SkillType[];
    allSkillsList: skills_skills[];
}

export function ModuleEvents({
    currentModule,
    skillTypes,
    allSkillsList,
}: Props):JSX.Element {
    const [openedMeeting, setOpenedMeeting] = useState<null | number>(null);
    const events = [
        ...currentModule.meetings.map(meeting => ({
            ...meeting,
            type: EventType.Meeting,
            numberOfEvent: moduleStore.getNumberOfEvent(currentModule.meetings, meeting.order),
        })),
        ...currentModule.evaluationPoints.map(point => ({
            ...point,
            type: EventType.EvaluationPoint,
            numberOfEvent: moduleStore.getNumberOfEvent(
                currentModule.evaluationPoints, point.order,
            ),
        })),
        ...currentModule.assignments.map(assignment => ({
            ...assignment,
            type: EventType.Assignment,
            numberOfEvent: moduleStore.getNumberOfEvent(
                currentModule.assignments, assignment.order,
            ),
        })),
    ]
        .sort((a, b) => a.order - b.order);
    return (
        <div className={classes.moduleMeetingAndEvalPoint}>
            <ul className={classes.moduleMeetingAndEvalPoint__list}>
                {events?.map((item, index) => (
                    <li
                        key={item.id}
                        className={classes.moduleMeetingAndEvalPoint__item}
                    >
                        <EventComponent
                            openedMeeting={openedMeeting}
                            setOpenedMeeting={setOpenedMeeting}
                            meetingIndex={index}
                            event={item}
                            skillTypes={skillTypes}
                            allSkillsList={allSkillsList}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}
