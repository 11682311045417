import React, { useState } from 'react';
import { PointIcon } from '@common/svg/PointIcon';
import { ArrowIcon } from '@common/svg/ArrowIcon';
import { SkillNode } from 'src/store/SkillHierarchy';

import classes from './ModuleSkills.module.scss';

interface Props {
    skill: SkillNode;
    level?: number;
}

export function ModuleSkill({ skill, level }: Props) {
    const [isShow, setIsShow] = useState(false);
    return (
        <div className={classes.skill}>
            {skill.children.length > 0 ? (
                <>
                    <div
                        onClick={() => setIsShow(!isShow)}
                        className={classes.skill__midle}
                    >
                        <ArrowIcon isOpen={isShow} />
                        <div className={classes.skill__midle_name}>
                            {skill.skill.name}
                        </div>
                    </div>
                    {isShow && skill.children.map(child => (
                        <ModuleSkill skill={child} key={child.skill.id} />
                    ))}
                </>
            ) : (
                <div className={classes.skill__child}>
                    <PointIcon size={4} />
                    <div className={classes.skill__name}>
                        {skill.skill.name}
                    </div>
                    {level && (
                        <div className={classes.skill__level}>
                            <span>Уровень:</span>
                            <span>{level}</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
