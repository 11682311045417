import React from 'react';

import { Tool, ToolBar } from '@admin/Users/ToolBar';

import { Table, columnConfigs } from './Table';
import { formatBTIName } from './util';
import { useUserList } from './useUserList';
import { Type } from './apollo-types';
import { UserRow } from './Table/UserRow';
import { FullName, Skills } from './Table/userCellInfo';

import classes from './Table/table.module.scss';

export function StudentsTable() {
    const {
        isAllSelected,
        isMoreButtonVisible,
        loading,
        users,
        error,
        selectedUsers,
        onMoreButtonClick,
        onChangeSearchParams,
        onSelect,
        onSelectAll,
        clearPreviousSelected,
        isUserSelected,
        refetchUserList,
    } = useUserList(Type.student);

    if (error) {
        return (
            <div>Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}</div>
        );
    }
    return (
        <>
            <div className={classes.toolBar}>
                <ToolBar
                    tools={[
                        Tool.BASE_TIME_INTERVAL_INSTANCE_UPDATE,
                        Tool.SKILL_UPDATE,
                        Tool.GENERATE_SAMPLE_IMPORT_STUDENTS_RATING,
                        Tool.IMPORT_STUDENTS_RATING,
                        Tool.GENERATE_SAMPLE_STUDENT_IMPORT,
                        Tool.STUDENT_IMPORT,
                        Tool.USER_IMPORT,
                    ]}
                    selectedUsers={selectedUsers}
                    refetchUserList={refetchUserList}
                />
            </div>

            <Table
                setSearchParams={onChangeSearchParams}
                columnConfigs={[
                    columnConfigs.fullName,
                    columnConfigs.studentTimeInterval,
                    columnConfigs.space,
                    columnConfigs.skills,
                    columnConfigs.status,
                ]}
                onShowMore={onMoreButtonClick}
                isMoreButtonVisible={isMoreButtonVisible}
                clearPreviousSelected={clearPreviousSelected}
                onSelectAll={onSelectAll}
                isAllSelected={isAllSelected}
                loading={loading}
            >
                {
                    users.map(user => {
                        const activeBTITitle = formatBTIName(
                            user.student?.activeBaseTimeIntervalInstance?.baseTimeIntervalInstance,
                        );
                        const isEnabled = user.roles.find(role => role.name === 'Student')?.enabled;

                        return (
                            <UserRow
                                key={user.id}
                                userId={user.id}
                                onChange={() => onSelect(user)}
                                checked={isUserSelected(user)}
                            >
                                <FullName user={user} />

                                <td className={classes.table__cell}>
                                    {activeBTITitle}
                                </td>

                                <td className={classes.table__cell}>
                                    {user.student?.space?.name ?? ''}
                                </td>

                                <Skills userSkills={user.student?.skills} />

                                <td>{isEnabled ? 'Активен' : 'Неактивен'}</td>
                            </UserRow>
                        );
                    })
                }
            </Table>
        </>
    );
}
