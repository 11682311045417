import React from 'react';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';

import {
    Space,
    SpaceBaseTimeIntervalParameters,
    ModuleAssessment,
    SelectionStatus,
} from '../../../../EducationPeriodParametersAndDatesInterfaces';
import { SelectionPointsButtons } from './SelectionPointsButtons';

import classes from './Parameters.module.scss';

interface Props {
    parameters?: SpaceBaseTimeIntervalParameters,
    space: Space,
    index: number,
    isSecret: boolean,
    refetchGetSpaces: (variables?:
    Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{
        spaces: Space[];
    }>>
}

export function Parameters(
    {
        parameters,
        space,
        index,
        isSecret,
        refetchGetSpaces,
    }: Props,
) {
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!parameters?.baseTimeIntervalInstanceId) {
            e.preventDefault();
        }
    };

    const getBTI = () => {
        const currentSpaceBTI = space.spaceBaseTimeIntervals
            .find(({ id }) => parameters?.spaceBaseTimeIntervalId === id)
            ?.baseTimeIntervalInstances;

        const currentBTI = currentSpaceBTI
            ?.find(({ id }) => parameters?.baseTimeIntervalInstanceId === id);

        return currentBTI;
    };
    return (
        <div className={classes.paramaters}>
            <div>
                <div className={classes.paramaters__selectionPointDate}>
                    даты выбора подпространств {index}: {formatDate(parameters?.subspaceSelectionStartDate) || 'не задано'}&nbsp;-&nbsp;{formatDate(parameters?.subspaceSelectionEndDate) || 'не задано'}
                </div>
                <div className={classes.paramaters__selectionPointDate}>
                    даты выбора модулей {index}: {formatDate(parameters?.moduleSelectionStartDate) || 'не задано'}&nbsp;-&nbsp;{formatDate(parameters?.moduleSelectionEndDate) || 'не задано'}
                </div>
                <div className={classes.paramaters__selectionPointDate}>
                    даты проведения СОП {index}: {parameters?.moduleAssessment
                        ? formatModuleAssessmentDates(parameters?.moduleAssessment!)
                        : 'не задано'
                    }
                </div>
                <div className={classes.paramaters__spaceBaseTimeIntervalDate}>
                    {index} {space.baseTimeIntervalType.name}:&nbsp;
                    {formatDate(parameters?.spaceBaseTimeIntervalStart) || 'не задано'}&nbsp;-&nbsp;
                    {formatDate(parameters?.spaceBaseTimeIntervalEnd) || 'не задано'}
                </div>
            </div>
            <SelectionPointsButtons
                BTIId={getBTI()?.id ?? ''}
                status={getBTI()?.selectionStatus ?? SelectionStatus.SELECTIONPENDING}
                refetchGetSpaces={refetchGetSpaces}
                isSecret={isSecret}
            />
            <NavLink
                onClick={(e) => handleClick(e)}
                to={`/base-time-interval/${parameters?.baseTimeIntervalInstanceId}/schedule`}
            >
                <div
                    className={
                        cn(
                            classes.paramaters__button,
                            {
                                // eslint-disable-next-line max-len
                                [classes.paramaters__button_inactive]: !parameters?.baseTimeIntervalInstanceId,
                            },
                        )
                    }
                >
                    Просмотр расписания
                </div>
            </NavLink>
        </div>
    );
}

function formatModuleAssessmentDates({ startDate, isDynamicStartDate, endDate }: ModuleAssessment) {
    const endDateWithoutTime = format(new Date(endDate!), 'yyyy-MM-dd');
    if (isDynamicStartDate) {
        return <>по окончанию модуля&nbsp;-&nbsp;{formatDate(endDateWithoutTime)}</>;
    }
    const startDateWithoutTime = format(new Date(startDate!), 'yyyy-MM-dd');
    return (
        <>
            {formatDate(startDateWithoutTime)}&nbsp;-&nbsp;{formatDate(endDateWithoutTime)}
        </>
    );
}

function formatDate(date: (string | undefined)) {
    const dateParts = date && date?.split('-');
    if (dateParts) {
        return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
    }
    return undefined;
}
