import React from 'react';

import { groupBy } from 'lodash';

import { SkillsBlock } from './SkillsBlock';

import classes from './SkillsBlock.module.scss';

import {
    SkillType,
    ModuleSkill,
} from '../../../subSpaceAdmin/subSpaceTypes';
import { skills_skills } from '../../../graphql-query-types';

interface Props {
    skills: ModuleSkill[];
    skillTypes: SkillType[];
    allSkillsList: skills_skills[];
    text: string;
}

export function ModuleSkills({
    skills, skillTypes, allSkillsList, text,
}: Props):JSX.Element {
    const arr = Object.entries(groupBy(skills, 'skill.typeId'));

    return (
        <ul>
            {arr.map(item => (
                <li
                    key={item[0]}
                    className={classes.moduleSkillsBlock__item_list}
                >
                    <SkillsBlock
                        text={text}
                        skillList={item}
                        skillTypes={skillTypes}
                        allSkillsList={allSkillsList}
                    />
                </li>
            ))}
        </ul>
    );
}
