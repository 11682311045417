import React, { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
    RoomFormSend,
    AlertMessage,
    Building,
    RoomBackendData,
    RoomMeetingFormat,
    RoomCreationEquipment,
    TerritorialZone,
} from './RoomTypes';
import {
    CREATE_ROOM,
    DELETE_ROOM,
    EDIT_ROOM,
    GET_BUILDINGS,
    GET_EQUIPMENT,
    GET_ROOMS,
    GET_ROOM_TYPES,
    GET_TERRITORIAL_ZONE,
    GET_TERRITORIAL_ZONES,
} from './quereies';
import { Alert } from '../../common/Alert';
import { RoomsPage } from './RoomsPage';

export function RoomsPageApollo(): JSX.Element {
    const [alert, setAlert] = useState<AlertMessage>({
        alert: false,
        message: '',
    });

    const [activeTab, setActiveTab] = useState<string>('all');

    const {
        data: getBuildings,
        loading: loadingBuildings,
        error: errorBuildings,
    } = useQuery<{ buildings: Building[] }>(GET_BUILDINGS, {
        fetchPolicy: 'cache-and-network',
    });

    const [getRooms, {
        data: getRoomData,
        loading: loadingRooms,
    }] = useLazyQuery<{ rooms: RoomBackendData[] }>(GET_ROOMS, {
        fetchPolicy: 'cache-and-network',
    });

    const {
        data: territorialZonesData,
        loading: territorialZonesLoading,
    } = useQuery<{ territorialZones: TerritorialZone[] }>(GET_TERRITORIAL_ZONES, {
        fetchPolicy: 'cache-first',
    });

    const {
        data: getRoomTypes,
        loading: loadingRoomTypes,
    } = useQuery<{ meetingFormats: RoomMeetingFormat[] }>(GET_ROOM_TYPES, {
        fetchPolicy: 'cache-and-network',
    });

    const {
        data: getStoreEquipment,
        loading: loadingStoreEquipment,
    } = useQuery<{ equipments: RoomCreationEquipment[] }>(GET_EQUIPMENT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            equipmentsInput: {
                status: 'STATIONARY',
            },
        },
    });

    const [
        getTerritorialZone,
        {
            data: territorialZoneData,
            loading: territorialZoneLoading,
        },
    ] = useLazyQuery<{ territorialZone: TerritorialZone }>(
        GET_TERRITORIAL_ZONE,
        { fetchPolicy: 'no-cache' },
    );

    const [deleteRoom] = useMutation(DELETE_ROOM,
        {
            onError: error => setAlert({ alert: true, message: error.message }),
            onCompleted: () => getRoomsHandler(),
        });

    const [editRoom] = useMutation(EDIT_ROOM,
        {
            onError: error => setAlert({ alert: true, message: error.message }),
            onCompleted: () => getRoomsHandler(),
        });

    const [createRoom] = useMutation(CREATE_ROOM,
        {
            onError: error => setAlert({ alert: true, message: error.message }),
            onCompleted: () => getRoomsHandler(),
        });

    const createRoomHandler = (createRoomData: RoomFormSend) => {
        createRoom({
            variables: {
                createRoomData,
            },
        });
    };

    const editRoomHandler = (editRoomData: RoomFormSend) => {
        editRoom({
            variables: {
                editRoomData,
            },
        });
    };

    const deleteRoomHandler = (id: string) => {
        deleteRoom({
            variables: {
                id,
            },
        });
    };

    const isLoading = loadingBuildings
    || loadingRooms
    || loadingRoomTypes
    || loadingStoreEquipment
    || territorialZonesLoading
    || territorialZoneLoading;

    if (errorBuildings) return <Alert message={errorBuildings?.message} time={2500} />;

    function getRoomsHandler() {
        if (activeTab === 'all') {
            getRooms();
        } else {
            getTerritorialZone({ variables: { id: activeTab } });
        }
    }
    return (
        <>
            <RoomsPage
                alert={alert}
                buildings={getBuildings?.buildings}
                allRooms={getRoomData?.rooms}
                territorialZoneRooms={territorialZoneData?.territorialZone.rooms}
                meetingFormats={getRoomTypes?.meetingFormats}
                storeEquipment={getStoreEquipment?.equipments}
                territorialZones={territorialZonesData?.territorialZones}
                isLoading={isLoading}
                tabsState={[activeTab, setActiveTab]}
                getRoomsHandler={getRoomsHandler}
                createRoomHandler={createRoomHandler}
                editRoomHandler={editRoomHandler}
                deleteRoomHandler={deleteRoomHandler}
            />
        </>
    );
}
