import React, { useRef, useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useQuery, gql } from '@apollo/client';

import { scheduleGenerationStore } from '@admin/ScheduleGenerationPage/store';
import { Loader } from '@common/Loader';

import { Vizualize } from './Visualize';

export const GET_CONFIGS = gql`
    query getScheduleScore($baseTimeIntervalInstanceId: String!) {
        scheduleScore(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId) {
          generationConfig
          scoreResult
        }
    }
`;

export const ConflictsReportApollo = (): JSX.Element => {
    const {
        data,
        loading,
    } = useQuery(GET_CONFIGS, {
        fetchPolicy: 'network-only',
        variables: {
            baseTimeIntervalInstanceId: scheduleGenerationStore.baseTimeIntervalInstance?.id!,
        },
    });
    const canvasRef = useRef(null);
    useEffect(
        () => {
            if (data) {
                new Vizualize().vizualize(
                    data?.scheduleScore?.generationConfig,
                    data?.scheduleScore?.generationConfig,
                    data?.scheduleScore?.scoreResult,
                    canvasRef.current,
                );
            }
        },
        [data],
    );

    return (
        <div>
            { loading && <Loader />}
            <canvas ref={canvasRef} />
        </div>
    );
};
