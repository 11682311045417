import { ApolloError, useMutation } from '@apollo/client';
import { GET_EVENT_DATA, UPDATE_ASSIGNMENT_INSTANCE } from '../../scheduleQueries';

export const useUpdateAssignmentDurationMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
) => {
    const [
        updateAssignmentInstance,
    ] = useMutation(UPDATE_ASSIGNMENT_INSTANCE, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        updateAssignmentDuration: (duration: number) => updateAssignmentInstance({
            variables: {
                updateAssignmentInstanceInput: {
                    id,
                    duration,
                },
            },
        }),
    };
};
