import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { format } from 'date-fns';

import { ActionButton } from '@common/ActionButton';
import { Alert } from '@common/Alert';
import { Select } from '@common/Select';
// import { MultiAutosuggest } from '@common/MultiAutosuggest';
// import { scheduleGenerationStore } from '@admin/ScheduleGenerationPage/store';

import { CalendarComponent } from './CalendarComponent';
import classes from './Filters.module.scss';
// import { GET_SUGGESTED_MODULES } from '../RoleConflictsDashboardQueries';

interface Props {
    getOccupation(
        fromDate: string,
        toDate: string,
        conflictTypes: string[],
        scaleType: string
    ): void;
}

interface ConflictTypes {
    [index: string]: boolean,
}

const SCALE = [
    {
        id: 'timeslot',
        name: 'Таймслот',
    },
    {
        id: 'day',
        name: 'День',
    },
    {
        id: 'week',
        name: 'Неделя',
    },
];

// interface ModuleTitleSuggestion {
//     id: string;
//     name: string;
//     location: { name: string; };
// }

// interface ModuleTitleSuggestionResponse {
//     modulesByNamePattern: ModuleTitleSuggestion[],
// }

export function Filters(
    {
        getOccupation,
    }: Props,
): JSX.Element {
    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [startDate, endDate] = dateRange;
    const [equipmentErrorAlerts, setEquipmentErrorAlerts] = useState<JSX.Element[] | []>([]);
    const [scale, setScale] = useState(SCALE[0].id);
    const [conflictTypes, setConflictTypes] = useState<ConflictTypes>(
        {
            teachersMeetingsOverlap: true,
            teacherWorkingTimeViolation: false,
            teacherReservationsViolation: false,
        },
    );
    // const [modules, setModules] = useState<ModuleTitleSuggestion[]>([]);
    const handleCheckBoxChange = (name: string) => () => setConflictTypes(
        (oldConflictTypes: ConflictTypes) => (
            { ...oldConflictTypes, [name]: !oldConflictTypes[name] }
        ),
    );

    const showDate = () => {
        if (!(dateRange[0] && dateRange[1])) {
            setEquipmentErrorAlerts((arr) => [...arr, (<Alert
                key={nanoid()}
                message="Нужно задать начальную и конечную дату"
            />)]);
        } else {
            getOccupation(
                format(startDate, 'yyyy.MM.dd'),
                format(endDate, 'yyyy.MM.dd'),
                Object.keys(conflictTypes).filter(key => conflictTypes[key]),
                scale,
                // modules.length ? modules?.map(({ id }) => id) : undefined,
            );
        }
    };

    // function moduleTransform(response: ModuleTitleSuggestionResponse): ModuleTitleSuggestion[] {
    //     return response.modulesByNamePattern;
    // }

    // function moduleTitleFormat(item: ModuleTitleSuggestion): string {
    //     return `${item.name}`;
    // }

    // function formatModulesSendindValue(value: string) {
    //     return {
    //         namePattern: value,
    //         baseTimeIntervalInstanceId: scheduleGenerationStore.baseTimeIntervalInstance?.id!,
    //     };
    // }

    return (
        <div
            className={classes.filters}
        >
            <CalendarComponent
                startDate={startDate}
                endDate={endDate}
                onChange={setDateRange}
            />
            <div className={classes.filters__item}>
                <div
                    className={classes.filters__itemName}
                >
                    Масштаб:
                </div>
                <Select
                    options={SCALE}
                    formatOption={(item: { id: string, name: string }) => item.name}
                    onChange={(item: { id: string, name: string }) => setScale(item.id)}
                    className={classes.filters__select}
                />
            </div>
            <div className={classes.filters__conflictTypes}>
                <div
                    className={classes.filters__itemName}
                >
                    Типы конфликтов:
                </div>
                <div className={classes.filters__checkbox}>
                    <input
                        type="checkbox"
                        id="teachersMeetingsOverlap"
                        name="teachersMeetingsOverlap"
                        value="teachersMeetingsOverlap"
                        checked={conflictTypes.teachersMeetingsOverlap}
                        onChange={handleCheckBoxChange('teachersMeetingsOverlap')}
                    />
                    <div onClick={handleCheckBoxChange('teachersMeetingsOverlap')}>Конлфикты пересечения встреч преподавателей</div>
                </div>
                <div className={classes.filters__checkbox}>
                    <input
                        type="checkbox"
                        id="teacherWorkingTimeViolation"
                        name="teacherWorkingTimeViolation"
                        value="teacherWorkingTimeViolation"
                        checked={conflictTypes.teacherWorkingTimeViolation}
                        onChange={handleCheckBoxChange('teacherWorkingTimeViolation')}
                    />
                    <div onClick={handleCheckBoxChange('teacherWorkingTimeViolation')}>Нарушения рабочего времени преподавателей</div>
                </div>
                <div className={classes.filters__checkbox}>
                    <input
                        type="checkbox"
                        id="teacherReservationsViolation"
                        name="teacherReservationsViolation"
                        value="teacherReservationsViolation"
                        checked={conflictTypes.teacherReservationsViolation}
                        onChange={handleCheckBoxChange('teacherReservationsViolation')}
                    />
                    <div onClick={handleCheckBoxChange('teacherReservationsViolation')}>Нарушения бронирования преподавателей</div>
                </div>
            </div>
            <ActionButton
                className={classes.filter__submitFilters}
                onClick={showDate}
            >
                Применить
            </ActionButton>
            {equipmentErrorAlerts}
        </div>
    );
}
