import React, { Fragment, useState } from 'react';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';

import { BorderCrossIcon, BorderPlusIcon } from '@common/svg';
import { Switcher } from '@common';

import { Calendar } from './Calendar';
import { PeriodChooser } from './Calendar/PeriodChooser';

import classes from './roomAvailable.module.scss';

enum DefaultTime {
    fromTime = '00:00',
    toTime = '23:59',
}

type TimeIntrval = {
    id: string;
    from: Date;
    to: Date;
};

interface Props {
    isAvailable: boolean;
    availableIntervals: TimeIntrval[];
    handlerAvailableIntervals: React.Dispatch<React.SetStateAction<TimeIntrval[]>>;
    handlerAvailable: React.Dispatch<React.SetStateAction<boolean>>;
}

export function RoomAvailable({
    isAvailable,
    availableIntervals = [],
    handlerAvailable,
    handlerAvailableIntervals,
}: Props) {
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [inputValues, setInputValues] = useState({
        fromTime: DefaultTime.fromTime,
        toTime: DefaultTime.toTime,
    });

    const hasStartDate = startDate ?? false;

    const onChangeCalendar = (update: []) => setDateRange(update);

    const { to, from } = formatTimeToNumber(inputValues.toTime, inputValues.fromTime);

    const dateFrom = new Date(startDate)
        .setHours(from.hours, from.minuts);

    const dateTo = new Date(endDate)
        .setHours(to.hours, to.minuts);

    const handleAddAvalebleTime = () => {
        if (startDate && endDate) {
            handlerAvailableIntervals([...availableIntervals, {
                id: nanoid(),
                from: new Date(dateFrom),
                to: new Date(dateTo),
            }]);
        }
        setDateRange([]);
        setInputValues({
            fromTime: DefaultTime.fromTime,
            toTime: DefaultTime.toTime,
        });
    };

    const handleRemoveAvalableTime = (intervalId: string) => {
        const newAvalebleTimes = availableIntervals.filter(item => item.id !== intervalId);
        handlerAvailableIntervals(newAvalebleTimes);
    };

    function formatTimeToNumber(timeTo: string, timeFrom: string) {
        return ({
            to: {
                hours: Number(timeTo.slice(0, 2)),
                minuts: Number(timeTo.slice(3, 5)),
            },
            from: {
                hours: Number(timeFrom.slice(0, 2)),
                minuts: Number(timeFrom.slice(3, 5)),
            },
        });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };
    return (
        <div className={classes.available}>
            <Switcher
                onClick={() => handlerAvailable(!isAvailable)}
                checked={!isAvailable}
                label="Помещение с ограниченным доступом"
            />
            {!isAvailable && (
                <>
                    <div className={classes.available__title}>
                        Выберите даты доступности помещения:
                    </div>
                    <div className={classes.available__calendar}>
                        <Calendar
                            start={startDate}
                            end={endDate}
                            onChange={onChangeCalendar}
                            modifier="placeholder"
                            showTimeInput
                            customInput={(
                                <button
                                    className={classes.calendar__button}
                                    aria-label="button"
                                    type="button"
                                    onClick={() => { }}
                                >
                                    {hasStartDate ? (`${format(dateFrom, 'dd.MM.yyyy HH:mm')}
                            ${endDate ? `${format(dateTo, '— dd.MM.yyyy HH:mm')}` : ''} `) : 'Выбрать даты'}
                                </button>
                            )}
                            customTimeInput={(
                                <PeriodChooser
                                    fromTime={inputValues.fromTime ?? ''}
                                    toTime={inputValues.toTime ?? ''}
                                    onChangeFromTime={handleChange}
                                    onChangeToTime={handleChange}
                                />
                            )}
                        />
                        <div className={classes.available__addButton}>
                            <BorderPlusIcon handler={handleAddAvalebleTime} size={20} />
                        </div>
                    </div>
                    <ul className={classes.available_list}>
                        {
                            availableIntervals?.map((item) => (
                                <li
                                    className={classes.available_listBlock}
                                    key={item.id}
                                >
                                    <div className={classes.available_nameGroup}>
                                        <div className={classes.available_name}>
                                            <p>
                                                {`${format(new Date(item.from), 'dd.MM.yyyy HH:mm')}
                                ${format(new Date(item.to), '— dd.MM.yyyy HH:mm ')}`}
                                            </p>
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className={classes.available_btn}
                                        onClick={() => handleRemoveAvalableTime(item.id)}
                                    >
                                        <BorderCrossIcon size={20} />
                                    </button>
                                </li>
                            ))
                        }
                    </ul>
                </>
            )}
        </div>
    );
}
