import React, { Fragment } from 'react';
import { SkillNode } from 'src/store/SkillHierarchy';
import { SkillWhithLevel } from '../../Config/interfaces';
import SkillType from '../../../../store/SkillType';
import { ModuleSkillType } from './ModuleSkillType';

import classes from './ModuleSkills.module.scss';

interface Props {
    skillsTree: SkillNode[];
    title: string;
    skills: SkillWhithLevel[];
}

export function ModuleSkills({ skillsTree, title, skills }: Props) {
    function getFiltredSkillsByType(typeId: string) {
        return skillsTree.filter(({ skill }) => skill.typeId === typeId);
    }
    return (
        <div className={classes.moduleSkills__container}>
            <div className={classes.moduleSkills__title}>
                {title}
            </div>
            {skillsTree.length === 0 && (<>{`Образовательные результаты ${title.toLowerCase()} отсутствуют`}</>)}
            {SkillType.skillTypes.map(type => (
                <Fragment key={type.id}>
                    {getFiltredSkillsByType(type.id).length > 0 && (
                        <ModuleSkillType
                            type={type}
                            skills={getFiltredSkillsByType(type.id)}
                            moduleSkills={skills}
                        />
                    )}
                </Fragment>
            ))}
        </div>
    );
}
