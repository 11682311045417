import React from 'react';

import classes from './ModuleCardInfo.module.scss';
import { ProgressBar } from '../../../common/ProgressBar';
import { EventProgress } from './EventProgress';
import {
    StudentSchedule,
    Module,
    EventType,
    EvaluationPoint,
    SelectionStatus,
} from '../../Config/interfaces';
import store from '../../store';

interface Props {
    module: Module;
    studentSchedule?: StudentSchedule;
}

export function ModuleCardInfo({
    module,
    studentSchedule,
}: Props): JSX.Element {
    function passedMeetingCount(): number {
        const moduleMeetings = module.meetings.map((meeting: EventType) => meeting.id);
        const moduleMeetingInstances = studentSchedule?.meetingInstances.filter(meeting => (
            moduleMeetings.includes(meeting.meeting.id)
        ));
        const passedMeetings = moduleMeetingInstances?.filter(meeting => (
            Number(meeting.endDate) < Date.now()
        ));
        return passedMeetings?.length || 0;
    }

    function passedAssignmentCount(): number {
        const moduleAssignments = module.assignments.map((assignment: EventType) => assignment.id);
        const moduleAssignmentInstances = studentSchedule?.assignmentInstances
            .filter(assignment => (moduleAssignments.includes(assignment.assignment.id)));
        const passedAssignments = moduleAssignmentInstances?.filter(assignment => (
            Number(assignment.endDate) < Date.now()
        ));
        return passedAssignments?.length || 0;
    }

    function passedEvaluationPointCount(): number {
        const moduleEvaluationPoints = module.evaluationPoints
            .map((evaluationPoint: EvaluationPoint) => evaluationPoint.id);
        const moduleEvaluationPointInstances = studentSchedule?.evaluationPointInstances
            .filter(evaluationPoint => (
                moduleEvaluationPoints.includes(evaluationPoint.evaluationPoint.id)
            ));
        const passedEvaluationPoints = moduleEvaluationPointInstances?.filter(evaluationPoint => (
            Number(evaluationPoint.endDate) < Date.now()
        ));
        return passedEvaluationPoints?.length || 0;
    }

    const passedPercent = Math.min(
        percent(
            0,
            passedAssignmentCount(),
            module.meetings.length,
            passedMeetingCount(),
            0,
            passedEvaluationPointCount(),
        ),
        100,
    );
    return (
        <div className={classes.moduleCardInfo}>
            {store.baseTimeIntervalSelectionStatus === SelectionStatus.SELECTIONFINISHED && (
                <div className={classes.moduleCardInfo__progressInfo}>
                    <div className={classes.moduleCardInfo__progressBarTitle}>
                        <p>Пройдено</p>
                        {typeof passedPercent === 'number' ? passedPercent : 0}%
                    </div>
                    <div className={classes.moduleCardInfo__progressBarContainer}>
                        <ProgressBar
                            progressPercents={typeof passedPercent === 'number' ? passedPercent : 0}
                        />
                    </div>
                    <EventProgress
                        eventName="Встречи"
                        events={module.meetings}
                        passedEventCount={passedMeetingCount()}
                    />
                    <EventProgress
                        eventName="Точки оценки"
                        events={module.evaluationPoints}
                        passedEventCount={passedEvaluationPointCount()}
                    />
                    <EventProgress
                        eventName="Самостоятельные работы"
                        events={module.assignments}
                        passedEventCount={passedAssignmentCount()}
                    />
                </div>
            )}
            <div
                className={classes.moduleCardInfo__description}
                dangerouslySetInnerHTML={{ __html: module.description }}
            />
        </div>
    );
}

function percent(
    assignmentCount: number,
    passedAssignmentCount: number,
    meetingCount: number,
    passedMeetingCount: number,
    evaluationPointCount: number,
    passedEvaluationPointCount: number,
): number {
    const totalCount: number = assignmentCount + meetingCount + evaluationPointCount;
    const passedCount: number = passedAssignmentCount
        + passedMeetingCount + passedEvaluationPointCount;

    return Number(((passedCount / totalCount) * 100).toFixed(2));
}
