import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import { Loader } from '@common/Loader';
import { StudentNotification } from '@common/qraphql/graphql-types';
import {
    BaseTimeIntervalInstance,
    StudentSchedule,
    ModuleSettings,
    CurOrPastBtiData,
    FutureBTI,
    SelectionStatus,
} from './Config/interfaces';
import store from './store';
import UserView from '../../store/UserView';
import { CurrentSubspace, FutureSubspaceList } from './Subspaces';

import classes from './StudentModulesPage.module.scss';
import { InfoCard } from './ChoicePoint/InfoCard';

interface Props {
    studentSubspacesIds?: string[];
    futureBtiData?: FutureBTI;
    curOrPastBtiData?: CurOrPastBtiData;
    studentSchedule?: StudentSchedule;
    moduleSettings?: ModuleSettings;
    baseTimeIntervalInstances?: BaseTimeIntervalInstance[],
    loading: boolean;
    studentPrioritiesData?: [];
    setStudentPriorities: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
    getCurrentOrPastBTI: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
    getFutureBTI: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
    getStudentPriorities: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
}

export function StudentModulesPage({
    studentSubspacesIds,
    curOrPastBtiData,
    studentSchedule,
    moduleSettings,
    baseTimeIntervalInstances,
    loading,
    futureBtiData,
    studentPrioritiesData = [],
    getCurrentOrPastBTI,
    setStudentPriorities,
    getFutureBTI,
    getStudentPriorities,
}: Props): JSX.Element {
    const { id } = useParams<{ id: string; }>();
    const { studentId } = UserView;

    const formatDateBTIToString = `${format(new Date(getBTI()?.startDate ?? 0), 'dd.MM.yy')} - ${format(new Date(getBTI()?.endDate ?? 0), 'dd.MM.yy')}`;

    const isFinishedBTI = getBTI()?.selectionStatus === SelectionStatus.SELECTIONFINISHED;

    store.setBaseTimeIntervalSelectionStatus(
        getBTI()?.selectionStatus ?? SelectionStatus.SELECTIONPENDING,
    );

    function getBTI() {
        return baseTimeIntervalInstances?.find(item => item?.id === id);
    }

    const getFutureBaseTimeInterval = () => {
        getStudentPriorities({
            variables: {
                baseTimeIntervalInstanceId: id,
            },
        });

        getFutureBTI({
            variables:
            {
                baseTimeIntervalInstanceId: id,
            },
        });
    };

    useEffect(() => {
        if (isFinishedBTI && studentId) {
            getCurrentOrPastBTI({
                variables: {
                    studentId,
                    baseTimeIntervalInstanceId: id,
                },
            });
        } else {
            getFutureBaseTimeInterval();
        }
    }, [studentId, id]);

    store.setStudentPriorities(studentPrioritiesData);
    store.sortForkSlotsByPriorities();

    const isModuleAssessmentActive = UserView.user?.student?.notifications?.includes(
        StudentNotification.moduleAssessment,
    );

    return (
        <div className={classes.studentModulesPage}>
            {getBTI() && (
                loading ? <Loader /> : (
                    <>
                        <div className={classes.studentModulesPage__period}>
                            Учебный период: {formatDateBTIToString}
                        </div>
                        {isModuleAssessmentActive && (
                            <>
                                <InfoCard
                                    hasExclamantion
                                    hasArrow={false}
                                    title="Дорогие студенты!"
                                >
                                    {`В период с 30 июня по 6 июля 2022 каждому студенту необходимо оставить обратную связь по пройденным учебным модулям.
                                    Оценка проводится, чтобы Вы могли выразить свое мнение об организации учебного процесса и оставить комментарии.`}
                                </InfoCard>
                                <InfoCard
                                    title="Инструкция для проведения Студенческой оценки модулей и преподавателей"
                                >
                                    <div>
                                        <b>Как оставить обратную связь?</b>
                                    </div>
                                    <ol>
                                        <li>
                                            {`Выберите модуль, по которому хотите оставить
                                                обратную связь.`}
                                        </li>
                                        <li>
                                            {`Оцените каждый критерий по 5-бальной шкале, где 1 - очень плохо, 2 - плохо, 3 - удовлетворительно ,
                                            4 - хорошо, 5 - очень хорошо. Если вы не посещали встречи модуля, то отметьте “Затрудняюсь ответить”.`}
                                        </li>
                                        <li>
                                            {`Вы можете оставить отзыв касательно модуля или преподавателя,
                                                нажав на кнопку в поле “Комментарий”.`}
                                        </li>
                                        <li>
                                            {`Результаты сохраняются только после нажатия
                                                кнопки «Завершить оценку».`}
                                        </li>
                                    </ol>
                                </InfoCard>
                            </>
                        )}
                        {isFinishedBTI ? (
                            curOrPastBtiData?.requestedBaseTimeIntervalInstance?.studentSubspaces
                                .map(item => (
                                    <CurrentSubspace
                                        key={item.subspace.id}
                                        studentSubspace={item}
                                        studentSchedule={studentSchedule}
                                        moduleSettings={moduleSettings}
                                    />
                                ))
                        ) : (
                            <FutureSubspaceList
                                studentSubspacesIds={studentSubspacesIds}
                                getFutureBaseTimeInterval={getFutureBaseTimeInterval}
                                setStdentPriorities={setStudentPriorities}
                                futureBtiData={futureBtiData}
                                moduleSettings={moduleSettings}
                            />
                        )
                        }
                    </>
                )
            )}
        </div>

    );
}
