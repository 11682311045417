import React from 'react';

import classes from './EquipmentTable.module.scss';

// import { equipmentArray } from '../mock';

import { EquipmentTableItem } from '../EquipmentTableItem';

import { Equipment } from '../equipmentTypes';

interface Props {
    equipmentArray: Equipment[];
}

export function EquipmentTable({ equipmentArray }: Props): JSX.Element {
    const sortedEquipments = equipmentArray
        .sort((a: Equipment, b: Equipment) => (
            a.consumableEquipment.name > b.consumableEquipment.name ? 1 : -1));
    return (
        <div className={classes.EquipmentTable}>
            <div className={classes.equipmentTable__header}>
                <div className={classes.equipmentTable__headerName}>
                    Название
                </div>
                <div className={classes.equipmentTable__headerUnit}>
                    Ед. измерения
                </div>
                <div className={classes.equipmentTable__headerCount}>
                    Колличество
                </div>
            </div>
            <ul className={classes.equipmentTable__list}>
                {sortedEquipments.map((item: Equipment) => (
                    <li className={classes.equipmentTable__item}>
                        <EquipmentTableItem
                            item={item}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}
