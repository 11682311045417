import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { Tabs } from '@common/Tabs';
import { AuthRoute } from '@common/AuthRoute';
import { SkillListPage } from './SkillListPage';
import { SkillAddPage } from './SkillAddPage';

import { skillTypesData_skillTypes, ViewType } from '../../graphql-query-types';

interface Skill {
    id: string;
    name: string;
    typeId: string;
    maxLevel?: number;
    description?: string;
    children?: Skill[];
    dependencies?: Skill[];
}

interface Props {
    skillType: skillTypesData_skillTypes;
    skillTypes: skillTypesData_skillTypes[];
    skillsList: Skill[],
    addSkill: (
        id: string,
        name: string,
        typeId: string,
        description?: string,
        maxLevel?: number,
    ) => void;
    addNestSkill: (
        id: string,
        name: string,
        typeId: string,
        children: Skill[],
        dependencies: string[],
    ) => void;
    updateSkill: (
        id: string,
        name: string,
        typeId: string,
        description: string,
        maxLevel: number
    ) => void;
    updateNestSkill: (
        id: string,
        name: string,
        typeId: string,
        children: Skill[],
        dependencies: string[],
        maxLevel?: number,
    ) => void;

    removeSkill(id: string): void;
}

export function SkillPage({
    skillType,
    skillTypes,
    skillsList,
    addSkill,
    addNestSkill,
    updateSkill,
    removeSkill,
    updateNestSkill,
}: Props): JSX.Element {
    const { path } = useRouteMatch();
    return (
        <>
            <Tabs
                tabsOptions={[
                    [
                        `Список ${(skillType.name.genitivePlural)?.toLowerCase()}`,
                        'list',
                    ],
                    [
                        `Добавление ${(skillType.name.genitivePlural)?.toLowerCase()}`,
                        'add',
                    ],
                ]}
            />
            <Switch>
                <AuthRoute exact path={path} requiredUserTypes={[ViewType.Admin]}>
                    {skillsList.length > 0
                        ? <Redirect to={`${path}/list`} />
                        : <Redirect to={`${path}/add`} />
                    }
                </AuthRoute>
                <AuthRoute exact path={`${path}/list`} requiredUserTypes={[ViewType.Admin]}>
                    <SkillListPage
                        skillType={skillType}
                        skillTypes={skillTypes}
                        skillsList={skillsList}
                        removeSkill={removeSkill}
                        updateSkill={updateSkill}
                        updateNestSkill={updateNestSkill}
                    />
                </AuthRoute>
                <AuthRoute exact path={`${path}/add`} requiredUserTypes={[ViewType.Admin]}>
                    <SkillAddPage
                        skillType={skillType}
                        skillTypes={skillTypes}
                        skillsList={skillsList}
                        skillPath={path}
                        addSkill={addSkill}
                        addNestSkill={addNestSkill}
                    />
                </AuthRoute>
            </Switch>
        </>
    );
}
