import React from 'react';

import { ActionButton } from '@common/ActionButton';

import { ProfileParams } from './ProfileParams';

import classes from './SubSpaceOptions.module.scss';

import {
    skills_skills,
    skillTypesData_skillTypes,
} from '../../graphql-query-types';

interface Skill {
    id: string
    name: string
    fullName: string
    typeId: string
    maxLevel?: number
    description?: string
    children?: Skill[]
    dependencies?: Skill[]
}
interface SubspaceSkill {
    skill: Skill
    level: number
}

interface Props {
    allSkillsList: skills_skills[];
    sortedSkillTypes: skillTypesData_skillTypes[];
    notSortedSkillTypes: skillTypesData_skillTypes[];
    profilePrerequisiteSkillsList: SubspaceSkill[];
    setPrerequisiteProfileSkills: (item: SubspaceSkill[]) => void;
    profileOutputSkillsList: SubspaceSkill[];
    setOutputProfileSkills: (item: SubspaceSkill[]) => void;
    saveSubspaceParams: () => void;
}

export function EditPrerequisiteAndOutputParams({
    allSkillsList,
    sortedSkillTypes,
    notSortedSkillTypes,
    profilePrerequisiteSkillsList,
    setPrerequisiteProfileSkills,
    profileOutputSkillsList,
    setOutputProfileSkills,
    saveSubspaceParams,
}: Props): JSX.Element {
    return (
        <>
            <div className={classes.subSpaceOptions__profileParamsTitle}>
                Профильные параметры подпространства:
            </div>
            <ul className={classes.subSpaceOptions__profileParamsList}>
                <li className={classes.subSpaceOptions__profileParamsItem}>
                    <ProfileParams
                        number={0}
                        skillsParamsType="prerequisite"
                        sortedSkillTypes={sortedSkillTypes}
                        notSortedSkillTypes={notSortedSkillTypes}
                        allSkillsList={allSkillsList}
                        profileSkillsList={profilePrerequisiteSkillsList}
                        setProfileSkills={setPrerequisiteProfileSkills}
                    />
                </li>
                <li className={classes.subSpaceOptions__profileParamsItem}>
                    <ProfileParams
                        number={sortedSkillTypes.length}
                        skillsParamsType="output"
                        sortedSkillTypes={sortedSkillTypes}
                        notSortedSkillTypes={notSortedSkillTypes}
                        allSkillsList={allSkillsList}
                        profileSkillsList={profileOutputSkillsList}
                        setProfileSkills={setOutputProfileSkills}
                    />
                </li>
            </ul>
            <div className={classes.subSpaceOptions__button}>
                <ActionButton onClick={saveSubspaceParams}>
                    Сохранить
                </ActionButton>
            </div>
        </>
    );
}
