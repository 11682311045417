import { useLazyQuery } from '@apollo/client';
import { OnlineRoom } from '../../ScheduleGenerationPageInterfaces';
import { GET_AVAILABLE_ONLINE_ROOMS } from '../../scheduleQueries';

export const useLoadAvailableOnlineRoomsLazyQuery = () => {
    const [
        getAvailableOnlineRoomsQuery, { data },
    ] = useLazyQuery<{ availableOnlineRooms: OnlineRoom[] }>(GET_AVAILABLE_ONLINE_ROOMS);

    return {
        loadAvailableOnlineRooms: (
            from: string,
            to: string,
            isAvailable: boolean,
        ) => {
            getAvailableOnlineRoomsQuery({
                variables: {
                    input: {
                        from,
                        to,
                        isAvailable,
                    },
                },
            });
        },
        availableOnlineRooms: data?.availableOnlineRooms,
    };
};
