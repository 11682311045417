import React from 'react';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { Parameters } from './Parameters';
import classes from './Intervals.module.scss';
import {
    SpaceEducationPeriod,
    Space,
    SpaceBaseTimeInterval,
} from '../../../EducationPeriodParametersAndDatesInterfaces';

interface Props {
    currentPeriod: SpaceEducationPeriod,
    space: Space,
    isSecret: boolean;
    refetchGetSpaces: (variables?:
    Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{
        spaces: Space[];
    }>>
}
export function Intervals(
    {
        currentPeriod,
        space,
        isSecret,
        refetchGetSpaces,
    }: Props,
) {
    return (
        <div className={classes.intervals}>
            {
                space.spaceBaseTimeIntervals.map(
                    (interval, index) => (
                        <Parameters
                            key={`${interval.id}`}
                            space={space}
                            index={index + 1}
                            isSecret={isSecret}
                            refetchGetSpaces={refetchGetSpaces}
                            parameters={getSpaceBaseTimeIntervalParameters(interval, currentPeriod)}
                        />
                    ),
                )
            }
        </div>
    );
}
function getSpaceBaseTimeIntervalParameters(
    spaceBaseTimeInterval: SpaceBaseTimeInterval,
    period: SpaceEducationPeriod,
) {
    return period.spaceBaseTimeIntervalParameters.find(
        (parameters) => (parameters.spaceBaseTimeIntervalId === spaceBaseTimeInterval.id),
    );
}
