import React from 'react';

import classes from './ModuleSkillsListSwitchParams.module.scss';

export function ModuleSkillsListSwitchParams():JSX.Element {
    return (
        <div className={classes.moduleSkillsListSwitchParams}>
            <div
                className={classes.moduleSkillsListSwitchParams__item}
            >
                Входные
            </div>
            <div
                className={classes.moduleSkillsListSwitchParams__item}
            >
                Выходные
            </div>
        </div>
    );
}
