import { map } from 'lodash';
import { nanoid } from 'nanoid';
import {
    CreateZoneToZoneInput,
    TerritorialZone,
    UpdateTerritorialZoneInput,
} from '@admin/UniversityPage/types';

export const addZone = (
    array: UpdateTerritorialZoneInput[], newZone: UpdateTerritorialZoneInput,
)
: void => {
    array.forEach((oldZone) => {
        const timeFromNewZoneToOldZone: CreateZoneToZoneInput = {
            id: newZone.id,
            travelTime:
                newZone.travelTimeBetweenZones.find(
                    (element) => element.id === oldZone.id,
                )?.travelTime || 0,
        };
        oldZone.travelTimeBetweenZones.push(timeFromNewZoneToOldZone);
    });
    array.push(newZone);
};

export const createTravelTime = (
    array: UpdateTerritorialZoneInput[],
    id: string,
    travelTime: number,
): CreateZoneToZoneInput[] => {
    const travelTimeBetweenZones = array.map((zone) => ({
        id: zone.id,
        travelTime: 0,
    }));
    travelTimeBetweenZones.push({ id, travelTime });
    return travelTimeBetweenZones;
};

export const addEmptyZones = (array: UpdateTerritorialZoneInput[], diff: number): void => {
    for (let i = 0; i < diff; i += 1) {
        const id = nanoid();
        const zone: UpdateTerritorialZoneInput = {
            id,
            name: '',
            travelTimeBetweenZones: createTravelTime(array, id, 0),
            isOptimalRoomCapacityForMeetingsEnabled: true,
            availableIntervals: [],
        };
        addZone(array, zone);
    }
};

export const sortTravelTime = (
    array: CreateZoneToZoneInput[],
    IDs: string[],
    id: string,
): CreateZoneToZoneInput[] => {
    let firstZone: CreateZoneToZoneInput = { id: '', travelTime: 0 };
    const travelTimesBetweenZones = [...array].filter((zone, index, zones) => {
        if (zone.id === id) {
            firstZone = zones[index];
            return false;
        }
        return true;
    });
    travelTimesBetweenZones.sort((a, b) => {
        if (IDs.indexOf(a.id) > IDs.indexOf(b.id)) {
            return 1;
        }
        return -1;
    });
    return [firstZone, ...travelTimesBetweenZones];
};

export const sortZones = (array: UpdateTerritorialZoneInput[]): UpdateTerritorialZoneInput[] => {
    const IDs: string[] = array.map((zone) => zone.id);
    const sortedZones = array.map((z) => {
        const zone = { ...z };
        zone.travelTimeBetweenZones = sortTravelTime(
            zone.travelTimeBetweenZones,
            IDs,
            zone.id,
        );
        return zone;
    });
    return sortedZones;
};

export const transformZonesToCreateZones = (
    zones: TerritorialZone[],
): UpdateTerritorialZoneInput[] => map(zones,
    (zone) => (
        {
            id: zone.id,
            name: zone.name,
            travelTimeBetweenZones: map(zone.travelTimesFromZone, (travelTimeZone) => ({
                id: travelTimeZone.toZoneId,
                travelTime: travelTimeZone.travelTime,
            })),
            isOptimalRoomCapacityForMeetingsEnabled:
            zone.isOptimalRoomCapacityForMeetingsEnabled ?? true,
            availableIntervals: zone.availableIntervals ?? [],
        }));
