import React from 'react';
import { observer } from 'mobx-react';

import { EventWithoutTest as EventWithoutTestType, Validator } from '@admin/NewModule/Store';
import { getArrowParams } from './getArrowParams';

import classes from './Arrows.module.scss';

interface ArrowProps {
    dependentEvent?: EventWithoutTestType;
    mainEvent: EventWithoutTestType;
    eventList?: HTMLDivElement | null;
    dependentElement?: HTMLDivElement | null;
    mainElement?: HTMLDivElement | null;
}

function updateForce() {
    const [, setValue] = React.useState(true);
    return () => setValue(value => !value);
}

export const Arrow = observer(({
    dependentEvent, mainEvent, eventList, dependentElement, mainElement,
}: ArrowProps) => {
    const [mouseOn, setMouseOn] = React.useState(false);
    const [mouseClick, setMouseClick] = React.useState(false);
    React.useEffect(updateForce(), [dependentEvent?.order, mainEvent.order]);

    const dependentElementParams = dependentElement?.getBoundingClientRect();
    const mainElementParams = mainElement?.getBoundingClientRect();
    const eventListParams = eventList?.getBoundingClientRect();

    if (!dependentElementParams || !mainElementParams || !eventListParams || !dependentEvent) {
        return null;
    }

    const { minDaysToNextEvent = 0, maxDaysToNextEvent = 0 } = mainEvent;
    const { minDaysToPreviousEvent = 0, maxDaysToPreviousEvent = 0 } = dependentEvent;
    const maxDays = Math.max(
        minDaysToNextEvent, maxDaysToNextEvent, minDaysToPreviousEvent, maxDaysToPreviousEvent,
    );
    const inRow = mainEvent.isNextEventInRow && dependentEvent.isPreviousEventInRow;
    const {
        widthArrows, eventListHeight, dLine, dArrow, dText, dLineText, lineLength,
    } = getArrowParams({
        eventListParams, mainElementParams, dependentElementParams, maxDays, inRow,
    });
    const idTextLine = `textLine_from_${mainEvent.id}_to_${dependentEvent.id}`;
    const xlinkHref = `#${idTextLine}`;

    const error = Validator.validateInterval(mainEvent, dependentEvent);
    const params = mouseOn || mouseClick ? {
        textColor: 'black', lineWidth: 2, arrowWidth: 4, textSize: '14px', kefOfTextSize: 4,
    } : {
        textColor: 'grey', lineWidth: 1, arrowWidth: 2, textSize: '12px', kefOfTextSize: 3,
    };

    return (
        <div className={classes.arrows__arrow}>
            <svg xmlns="http://www.w3.org/2000/svg" width={widthArrows} height={eventListHeight}>
                <g
                    onMouseEnter={() => setMouseOn(true)}
                    onMouseLeave={() => setMouseOn(false)}
                    onClick={() => setMouseClick(!mouseClick)}
                    pointerEvents="stroke"
                >
                    <path d={dLine} stroke={error.valid ? 'black' : 'red'} strokeWidth={params.lineWidth} fillOpacity="0" />
                    <path d={dArrow} stroke={error.valid ? 'black' : 'red'} strokeWidth={params.arrowWidth} fillOpacity="0" />
                    <path id={idTextLine} d={dLineText} fill="transparent" />
                    <text fill={error.valid ? params.textColor : 'red'}>
                        <textPath
                            xlinkHref={xlinkHref}
                            startOffset={(lineLength / 2) - (dText.length * params.kefOfTextSize)}
                            fontSize={params.textSize}
                        >
                            {dText}
                        </textPath>
                    </text>
                </g>
            </svg>
        </div>
    );
});
