import { DistributionType } from 'src/subSpaceAdmin/subSpaceTypes';

export enum SelectionStatus {
    SELECTIONPENDING = 'selectionPending',
    SUBSPACESELECTIONENABLED = 'subspaceSelectionEnabled',
    SUBSPACESELECTIONINPROCESS = 'subspaceSelectionInProcess',
    SUBSPACEDISTRIBUTIONENABLED = 'subspaceDistributionEnabled',
    SUBSPACEDISTRIBUTIONINPROCESS = 'subspaceDistributioninProcess',
    SUBSPACEDISTRIBUTIONPENDING = 'subspaceDistributionPending',
    MODULESELECTIONENABLED = 'moduleSelectionEnabled',
    MODULESELECTIONINPROCESS = 'moduleSelectionInProcess',
    MODULEDISTRIBUTIONENABLED = 'moduleDistributionEnabled',
    MODULEDISTRIBUTIONINPROCESS = 'moduleDistributioninProcess',
    MODULEDISTRIBUTIONPENDING = 'moduleDistributionPending',
    SELECTIONFINISHED = 'selectionfinished',
}

export interface BaseTimeIntervalInstance {
    selectionStatus: SelectionStatus;
    id: string;
    startDate: string;
    endDate: string;
    spaceBaseTimeInterval: {
        order: number;
        space: {
            baseTimeIntervalType: {
                name: string;
            }
        }
    }
}

export interface StudentSubspace {
    subspace: Subspace;
    studentModules: StudentModule[];
}
export interface RequestedBaseTimeIntervalInstance {
    baseTimeIntervalInstance: BaseTimeIntervalInstance;
    studentSubspaces: StudentSubspace[];
}

export interface CurOrPastBtiData {
    id: string;
    requestedBaseTimeIntervalInstance: RequestedBaseTimeIntervalInstance;
}

export interface Fork {
    id: string;
    spaceBaseTimeIntervalId: string;
    nextSlots: {
        id: string;
        spaceBaseTimeIntervalId: string;
        module: Module;
    }[];
    setting: {
        moduleSelectionCount: number;
        distributionType: DistributionType;
        studentPickingType: string;
        studentPriorityCount: number;
    };
}

export interface Subspace {
    id: string;
    name: string;
    type: SubspaceType;
    slotDiagram: {
        forks: Fork[]
        slots: {
            id: string;
            spaceBaseTimeIntervalId: string;
            module: Module;
        }[];
    }
}
export interface FutureBTI {
    id: string;
    selectionPoint: {
        moduleSelectionStartDate: string;
        moduleSelectionEndDate: string;
    }
    subspaceTypeLayouts: {
        subspaceType: {
            isSelective: boolean;
            subspaces: Subspace[];
        }
    }[];
}

export interface Duration {
    id: string;
    name: string;
    academicHours: number;
}

export interface Skill {
    id: string;
    name: string;
    fullName: string;
    typeId: string;
    maxLevel?: number;
    description?: string;
    children?: Skill[];
    dependencies?: Skill[];
}

export interface SkillWhithLevel {
    skill: Skill;
    level?: number;
}

export interface EventType {
    id: string;
    topic: string;
    order: number;
    duration: Duration;
    prerequisiteSkills: SkillWhithLevel[];
    outputSkills: SkillWhithLevel[];
    eventName: string;
    eventOrder?: number;
}

export interface EvaluationPoint {
    id: string;
    topic: string;
    description: string;
    order: number;
    duration: Duration;
    evaluationPointSkills: SkillWhithLevel[];
    eventName: string;
    eventOrder?: number;
}

export type AnyEvent = EventType | EvaluationPoint | null;

interface User {
    firstName: string;
    lastName: string;
    patronymic: string;

}

interface Teacher {
    user: User;
}

export interface Module {
    id: string;
    name: string;
    description: string;
    meetings: EventType[];
    assignments: EventType[];
    evaluationPoints: EvaluationPoint[];
    prerequisiteSkills: SkillWhithLevel[];
    outputSkills: SkillWhithLevel[];
    moduleTeachers: { teacher: Teacher, teacherRoles: { name: string }[] }[];
}

export interface SubspaceType {
    id: string;
    name: string;
}

export interface StudentModule {
    id?: string;
    module: Module;
    slotId?: string;
    isOpen?: boolean;
    isStudentModuleAssessmentActive?: boolean;
}

export type ModuleEventType = 'meetings' | 'evaluationPoints' | 'assignments';

export interface ModuleEvent {
    eventType?: ModuleEventType;
    event?: EventType | EvaluationPoint | null;
    prerequisiteSkills?: SkillWhithLevel[];
    outputSkills?: SkillWhithLevel[];
    evaluationPointSkills?: SkillWhithLevel[];
}

export interface EventInstance {
    id: string;
    startDate: string;
    endDate: string;
}

export interface MeetingInstance extends EventInstance {
    meeting: {
        id: string;
    };
    meetingInstanceToStudents: {
        attendance: boolean;
        student: {
            id: string;
        }
    }[]
}

export interface AssignmentInstance extends EventInstance {
    assignment: {
        id: string;
    };
}

export interface EvaluationPointInstance extends EventInstance {
    evaluationPoint: {
        id: string;
    };
}

export interface StudentSchedule {
    meetingInstances: MeetingInstance[];
    assignmentInstances: AssignmentInstance[];
    evaluationPointInstances: EvaluationPointInstance[];
}

export interface ModuleSettings {
    meetingSkillTypes: { id: string; isLevelIncreased: boolean; }[];
    moduleSkillTypes: { id: string; isLevelIncreased: boolean; }[];
}

export interface AttendanceStudentSchedule {
    meetingId: string;
    attendance: boolean;
}

export interface StudentPriority {
    forkId: string;
    moduleId: string;
    priority: number;
    selectionIterationId: string;
    slotId: string;
    studentId: string;
}
