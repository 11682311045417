import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { gql } from '@apollo/client';
import { sortBy, uniqBy } from 'lodash';

import { Alert } from '@common/Alert';
import { useUrlQuery } from '@common/hooks';
import { ActionButton } from '@common/ActionButton';
import { FilterSelect } from './FilterSelect';
import { CalendarComponent } from './CalendarComponent';
import { SchedulePagination } from '../SchedulePagination';
import {
    LoadFilteredSchedule,
    ModuleTitleSuggestion,
    RoomSuggestion,
    StudentSuggestion,
    TeacherSuggestion,
    WaveIndexSuggestionResponse,
    StatusType,
    EventType,
    Meeting,
} from '../ScheduleGenerationPageInterfaces';
import { ArrowSvg } from './ArrowSvg';

import classes from './FiltrationComponents.module.scss';
import {
    events,
    FindParameters,
    getSuggesTeachers,
    getSuggestedModules,
    getSuggestedRooms,
    getSuggestedStudents,
    Type,
    UserStatus,
} from './apollo-types';

interface Props {
    pagesCount?: number,
    baseTimeIntervalInstanceId: string,
    refetchPagesCount(): void,
    loadFilteredSchedule: LoadFilteredSchedule,
}

const GET_SUGGESTED_TEACHERS = gql`
    query getSuggesTeachers($value: FindParameters!) {
        users(findParams: $value) {
            list {
                firstName
                lastName
                patronymic
                teacherId
            }
          }
    }
`;

const GET_SUGGESTED_STUDENTS = gql`
    query getSuggestedStudents($value: FindParameters!) {
        users(findParams: $value) {
            list {
                studentId
                firstName
                lastName
                patronymic
            }
          }
    }
`;

const GET_SUGGESTED_ROOMS = gql`
    query getSuggestedRooms($value: String!) {
        roomsByTitlePattern(title: $value) {
            id
            title
            location {
              name
            }
          }
    }
`;

const GET_SUGGESTED_MODULES = gql`
    query getSuggestedModules($value: ModulesByFiltersInput!) {
        modulesByFilters(modulesByFiltersInput: $value) {
            id
            name
        }
    }
`;

const GET_SUGGESTED_WAVES = gql`
    query getSuggestedWaves($value: WavesUniqueIndexesInput!) {
        wavesUniqueIndexesByIndexPattern(wavesUniqueIndexesInput: $value)
    }
`;

const GET_SUGGESTED_MEETINGS = gql`
    query events($value: EventsInput!) {
        events(eventsInput: $value) {
            meetings {
                id
                topic
            }
            assignments {
                id
                topic
            }
            evaluationPoints {
                id
                topic
            }
        }
    }
`;

const statusTypes = [
    {
        id: '1',
        name: 'Опубликованные',
        status: 'active',
    },
    {
        id: '2',
        name: 'Неопубликованные',
        status: 'generation',
    },
    {
        id: '3',
        name: 'Удалённые',
        status: 'deleted',
    },
];

const eventTypes = [
    {
        id: 'meeting',
        name: 'Встреча',
    },
    {
        id: 'assignment',
        name: 'Самостоятельная работа',
    },
    {
        id: 'evaluationPoint',
        name: 'Точка оценки',
    },
];

enum RoomType {
    online = 'online',
    indoor = 'indoor',
}

const roomTypeOptions = [{
    id: RoomType.online,
    name: 'Онлайн',
}, {
    id: RoomType.indoor,
    name: 'Обычное помещение',
}];

export function FiltrationComponents(
    {
        pagesCount,
        baseTimeIntervalInstanceId,
        loadFilteredSchedule,
        refetchPagesCount,
    }: Props,
): JSX.Element {
    const { getUrlQuery, setUrlQuery } = useUrlQuery();
    const [errorAlerts, setErrorAlerts] = useState<JSX.Element[] | []>([]);
    const [modules, setModules] = useState<ModuleTitleSuggestion[]>([]);
    const [meetings, setMeetings] = useState<Meeting[]>([]);
    const [statuses, setStatuses] = useState<StatusType[]>([]);
    const [eventType, setEventType] = useState<EventType[]>([]);
    const [rooms, setRooms] = useState<RoomSuggestion[]>([]);
    const [teachers, setTeachers] = useState<TeacherSuggestion[]>([]);
    const [students, setStudents] = useState<StudentSuggestion[]>([]);
    const [waves, setWaves] = useState<{ id: string, waveIndex: number; }[]>([]);
    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [roomTypes, setRoomTypes] = useState<EventType[]>([]);
    const [startDate, endDate] = dateRange;
    const onChangeCalendar = (update: []) => setDateRange(update);
    const [inputValues, setInputValues] = useState({ fromTime: '00:00', toTime: '23:59' });
    const loadFiltersFromURL = () => {
        const modulesFromURL = getUrlQuery('modules');
        const roomsFromURL = getUrlQuery('rooms');
        const teachersFromURL = getUrlQuery('teachers');
        const studentsFromURL = getUrlQuery('students');
        const wavesFromURL = getUrlQuery('waves');
        const fromFromURL = getUrlQuery('from');
        const toFromURL = getUrlQuery('to');
        const meetingFromURL = getUrlQuery('meetings');
        const statusesFromURL = getUrlQuery('statuses');
        const typesFromURL = getUrlQuery('types');
        const roomTypesFromURL = getUrlQuery('roomTypes');
        const decodedModules = modulesFromURL ? decodeObject(modulesFromURL) : [];
        const decodedMeetings = meetingFromURL ? decodeObject(meetingFromURL) : [];
        const decodedStatuses = statusesFromURL ? decodeObject(statusesFromURL) : [];
        const decodedEventTypes = typesFromURL ? decodeObject(typesFromURL) : [];
        const decodedRooms = roomsFromURL ? decodeObject(roomsFromURL) : [];
        const decodedTeachers = teachersFromURL ? decodeObject(teachersFromURL) : [];
        const decodedStudents = studentsFromURL ? decodeObject(studentsFromURL) : [];
        const decodedWaves = wavesFromURL ? decodeObject(wavesFromURL) : [];
        const decodedFrom = fromFromURL ? parse(decodeURIComponent(fromFromURL), 'yyyy.MM.dd HH:mm', new Date()) : null;
        const decodedTo = toFromURL ? parse(decodeURIComponent(toFromURL), 'yyyy.MM.dd HH:mm', new Date()) : null;
        const decodeRoomTypes = roomTypesFromURL ? decodeObject(roomTypesFromURL) : [];
        setModules(decodedModules);
        setRooms(decodedRooms);
        setTeachers(decodedTeachers);
        setStudents(decodedStudents);
        setWaves(decodedWaves);
        setDateRange([decodedFrom, decodedTo]);
        setMeetings(decodedMeetings);
        setStatuses(decodedStatuses);
        setEventType(decodedEventTypes);
        setRoomTypes(decodeRoomTypes);
        loadFilteredSchedule(
            decodedModules,
            decodedMeetings,
            decodedRooms,
            decodedTeachers,
            decodedStudents,
            decodedWaves,
            fromFromURL === 'undefined' || !fromFromURL ? '' : decodeURIComponent(fromFromURL),
            toFromURL === 'undefined' || !toFromURL ? '' : decodeURIComponent(toFromURL),
            decodedStatuses,
            decodedEventTypes,
            decodeRoomTypes,
        );
    };

    useEffect(() => loadFiltersFromURL(), []);

    const handlerFilterSubmit = () => {
        setUrlQuery({ page: '1' });
        const from = startDate ? `${format(startDate, 'yyyy.MM.dd')} ${inputValues.fromTime}` : '';
        const to = endDate ? `${format(endDate, 'yyyy.MM.dd')} ${inputValues.toTime}` : '';
        loadFilteredSchedule(
            modules,
            meetings,
            rooms,
            teachers,
            students,
            waves,
            from,
            to,
            statuses,
            eventType,
            roomTypes,
        );
        refetchPagesCount();
    };

    const handlerFilterReset = () => {
        setUrlQuery({ page: '1' });
        setModules([]);
        setMeetings([]);
        setWaves([]);
        setRooms([]);
        setTeachers([]);
        setStudents([]);
        setDateRange([null, null]);
        setStatuses([]);
        setEventType([]);
        loadFilteredSchedule([], [], [], [], [], [], '', '', [], [], []);
    };

    const formatWaveIndexSendindValue = (value: string) => {
        if (value === '') {
            return {
                indexPattern: value,
                baseTimeIntervalInstanceId,
            };
        }

        let index = parseInt(value, 10);

        if (Number.isNaN(index)) {
            setErrorAlerts((arr) => [...arr, (<Alert
                key={new Date().toISOString()}
                message="Введите номер потока цифрами"
            />)]);
        } else {
            index -= 1;
            return {
                indexPattern: index.toString(),
                baseTimeIntervalInstanceId,
            };
        }

        return {
            indexPattern: '',
            baseTimeIntervalInstanceId,
        };
    };

    const formatModulesSendindValue = (value: string) => ({
        namePattern: value,
        baseTimeIntervalInstanceId,
    });

    const formatStudentsSendindValue = (value: string): FindParameters => ({
        userType: Type.student,
        fullName: value,
        status: UserStatus.active,
        studentFindParameters: {
            baseTimeIntervalInstanceId,
        },
    });

    const formatTeachersSendindValue = (value: string): FindParameters => ({
        userType: Type.teacher,
        fullName: value,
        status: UserStatus.active,
    });

    const formatEventsSendindValue = (value: string) => ({
        eventNamePatterns: value ? [value] : undefined,
        baseTimeIntervalInstanceId,
    });

    return (
        <>
            <div className={classes.filters}>
                <FilterSelect
                    filterName="Модуль:"
                    placeholder="Выбрать модуль"
                    setState={setModules}
                    selectedOptions={modules}
                    query={GET_SUGGESTED_MODULES}
                    formatOption={moduleTitleFormat}
                    transformResponse={moduleTransform}
                    formatSendingValue={formatModulesSendindValue}
                />

                <FilterSelect
                    filterName="Аудитория:"
                    placeholder="Выбрать аудиторию"
                    setState={setRooms}
                    selectedOptions={rooms}
                    query={GET_SUGGESTED_ROOMS}
                    formatOption={roomFormat}
                    transformResponse={roomTransform}
                />

                <FilterSelect
                    filterName="Преподаватель:"
                    placeholder="Выбрать преподавателя"
                    setState={setTeachers}
                    selectedOptions={teachers}
                    query={GET_SUGGESTED_TEACHERS}
                    formatOption={teacherFormat}
                    transformResponse={teacherTransform}
                    formatSendingValue={formatTeachersSendindValue}
                />

                <FilterSelect
                    filterName="Студент:"
                    placeholder="Выбрать студента"
                    setState={setStudents}
                    selectedOptions={students}
                    query={GET_SUGGESTED_STUDENTS}
                    formatOption={studentFormat}
                    transformResponse={studentTransform}
                    formatSendingValue={formatStudentsSendindValue}
                />

                <FilterSelect
                    filterName="Поток:"
                    placeholder="Выбрать поток"
                    setState={setWaves}
                    selectedOptions={waves}
                    query={GET_SUGGESTED_WAVES}
                    transformResponse={waveIndexTransform}
                    formatOption={waveIndexFormat}
                    formatSendingValue={formatWaveIndexSendindValue}
                />

                <CalendarComponent
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChangeCalendar}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                />

                <FilterSelect
                    filterName="Название события:"
                    placeholder="Выбрать встречу"
                    setState={setMeetings}
                    selectedOptions={meetings}
                    query={GET_SUGGESTED_MEETINGS}
                    formatOption={meetingTitleFormat}
                    transformResponse={meetingTransform}
                    formatSendingValue={formatEventsSendindValue}
                />

                <FilterSelect
                    filterName="Статус события:"
                    placeholder="Выбрать статус"
                    setState={setStatuses}
                    selectedOptions={statuses}
                    options={statusTypes}
                    formatOption={statusFormat}
                />

                <FilterSelect
                    filterName="Тип события:"
                    placeholder="Выбрать тип"
                    setState={setEventType}
                    selectedOptions={eventType}
                    options={eventTypes}
                    formatOption={eventTypeFormat}
                />

                <FilterSelect
                    filterName="Тип аудитории"
                    placeholder="Выбрать тип"
                    setState={setRoomTypes}
                    selectedOptions={roomTypes}
                    options={roomTypeOptions}
                    formatOption={eventTypeFormat}
                />
            </div>

            <div className={classes.filters__buttons}>
                <div className={classes.filters__resetButton} onClick={handlerFilterReset}>
                    <ArrowSvg />
                    <p className={classes.filters__resetButtonText}>
                        Сбросить фильтры
                    </p>
                </div>

                <ActionButton
                    className={classes.filters__submitButton}
                    onClick={handlerFilterSubmit}
                >
                    Применить
                </ActionButton>
            </div>

            {errorAlerts}

            <SchedulePagination
                pagesCount={pagesCount}
                onChange={loadFiltersFromURL}
                refetchPagesCount={refetchPagesCount}
            />
        </>
    );
}

function roomFormat(item: RoomSuggestion): string {
    return `${item.title} ${item.location.name}`;
}

function roomTransform(response: getSuggestedRooms): RoomSuggestion[] {
    return response.roomsByTitlePattern;
}

function studentFormat(item: StudentSuggestion): string {
    return `${item.lastName} ${item.firstName} ${item.patronymic}`;
}

function studentTransform(response: getSuggestedStudents): StudentSuggestion[] {
    const result = response.users.list.map(
        (user) => ({
            id: user.studentId!,
            ...user,
        }),
    );
    return result;
}

function teacherFormat(item: TeacherSuggestion): string {
    return `${item.lastName} ${item.firstName} ${item.patronymic} (${item.id.slice(0, 4).toLowerCase()})`;
}

function teacherTransform(response: getSuggesTeachers): TeacherSuggestion[] {
    const result = response.users.list.map(
        (user) => ({
            id: user.teacherId!,
            ...user,
        }),
    );
    return result;
}

function moduleTitleFormat(item: ModuleTitleSuggestion): string {
    return `${item.name}`;
}

function statusFormat(item: StatusType): string {
    return `${item.name}`;
}

function eventTypeFormat(item: EventType): string {
    return `${item.name}`;
}

function meetingTitleFormat(item: Meeting): string {
    return `${item.topic}`;
}

function moduleTransform(response: getSuggestedModules): ModuleTitleSuggestion[] {
    return response.modulesByFilters;
}

function meetingTransform(
    response: events,
) {
    const concatedEventNames = [
        ...response?.events?.meetings,
        ...response?.events?.assignments,
        ...response?.events?.evaluationPoints,
    ];
    return sortBy(uniqBy(concatedEventNames, 'topic'), 'topic');
}
function waveIndexTransform(
    response: WaveIndexSuggestionResponse,
): { id: string, waveIndex: number; }[] {
    return response.wavesUniqueIndexesByIndexPattern.map(
        (waveIndex) => ({
            id: waveIndex.toString(),
            waveIndex,
        }),
    ).sort((a, b) => (a.waveIndex > b.waveIndex ? 1 : -1));
}

function waveIndexFormat(item: { id: string, waveIndex: number; }): string {
    return `${item.waveIndex + 1}`;
}

export function decodeObject(stringifiedObject: string): any {
    return JSON.parse(decodeURIComponent(stringifiedObject));
}
