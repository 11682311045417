import React, { Fragment } from 'react';

import { groupBy } from 'lodash';

import classes from './ModuleSkillsList.module.scss';
import { ModuleSkillsListSpecificSkills } from './ModuleSkillsListSpecificSkills';
import { ModuleSkillsListSwitchParams } from './ModuleSkillsListSwitchParams';

import {
    SkillType,
    Module as ModuleType,
    ModuleSkill,
} from '../../../subSpaceAdmin/subSpaceTypes';
import { skills_skills } from '../../../graphql-query-types';

interface Props {
    currentModule: ModuleType;
    skillTypes: SkillType[];
    allSkillsList: skills_skills[];
}

export function ModuleSkillsList({
    currentModule,
    skillTypes,
    allSkillsList,
}: Props): JSX.Element {
    const sortedSkills: any = skillTypes
        .filter((skill: SkillType) => skill.parentId === null);

    const getFinalSkillTypeId = (typeId: string): string => {
        const skillTypeGroups = groupBy(skillTypes, 'parentId');
        let finalSkillTypeId = typeId;
        while (skillTypeGroups[finalSkillTypeId]) {
            const [child] = skillTypeGroups[finalSkillTypeId];
            finalSkillTypeId = child.id;
        }

        return finalSkillTypeId;
    };

    function isParentSkill(parent: any, child: any): boolean {
        return !!parent.children?.filter((item: any) => item.id !== parent.id)
            .some((item: any) => item.id === child.id);
    }

    function findParentSkill(skill: any): any {
        return allSkillsList.find((item: any) => isParentSkill(item, skill));
    }

    function formatSkillName(skill: any): string {
        let { name } = skill.skill;
        let parent = findParentSkill(skill.skill);

        while (parent) {
            name = `${name}. ${parent.name}`;
            parent = findParentSkill(parent);
        }

        return name;
    }

    function setCurrentSkillType(itemId: string, skills: ModuleSkill[]) {
        const currenSkillType: any = [];
        skills?.forEach((item) => {
            if (itemId === item.skill.typeId) {
                currenSkillType.push({
                    id: item.skill.id,
                    name: item.skill.name,
                    level: item.level ?? '',
                });
            }
            if (getFinalSkillTypeId(itemId) > sortedSkills.length
                && getFinalSkillTypeId(itemId) <= item.skill.typeId) {
                currenSkillType.push({
                    id: item.skill.id,
                    name: formatSkillName(item),
                    level: item.level ?? '',
                });
            }
        });

        return skills ? currenSkillType : [];
    }

    return (
        <div className={classes.moduleSkillsList}>
            {sortedSkills.map((item: any) => (
                (!!setCurrentSkillType(item.id, currentModule.prerequisiteSkills).length
                    || !!setCurrentSkillType(item.id, currentModule.outputSkills).length)
                && (
                    <Fragment key={item.id}>
                        <div className={classes.moduleSkillsList__title}>
                            {item.name.genitiveSingular}
                        </div>
                        <ModuleSkillsListSwitchParams />
                        <ModuleSkillsListSpecificSkills
                            skillListIntro={
                                setCurrentSkillType(item.id, currentModule.prerequisiteSkills)
                            }
                            skillListAutro={
                                setCurrentSkillType(item.id, currentModule.outputSkills)
                            }
                        />
                    </Fragment>
                )
            ))}
        </div>
    );
}
