import React, {
    FormEvent, ReactChild, useRef, useState,
} from 'react';
import classnames from 'classnames';
import { useResetEscapeMouseClick } from '@admin/Users/utils/hooks';
import { ActionButton } from '@common/ActionButton';
import classes from './ToolButton.module.scss';
import { SimpleToolButton } from './SimpleToolButton';

interface Props {
    contextMenu: ReactChild;
    text?: string;
    icon: string;
    modifier?: string;
    isLoader?: boolean;
    onSubmit?(event: FormEvent<HTMLFormElement>): void;
    handlerLoad?(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const ContextMenuToolButton = ({
    contextMenu,
    text,
    icon,
    modifier,
    isLoader = false,
    onSubmit,
    handlerLoad,
}: Props): JSX.Element => {
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    useResetEscapeMouseClick(wrapperRef, setIsContextMenuOpen, false);
    const handleToggle = () => {
        setIsContextMenuOpen(!isContextMenuOpen);
    };
    return (
        <div className={classes.toolButton} ref={wrapperRef}>
            <SimpleToolButton
                text={text}
                icon={icon}
                onClick={() => handleToggle()}
                modifier={modifier}
            />
            <div
                className={
                    classnames(classes.toolButton__radioList,
                        { [classes.toolButton__radioList_opened]: isContextMenuOpen })
                }
            >
                <form onSubmit={onSubmit}>
                    {contextMenu}
                    {isLoader && (
                        <div className={classes.toolButton__button}>
                            <input
                                type="file"
                                onChange={handlerLoad}
                                accept=".csv"
                            />
                        </div>
                    )}
                    <div className={classes.toolButton__button}>

                        <ActionButton
                            type="submit"
                            className={classes.toolButton__button_width}
                            onClick={() => handleToggle()}
                        >
                            {isLoader ? 'Загрузить' : 'ОК'}
                        </ActionButton>
                    </div>
                </form>
            </div>
        </div>
    );
};
