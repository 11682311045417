import { makeAutoObservable } from 'mobx';
import { maxBy, min, orderBy, uniqBy } from 'lodash';
import { nanoid } from 'nanoid';

import { Meeting } from '@admin/NewModule/Store/Meeting';
import { Assignment } from '@admin/NewModule/Store/Assignment';
import { ModuleSkill, PointSkill } from './moduleStoreTypes';
/* eslint import/no-cycle: "off" */
import { moduleStore } from './moduleStore';
import { dictionaryStore } from './dictionaryStore';
import { EvaluationPoint } from './EvaluationPoint';
import { EventWithoutTest } from './ModuleModel';

export class EvaluationPointListModel {
    evaluationPoints: EvaluationPoint[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    updateALLEvaluationPoints = (points: EvaluationPoint[]): void => {
        this.evaluationPoints = points;
    };

    getEvaluationPoint = (id: string): EvaluationPoint | undefined => this.evaluationPoints
        .find(item => item.id === id);

    updateEvaluationPoint = (id: string, data: Partial<EvaluationPoint>): void => {
        const point = this.getEvaluationPoint(id);
        Object.keys(data).forEach(key => {
            // @ts-ignore
            point[key] = data[key];
        });
    };

    addEvaluationPoint = (): void => {
        const module = moduleStore.moduleModel;
        const lastOrder = module.events.reduce((acc, { order }) => Math.max(acc, order), 0);

        const newPoint = new EvaluationPoint();
        newPoint.id = nanoid();
        newPoint.order = lastOrder + 1;

        this.evaluationPoints.push(newPoint);
    };

    removeEvaluationPoint = (id: string): void => {
        const module = moduleStore.moduleModel;
        const evaluationPoint = module.getEvaluationPoint(id);
        if (!evaluationPoint) return;
        const newEvaluationPoints = this.evaluationPoints.filter(item => item.id !== id);
        this.evaluationPoints = newEvaluationPoints;
        module.changeOrder(
            module.events.find(({ order }) => order === evaluationPoint.order + 1)?.id ?? '',
            evaluationPoint?.order,
        );
    };

    updateEvaluationPointTeacherRoles(id: string): void {
        const point = this.getEvaluationPoint(id);
        point!.evaluationPointTeacherRoles = moduleStore.removeNonexistentRoles(
            point!.evaluationPointTeacherRoles,
        );
    }

    getInitPointSkills = (skills: PointSkill[]): PointSkill[] => skills.map(skill => {
        const { isLevelIncreased } = dictionaryStore.getSkillType(
            dictionaryStore.getInitSkillTypeId(skill.skill.typeId ?? ''),
        );
        if (isLevelIncreased) return { ...skill, level: skill.level || 1 };
        return skill;
    });

    private getFutureEvaluationPoints = (evaluationPointOrder: Number): EvaluationPoint[] => this
        .evaluationPoints.filter(point => point.order > evaluationPointOrder);

    updateEvaluationPointSkills = (
        pointId: string, skillTypeId: string, newSkills: PointSkill[],
    ): void => {
        const refactorNewSkills = this.getInitPointSkills(newSkills);
        const evaluationPoint = this.getEvaluationPoint(pointId)!;
        evaluationPoint.updateEvaluationPointSkills(refactorNewSkills, skillTypeId);

        const points = this.getFutureEvaluationPoints(evaluationPoint.order);
        this.saveValidEvaluationPointsSkills(points);
    };

    updateEvaluationPointSkillLevel = (
        pointId: string, typeId: string, skillId: string, level: number,
    ): void => {
        const point = this.getEvaluationPoint(pointId);
        point?.updateEvaluationPointSkillLevel(skillId, level);
    };

    saveValidEvaluationPointsSkills = (points: EvaluationPoint[]): void => {
        orderBy(points, ['order'], ['asc']).forEach(point => {
            const possibleSkills = this.getPointSkillsForPoint(
                point.id, point.previousEvent?.id, true,
            );
            const currentPointSkills = point.evaluationPointSkills ?? [];
            const evaluationPointSkills = currentPointSkills
                .filter(({ skill: { id } }) => possibleSkills.some(skill => skill.skill.id === id));

            this.updateEvaluationPoint(point.id, { evaluationPointSkills });
        });
    };

    refreshDefaultEvaluationPointSkills = () => {
        const { eventsWithoutTest, options } = moduleStore.moduleModel;
        orderBy(eventsWithoutTest, 'order', 'asc').forEach(event => {
            const notEvaluatedSkills = this.getNotEvaluatedSkills(event);
            const closestEvaluationPoint = this.getClosestEvaluationPoint(event);

            closestEvaluationPoint?.addEvaluationPointSkills(notEvaluatedSkills);
        });
        const { outputSkills } = options;
        const lastEvaluationPoint = maxBy(this.evaluationPoints, 'order');
        lastEvaluationPoint?.addEvaluationPointSkills(outputSkills);
    };

    getNotEvaluatedSkills(event: Meeting | Assignment): ModuleSkill[] {
        const { outputSkills } = event;
        const pointsThatCanEvaluate = this.getPointsThatCanEvaluate([event]);
        const notEvaluatedSkills = outputSkills?.filter(({
            skill: { id },
        }) => !this.isEvaluatedSkill(id, pointsThatCanEvaluate)) ?? [];
        return notEvaluatedSkills;
    }

    getNotEvaluatedModuleSkills(): ModuleSkill[] {
        const { outputSkills } = moduleStore.moduleModel.options;
        const pointsThatCanEvaluate = this.evaluationPoints;
        const notEvaluatedSkills = outputSkills?.filter(({
            skill: { id },
        }) => !this.isEvaluatedSkill(id, pointsThatCanEvaluate)) ?? [];
        return notEvaluatedSkills;
    }

    private getClosestEvaluationPoint(event: Meeting | Assignment): EvaluationPoint | undefined {
        const { order: eventOrder } = event;
        const pointsThatCanEvaluate = this.getPointsThatCanEvaluate([event]);
        const evaluationPointOrders = pointsThatCanEvaluate.map(({ order }) => order);
        const futureEvaluationPointOrders = evaluationPointOrders.filter(
            order => order > eventOrder,
        );
        const minEvaluationPointOrder = min(futureEvaluationPointOrders);
        return this.evaluationPoints.find(({ order }) => order === minEvaluationPointOrder);
    }

    saveValidAllEvaluationPointsSkills(): void {
        this.saveValidEvaluationPointsSkills(this.evaluationPoints);
    }

    updateEvaluationPointPreviousEvent = (pointId: string, previousEventId: string): void => {
        const module = moduleStore.moduleModel;
        const event = module.getEvent(previousEventId);
        const updatedPoint = this.getEvaluationPoint(pointId);
        if (!event || !updatedPoint) return;

        const skills = this.getPointSkillsForPoint(pointId, previousEventId);
        const evaluationPointSkills = uniqBy(
            this.getInitPointSkills(skills.map(skill => ({ ...skill, pointId }))),
            item => item.skill.id,
        );

        if (updatedPoint.previousEvent?.id !== previousEventId) {
            const dependentEvaluationPoints = module.evaluationPoints
                .filter(point => point.previousEvent?.id === previousEventId);
            const maxEventOrder = dependentEvaluationPoints
                .reduce((acc, cur) => Math.max(acc, cur.order), event.order);

            module.changeOrder(pointId, maxEventOrder + 1);
        }

        this.updateEvaluationPoint(
            pointId, { previousEvent: { id: event.id, type: event.type }, evaluationPointSkills },
        );
        const pointsToUpdate = this.getFutureEvaluationPoints(updatedPoint.order);
        this.saveValidEvaluationPointsSkills(pointsToUpdate);
    };

    getSkillsSuggestForEvaluationPoint = (pointId: string, typeId: string): PointSkill[] => {
        const point = this.getEvaluationPoint(pointId);
        if (!point?.previousEvent?.id) return [];
        const allSkills = this.getPointSkillsForPoint(pointId, point.previousEvent?.id, true, true);
        const finalTypeId = dictionaryStore.getFinalSkillTypeId(typeId);
        const skills = allSkills.filter(({ skill }) => skill.typeId === finalTypeId);
        const uniquePointSkills = EvaluationPointListModel.getUniquePointSkills(skills);

        return uniquePointSkills;
    };

    private getPrecedingEvents(
        eventId: string, dependentEvents?: EventWithoutTest[],
    ): EventWithoutTest[] {
        const module = moduleStore.moduleModel;
        const { eventsWithoutTest } = module;
        const eventsOneLevelHigher = module.getEventsOneLevelHigher(eventId);
        const soughtEvent = eventsWithoutTest.find(({ id }) => id === eventId)!;
        if (!eventsOneLevelHigher.length) {
            return [...(dependentEvents ?? []), soughtEvent];
        }

        const results = eventsOneLevelHigher.flatMap(({ id }) => this.getPrecedingEvents(
            id, [...(dependentEvents ?? []), soughtEvent],
        ));
        if (!dependentEvents) {
            const eventsMap = new Map(results.map(item => [item.id, item]));
            const uniqueEvents = Array.from(eventsMap.values());

            return uniqueEvents;
        }

        return results;
    }

    private getUniqueDependentEvents = (
        event: EventWithoutTest, events?: EventWithoutTest[],
    ): EventWithoutTest[] => {
        if (!event?.dependentEvents.length) {
            return [...(events ?? []), event];
        }

        const module = moduleStore.moduleModel;
        const eventsOneLevelLower = event.dependentEvents?.map(
            ({ id }) => module.getEventWithoutTest(id)!,
        );

        const results = eventsOneLevelLower
            .flatMap(eventOneLevelLower => this.getUniqueDependentEvents(
                eventOneLevelLower, [...(events ?? []), event],
            ));
        if (!events) {
            const eventsMap = new Map(results?.map(item => [item?.id, item]));
            const uniqueEvents = Array.from(eventsMap.values());

            return uniqueEvents;
        }

        return results;
    };

    getPointsThatCanEvaluate = (filteredEvents: EventWithoutTest[]): EvaluationPoint[] => {
        const { evaluationPoints } = moduleStore.moduleModel;
        let pointsThatCanEvaluate: EvaluationPoint[] = [];
        filteredEvents?.forEach(event => {
            const dependentEvents = this.getUniqueDependentEvents(event);
            const points = evaluationPoints
                ?.filter(
                    point => dependentEvents?.some((item) => item?.id === point.previousEvent?.id),
                )
                .filter(point => !pointsThatCanEvaluate?.some((item) => item?.id === point?.id));
            pointsThatCanEvaluate = [...pointsThatCanEvaluate, ...points];
        });
        return pointsThatCanEvaluate;
    };

    getPointSkillsForPoint(
        pointId: string,
        previousEventId?: string,
        withModuleSkills = false,
        notOnlyEvaluated = false,
    ): PointSkill[] {
        const module = moduleStore.moduleModel;
        const point = module.getEvaluationPoint(pointId);
        const eventId = previousEventId ?? point?.previousEvent?.id;
        if (!eventId) return [];
        const filteredEvents = !module.hasLoopingEventDependencies(eventId)
            ? this.getPrecedingEvents(eventId) : [];

        const moduleSkills = withModuleSkills ? module.options.outputSkills : [];
        const outputSkills = [
            ...filteredEvents.flatMap(event => event.outputSkills ?? []),
            ...moduleSkills,
        ];

        const pointsThatCanEvaluate = this.getPointsThatCanEvaluate(filteredEvents)
            .filter(({ id }) => id !== pointId);
        const pointSkills = outputSkills.filter(skill => {
            const notEvaluated = !this.isEvaluatedSkill(skill.skill.id, pointsThatCanEvaluate);
            return notEvaluated || notOnlyEvaluated;
        });

        return pointSkills;
    }

    private isEvaluatedSkill = (
        skillId: string, pointsThatCanEvaluate: EvaluationPoint[],
    ): boolean => !!pointsThatCanEvaluate.find(point => point.evaluationPointSkills
        ?.some(skill => skill.skill.id === skillId));

    private static getUniquePointSkills(skills: PointSkill[]): PointSkill[] {
        const skillsMap = new Map(
            skills.map((skill: PointSkill) => [skill.skill.id, skill]),
        );
        const uniquePointSkills = Array.from(skillsMap.values());
        return uniquePointSkills;
    }
}
