/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: filteredTeacherAssignmentInstances
// ====================================================

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_nextMeetingInstance_meeting {
  __typename: "Meeting";
  topic: string | null;
}

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_nextMeetingInstance {
  __typename: "MeetingInstance";
  meeting: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_nextMeetingInstance_meeting;
}

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_wave {
  __typename: "Wave";
  id: string;
  index: number | null;
}

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_teacherAssignmentInstances_teacher_user {
  __typename: "User";
  lastName: string;
  firstName: string;
  patronymic: string | null;
  status: string;
}

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_teacherAssignmentInstances_teacher {
  __typename: "Teacher";
  user: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_teacherAssignmentInstances_teacher_user;
}

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_teacherAssignmentInstances {
  __typename: "TeacherAssignmentInstance";
  note: string | null;
  isCurrentTeacher: boolean;
  teacher: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_teacherAssignmentInstances_teacher;
}

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_assignment_duration {
  __typename: "MeetingDuration";
  name: string | null;
}

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_assignment_module {
  __typename: "Module";
  id: string;
  name: string | null;
}

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_assignment {
  __typename: "Assignment";
  id: string;
  topic: string | null;
  description: string | null;
  duration: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_assignment_duration | null;
  module: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_assignment_module;
}

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance {
  __typename: "AssignmentInstance";
  id: string;
  status: InstanceStatus;
  deadline: any | null;
  start: any | null;
  nextMeetingInstance: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_nextMeetingInstance | null;
  wave: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_wave;
  teacherAssignmentInstances: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_teacherAssignmentInstances[];
  assignment: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance_assignment;
}

export interface filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances {
  __typename: "TeacherAssignmentInstance";
  assignmentInstance: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances_assignmentInstance;
  note: string | null;
  isCurrentTeacher: boolean;
}

export interface filteredTeacherAssignmentInstances {
  filteredTeacherAssignmentInstances: filteredTeacherAssignmentInstances_filteredTeacherAssignmentInstances[];
}

export interface filteredTeacherAssignmentInstancesVariables {
  assignmentInstancesInput: TeacherAssignmentInstancesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTeacher
// ====================================================

export interface GetTeacher_teacher_baseTimeIntervalInstancesModules_baseTimeIntervalInstance_spaceEducationPeriod {
  __typename: "SpaceEducationPeriod";
  id: string;
  name: string;
}

export interface GetTeacher_teacher_baseTimeIntervalInstancesModules_baseTimeIntervalInstance_spaceBaseTimeInterval_space_baseTimeIntervalType {
  __typename: "BaseTimeIntervalType";
  id: string;
  name: string;
}

export interface GetTeacher_teacher_baseTimeIntervalInstancesModules_baseTimeIntervalInstance_spaceBaseTimeInterval_space {
  __typename: "Space";
  id: string;
  name: string;
  baseTimeIntervalType: GetTeacher_teacher_baseTimeIntervalInstancesModules_baseTimeIntervalInstance_spaceBaseTimeInterval_space_baseTimeIntervalType;
}

export interface GetTeacher_teacher_baseTimeIntervalInstancesModules_baseTimeIntervalInstance_spaceBaseTimeInterval {
  __typename: "SpaceBaseTimeInterval";
  id: string;
  order: number;
  space: GetTeacher_teacher_baseTimeIntervalInstancesModules_baseTimeIntervalInstance_spaceBaseTimeInterval_space;
}

export interface GetTeacher_teacher_baseTimeIntervalInstancesModules_baseTimeIntervalInstance {
  __typename: "BaseTimeIntervalInstance";
  id: string;
  spaceEducationPeriod: GetTeacher_teacher_baseTimeIntervalInstancesModules_baseTimeIntervalInstance_spaceEducationPeriod;
  spaceBaseTimeInterval: GetTeacher_teacher_baseTimeIntervalInstancesModules_baseTimeIntervalInstance_spaceBaseTimeInterval;
}

export interface GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments_duration {
  __typename: "MeetingDuration";
  name: string | null;
}

export interface GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments {
  __typename: "Assignment";
  id: string;
  topic: string | null;
  description: string | null;
  duration: GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments_duration | null;
}

export interface GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_waves {
  __typename: "Wave";
  id: string;
  index: number | null;
}

export interface GetTeacher_teacher_baseTimeIntervalInstancesModules_modules {
  __typename: "Module";
  id: string;
  name: string | null;
  assignments: GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_assignments[] | null;
  waves: GetTeacher_teacher_baseTimeIntervalInstancesModules_modules_waves[];
}

export interface GetTeacher_teacher_baseTimeIntervalInstancesModules {
  __typename: "BaseTimeIntervalInstanceModules";
  baseTimeIntervalInstance: GetTeacher_teacher_baseTimeIntervalInstancesModules_baseTimeIntervalInstance;
  modules: GetTeacher_teacher_baseTimeIntervalInstancesModules_modules[];
}

export interface GetTeacher_teacher {
  __typename: "Teacher";
  baseTimeIntervalInstancesModules: GetTeacher_teacher_baseTimeIntervalInstancesModules[] | null;
}

export interface GetTeacher {
  teacher: GetTeacher_teacher | null;
}

export interface GetTeacherVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssignmentInstanceOrder {
  deadlineAsc = "deadlineAsc",
  deadlineDesc = "deadlineDesc",
  startAsc = "startAsc",
  startDesc = "startDesc",
}

export enum InstanceStatus {
  active = "active",
  deleted = "deleted",
  generation = "generation",
}

export enum TeacherAssignmentInstanceStatus {
  actual = "actual",
  archived = "archived",
  future = "future",
}

export interface TeacherAssignmentInstancesInput {
  baseTimeIntervalInstanceId: string;
  assignmentInstanceStatus: TeacherAssignmentInstanceStatus;
  orderBy: AssignmentInstanceOrder;
  isCurrentTeacher: boolean;
  moduleId: string;
  waveIds?: string[] | null;
  assignmentId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
