export enum RoomType {
    Online = 'online',
    Indoor = 'indoor',
}

export interface ScheduleEvent {
    id: string,
    type: 'meeting' | 'assignment' | 'evaluationPoint';
    pinned: boolean;
    startTime: string;
    endTime: string;
    status?: string;
    module: {
        id: string;
        name: string;
        waves: {
            id: string;
            index: number;
        }
    };
    meeting: Meeting;
    assignment?: {
        id: string;
        topic: string;
    }
    evaluationPoint?: {
        id: string;
        topic: string;
    }
    roomType: RoomType;
    room?: {
        id: string;
        title: string;
        location: {
            location: string;
        }
        equipmentToRoom: {
            equipmentId: string,
            countEquipments: number,
        }[],
    };
    onlineRoom?: {
        id: string;
        url: string;
    };
    waves: {
        id: string
        index: number;
    }[];
    students: {
        id: string;
        user: {
            id: string
            lastName: string;
            firstName: string;
            patronymic: string;

        };
    }[];
    teachers: {
        id: string;
        user: {
            id: string
            lastName: string;
            firstName: string;
            patronymic: string;
        };
    }[];
    teacherToTeacherRoles: {
        teacher: {
            id: string;
        }
        teacherRole: {
            id: string;
        };
    }[];
    scheduleGenerationProcessId?: string;
}

export interface Durations {
    id: string;
    name: string;
    academicHours: number;
}

export interface UpdateDurationData {
    id: string;
    endDate: string;
    startDate: string;
}

export interface MeetingInstance {
    id: string;
    startDate: string;
    endDate: string;
    meetingInstanceToStudents: {
        student: {
            id: string;
            user: {
                id: string;
                firstName: string;
                lastName: string;
                patronymic: string;
            }
        }
    }[];
    meetingInstanceToTeachers: {
        teacher: {
            id: string;
            user: {
                id: string;
                firstName: string;
                lastName: string;
                patronymic: string;
            }
        }
    }[];
    meeting: {
        id: string;
        topic: string;
        format: {
            name: string;
        }
        duration: {
            academicHours: number;
        }
        module: {
            id: string;
            name: string;
            waves: {
                id: string;
                index: number;
            };
        }
    }
    room: {
        id: string;
        title: string;
        location?: {
            location: string;
        }
        equipmentToRoom: {
            equipmentId: string;
            countEquipments: number;
        }[];
    }
    waves: {
        id: string;
        index: number;
    }[];
    status: string;
}
export interface Meeting {
    id: string;
    topic: string;
    format: {
        name: string;
    };
    module: {
        waves: {
            id: string;
            index: number;
        }[];
    };
    duration: {
        academicHours: number;
    };
    meetingInstances?: MeetingInstance[];
    equipmentPerMeeting?: {
        equipment: {
            id: string;
        };
        count: number;
    }[];
    equipmentPerStudent?: {
        equipment: {
            id: string;
        };
        count: number;
    }[];
}

export interface MeetingForReplace {
    meeting: {
        id: string;
        topic: string;
        order: number;
    }
    meetingInstances: MeetingInstance[]
}

export interface ReplaceMeetingInput {
    meetingInstanceId: string;
    newMeetingId?: string;
    meetingInstanceToSwapId?: string;
}

export interface UpdateWavesInput {
    meetingInstanceId: string;
    newWaveIds: string[];
}

export interface UpdateAssignmentAndEvalutionPointInstanceInput {
    id: string;
    startDate?: Date;
    duration?: number;
}

export interface EventTeacher {
    currentTeacherId: string;
    newTeacherId: string;
    meetingInstanceId: string;
    teacherRole: string;
}

export type UpdateEventTeacherFunc = (
    currentTeacherId: string, eventTeachers: EventTeacher[]) => void;

export type UpdateEventDateFunc = (startDate: string) => void;

export type UpdateEventDurationFunc = (newDuration: number) => void;

export type UpdateEventStatusFunc = (eventId: string, eventStatus: string) => void;

export type PinEventFunc = (pin: boolean) => void;
