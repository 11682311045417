import { FetchResult } from '@apollo/client';
import React from 'react';
import { EquipmentGroupItem } from './EquipmentGroupItem';
import classes from './EquipmentGroups.module.scss';

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

interface Equipment {
    [index: string]: any;
    id: string;
    name: string;
    count: number;
    isMovable?: boolean;
    territorialZoneId?: string | null;
}

interface TerritorialZone {
    id: string;
    name: string;
}

interface EquipmentGroupProps {
    dialogView: string;
    setDialogView: React.Dispatch<React.SetStateAction<string>>;
    letter: string;
    equipments: Equipment[];
    del: (id: string) => void;
    upd: (equipments: Equipment) => ApolloPromise;
    territorialZones?: TerritorialZone[];
}

export function EquipmentGroup(props: EquipmentGroupProps) {
    const {
        dialogView,
        setDialogView,
        letter,
        equipments,
        del,
        upd,
        territorialZones = [],
    } = props;

    return (
        <div className={classes.equipmentList__card}>
            <div className={classes.equipmentList__cardLetter}>
                {letter}
            </div>

            <div className={classes.equipmentList__cardItems}>
                {
                    equipments.map(
                        (element) => (
                            <EquipmentGroupItem
                                dialogView={dialogView}
                                setDialogView={setDialogView}
                                element={element}
                                upd={upd}
                                del={del}
                                key={element.id}
                                territorialZones={territorialZones}
                            />
                        ),
                    )
                }
            </div>
        </div>
    );
}
