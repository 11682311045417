import React, { useState } from 'react';
import {
    ApolloError, gql, useMutation, useQuery,
} from '@apollo/client';
import { TerritorialZone } from '@admin/UniversityPage/types';
import { UniversityPage } from '@admin/UniversityPage/UniversityPage';
import { Loader } from '@common/Loader';
import { Alert } from '@common/Alert';

const GET_TERRITORIAL_ZONES = gql`
    query getTerritorialZones {
        territorialZones {
            id
            name

            buildings {
                id
                name
                location
            }

            travelTimesFromZone {
                fromZoneId
                toZoneId
                travelTime
            }

            availableIntervals {
                id
                from
                to
            }

            isOptimalRoomCapacityForMeetingsEnabled
        }
    }
`;

const GET_BUILDINGS = gql`
query {
    buildings {
      id
      name
      location
    }
  }
`;

const UPDATE_TERRITORIAL_ZONES = gql`
    mutation updateTerritorialZones($updateZonesInput: [UpdateTerritorialZoneInput!]!) {
        updateTerritorialZones(updateZonesInput: $updateZonesInput) {
            id
            name
        }
    }
`;

const CREATE_BUILDING = gql`
    mutation createBuilding($building: CreateBuildingInput!) {
        createBuilding(createBuildingInput: $building) {
            id
            name
        }
    }
`;

const UPDATE_BUILDING = gql`
    mutation updateBuilding($building: UpdateBuildingInput!) {
        updateBuilding(updateBuildingInput: $building) {
            id
            name
        }
    }
`;

const REMOVE_BUILDING = gql`
    mutation removeBuilding($id: String!) {
        removeBuilding(id: $id) {
            status
        }
    }
`;

export interface BuildingsErrors {
    createBuilding?: ApolloError;
    updateBuilding?: ApolloError;
    removeBuilding?: ApolloError;
}

export function UniversityPageApollo(): JSX.Element {
    const [
        updateTerritorialZonesError,
        setUpdateTerritorialZonesError,
    ] = useState<ApolloError | undefined>();

    const [buildingsErrors, setBuildingsErrors] = useState<BuildingsErrors>({
        createBuilding: undefined,
        updateBuilding: undefined,
        removeBuilding: undefined,
    });

    const {
        data: territorialZones,
        loading: territorialZonesLoading,
        error: territorialZonesError,
    } = useQuery<{ territorialZones: TerritorialZone[]; }>(GET_TERRITORIAL_ZONES, {
        fetchPolicy: 'cache-and-network',
    });

    const [updateTerritorialZones] = useMutation(UPDATE_TERRITORIAL_ZONES, {
        refetchQueries: [{ query: GET_TERRITORIAL_ZONES }],
        onError: error => setUpdateTerritorialZonesError(error),
    });

    const [createBuilding] = useMutation(CREATE_BUILDING, {
        refetchQueries: [{ query: GET_TERRITORIAL_ZONES }, { query: GET_BUILDINGS }],
        onError: error => setBuildingsErrors({
            ...buildingsErrors,
            createBuilding: error,
        }),
    });

    const [updateBuilding] = useMutation(UPDATE_BUILDING, {
        refetchQueries: [{ query: GET_TERRITORIAL_ZONES }, { query: GET_BUILDINGS }],
        onError: error => setBuildingsErrors({
            ...buildingsErrors,
            updateBuilding: error,
        }),
    });

    const [removeBuilding] = useMutation(REMOVE_BUILDING, {
        refetchQueries: [{ query: GET_TERRITORIAL_ZONES }, { query: GET_BUILDINGS }],
        onError: error => setBuildingsErrors({
            ...buildingsErrors,
            removeBuilding: error,
        }),
    });

    return (
        <>

            {territorialZonesError?.message && <Alert message={territorialZonesError?.message} />}

            {
                territorialZonesLoading ? <Loader />
                    : (
                        <UniversityPage
                            territorialZones={territorialZones?.territorialZones}
                            territorialZonesLoading={territorialZonesLoading}
                            updateTerritorialZones={(newZones) => updateTerritorialZones(
                                {
                                    variables: { updateZonesInput: newZones },
                                },
                            )
                            }
                            updateBuilding={(building) => updateBuilding({
                                variables: { building },
                            })
                            }
                            createBuilding={(building) => createBuilding({
                                variables: { building },
                            })
                            }
                            removeBuilding={(id) => removeBuilding({ variables: { id } })}
                            updateTerritorialZonesError={updateTerritorialZonesError}
                            buildingsErrors={buildingsErrors}
                        />
                    )
            }
        </>

    );
}
