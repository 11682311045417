import React from 'react';

import { ScheduleEvent } from '../types';
import { TableModule } from './TableModule';
import { PlannedMeetingInstance } from './ScheduleTableInterfaces';
import { buildScheduleTable } from './buildScheduleTable';
import { Legend } from './Legend';

import classes from './ScheduleTable.module.scss';

type Wave = {
    id: string;
    index: number;
};

type Meeting = {
    id: string;
    topic: string;
    order: number;
};

type Module = {
    id: string;
    name: string;
    meetings: Meeting[];
    waves: Wave[];
};

interface Props {
    isSecret?: boolean;
    eventInstances: ScheduleEvent[];
    planingMeetingInstances: PlannedMeetingInstance[];
    modules: Module[];
}

export const ScheduleTable = React.memo(({
    isSecret,
    eventInstances,
    planingMeetingInstances,
    modules,
}: Props): JSX.Element => {
    const tableModules = modules.map(module => buildScheduleTable(
        module,
        module.meetings,
        module.waves,
        eventInstances.filter(instance => instance.module.id === module.id && instance.type === 'meeting'),
        planingMeetingInstances,
    ));

    return (
        <div className={classes.tableSchedule}>
            <Legend />

            {tableModules.map(item => (
                <TableModule
                    isSecret={isSecret}
                    key={item.id}
                    tableModule={item}
                />
            ))}
        </div>
    );
});
