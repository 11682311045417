import React from 'react';
import { SkillCardWithoutNest } from './SkillCardWithoutNest';
import { SkillCardWithNest } from './SkillCardWithNest';
import { checkChild } from '../SkillAddPage/SkillAddWithNest/SkillAddFunctions';

import classes from './SkillCard.module.scss';

import {
    skillTypesData_skillTypes,
    // skills_skills,
} from '../../../graphql-query-types';

interface Skill {
    id: string;
    name: string;
    typeId: string;
    maxLevel?: number;
    description?: string;
    children?: Skill[];
    dependencies?: Skill[];
}

interface Props {
    skillId: string,
    currentSkill: Skill;
    editState: boolean;
    skillType: skillTypesData_skillTypes;
    skillTypes: skillTypesData_skillTypes[];
    skillsList: any;
    removeSkill(id: string): void;
    updateSkill: (
        id: string,
        name: string,
        typeId: string,
        description: string,
        maxLevel: number
    ) => void;
    updateNestSkill: (
        id: string,
        name: string,
        typeId: string,
        children: Skill[],
        dependencies: string[],
    ) => void;
}

export function SkillCard({
    skillId,
    currentSkill,
    editState,
    skillType,
    skillTypes,
    skillsList,
    removeSkill,
    updateSkill,
    updateNestSkill,
}: Props): JSX.Element {
    const { hasChild } = checkChild(skillTypes, skillType.id);
    return (
        <div className={classes.skillCard}>
            {hasChild
                ? (
                    <SkillCardWithNest
                        currentSkill={currentSkill}
                        skillType={skillType}
                        skillTypes={skillTypes}
                        skillsList={skillsList}
                        removeSkill={removeSkill}
                        updateNestSkill={updateNestSkill}
                    />
                ) : (
                    <SkillCardWithoutNest
                        skillId={skillId}
                        skillData={currentSkill}
                        editState={editState}
                        skillType={skillType}
                        removeSkill={removeSkill}
                        updateSkill={updateSkill}
                    />
                )
            }
        </div>
    );
}
