import React from 'react';

interface Props {
    disabled?: boolean;
    fromTime?: string;
    toTime?: string;
    onChangeFromTime?(event: React.ChangeEvent<HTMLInputElement>): void;
    onChangeToTime?(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const PeriodChooser = ({
    disabled,
    fromTime,
    toTime,
    onChangeFromTime,
    onChangeToTime,
}: Props): JSX.Element => (
    <div>
        <span>
            <input
                type="time"
                disabled={disabled}
                value={fromTime}
                name="fromTime"
                onChange={onChangeFromTime}
            />
            {' — '}
            <input
                type="time"
                disabled={disabled}
                value={toTime}
                name="toTime"
                onChange={onChangeToTime}
            />
        </span>
    </div>
);
