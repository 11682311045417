import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { StudentTargetName, useSendMetriks } from '@common/hooks';

import { ViewType } from '../../graphql-query-types';

import classes from './Tabs.module.scss';

interface Props {
    tabsOptions: string[][];
}

export function Tabs({ tabsOptions }: Props): JSX.Element {
    const { path } = useRouteMatch();

    const { sendYandexMetrika } = useSendMetriks([ViewType.Student]);

    return (
        <div className={classes.tabs}>
            <ul className={classes.tabs__list}>
                {[...Array(tabsOptions.length)].map((_, index: number) => (
                    <li
                        key={tabsOptions[index][1]}
                        className={classes.tabs__item}
                    >
                        <NavLink
                            className={classes.tabs__link}
                            onClick={() => {
                                if (path === '/modules') {
                                    sendYandexMetrika(StudentTargetName.modules, {
                                        statusModule: tabsOptions[index][2],
                                    });
                                }
                            }}
                            activeClassName={classes.tabs__link_selected}
                            to={`${path}/${tabsOptions[index][1]}`}
                        >
                            {tabsOptions[index][0]}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
}
