import React from 'react';
import { FetchResult } from '@apollo/client';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { GradientTabs } from '@common/Menu/GradientTabs';

import { AuthRoute } from '@common/AuthRoute';
import { EquipmentGroups } from './EquipmentGroups';
import { AddEquipments } from './AddEquipments';
import { ViewType } from '../../graphql-query-types';

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

export interface Equipment {
    id: string;
    name: string;
    count: number;
    isMovable?: boolean;
    territorialZoneId?: string | null;

    [index: string]: any;
}

interface TerritorialZone {
    id: string;
    name: string;
}

export interface EquipmentState extends Equipment {
    warn: boolean;
}

interface EquipmentPageProps {
    dialogView: string;
    setDialogView: React.Dispatch<React.SetStateAction<string>>;
    setIsAddFormClearable: React.Dispatch<React.SetStateAction<boolean>>;
    isAddFormClearable: boolean;
    add: (equipments: Equipment) => void;
    upd: (equipments: Equipment) => ApolloPromise;
    del: (id: string) => void;
    equipmentArrayProps: Equipment[];
    stationaryEquipments: Equipment[];
    movableEquipments: Equipment[];
    territorialZones: TerritorialZone[];
}

export function EquipmentPage({
    del,
    add,
    upd,
    equipmentArrayProps,
    stationaryEquipments,
    movableEquipments,
    territorialZones,
    dialogView,
    setDialogView,
    isAddFormClearable,
    setIsAddFormClearable,
}: EquipmentPageProps) {
    const { path } = useRouteMatch();
    return (
        <>
            <GradientTabs
                tabsOptions={[
                    [
                        'Все оборудование',
                        'all',
                    ],
                    [
                        'Стационарное',
                        'stationary',
                    ],
                    [
                        'Перемещаемое',
                        'relocatable',
                    ],
                    [
                        'Добавить оборудование',
                        'add',
                    ],
                ]}
            />
            <Switch>
                <AuthRoute exact path={`${path}`} requiredUserTypes={[ViewType.Admin]}>
                    <Redirect to={`${path}/all`} />
                </AuthRoute>
                <AuthRoute exact path={`${path}/all`} requiredUserTypes={[ViewType.Admin]}>
                    <EquipmentGroups
                        equipmentArray={equipmentArrayProps}
                        del={del}
                        upd={upd}
                        territorialZones={territorialZones}
                        dialogView={dialogView}
                        setDialogView={setDialogView}
                    />
                </AuthRoute>
                <AuthRoute exact path={`${path}/stationary`} requiredUserTypes={[ViewType.Admin]}>
                    <EquipmentGroups
                        equipmentArray={stationaryEquipments}
                        equipmentType="стационарного"
                        del={del}
                        upd={upd}
                        territorialZones={territorialZones}
                        dialogView={dialogView}
                        setDialogView={setDialogView}
                    />
                </AuthRoute>
                <AuthRoute exact path={`${path}/relocatable`} requiredUserTypes={[ViewType.Admin]}>
                    <EquipmentGroups
                        equipmentArray={movableEquipments}
                        equipmentType="перемещаемого"
                        del={del}
                        upd={upd}
                        territorialZones={territorialZones}
                        dialogView={dialogView}
                        setDialogView={setDialogView}
                    />
                </AuthRoute>
                <AuthRoute exact path={`${path}/add`} requiredUserTypes={[ViewType.Admin]}>
                    <AddEquipments
                        add={add}
                        territorialZones={territorialZones}
                        isAddFormClearable={isAddFormClearable}
                        setIsAddFormClearable={setIsAddFormClearable}
                    />
                </AuthRoute>
            </Switch>
        </>
    );
}
