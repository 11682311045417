import { gql } from '@apollo/client';

export const GET_TERRITORIAL_ZONES = gql`
query territorialZones {
  territorialZones {
    id
    name
  }
}`;
export const GET_TERRITORIAL_ZONE = gql`
query territorialZone($id: String!){
  territorialZone(id: $id) {
    rooms {
      id
      isAvailable
      availableIntervals {
        id
        from
        to
      }
      meetingFormats {
        id
        name
      }
      title
      capacity
      location {
        id
        name
        location
      }
      equipmentToRoom {
        roomId
        equipmentId
        equipment {
          name
        }
        countEquipments
    }
    }
  }
}
`;

export const GET_BUILDINGS = gql`
query {
    buildings {
      id
      name
      location
    }
  }
`;

export const GET_ROOMS = gql`
query rooms {
    rooms {
      id
      isAvailable
      availableIntervals {
        id
        from
        to
      }
      meetingFormats {
        id
        name
      }
      title
      capacity
      location {
        id
        name
        location
      }
      equipmentToRoom {
        roomId
        equipmentId
        equipment {
          name
        }
        countEquipments
      }
    }
  }
`;

export const DELETE_ROOM = gql`
mutation deleteRoom ($id: String!) {
    removeRoom(id: $id) {
      status
    }
  }
`;

export const EDIT_ROOM = gql`
mutation updateRoom ($editRoomData: UpdateRoomInput!) {
  updateRoom(updateRoomInput:$editRoomData) {
    id
    availableIntervals {
      from
      to
      id
    }
  }
}
`;
export const CREATE_ROOM = gql`
mutation createRoom ($createRoomData: CreateRoomInput!) {
  createRoom(createRoomInput: $createRoomData) {
    id
  }
}
`;
export const GET_ROOM_TYPES = gql`
query {
    meetingFormats{
    id
    name
  }
}
`;

export const GET_EQUIPMENT = gql`
query equipments($equipmentsInput: EquipmentsInput!) {
  equipments (equipmentsInput: $equipmentsInput) {
    id
    name
    count
  }
}
`;
