import React from 'react';
import { format } from 'date-fns';

import { IconDeprecated } from '@common';
import { ShowEquipmentShortageViolation, ShowMeetingViolation, ShowStudentLimitViolation } from './shcheduleViolations';
import { ScheduleGenerationConflicts } from './scheduleViolationInterface';

import classes from './scheduleGenerationConflicts.module.scss';

interface Props {
    resetConflicts: () => void;
    conflicts: ScheduleGenerationConflicts;
}

const formatDate = (date: string) => format(new Date(date), 'dd.MM.yy');

export const ScheduleGenerationConflict = ({ conflicts, resetConflicts }: Props) => (
    <div className={classes.schedule__score}>
        <IconDeprecated
            id="Cross"
            className={classes.schedule__closeScore}
            click={resetConflicts}
        />
        <div className={classes.schedule__scoreValue}>
            Результат проверки конфликтов
        </div>
        <div className={classes.schedule__conflict_info}>
            {`За период: ${formatDate(conflicts.fromDate)} - ${conflicts.toDate ? formatDate(conflicts.toDate) : 'конец сгенерированного расписания'}`}
        </div>
        <div className={classes.schedule__scoreValue}>
            {conflicts.isSuccess ? 'Нарушений правил не найдено' : 'Нарушены следующие правила:'}
        </div>
        <div className={classes.schedule__scoreExplanation}>
            {conflicts.meetingViolations?.map(meetingViolations => (
                <ShowMeetingViolation
                    key={meetingViolations.violationName}
                    meetingViolations={meetingViolations}
                />
            ))}
            <ShowStudentLimitViolation
                studentLimitViolation={conflicts.studentMeetingLimitDurationViolation}
            />
            <ShowStudentLimitViolation
                studentLimitViolation={conflicts.studentBreakLimitDurationViolation}
            />
            <ShowEquipmentShortageViolation
                equipmentShortageViolation={conflicts.equipmentShortageViolation}
            />
        </div>
    </div>
);
