import React, { Fragment } from 'react';
import { format } from 'date-fns/esm';
import { observer } from 'mobx-react';
import { groupBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Switcher } from '@common';
import { HorizontalArrowIcon } from '@common/svg';
import { eventsStore, timeStore } from '../../Store';

import classes from './ScheduleAssignmentsList.module.scss';

export const ScheduleAssignmentsList = observer((): JSX.Element => {
    const history = useHistory();
    const {
        getAssignmentInstances,
        selectEvent,
        showAssignmentsList,
        setFillterCompletedAssignments,
        getFillterCompletedAssignments,
    } = eventsStore;
    const chooseAssignmentEvent = (id: string) => {
        const chosenAssignmentEvent = getAssignmentInstances()
            ?.find((event) => event.assignmentInstance.id === id);
        if (chosenAssignmentEvent) {
            selectEvent(chosenAssignmentEvent);
            history.push(`/schedule/${chosenAssignmentEvent?.assignmentInstance.id}`);
            showAssignmentsList(false);
        }
    };
    const period = {
        startTime: format(new Date(timeStore.getScheduleStart()), 'dd.MM.yy'),
        endTime: format(new Date(timeStore.getScheduleEnd()), ' dd.MM.yy'),
    };
    const assignmentInstancesWithFormatDeadLine = getAssignmentInstances()
        .map((studentAssignment) => {
            const { assignmentInstance } = studentAssignment;
            const { deadline } = assignmentInstance;
            return ({
                ...studentAssignment,
                assignmentInstance: {
                    ...assignmentInstance,
                    deadline: deadline ? (moment(deadline).format('DD[.]MM')) : 'не определен',
                },
            });
        });
    const groupByDeadline = groupBy(assignmentInstancesWithFormatDeadLine, 'assignmentInstance.deadline');
    const deadlines = Object.keys(groupByDeadline);
    return (
        <>
            <div className={classes.assignmentList__header}>
                Самостоятельные работы
                <div className={classes.assignmentList__header_period}>
                    За период:
                    {` ${period.startTime} - ${period.endTime}`}
                </div>
            </div>
            <div className={classes.assignments__list}>
                <Switcher
                    label="Только невыполенные"
                    onClick={setFillterCompletedAssignments}
                    checked={!getFillterCompletedAssignments()}
                />
                {deadlines.map((deadline) => (
                    <Fragment
                        key={deadline}
                    >
                        <div className={classes.assignments__list_deadline}>
                            Срок сдачи: {deadline}
                        </div>
                        {groupByDeadline[deadline].map((studentAssignmetInstance) => {
                            const { assignmentInstance, isCompleted } = studentAssignmetInstance;
                            const { assignment, id } = assignmentInstance;
                            return (
                                <div
                                    key={id}
                                    className={classes.assignment}
                                    onClick={() => chooseAssignmentEvent(id)}
                                >
                                    <div
                                        className={classes.assignment__module}
                                    >
                                        {assignment.module.name}
                                    </div>
                                    <div
                                        className={classes.assignment__topic}
                                    >
                                        {assignment.topic}
                                    </div>
                                    <div className={classes.assignmetnt__footer}>
                                        <div
                                            className={classes.assignmetnt__footer_status}
                                        >
                                            {isCompleted ? 'Выполнена' : 'Не выполнена'}
                                        </div>
                                        <button
                                            type="button"
                                            className={classes.assignmetnt__footer_more}
                                        >
                                            Подробнее
                                            <HorizontalArrowIcon />
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </Fragment>
                ))
                }
            </div>
        </>
    );
});
