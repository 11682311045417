import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CurrentUser, InterfaceSection, ViewType } from '../../graphql-query-types';
import UserView from '../../store/UserView';

interface Props {
    path: string;
    exact?: boolean;
    children: JSX.Element;
    requiredUserTypes: ViewType[];
    user?: CurrentUser;
    requiredSections?: InterfaceSection[] | null;
}

export const AuthRoute = observer(({
    children,
    path,
    exact,
    requiredUserTypes,
    requiredSections,
    // user,
}: Props): JSX.Element => {
    const viewTypeInMobX = UserView.userViewType?.name;

    let isUserHasRequiredType: boolean = false;

    // Проверка на доступность страниц для разных ролей администраторов
    const checkPermsForPages = (url: string) => {
        requiredSections?.map(section => {
            if (
                section !== InterfaceSection.University
                || InterfaceSection.Space
                || InterfaceSection.Users
                || InterfaceSection.EducationalPeriod
                || InterfaceSection.Modules
                || InterfaceSection.ModuleStatistics
                || InterfaceSection.Trajectories
            ) {
                <Redirect to="/university" />;
            }

            return url;
        });
    };

    useEffect(() => checkPermsForPages(path));

    // TODO: При данной проверке, когда пользователю прилетает ссылка, которая ему недоступна
    // его редиректир на ссылку, доступную его первому приоритерному типу пользователя
    // TODO: Если ему ссылка доступна, но текущий тип пользователя не совпадает с типом,
    // которой требует ссылка, его все равно редиректир на доступную для текущего типа страницу
    // TODO: Проблема: нужно ли менять текущий тип пользователя на тип пользователя, которому
    // доступна ссылка
    // user.roles.forEach(role => {
    if (
        // requiredUserTypes.includes(role.name)
        // &&
        requiredUserTypes.includes(viewTypeInMobX as ViewType)
    ) {
        isUserHasRequiredType = true;
    }
    // });

    if (!isUserHasRequiredType) return <Redirect to="/" />;

    return (
        <Route exact={exact} path={path}>
            {children}
        </Route>
    );
});
