import React, { ChangeEvent, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import { FetchResult } from '@apollo/client';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';

import { EducationPeriodParametersDaySchedules } from '../EducationPeriodParametersDaySchedules';
import { TimeScheduleSelector } from './TimeScheduleSelector';
import { WorkLoad } from './WorkLoad';

import classes from './EducationPeriodParametersEdit.module.scss';

import {
    Space,
    DaySchedule,
    StudentWorkload,
} from '../../EducationPeriodParametersAndDatesInterfaces';

interface Props {
    space: Space,
    toggleMode(): void,
    update(
        id: string,
        daySchedules: DaySchedule[],
        studentWorkload: StudentWorkload,
    ): Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>,
}

function getSchedulePropsSubscriber(
    daySchedules: DaySchedule[],
    setDaySchedules: (daySchedules: DaySchedule[]) => void,
) {
    return () => setDaySchedules(cloneDeep(daySchedules));
}

export function EducationPeriodParametersEdit(
    {
        space: {
            id,
            baseTimeIntervalType: {
                name: baseTimeIntervalTypeName,
            },
            spaceParameters: {
                daySchedules: daySchedulesProps,
                studentWorkload: studentWorkloadProps,
            },
        },
        toggleMode,
        update,
    }: Props,
) {
    const [daySchedules, setDaySchedules] = useState<DaySchedule[]>(cloneDeep(daySchedulesProps));
    useEffect(getSchedulePropsSubscriber(daySchedulesProps, setDaySchedules), [daySchedulesProps]);
    const [
        studentWorkload,
        setStudentWorkload,
    ] = useState<StudentWorkload>(cloneDeep(studentWorkloadProps));
    // const [
    //     selectionPointParameters,
    //     setSelectionPointParameters,
    // ] = useState<SelectionPointParameters>(cloneDeep(selectionPointParametersProps));

    useEffect(getSchedulePropsSubscriber(daySchedulesProps, setDaySchedules), [daySchedulesProps]);

    const getStudentWorkloadUpdater = (key: (keyof StudentWorkload)) => (
        (event: ChangeEvent<HTMLInputElement>) => (
            setStudentWorkload((oldStudentWorkload: StudentWorkload) => {
                const copyOldStudentWorkload = cloneDeep(oldStudentWorkload);
                copyOldStudentWorkload[key] = Number.parseInt(event.target.value, 10);
                if (Number.isNaN(copyOldStudentWorkload[key])) {
                    copyOldStudentWorkload[key] = 0;
                }
                return copyOldStudentWorkload;
            })
        ));
    // const getSelectionPointParametersUpdater = (key: (keyof SelectionPointParameters)) => (
    //     (event: ChangeEvent<HTMLInputElement>) => (
    // eslint-disable-next-line max-len
    //         setSelectionPointParameters((oldSelectionPointParameters: SelectionPointParameters) => {
    //             const copyOldSelectionPointParameters = cloneDeep(oldSelectionPointParameters);
    //             copyOldSelectionPointParameters[key] = Number.parseInt(event.target.value, 10);
    //             if (Number.isNaN(copyOldSelectionPointParameters[key])) {
    //                 copyOldSelectionPointParameters[key] = 0;
    //             }
    //             return copyOldSelectionPointParameters;
    //         })
    //     ));

    return (
        <div className={classes.educationPeriodParametersEdit}>
            <div className={classes.educationPeriodParametersEdit__schedules}>
                <div className={classes.educationPeriodParametersEdit__title}>
                    График работы:
                </div>

                <TimeScheduleSelector
                    daySchedules={daySchedules}
                    update={
                        (newDaySchedules: DaySchedule[]) => setDaySchedules(newDaySchedules)
                    }
                />

                <EducationPeriodParametersDaySchedules
                    daySchedules={daySchedules}
                />
            </div>
            <div className={classes.educationPeriodParametersEdit__workload}>
                <WorkLoad
                    maxDaysPerWeek={studentWorkload.maxDaysPerWeek}
                    updateMaxDaysPerWeek={getStudentWorkloadUpdater('maxDaysPerWeek')}
                    maxHoursPerDay={studentWorkload.maxHoursPerDay}
                    updateMaxHoursPerDay={getStudentWorkloadUpdater('maxHoursPerDay')}
                    maxHoursPerSpaceBaseTimeInterval={
                        studentWorkload.maxHoursPerSpaceBaseTimeInterval
                    }
                    updateMaxHoursPerSpaceBaseTimeInterval={getStudentWorkloadUpdater('maxHoursPerSpaceBaseTimeInterval')}
                    baseTimeIntervalTypeName={baseTimeIntervalTypeName}
                />
            </div>
            <div className={classes.educationPeriodParametersEdit__buttonPanel}>
                <ActionButton
                    actionType={ActionTypeOfButton.SECONDARY}
                    className={classes.educationPeriodParametersEdit__cancelButton}
                    onClick={toggleMode}
                >
                    Отменить
                </ActionButton>
                <ActionButton
                    onClick={() => {
                        update(
                            id,
                            daySchedules,
                            studentWorkload,
                        );
                    }}
                >
                    Сохранить
                </ActionButton>
            </div>
        </div>
    );
}
