import React from 'react';

import { Link } from 'react-router-dom';

import { IconDeprecated } from '@common';

import classes from './BackToTheSpaces.module.scss';

import { Space } from '../subSpaceTypes';

interface Props {
    space: Space;
    routerPath: string;
}

export function BackToTheSpaces({ space, routerPath }: Props): JSX.Element {
    return (
        <Link to={`${routerPath}/space/${space.id}`}>
            <div
                className={classes.backToTheSpaces}
            >
                <IconDeprecated
                    id="backArrow"
                    className={classes.backToTheSpaces__icon}
                />
                <div className={classes.backToTheSpaces__title}>
                    Вернуться к настройкам пространства {space.name}
                </div>
            </div>
        </Link>
    );
}
