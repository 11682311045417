import { compact, find, map } from 'lodash';
import { skillTypesData_skillTypes } from 'src/graphql-query-types';
import { SkillWhithLevel } from '../../Config/interfaces';

export const filterSkillTypesForEventView = (skillsWithTypes: skillTypesData_skillTypes[]):
skillTypesData_skillTypes[] => compact(
    map(skillsWithTypes, skillType => (skillType.parentId === null ? skillType : null)),
);

export const filterSkillsBySkillType = (
    skillType: skillTypesData_skillTypes,
    skills: SkillWhithLevel[],
): SkillWhithLevel[] => skills.filter(skill => skill.skill.typeId === skillType.id);

export const findSkillTypeById = (
    skillTypes: skillTypesData_skillTypes[],
    id: string,
): skillTypesData_skillTypes | undefined => find(skillTypes, { id });
