import React, { Fragment, useState } from 'react';
// import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { groupBy } from 'lodash';
import cn from 'classnames';

import { ActionButton } from '@common/ActionButton';
import { CrossIcon, PointIcon } from '@common/svg';
import { OperationVariables, QueryLazyOptions } from '@apollo/client';

import { FutureSubspace } from './FutureSubspace';
import { DistributionType } from '../../../subSpaceAdmin/subSpaceTypes';
import { FutureBTI, ModuleSettings, SelectionStatus } from '../Config/interfaces';
import { ElectivePoint, DistributionPoint } from '../ChoicePoint';
import UserView from '../../../store/UserView';
import store from '../store';

import classes from './Subspaces.module.scss';
import { FinishSelectPointPopup } from './FihishSelectPointPopup';
import { InfoCard } from '../ChoicePoint/InfoCard';

enum Tabs {
    ALL = 'Все',
    OBLIGATORY = 'Обязательные',
    DISTRIBUTION = 'По распределению',
    ELECTIVE = 'Выборные',
}

interface Props {
    studentSubspacesIds?: string[];
    futureBtiData?: FutureBTI;
    moduleSettings?: ModuleSettings;
    getFutureBaseTimeInterval: () => void;
    setStdentPriorities: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
}
const slotsAfterForkIds: string[] = [];

export const FutureSubspaceList = observer(({
    studentSubspacesIds,
    futureBtiData,
    moduleSettings,
    setStdentPriorities,
    getFutureBaseTimeInterval,
}: Props) => {
    const [isShowFinishSelectPopup, setIsShowFinishSelectPopup] = useState(false);
    const [activeTab, setActiveTab] = useState(Tabs.ALL);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { studentId, user } = UserView;
    const { id } = useParams<{ id: string; }>();

    const allOrObligatiry = activeTab === Tabs.ALL || activeTab === Tabs.OBLIGATORY;

    const allModules = getNoDistributionModules(Tabs.ALL);

    const electiveForks = getDistributionForks(DistributionType.STUDENTCHOICE);

    const distributionForks = [
        ...getDistributionForks(DistributionType.MANUALLY),
        ...getDistributionForks(DistributionType.AUTOMATICALLY),
    ];

    const groupElectiveForksBySubspace = groupBy(electiveForks, 'subspace.name');
    const spaceElectiveForkNames = Object.keys(groupElectiveForksBySubspace);

    const groupDistributionForksBySubspace = groupBy(distributionForks, 'subspace.name');
    const spaceDistributionForkNames = Object.keys(groupDistributionForksBySubspace);

    const obligatoryModules = getNoDistributionModules(Tabs.OBLIGATORY);

    const defaultView = (
        <div className={classes.subspace__subtitle}>В данном разделе нет модулей</div>
    );

    const showModules = {
        [Tabs.ALL]: allModules,
        [Tabs.OBLIGATORY]: obligatoryModules,
        [Tabs.ELECTIVE]: allModules,
        [Tabs.DISTRIBUTION]: allModules,
    };

    const isModuleSelectionTime = store.baseTimeIntervalSelectionStatus
        === SelectionStatus.MODULESELECTIONINPROCESS
        && !(store.studentPriorities.length > 0);

    function getNoDistributionModules(filterType: Tabs) {
        return futureBtiData?.subspaceTypeLayouts
            ?.flatMap(item => item.subspaceType.subspaces
                .map(subspace => {
                    const isObligatoryType = filterType === Tabs.OBLIGATORY;
                    const slots = subspace.slotDiagram.slots
                        ?.filter(slot => slot.spaceBaseTimeIntervalId === futureBtiData.id);
                    const filtredSlots = isObligatoryType ? slots
                        .filter(slot => isNoDistributionSlot(slot.id)) : slots;
                    return ({
                        subspace,
                        studentModules: filtredSlots
                            .map(slot => ({ module: slot.module, subspace }))
                            .filter(studModule => studModule.module !== null),
                    });
                    // eslint-disable-next-line max-len
                })).filter(item => !!studentSubspacesIds?.find(subspaceId => subspaceId === item.subspace.id)) ?? [];
    }

    function getDistributionForks(distributionType: DistributionType) {
        return futureBtiData?.subspaceTypeLayouts
            ?.flatMap(item => item.subspaceType.subspaces
                .flatMap((subspace) => {
                    subspace.slotDiagram.forks
                        .forEach(fork => fork.nextSlots
                            .forEach(slot => slotsAfterForkIds.push(slot.id)));

                    return subspace.slotDiagram.forks
                        .filter(fork => (fork.setting?.distributionType === distributionType)
                            && fork.spaceBaseTimeIntervalId === futureBtiData.id)
                        .map(fork => ({ fork, subspace }));
                    // eslint-disable-next-line max-len
                })).filter(item => !!studentSubspacesIds?.find(subspaceId => subspaceId === item.subspace.id))
            ?? [];
    }

    // const { selectionPoint } = futureBtiData!;
    return (
        <>
            {isModuleSelectionTime && (
                <InfoCard
                    hasExclamantion
                    hasArrow={false}
                    title="Уважаемые студенты 1 курса!"
                >
                    <div style={{ marginBottom: '10px' }}>
                        {`Вы приступаете к обучению на 1 курсе бакалавриата, и Ваш учебный план будет содержать как обязательные для изучения дисциплины, так и дисциплины по выбору.
                        `}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        В осеннем семестре Вам предстоит изучить
                        1 элективную дисциплину из 28, а также
                        выбрать 1 из 3 направлений по информатике.
                    </div>
                    <div>{`
                Элективные дисциплины представлены курсами нашего университета, а также новые курсы с платформы Открытое Образование Ведущих университетов страны!`}
                    </div>
                </InfoCard>
            )}
            <ul className={classes.tabs__list}>
                {
                    Object.entries(Tabs).map((tabsOption) => (
                        <li key={tabsOption[0]} className={classes.tabs__item}>
                            <div
                                onClick={() => setActiveTab(tabsOption[1])}
                                className={
                                    cn(classes.tabs__tab, {
                                        [classes.tabs__tab_selected]: tabsOption[1] === activeTab,
                                    })
                                }
                            >
                                {tabsOption[1]}

                                {
                                    tabsOption[1] === Tabs.ELECTIVE
                                    && (user?.student?.notifications?.length ?? 0) > 0
                                    && <PointIcon className={classes.tabs__tab_point} />
                                }
                            </div>
                        </li>
                    ))
                }
            </ul>

            {
                allOrObligatiry
                && showModules[activeTab].length > 0
                && showModules[activeTab]?.map(module => (
                    <Fragment key={module.subspace.id}>
                        {
                            module.studentModules?.length > 0 && (
                                <FutureSubspace
                                    moduleSettings={moduleSettings}
                                    studentSubspace={module}
                                />
                            )
                        }
                    </Fragment>
                ))
            }

            {
                activeTab === Tabs.DISTRIBUTION && spaceDistributionForkNames.map(spaceName => (
                    <>
                        <div className={classes.subspace__subtitle}>
                            {spaceName}
                        </div>

                        {
                            groupDistributionForksBySubspace[spaceName].map((item, index) => (
                                <DistributionPoint
                                    key={item.fork.id}
                                    fork={item.fork}
                                    order={index + 1}
                                    moduleSettings={moduleSettings}
                                    subspace={item.subspace}
                                    isModuleSelectionTime={isModuleSelectionTime}
                                />
                            ))
                        }
                    </>
                ))
            }

            {
                activeTab === Tabs.ELECTIVE && (
                    <div className={classes.subspace__electivePoint}>
                        {
                            spaceElectiveForkNames.map(spaceName => (
                                <>
                                    <div className={classes.subspace__subtitle}>
                                        {spaceName}
                                    </div>

                                    {
                                        groupElectiveForksBySubspace[spaceName]
                                            .map((item, index) => (
                                                <ElectivePoint
                                                    isModuleSelectionTime={isModuleSelectionTime}
                                                    key={item.fork.id}
                                                    fork={item.fork}
                                                    order={index + 1}
                                                    moduleSettings={moduleSettings}
                                                    subspace={item.subspace}
                                                />
                                            ))
                                    }
                                </>
                            ))
                        }

                        {
                            isModuleSelectionTime && (
                                <ActionButton
                                    onClick={() => setIsShowFinishSelectPopup(true)}
                                    disabled={!store.isChocePointFinished()}
                                    className={classes.subspace__button}
                                >
                                    Завершить выбор модулей
                                </ActionButton>
                            )
                        }
                    </div>
                )
            }

            {
                isShowFinishSelectPopup && (
                    <FinishSelectPointPopup>
                        <>
                            <div className={classes.popup__title}>Завершить выбор?</div>

                            <CrossIcon
                                handler={() => setIsShowFinishSelectPopup(false)}
                                className={classes.popup__cross}
                            />

                            <div className={classes.popup__subtitle}>
                                После завершения выбора модулей вы не
                                сможете изменить ваш выбор на платформе.
                            </div>

                            <div className={classes.popup__buttonContainer}>
                                <ActionButton
                                    onClick={() => setIsShowFinishSelectPopup(false)}
                                    className={classes.subspace__button}
                                >
                                    Отменить
                                </ActionButton>

                                <ActionButton
                                    onClick={() => {
                                        getFutureBaseTimeInterval();

                                        setStdentPriorities({
                                            variables: {
                                                setStudentPrioritiesInput: {
                                                    // studentId,
                                                    baseTimeIntervalInstanceId: id,
                                                    forks: store.getFilteredForks(),
                                                },
                                            },
                                        });
                                    }}
                                    className={classes.subspace__button}
                                >
                                    Завершить
                                </ActionButton>
                            </div>
                        </>
                    </FinishSelectPointPopup>
                )
            }

            {
                activeTab === Tabs.DISTRIBUTION
                && spaceDistributionForkNames.length === 0
                && defaultView
            }

            {
                activeTab === Tabs.ELECTIVE
                && spaceElectiveForkNames.length === 0
                && defaultView
            }

            {showModules[activeTab].length === 0 && defaultView}
        </>
    );
});

function isNoDistributionSlot(id: string) {
    return !slotsAfterForkIds.includes(id);
}
