import React, { useState } from 'react';

import { StudentLimitViolation } from '../../scheduleViolationInterface';
import { ArrowInShowViolation } from '../ArrowInShowViolation';

import classes from '../scheduleViolation.module.scss';

interface Props {
    studentLimitViolation: StudentLimitViolation;
}

export const ShowStudentLimitViolation = ({ studentLimitViolation }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    return studentLimitViolation && (
        <div className={classes.schedule__violation_container}>
            <div
                className={classes.schedule__violation_title}
                onClick={() => setIsOpen(!isOpen)}
            >
                {studentLimitViolation?.violationName}
                <ArrowInShowViolation isOpen={isOpen} />
            </div>
            {isOpen && studentLimitViolation?.studentDurationLimitsByDate
                .map((violationsByDate, index) => (
                    /* eslint-disable */
                    <div
                        key={`${violationsByDate.date} ${index}`}
                        className={classes.schedule__violation_conflict_title}>
                        {/* eslint-enable */}
                        Дата: {`${violationsByDate.date}`}
                        {violationsByDate.studentDurationLimits.map((limit) => {
                            const { user } = limit.student;
                            return (
                                <div
                                    key={`${violationsByDate.date} ${user.id}`}
                                    className={classes.schedule__violation_conflict}
                                >
                                    Студент: {`${user.lastName} ${user.firstName} ${user.patronymic} `}
                                    <div>Превышение: {`${limit.extraDurationMinutes} минут`}</div>
                                </div>
                            );
                        })}
                    </div>
                ))}
        </div>
    );
};
