import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { GET_SKILLS_TYPE } from '@common/qraphql/queries';

import { useRouteMatch } from 'react-router-dom';
import { SubSpaceOptions } from './SubSpaceOptions';

import { Space, Subspace, SubspaceType } from '../subSpaceTypes';

import { skills_skills } from '../../graphql-query-types';

export const GET_SUBSPACE = gql`
  query subspace($id: String!) {
    subspace(
      id: $id
    ) {
      id
      name
      description
      owner {
        id
      }
      type {
        id
        isDescriptionEnabled
      }
      prerequisiteSpecializedSkills {
        skill {
          id
          name
          typeId
        }
        level
      }
      outputSpecializedSkills {
        skill {
          id
          name
          typeId
        }
        level
      }
    }
  }
`;

const UPDATE_SUBSPACE = gql`
  mutation updateSubspace(
    $id: String!
    $minStudentCount: Int!
    $maxStudentCount: Int!
    $prerequisiteSpecializedSkills: [UpdateSubspaceSkillInput!]
    $outputSpecializedSkills: [UpdateSubspaceSkillInput!]
    $description: String
  ) {
    updateSubspace(
      updateSubspaceInput: {
        id: $id
        minStudentCount: $minStudentCount
        maxStudentCount: $maxStudentCount
        prerequisiteSpecializedSkills: $prerequisiteSpecializedSkills
        outputSpecializedSkills: $outputSpecializedSkills
        description: $description
      }
    ) {
      name
      id
    }
  }
`;

interface Props {
    editState: boolean;
    setEditState: (flag: boolean) => void;
    allSkillsList: skills_skills[];
    space: Space;
    subspace: Subspace;
    subspaceType: SubspaceType;

    refetchGetSubspacePageData(params: any): void
}

export function SubSpaceOptionsApollo({
    refetchGetSubspacePageData,
    editState,
    setEditState,
    allSkillsList,
    space,
    subspace,
    subspaceType,
}: Props): JSX.Element {
    const { params }: any = useRouteMatch();
    const {
        error, loading, data,
    } = useQuery(GET_SKILLS_TYPE, {
        fetchPolicy: 'cache-and-network',
    });

    const [updateSubspaceInput] = useMutation(UPDATE_SUBSPACE, {
        onCompleted: () => refetchGetSubspacePageData({ variables: params }),
    });

    if (loading) return <></>;
    if (error) return <>`Error! ${error.message}`</>;
    return (
        <SubSpaceOptions
            editState={editState}
            setEditState={setEditState}
            skillTypes={data.skillTypes}
            allSkillsList={allSkillsList}
            space={space}
            subspace={subspace}
            subspaceType={subspaceType}
            updateSubspace={(
                id,
                minStudentCount,
                maxStudentCount,
                prerequisiteSpecializedSkills?,
                outputSpecializedSkills?,
                description?,
            ) => updateSubspaceInput({
                variables: {
                    id,
                    minStudentCount,
                    maxStudentCount,
                    prerequisiteSpecializedSkills,
                    outputSpecializedSkills,
                    description,
                },
            })}
        />
    );
}
