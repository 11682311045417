import React, { useState } from 'react';
import cn from 'classnames';
import { ArrowIcon } from '@common/svg/ArrowIcon';
import { skillTypesData_skillTypes } from 'src/graphql-query-types';
import { SkillNode } from 'src/store/SkillHierarchy';
import { SkillWhithLevel } from '../../Config/interfaces';
import { ModuleSkill } from './ModuleSkill';

import classes from './ModuleSkills.module.scss';

interface Props {
    type: skillTypesData_skillTypes;
    skills: SkillNode[];
    moduleSkills: SkillWhithLevel[];
}

export function ModuleSkillType({ type, skills, moduleSkills }: Props) {
    const [showType, setShowType] = useState(false);

    function getModuleSkill(id: string) {
        return moduleSkills.find(skill => skill.skill.id === id);
    }
    return (
        <>
            <div
                className={cn(classes.skillType, { [classes.skillType__open]: showType })}
                onClick={() => setShowType(!showType)}
            >
                <ArrowIcon isOpen={showType} />
                <div
                    className={classes.skillType__title}
                >
                    {type.name.nominativePlural}
                </div>
            </div>
            <div className={classes.skill__list}>
                {showType && skills.map(skill => (
                    <ModuleSkill
                        skill={skill}
                        level={getModuleSkill(skill.skill.id)?.level}
                    />
                ))}
            </div>
        </>
    );
}
