import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { Loader } from '@common/Loader';
import { useUrlQuery } from '@common/hooks';
import { ModuleStatistics } from './ModuleStatistics';
import { EvaluationPointOption, RouteParams, StudentOption, WaveOption } from './ModuleStatisticsTypes';
import {
    GetModuleFilters,
    GetModuleFiltersVariables,
    GetModuleFilters_moduleWavesByBaseTimeIntervalInstanceId,
} from './graphql-types';

import classes from './ModuleStatistics.module.scss';

export const GET_MODULE_FILTERS = gql`
  query GetModuleFilters($moduleId: String!, $btiId: String!) {
    module(id: $moduleId) {
      id
      name
      evaluationPoints {
        id
        topic
        order
      }
    }
    moduleWavesByBaseTimeIntervalInstanceId(moduleId: $moduleId, baseTimeIntervalInstanceId: $btiId) {
      id
      index
      students {
        id
        user {
          id
          lastName
          firstName
          patronymic
        }
      }
    }
  }
`;

export function ModuleStatisticsApollo() {
    const { moduleId, btiId } = useParams<RouteParams>();
    const [isDetailedModeOn, setDetailedModeActivity] = useState<boolean>(false);
    const [isDetailedModeEnabled, setDetailedModeAvailability] = useState<boolean>(true);
    const { history, setUrlQuery, getUrlQuery } = useUrlQuery();
    const [selectedPoints, setSelectedPoints] = useState<EvaluationPointOption[]>([]);
    const [selectedWaves, setSelectedWaves] = useState<WaveOption[]>([]);
    const [selectedStudents, setSelectedStudents] = useState<StudentOption[]>([]);

    const { data, loading, error } = useQuery<GetModuleFilters, GetModuleFiltersVariables>(
        GET_MODULE_FILTERS,
        {
            variables: { moduleId, btiId },
            fetchPolicy: 'network-only',
        },
    );
    const rawSkillTypeId = getUrlQuery('skillTypeId');

    useEffect(() => {
        if (!rawSkillTypeId) {
            setDetailedModeActivity(false);
            setDetailedModeAvailability(false);
        } else {
            setDetailedModeAvailability(true);
        }
    }, [rawSkillTypeId]);

    useEffect(() => {
        if (data?.module.evaluationPoints) {
            const rawEvaluationPointIds = getUrlQuery('evaluationPointIds');
            const evaluationPointIds: string[] | null = rawEvaluationPointIds
              && JSON.parse(decodeURIComponent(rawEvaluationPointIds));
            if (evaluationPointIds) {
                setSelectedPoints(data.module.evaluationPoints.filter(
                    ({ id }) => evaluationPointIds.includes(id),
                ));
            }
        }

        if (data?.moduleWavesByBaseTimeIntervalInstanceId) {
            const rawWaveIds = getUrlQuery('waveIds');
            const waveIds: string[] | null = rawWaveIds
              && JSON.parse(decodeURIComponent(rawWaveIds));
            if (waveIds) {
                setSelectedWaves(data.moduleWavesByBaseTimeIntervalInstanceId.filter(
                    ({ id }) => waveIds.includes(id),
                ));
            }

            const rawStudentIds = getUrlQuery('studentIds');
            const studentIds: string[] | null = rawStudentIds
              && JSON.parse(decodeURIComponent(rawStudentIds));
            if (studentIds) {
                const allStudents = data?.moduleWavesByBaseTimeIntervalInstanceId
                    .flatMap(({ students }) => students);
                setSelectedStudents(allStudents.filter(({ id }) => studentIds.includes(id)));
            }
        }
    }, [data]);

    function handleGoBack() {
        history.push(history.location.pathname.split('/').slice(0, 4).join('/'));
    }

    function onEvaluationPointChange(items: EvaluationPointOption[]) {
        setSelectedPoints(items);
        const evaluationPointIds = items.length ? items.map(({ id }) => id) : undefined;
        setUrlQuery({ evaluationPointIds });
    }

    function onWaveChange(items: WaveOption[]) {
        setSelectedWaves(items);
        const waveIds = items.length ? items.map(({ id }) => id) : undefined;
        setUrlQuery({ waveIds });
    }

    function onStudentChange(items: StudentOption[]) {
        setSelectedStudents(items);
        const studentIds = items.length ? items.map(({ id }) => id) : undefined;
        setUrlQuery({ studentIds });
    }

    function onDetailedModeActivityToggle() {
        setDetailedModeActivity(!isDetailedModeOn);
    }

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return (
            <div className={classes['module-statistics__container']}>
                Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}
            </div>
        );
    }

    if (data) {
        const studentOptions = formatStudentsOptionsFromWaveList(
            data.moduleWavesByBaseTimeIntervalInstanceId,
            selectedWaves,
        );
        return (
            <ModuleStatistics
                isDetailedModeOn={isDetailedModeOn}
                isDetailedModeEnabled={isDetailedModeEnabled}
                onDetailedModeActivityToggle={onDetailedModeActivityToggle}
                handleGoBack={handleGoBack}
                module={data.module}
                waves={data.moduleWavesByBaseTimeIntervalInstanceId}
                students={studentOptions}
                selectedEvaluationPoints={selectedPoints}
                selectedWaves={selectedWaves}
                selectedStudents={selectedStudents}
                onEvaluationPointChange={onEvaluationPointChange}
                onWaveChange={onWaveChange}
                onStudentChange={onStudentChange}
            />
        );
    }
    return null;
}

function formatStudentsOptionsFromWaveList(
    totalWaves: GetModuleFilters_moduleWavesByBaseTimeIntervalInstanceId[],
    selectedWaves: WaveOption[],
) {
    if (!selectedWaves.length) {
        return totalWaves.flatMap(({ students }) => students);
    }

    return totalWaves.filter(({ id }) => selectedWaves.find(wave => wave.id === id))
        .flatMap(({ students }) => students);
}
