import React from 'react';

import { groupBy } from 'lodash';

import classes from './SkillsBlock.module.scss';

import { SkillType } from '../../../subSpaceAdmin/subSpaceTypes';
import { skills_skills as Skill } from '../../../graphql-query-types';

interface Props {
    text: string;
    skillList: any;
    skillTypes: SkillType[];
    allSkillsList: Skill[];
}

export function SkillsBlock({
    text,
    skillList,
    skillTypes,
    allSkillsList,
}: Props):JSX.Element {
    const currentSkillList = skillList[1];
    const skillType = skillList[0];

    const sortedSkills: SkillType[] = skillTypes
        .filter((skill: SkillType) => skill.parentId === null);

    const getFinalSkillTypeId = (typeId: string): string => {
        const skillTypeGroups = groupBy(skillTypes, 'parentId');
        let finalSkillTypeId = typeId;
        while (skillTypeGroups[finalSkillTypeId]) {
            const [child] = skillTypeGroups[finalSkillTypeId];
            finalSkillTypeId = child.id;
        }

        return finalSkillTypeId;
    };

    function isParentSkill(parent: Skill, child: Skill): boolean {
        return !!parent.children?.filter(item => item.id !== parent.id)
            .some((item: any) => item.id === child.id);
    }

    function findParentSkill(skill: Skill): Skill | undefined {
        return allSkillsList.find(item => isParentSkill(item, skill));
    }

    function formatSkillName(skill: { skill: Skill }): string {
        let { name } = skill.skill;
        let parent = findParentSkill(skill.skill);

        while (parent) {
            name = `${name}. ${parent.name}`;
            parent = findParentSkill(parent);
        }

        return name;
    }

    const currenSkillTypeParams: { id: string; name: string; level: number | null; }[] = [];
    const paramsType = currentSkillList;
    paramsType.forEach((item: any) => {
        if (skillType === item.skill.typeId && skillType < sortedSkills.length) {
            currenSkillTypeParams.push({
                id: item.skill.id,
                name: item.skill.name,
                level: item.level,
            });
        }
        if (Number(getFinalSkillTypeId(skillType)) > sortedSkills.length
            && getFinalSkillTypeId(skillType) <= item.skill.typeId) {
            currenSkillTypeParams.push({
                id: item.skill.id,
                name: formatSkillName(item),
                level: null,
            });
        }
    });
    const currentSkillType = sortedSkills.filter(item => skillType === item.id);
    if (skillType > sortedSkills.length) {
        currentSkillType.push(sortedSkills[sortedSkills.length - 1]);
    }
    return (
        <div className={classes.moduleSkillsBlock}>
            <div className={classes.moduleSkillsBlock__text}>
                {`${currentSkillType[0].name.genitiveSingular}, ${text}`}
            </div>
            <ul className={classes.moduleSkillsBlock__list}>
                {currenSkillTypeParams.map(item => (
                    <li
                        key={item.id}
                        className={classes.moduleSkillsBlock__item}
                    >
                        {item.name}
                    </li>
                ))}
            </ul>
        </div>
    );
}
