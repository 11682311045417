import { ApolloError, gql, useMutation } from '@apollo/client';

const UPDATE_RESERVATION = gql`
    mutation updateReservation($baseTimeIntervalInstanceId: String!){
        updateReservation(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId)
}
`;

export function useUpdateReservation() {
    const [handler, { data, error }] = useMutation(UPDATE_RESERVATION);

    async function onReservationUpdate(baseTimeIntervalInstanceId: string) {
        try {
            await handler({
                variables: { baseTimeIntervalInstanceId },
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error((e as ApolloError).graphQLErrors?.[0].message);
        }
    }

    return [
        data,
        error,
        onReservationUpdate,
    ];
}
