import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Modules } from './Modules';
import Diagram from '../store/Diagram';

import {
    UpdateSlotDiagramInput,
} from '../subSpaceTypes';
import { skills_skills, skillTypesData_skillTypes } from '../../graphql-query-types';

interface Props {
    subspaceId: string
    slotId: string
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
    updateSlotDiagram(moduleSlotInput: UpdateSlotDiagramInput): void
}

const GET_MODULE_SLOT = gql`
    query moduleSlot($id: String!){
        moduleSlot(id: $id ){
            id
            spaceBaseTimeIntervalId
            module {
                id
                name
                description
                minStudentCount
                maxStudentCount
                maxWaveCount
                creditCount
                teachersRoles {
                    id
                    name
                }
                moduleTeachers {
                    teacher {
                        id
                        user {
                            firstName
                            lastName
                            patronymic
                        }
                    }
                    teacherRoles {
                        id
                        name
                    }
                }
                prerequisiteSkills {
                    skill {
                        id
                        name
                        fullName
                        typeId
                    }
                    level
                }
                outputSkills {
                    skill {
                        id
                        name
                        fullName
                        typeId
                    }
                    level
                }
                meetings {
                    id
                    topic
                    format {
                        id
                        name
                    }
                    duration {
                        id
                        name
                        academicHours
                    }
                    minStudentCount
                    maxStudentCount
                    prerequisiteSkills {
                        skill {
                            id
                            name
                            fullName
                            typeId
                        }
                        level
                    }
                    outputSkills {
                        skill {
                            id
                            name
                            fullName
                            typeId
                        }
                        level
                    }
                    order
                }
                evaluationPoints {
                    id
                    topic
                    description
                    previousEvent {
                        id
                        type
                    }
                    evaluationPointSkills {
                        skill {
                            id
                            name
                            fullName
                            typeId
                        }
                        level
                    }
                    duration {
                        id
                        name
                        academicHours
                    }
                    order
                }
                assignments {
                    id
                    topic
                    duration {
                        id
                        name
                        academicHours
                    }
                    prerequisiteSkills {
                        skill {
                            id
                            name
                            typeId
                        }
                        level
                    }
                    outputSkills {
                        skill {
                            id
                            name
                            typeId
                        }
                        level
                    }
                    isPreviousEventInRow
                    isNextEventInRow
                    minDaysToPreviousEvent
                    maxDaysToPreviousEvent
                    minDaysToNextEvent
                    maxDaysToNextEvent
                    order
                    dependentEvents {
                        id
                        type
                    }
                }
            }
        }
    }
`;

const UPDATE_SLOT_MODULE = gql`
    mutation updateSlotModule($updateSlotModuleInput: UpdateSlotModuleInput!) {
        updateSlotModule(updateSlotModuleInput: $updateSlotModuleInput) {
            id
        }
    }
`;

export function ModulesApollo({
    subspaceId,
    slotId,
    skillTypes,
    allSkillsList,
    updateSlotDiagram,
}: Props): JSX.Element {
    const { slotSettings } = Diagram;
    const [updateSlotModule] = useMutation(UPDATE_SLOT_MODULE, {
        refetchQueries: [{ query: GET_MODULE_SLOT, variables: { id: slotSettings.id || slotId } }],
    });
    const {
        data,
        loading,
        error,
    } = useQuery(GET_MODULE_SLOT, {
        fetchPolicy: 'cache-and-network',
        variables: { id: slotSettings.id },
    });
    if (loading) return <>`Loading...`</>;
    if (error) return <>`Error! ${error.message}`</>;
    return (
        <Modules
            subspaceId={subspaceId}
            slotId={slotId}
            skillTypes={skillTypes}
            allSkillsList={allSkillsList}
            selectedModule={data.moduleSlot.module}
            updateSlotModule={(
                updateSlotModuleInput,
            ) => updateSlotModule(
                {
                    variables: { updateSlotModuleInput },
                },
            )}
            updateSlotDiagram={updateSlotDiagram}
        />
    );
}
