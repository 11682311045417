import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { groupBy } from 'lodash';
import cn from 'classnames';
import { ApolloError } from '@apollo/client';
import { format } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';

import { ActionButton } from '@common/ActionButton';
import { Alert } from '@common/Alert';
import { Loader } from '@common/Loader';
import { MultiAutosuggest } from '@common/MultiAutosuggest';
import { Building, Room, RoomLoad, RoomLoadInput, RoomTimeslot, TerritorialZone } from './RoomOccupationDashboardInterfaces';
import classes from './RoomOccupationDashboard.module.scss';
import { GET_SUGGESTED_MEETING_FORMATS, GET_SUGGESTED_ROOMS } from './RoomOccupationDashboardQueries';
import { CalendarComponent } from '../OccupationDashboard/Filters/CalendarComponent';

interface Suggestion {
    id: string;
    name: string;
}

interface RoomSuggestion {
    id: string;
    title: string;
}

interface MeetingFotmatsSuggestionResponse {
    meetingFormatsByFilters: Suggestion[];
}

interface RoomsSuggestionResponse {
    rooms: RoomSuggestion[];
}

interface Props {
    getRoomLoadForDateRange(args: Omit<RoomLoadInput, 'baseTimeIntervalInstanceId'>): void;
    roomLoadData?: RoomLoad | null;
    roomLoadLoading?: boolean;
    roomLoadError?: ApolloError;
}

type DateRange = [Date | null, Date | null];

export function RoomOccupationDashboard({
    getRoomLoadForDateRange,
    roomLoadData,
    roomLoadLoading,
    roomLoadError,
}: Props) {
    const [dateRange, setDateRange] = useState<DateRange>([null, null]);
    const [filtersErrorAlerts, setFiltersErrorAlerts] = useState<JSX.Element[]>([]);
    const [isTReservationsVisible, setTReservationsVisibility] = useState<boolean>(false);
    const [meetingFormats, setMeetingFormats] = useState<Suggestion[]>([]);
    const [rooms, setRooms] = useState<RoomSuggestion[]>([]);
    const [capacity, setCapacity] = useState<number>();
    const [isValidCapacity, setCapacityValidity] = useState<boolean>(true);

    function onRoomCapacityChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value, validity: { valid } } = e.target;
        setCapacity(!value ? undefined : Number(value));
        setCapacityValidity(valid);
    }

    function handleChangeCheckBox() {
        setTReservationsVisibility(!isTReservationsVisible);
    }

    function onFiltersSubmit() {
        if (!dateRange[0] || !dateRange[1]) {
            setFiltersErrorAlerts(prevAlerts => ([...prevAlerts, (
                <Alert
                    key={nanoid()}
                    message="Нужно задать начальную и конечную даты"
                    time={5000}
                />
            )]));
            return;
        }
        if (!isValidCapacity) {
            setFiltersErrorAlerts(prevAlerts => ([...prevAlerts, (
                <Alert
                    key={nanoid()}
                    message="Укажите валидную вместимость помещения"
                    time={5000}
                />
            )]));
            return;
        }
        getRoomLoadForDateRange({
            fromDate: format(dateRange[0], 'yyyy-MM-dd'),
            toDate: format(dateRange[1], 'yyyy-MM-dd'),
            meetingFormatIds: meetingFormats?.length
                ? meetingFormats.map(({ id }) => id)
                : undefined,
            roomIds: rooms.length
                ? rooms.map(({ id }) => id)
                : undefined,
            capacity,
        });
    }

    function formatRoomsSendindValue(value: string) {
        return {
            namePattern: value,
            meetingFormatIds: meetingFormats?.length
                ? meetingFormats.map(({ id }) => id)
                : undefined,
        };
    }

    function formatMeetingFormatsSendindValue(value: string) {
        return {
            namePattern: value,
            roomIds: rooms.length
                ? rooms.map(({ id }) => id)
                : undefined,
        };
    }

    const roomTimeslotMap = groupBy(roomLoadData?.rooms, 'id');

    return (
        <div className={classes['room-occupation']}>
            <div className={classes['room-occupation__filters']}>
                <div className={classes.filters__item}>
                    <CalendarComponent
                        startDate={dateRange[0]}
                        endDate={dateRange[1]}
                        onChange={setDateRange}
                    />
                </div>
                <div className={classes.filters__item}>
                    <div
                        className={classes.filters__itemName}
                    >
                        Форматы встреч:
                    </div>
                    <MultiAutosuggest
                        placeholder="Выбрать формат встречи"
                        query={GET_SUGGESTED_MEETING_FORMATS}
                        formatOption={titleFormat}
                        transformResponse={meetingFormatsTransform}
                        onChange={setMeetingFormats}
                        selectedOptions={meetingFormats}
                        formatSendingValue={formatMeetingFormatsSendindValue}
                    />
                </div>
                <div className={classes.filters__item}>
                    <div
                        className={classes.filters__itemName}
                    >
                        Помещения:
                    </div>
                    <MultiAutosuggest
                        placeholder="Выбрать помещение"
                        query={GET_SUGGESTED_ROOMS}
                        formatOption={roomTitleFormat}
                        transformResponse={roomsTransform}
                        onChange={setRooms}
                        selectedOptions={rooms}
                        formatSendingValue={formatRoomsSendindValue}
                    />
                </div>
                <div className={classes.filters__item}>
                    <div
                        className={classes.filters__itemName}
                    >
                        Вместимость помещения:
                    </div>
                    <input
                        className={classes.filters__capacity}
                        name="room-capacity"
                        onInput={onRoomCapacityChange}
                        onChange={onRoomCapacityChange}
                        value={capacity}
                        type="number"
                        placeholder="Кол-во студентов"
                        min={1}
                        step={1}
                        pattern="[-/d]*"
                    />
                </div>
            </div>
            <div className={classes.filters__checkbox}>
                <input
                    onChange={handleChangeCheckBox}
                    type="checkbox"
                    id="room-load-report"
                    checked={isTReservationsVisible}
                />
                <label htmlFor="room-load-report">Показывать встречи Т-университета</label>
            </div>
            <ActionButton
                className={classes['filters__submit-button']}
                onClick={onFiltersSubmit}
            >
                Применить
            </ActionButton>
            {roomLoadLoading && <Loader />}
            {!roomLoadLoading && roomLoadData?.rooms.length && (
                <table className={classes['room-occupation__report']}>
                    <ReportTableHead roomLoadData={roomLoadData} />
                    <tbody className={classes.report__body}>
                        {roomLoadData?.territorialZones.map((territorialZone) => (
                            territorialZone.buildings.map((building, buildingIndex) => (
                                building.rooms.map((room, roomIndex) => (
                                    <tr
                                        key={room.id}
                                        className={classes['report-body__row']}
                                    >
                                        {buildingIndex === 0 && roomIndex === 0 && (
                                            <td
                                                rowSpan={countRowSpan(territorialZone)}
                                                className={classes['report-body__title-cell']}
                                            >
                                                <div className={classes['report-body__title-cell-tooltip']}>
                                                    <div
                                                        className={classes['title-cell-tooltip__inner-text']}
                                                    >
                                                        {territorialZone.name}
                                                    </div>
                                                </div>
                                            </td>
                                        )}
                                        {roomIndex === 0 && (
                                            <td
                                                rowSpan={building.rooms.length}
                                                className={classes['report-body__title-cell']}
                                            >
                                                <div className={classes['report-body__title-cell-tooltip']}>
                                                    <div
                                                        className={classes['title-cell-tooltip__inner-text']}
                                                    >
                                                        {building.name}
                                                    </div>
                                                </div>
                                            </td>
                                        )}
                                        {roomTimeslotMap[room.id] && (
                                            <>
                                                <th className={cn(
                                                    classes['report-body__title-cell'],
                                                    classes['report-body__room-title-cell'],
                                                )}
                                                >
                                                    <div
                                                        className={classes['title-cell-tooltip__inner-text']}
                                                    >
                                                        {room.title} ({room.capacity})
                                                    </div>
                                                </th>
                                                {roomTimeslotMap[room.id].map(({ timeslots }) => (
                                                    timeslots.map(t => (
                                                        <RoomTimeslotCell
                                                            timeslot={t}
                                                            location={building}
                                                            room={room}
                                                            /* eslint-disable-next-line max-len */
                                                            isTReservationsVisible={isTReservationsVisible}
                                                        />
                                                    ))
                                                ))}
                                            </>
                                        )}
                                    </tr>
                                ))
                            ))
                        ))}
                    </tbody>
                </table>
            )}
            {filtersErrorAlerts}
            {roomLoadError && (
                <Alert
                    message={roomLoadError?.graphQLErrors?.[0].message || roomLoadError.message}
                    time={3000}
                />
            )}
            {roomLoadData === null && (
                <Alert
                    message="Нет данных за выбранный период"
                    time={3000}
                />
            )}
        </div>
    );
}

interface RoomTimeslotCellProps {
    timeslot: RoomTimeslot;
    room: Room;
    location: Building;
    isTReservationsVisible: boolean;
}

function RoomTimeslotCell({
    timeslot,
    room,
    location,
    isTReservationsVisible,
}: RoomTimeslotCellProps) {
    const { location: { pathname } } = useHistory();

    function getTimeslotLink() {
        const UrlQueryParams = new URLSearchParams();

        const timeslotDate = format(new Date(timeslot.date), 'yyyy.MM.dd');
        UrlQueryParams.set('from', encodeURIComponent(`${timeslotDate} 00:00`));
        UrlQueryParams.set('to', encodeURIComponent(`${timeslotDate} 23:59`));

        const rooms = [{
            id: room.id,
            title: room.title,
            location: {
                name: location.name,
            },
        }];

        UrlQueryParams.set('rooms', encodeURIComponent(JSON.stringify(rooms)));
        const types = [{ id: 'meeting', name: 'Встреча' }];
        UrlQueryParams.set('types', encodeURIComponent(JSON.stringify(types)));

        return `${pathname}?${UrlQueryParams.toString()}`;
    }

    return (
        <td
            className={getCellClassName(timeslot, isTReservationsVisible)}
            key={`${timeslot.date}${timeslot.endTime}`}
        >
            {isTReservationsVisible && timeslot.isReservedByTUniversity && (
                <Link
                    className={classes['timeslot-cell__link']}
                    to={getTimeslotLink}
                    target="_blank"
                />
            )}
        </td>
    );
}

interface ReportTableHeadProps {
    roomLoadData?: RoomLoad | null;
}

function ReportTableHead({
    roomLoadData,
}: ReportTableHeadProps) {
    const dates = groupBy(roomLoadData?.timeslots, 'date');

    return (
        <thead className={classes.report__head}>
            <tr className={classes['report-head__row']}>
                <td className={classes['report-head__cell']}>
                    Территориальная зона
                </td>
                <td className={classes['report-head__cell']}>
                    Корпус
                </td>
                <td className={classes['report-head__cell']}>
                    Помещение
                </td>
                {Object.entries(dates).map(([date, dateTimeslots]) => {
                    const splittedDate = date.split('-');
                    return (
                        <td
                            key={date}
                            colSpan={dateTimeslots.length}
                            className={classes['report-head__cell']}
                        >
                            {splittedDate[2]}.{splittedDate[1]}.{splittedDate[0]}
                        </td>
                    );
                })}
            </tr>
            <tr className={classes['report-head__row']}>
                <td
                    className={classes['report-head__legend']}
                    colSpan={3}
                >
                    <div className={classes['report-legend__item']}>
                        <div className={classes['report-legend__mark']} />
                        - Помещение доступно Т-университету
                    </div>
                    <div className={classes['report-legend__item']}>
                        <div className={cn(classes['report-legend__mark'], classes['report-legend__mark_gray'])} />
                        - Помещение забронировано классическим университетом
                    </div>
                    <div className={classes['report-legend__item']}>
                        <div className={cn(classes['report-legend__mark'], classes['report-legend__mark_red'])} />
                        - Помещение не доступно Т-университету
                    </div>
                    <div className={classes['report-legend__item']}>
                        <div className={cn(classes['report-legend__mark'], classes['report-legend__mark_srtiped'])} />
                        - В помещении стоит встреча Т-университета
                    </div>
                </td>
                {roomLoadData?.timeslots.map(timeslot => (
                    <td
                        key={`${timeslot.date}${timeslot.startTime}`}
                        className={classes['report-head__timeslot-cell']}
                    >
                        {`${timeslot.startTime.slice(0, 5)}-${timeslot.endTime.slice(0, 5)}`}
                    </td>
                ))}
            </tr>
        </thead>
    );
}

function titleFormat(item: Suggestion): string {
    return `${item.name}`;
}

function roomTitleFormat(item: RoomSuggestion): string {
    return `${item.title}`;
}

function roomsTransform(response: RoomsSuggestionResponse): RoomSuggestion[] {
    return response.rooms;
}

function meetingFormatsTransform(response: MeetingFotmatsSuggestionResponse): Suggestion[] {
    return response.meetingFormatsByFilters;
}

const countRowSpan = (territorialZone: TerritorialZone) => (
    territorialZone.buildings.reduce((rowSpan, building) => {
        const acc = rowSpan + building.rooms.length;
        return acc;
    }, 0)
);

function getCellClassName({
    isAvailable,
    isReservedByClassicUniversity,
    isReservedByTUniversity,
}: RoomTimeslot, isTReservationsVisible: boolean) {
    const classNames = [];
    if (!isAvailable) {
        classNames.push(classes['report-body__timeslot-cell_non-available']);
    }
    // помещение доступно (white)
    if (isAvailable) {
        classNames.push(classes['report-body__timeslot-cell_available']);
    }
    // помещение доступно, на забронено классическим университетом (gray)
    if (isReservedByClassicUniversity && isAvailable) {
        classNames.push(classes['report-body__timeslot-cell_reserved-by-classic']);
    }
    // в помещении назначена встреча Т-университета (striped)
    if (isReservedByTUniversity && isTReservationsVisible) {
        classNames.push(classes['report-body__timeslot-cell_reserved-by-t']);
    }
    return cn(...classNames, classes['report-body__timeslot-cell']);
}
