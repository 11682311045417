import React from 'react';
import cn from 'classnames';

import classes from './Input.module.scss';

interface Props {
    id?: string;
    name?: string;
    value?: string | number;
    label?: string;
    placeholder?: string;
    type?: InputTypes;
    labelModifier?: string;
    max?: number;
    min?: number;
    inline?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export enum InputTypes {
    Text = 'text',
    Number = 'number',
}

/**
 *
 * @param name
 * @param value
 * @param label
 * @param placeholder
 * @param onChange
 * @param type          -- Possible options: 'text', 'number', Default option: 'text'
 * @param labelModifier -- Possible options: 'bold', Default option: ''
 * @constructor
 */

export const Input: React.FC<Props> = ({
    id,
    name,
    value,
    label,
    placeholder,
    type = InputTypes.Text,
    max = 10000,
    min = 0,
    labelModifier = '',
    inline,
    onChange = () => { },
}: Props) => {
    const blockClass = cn(classes.input,
        [classes[`input_${type}`]],
        { [classes.input_inline]: inline });
    const inputClass = cn(classes.input__input, [classes[`input__input_${type}`]]);
    const labelClass = cn(classes.input__label, [classes[`input__label_${type}`]], classes[`${labelModifier}`]);
    const validateNumberValue = Number(value) > max ? max : value;
    const isNumber = type === 'number';
    const getTextType = (textType: string) => textType;
    return (
        <div className={blockClass}>
            {label
                && (
                    <label htmlFor={name} className={labelClass}>
                        {label}
                    </label>
                )}
            <input
                id={id}
                className={inputClass}
                name={name}
                value={isNumber ? validateNumberValue : value}
                type={getTextType(type)}
                placeholder={placeholder}
                onChange={onChange}
                min={min}
                max={max}
                autoComplete="off"
            />
        </div>
    );
};
