import React, { SyntheticEvent } from 'react';

import { IconDeprecated } from '@common';
import { AvatarIcon } from '@common/svg';
import { Student } from '../../../../../Interfaces';

import classes from './StudentWithAttendance.module.scss';

interface Props {
    student: Student;
    editStudentMode: boolean;

    toggleAttendStudent({ currentTarget }: SyntheticEvent<HTMLDivElement>): void;
}

export function StudentWithAttendance({
    student,
    editStudentMode,
    toggleAttendStudent,
}: Props): JSX.Element {
    return (
        <ul>
            <li className={classes.schedulePage_eventsSection_students}>
                <div className={classes.schedulePage_eventsSection_userContainer}>
                    <div className={classes.schedulePage_eventsSection_btnCntner}>
                        {!editStudentMode
                            ? (
                                <div
                                    id={student.student.user.id}
                                    className={classes.schedulePage_eventsSection_cbxStudn}
                                    onClick={(e) => toggleAttendStudent(e)}
                                >
                                    {student.attendance && (
                                        <IconDeprecated
                                            id="checkmark"
                                            className={classes.schedulePage_evntSctn_Ok1}
                                        />
                                    )}
                                </div>
                            )
                            : (student.attendance && (
                                <IconDeprecated
                                    id="checkmark"
                                    className={classes.schedulePage_evntSctn_Ok1}
                                />
                            ))}
                    </div>
                    <div className={classes.schedulePage_eventsSection_userInfo}>
                        <div className={classes.schedulePage_eventsSection_userPicture}>
                            <div className={classes.schedulePage_eventsSection_avatar}>
                                <AvatarIcon size={50} />
                            </div>
                        </div>
                        <div>
                            <div>{student.student.user.lastName}&nbsp;
                                {student.student.user.firstName}
                            </div>
                            <div>{student.student.user.patronymic}</div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

    );
}
