import React from 'react';
import { Calendar } from '../Calendar';
import classes from './DateSelector.module.scss';

interface Props {
    start: string|undefined,
    end: string|undefined,
    description: string,
    onChange(data:(Date|null)[]): void,
}

export function DateSelector(
    {
        start,
        end,
        onChange,
        description,
    }: Props,
) {
    return (
        <div className={classes.dateSelector}>
            <Calendar
                onChange={onChange}
                start={start && new Date(start)}
                end={end && new Date(end)}
            />
            <div className={classes.dateSelector__description}>
                Даты<div className={classes.dateSelector__description_bold}>{` ${description}`}</div>
            </div>
        </div>
    );
}
