import React, { useState } from 'react';
import cn from 'classnames';

import { Dialog } from '@common/Dialog';
import { BorderCrossIcon } from '@common/svg';

import NestedSkill from '@admin/SkillPage/SkillAddPage/store/NestedSkill';
import { SkillCardWithNestChildrenEdit } from './SkillCardWithNestChildrenEdit';
import { PortalPopUpSkillAddWithNest } from './PortalPopUpSkillAddWithNest';
import { checkChild } from '../../SkillAddPage/SkillAddWithNest/SkillAddFunctions';

import classes from './SkillCardWithNest.module.scss';

import { skillTypesData_skillTypes } from '../../../../graphql-query-types';

interface Props {
    skillTypes: skillTypesData_skillTypes[];
    currentSkill: any;
    isEdit: boolean;
    skillsList: any;
    removeSkill(id: string): void;
    updateNestSkill: (
        id: string,
        name: string,
        typeId: string,
        children: any,
        dependencies: any,
        maxLevel?: number,
    ) => void;
}

export function SkillCardWithNestChildren({
    skillTypes,
    currentSkill,
    isEdit,
    skillsList,
    removeSkill,
    updateNestSkill,
}: Props) {
    const [height, setHeight] = useState(true);
    const [popUpPortal, togglePopUpPortal] = useState(false);
    const [isEditPortal, toggleIsEditPortal] = useState(false);
    const { hasChild } = checkChild(skillTypes, currentSkill.typeId);

    const checkAndRemoveSkill = () => {
        togglePopUpPortal(!popUpPortal);
    };

    const onEdit = () => {
        if (isEdit) {
            NestedSkill.full(currentSkill);
            toggleIsEditPortal(!isEditPortal);
        }
    };

    return (
        <div className={classes['skill-nested-card__child']}>
            <div className={classes['skill-nested-card__child-name-container']}>
                {hasChild && (
                    <div
                        className={
                            cn(classes['skill-nested-card__child-name-triangle'], {
                                [classes['skill-nested-card__child-name-triangle--none-desc']]:
                                currentSkill.children?.length > 0,
                                [classes['skill-nested-card__child-name-triangle--active']]: !height,
                            })}
                        onClick={currentSkill.children?.length > 0
                            ? () => setHeight(!height)
                            : () => null
                        }
                    />
                )}
                <div className={classes.skill_nested_card__skill_name_container}>
                    <div
                        className={classes['skill-nested-card__child-name']}
                        onClick={isEdit === false && currentSkill.children?.length > 0
                            ? () => setHeight(!height)
                            : () => onEdit()
                        }
                    >
                        {currentSkill.name}
                    </div>
                    {isEdit && (
                        <BorderCrossIcon
                            size={18}
                            handler={checkAndRemoveSkill}
                            className={classes['yellow-cross-15px']}
                        />
                    )}
                </div>
            </div>
            <ul
                className={
                    cn(classes['skill-nested-card__child-of-child'], {
                        [classes['skill-nested-card__child-of-child--null-height']]: height,
                    })}
            >
                {currentSkill.children && currentSkill.children.map((item: any) => (
                    <li
                        key={item.id}
                        className={classes['skill-nested-card__child-of-child-item']}
                    >
                        <SkillCardWithNestChildren
                            skillTypes={skillTypes}
                            currentSkill={item}
                            isEdit={isEdit}
                            skillsList={skillsList}
                            removeSkill={removeSkill}
                            updateNestSkill={updateNestSkill}
                        />
                    </li>
                ))}
            </ul>
            {popUpPortal && (
                <Dialog
                    id="portal-edit-root"
                    dom={(
                        <PortalPopUpSkillAddWithNest
                            closePortal={() => togglePopUpPortal(!popUpPortal)}
                            text={`весь раздел «${(currentSkill.name)}»`}
                            removeSkill={removeSkill}
                            skillId={currentSkill.id}
                        />
                    )}
                />
            )}
            {isEditPortal && (
                <Dialog
                    id="portal-edit-root"
                    dom={(
                        <SkillCardWithNestChildrenEdit
                            size="middle"
                            skillTypes={skillTypes}
                            skillsList={skillsList}
                            closePortal={() => toggleIsEditPortal(!isEditPortal)}
                            updateNestSkill={updateNestSkill}
                        />
                    )}
                />
            )}
        </div>
    );
}
