import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { ApolloError } from '@apollo/client';
import { reject } from 'lodash';

import { BorderCrossIcon, EditIcon } from '@common/svg';
import { Loader, LoaderSizes } from '@common/Loader';
import { Confirm } from '@common/Confirm';
import { OnlineRoom as OnlineRoomInterface } from './interfaces';
import { EdibleOnlineRoom } from './EdibleOnlineRoom';
import { useRemoveOnlineRoomMutation } from './useRemoveOnlineRoomMutation';
import { useUpdateOnlineRoomMutation } from './useUpdateOnlineRoomMutation';

import classes from './OnlineRoom.module.scss';

interface Props {
    onlineRoom: OnlineRoomInterface;
    refetchList(): void;
    setOnlineRoom(onlineRooms?: OnlineRoomInterface): void;
    onError(error: ApolloError): void;
}

export function OnlineRoom({ onlineRoom, refetchList, setOnlineRoom, onError }: Props) {
    const [isEdible, setEdible] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const changeEdited = () => setEdible(prev => !prev);
    const changeDeleted = () => setDeleted(prev => !prev);
    const { updateOnlineRoom, loading: updateLoading } = useUpdateOnlineRoomMutation(
        refetchList, onError,
    );
    const { removeOnlineRoom, loading: removeLoading } = useRemoveOnlineRoomMutation(
        onlineRoom.id, refetchList, onError,
    );
    const isLoading = updateLoading || removeLoading;

    return (
        isEdible
            ? (
                <EdibleOnlineRoom
                    onlineRoom={onlineRoom}
                    onSubmit={(item: OnlineRoomInterface) => {
                        updateOnlineRoom(item)
                            .then(res => {
                                if (res.errors) {
                                    reject(new Error());
                                } else {
                                    changeEdited();
                                    setOnlineRoom(item);
                                }
                            })
                            .catch();
                    }}
                    onCancel={changeEdited}
                    loading={isLoading}
                />
            ) : (
                <>
                    <div className={cn(classes.onlineRoom_url, classes.onlineRoom)}>
                        {onlineRoom.url}
                    </div>
                    <div className={cn(classes.onlineRoom, classes.onlineRoom_capacity)}>
                        {onlineRoom.capacity ?? 'Неограничено'}
                    </div>
                    <div className={cn(classes.onlineRoom, classes.onlineRoom__icons)}>
                        {isLoading
                            ? <Loader size={LoaderSizes.small} />
                            : (
                                <>
                                    <EditIcon handler={changeEdited} />
                                    <BorderCrossIcon size={24} handler={changeDeleted} />
                                </>
                            )
                        }
                    </div>
                    {deleted && (
                        createPortal(
                            (
                                <Confirm
                                    headerText="Вы уверены что хотите удалить это онлайн-помещение?"
                                    descriptionText={onlineRoom.url}
                                    primaryBtnText="Удалить"
                                    secondaryBtnText="Отменить"
                                    onPrimaryBtnClick={() => {
                                        removeOnlineRoom()
                                            .then(res => {
                                                if (res.errors) {
                                                    reject(new Error());
                                                } else {
                                                    changeDeleted();
                                                    setOnlineRoom();
                                                }
                                            })
                                            .catch();
                                    }}
                                    onSecondaryBtnClick={changeDeleted}
                                    onOutClick={changeDeleted}
                                />
                            ),
                            document.body,
                        )
                    )}
                </>
            )
    );
}
