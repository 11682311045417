import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { IconDeprecated } from '@common';
import { DaySchedule } from '../../../EducationPeriodParametersAndDatesInterfaces';
import classes from './TimeScheduleSelector.module.scss';
import { TimePicker } from './TimePicker';

interface Props {
    daySchedules: DaySchedule[],
    update(daySchedules: DaySchedule[]):void,
}
const SHOW_TIME_PICKER = 1;
const HIDE_TIME_PICKER = 0;
export function TimeScheduleSelector(
    {
        daySchedules,
        update,
    }: Props,
) {
    const [mode, setMode] = useState(HIDE_TIME_PICKER);
    const toggleMode = () => setMode((oldMode) => (oldMode ? HIDE_TIME_PICKER : SHOW_TIME_PICKER));

    const enhancedUpdate = (newDaySchedules: DaySchedule[]) => {
        update(newDaySchedules);
        toggleMode();
    };
    const timeScheduleSelectorRef = useRef<HTMLDivElement|undefined>(
        undefined,
    ) as React.MutableRefObject<HTMLDivElement>;
    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (timeScheduleSelectorRef.current
                && !timeScheduleSelectorRef.current.contains(event.target as Node)) {
                setMode(HIDE_TIME_PICKER);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [timeScheduleSelectorRef]);

    return (
        <div className={classes.timeScheduleSelector} ref={timeScheduleSelectorRef}>
            <button
                className={classes.timeScheduleSelector__button}
                type="button"
                onClick={toggleMode}
            >
                Редактировать дни и время
                <IconDeprecated
                    id="triangle"
                    className={
                        cn(
                            classes.timeScheduleSelector__triangle,
                            {
                                [classes.timeScheduleSelector__triangle_active]: mode,
                            },
                        )
                    }
                />
            </button>
            {mode ? (<TimePicker update={enhancedUpdate} daySchedules={daySchedules} />) : null}
        </div>
    );
}
