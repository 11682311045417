import { gql } from '@apollo/client';

export const SET_ADMIN_ROLES = gql`
mutation setAdminRoles($adminRoles: UpdateRolesInput!){
    updateAdminRoles(updateAdminRolesInput: $adminRoles){
        id
    }
}`;

export const UPDATE_TEACHER_WORKLOAD = gql`
    mutation updateTeacherWorkload($updateWorkload: UpdateTeacherWorkloadInput!){
        updateTeacherWorkload(updateTeacherWorkloadInput: $updateWorkload){
            id
        }
    }
`;

export const SET_ROLES = gql`
mutation setRoles($setList: UpdateRoleTypesInput!){
    updateRoleTypes(updateRoleTypesInput: $setList){
        id
        roles {
            roleId
            name
            enabled
        }
        teacherId
        teacher {
            id
            workingHours {
                id
              }
          }
    }
}`;

export const SET_STUDENT_WAVES = gql`
mutation updateStudentWaves($updateStudentWaveInput: UpdateStudentWaveInput! ){
    updateStudentWaves(updateStudentWaveInput: $updateStudentWaveInput) {
        id
        module {
            id
        }
    }
}`;

export const LOGIN = gql`
mutation login($id: String!){
    login(id: $id)
}`;

export const UPDATE_TRAJECTORY_DRAFT = gql`
    mutation updateTrajectoryDraft($updateTrajectoryInput: UpdateTrajectoryInput!) {
        updateTrajectoryDraft(updateTrajectoryDraftInput: $updateTrajectoryInput) {
            id
        }
    }
`;

export const UPDATE_TRAJECTORY = gql`
    mutation updateTrajectory($updateTrajectoryInput: UpdateTrajectoryInput!) {
        updateTrajectory(updateTrajectoryInput: $updateTrajectoryInput) {
            id
        }
    }
`;

export const REMOVE_TRAJECTORY = gql`
    mutation removeTrajectory($id: String!) {
        removeTrajectory(id: $id) {
            id
        }
    }
`;

export const PUBLISH_TRAJECTORIES = gql`
    mutation publishTrajectories($ids: [String!]!) {
        publishTrajectories(ids: $ids) {
            id
        }
    }
`;
