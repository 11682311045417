import React from 'react';
import { map } from 'lodash';
import { CreateBuildingInput, TerritorialZone } from '@admin/UniversityPage/types';
import { UniversityBuildingsListItem } from '../UniversityBuildingsListItem';

import classes from './UniversityBuildingsList.module.scss';

interface Props {
    territorialZones: TerritorialZone[];
    handleSetEditedBuilding(building: CreateBuildingInput): void;
}

export function UniversityBuildingsList({
    territorialZones,
    handleSetEditedBuilding,
}: Props): JSX.Element {
    return (
        <>
            {territorialZones.map((zone) => {
                const sortedBuildings = [...zone.buildings]
                    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
                return (
                    <div key={zone.id}>
                        <div className={classes.zone__title}>
                            {`Зона ${zone.name}:`}
                        </div>
                        {map(sortedBuildings, (building) => (
                            <UniversityBuildingsListItem
                                key={building.id}
                                building={{
                                    id: building.id,
                                    name: building.name,
                                    location: building.location,
                                    zoneId: zone.name,
                                }}
                                handleSetEditedBuilding={handleSetEditedBuilding}
                            />
                        ))}
                    </div>
                );
            })}
        </>
    );
}
