import React from 'react';
import { format } from 'date-fns';

import { KeyValue } from '@common/KeyValue';
import classes from './UserCommonInfo.module.scss';
import { RolesPicker } from '../RolesPicker';
import { Role } from '../models';

interface Props {
    birthday?: string,
    email?: string,
    roles: Role[],
    edit: boolean,
}

export const UserCommonInfo = ({
    birthday, email, roles, edit,
}: Props): JSX.Element => (
    <div className={classes.userCommonInfo__block}>
        <div className={classes.userCommonInfo__block}>
            <RolesPicker roles={roles} edit={edit} />
        </div>
        <div>
            <div>
                <KeyValue
                    title="Дата рождения: "
                >
                    {birthday && format(Number(birthday), 'dd.MM.yyyy')}
                </KeyValue>
                <KeyValue
                    title="e-mail: "
                >
                    {email}
                </KeyValue>
            </div>
        </div>
    </div>
);
