import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import { Loader } from '@common/Loader';

import { ExternalModuleList } from './ExternalModuleList';
import { deleteExternalModule, deleteExternalModuleVariables, listExternalModules, listExternalModulesVariables } from '../apollo-types';

const LIST_EXTERNAL_MODULE = gql`
    query listExternalModules($externalModulesInput: ExternalModulesInput) {
        externalModules(externalModulesInput: $externalModulesInput) {
            id
            status
            ownerFullName
            ownerEmail
            ownerInfo
            name
            description
            minStudentCount
            maxStudentCount
            maxWaveCount
            creditCount
            syllabusReference
            reviews {
                id
                message
                reviewer {
                    id
                    firstName
                    lastName
                    patronymic
                }
            }
        }
    }
`;

const DELETE_EXTERNAL_MODULE = gql`
    mutation deleteExternalModule($externalModuleId: String!) {
        updateExternalModuleStatus(updateExternalModuleStatusInput: { status: deleted, externalModuleId: $externalModuleId }) {
            id
        }
    }
`;

export function ExternalModuleListApollo() {
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<listExternalModules, listExternalModulesVariables>(
        LIST_EXTERNAL_MODULE,
        { fetchPolicy: 'network-only' },
    );

    const [removeExternalModule] = useMutation<deleteExternalModule, deleteExternalModuleVariables>(
        DELETE_EXTERNAL_MODULE,
        // eslint-disable-next-line no-console
        {
            onError: err => console.error(err),
            onCompleted: () => {
                onCancelRemove();
                refetch();
            },
        },
    );
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [removableModuleId, setRemovableModuleId] = useState<string>();

    function onRemoveButtonClick(moduleId: string) {
        setPopupVisibility(true);
        setRemovableModuleId(moduleId);
    }

    function onRemoveConfirmation() {
        removeExternalModule({
            variables: { externalModuleId: removableModuleId! },
        });
    }

    function onCancelRemove() {
        setPopupVisibility(false);
        setRemovableModuleId(undefined);
    }

    if (error) {
        return (
            <div>
                Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}
            </div>
        );
    }

    if (loading) {
        return <Loader />;
    }

    if (data?.externalModules.length === 0) {
        return (
            <div>Здесь появится список Внешних модулей.</div>
        );
    }

    return (
        <ExternalModuleList
            moduleList={data?.externalModules}
            isPopupVisible={isPopupVisible}
            onConfirmRemove={onRemoveConfirmation}
            onCancelRemove={onCancelRemove}
            onRemoveButtonClick={onRemoveButtonClick}
        />
    );
}
