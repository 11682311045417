import React, { useState } from 'react';
import { FetchResult } from '@apollo/client';

import { ActionButton } from '@common/ActionButton';
import { BorderCrossIcon } from '@common/svg';
import { EquipmentForm } from '../EquipmentForm';

import classes from './EquipmentGroups.module.scss';

interface Element {
    [index: string]: any;
    id: string;
    name: string;
    count: number;
    isMovable?: boolean;
    territorialZoneId?: string | null;
}

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

interface TerritorialZone {
    id: string;
    name: string;
}

interface Props {
    setDialogView: React.Dispatch<React.SetStateAction<string>>;
    element: Element;
    upd: (equipments: Element) => ApolloPromise;
    territorialZones?: TerritorialZone[];
}

export function EquipmentGroupDialog({
    setDialogView,
    element,
    upd,
    territorialZones = [],
}: Props): JSX.Element {
    const [name, setName] = useState(element.name);
    const [count, setCount] = useState(element.count);
    const [isMovable, setIsMovable] = useState(element.isMovable);
    const [territorialZoneId, setTerritorialZoneId] = useState(element.territorialZoneId);
    const updateEquipment = () => {
        const { id } = element;
        if (name.trim()) {
            upd({
                id,
                name,
                count,
                isMovable,
                territorialZoneId,
            });
        }
    };
    const handleInputChange = (index: number, inputName: any, value: string) => {
        if (inputName === 'name') {
            setName(value);
        } else {
            setCount(parseInt(value === '' ? '-1' : value, 10));
        }
    };
    const handleDeleteInput = () => {
        setCount((oldCount) => (oldCount - 1));
    };
    const handleIsMovableChange = () => {
        setIsMovable((oldIsMovable) => !(oldIsMovable));
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleZoneSelectChange = (__index: number, territorialZoneIdArg: string|null) => {
        setTerritorialZoneId(territorialZoneIdArg);
    };
    return (
        <div
            className={classes.equipmentGroupDialog}
            onClick={() => setDialogView('-1')}
        >
            <div
                className={classes.equipmentGroupDialog__container}
                onClick={e => e.stopPropagation()}
            >
                <div className={classes.equipmentGroupDialog__content}>
                    <h2>Редактировать оборудование</h2>
                    <div className={classes.equipmentGroupDialog__inputsContainer}>
                        <EquipmentForm
                            equipmentIndex={0}
                            equipment={{
                                id: element.id,
                                name,
                                count,
                                isMovable,
                                territorialZoneId,
                            }}
                            handleDeleteInput={handleDeleteInput}
                            handleInputChange={handleInputChange}
                            handleZoneSelectChange={handleZoneSelectChange}
                            handleIsMovableChange={handleIsMovableChange}
                            warn={false}
                            territorialZones={territorialZones}

                        />
                    </div>
                    <div
                        className={classes.equipmentGroupDialog__button}
                        onClick={() => updateEquipment()}
                    >
                        <ActionButton>
                            Изменить
                        </ActionButton>
                    </div>
                    <div
                        className={classes.equipmentGroupDialog__iconContainer}
                        onClick={() => setDialogView('-1')}
                    >
                        <BorderCrossIcon
                            size={30}
                            className={classes.equipmentGroupDialog__icon}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
