import React, { ReactElement } from 'react';

import classes from './Subspaces.module.scss';

interface Props {
    children: ReactElement;
}

export function FinishSelectPointPopup({ children }: Props) {
    return (
        <div className={classes.popup}>
            <div className={classes.popup__container}>
                {children}
            </div>
        </div>
    );
}
