import React, { useState } from 'react';
import {
    gql,
    useQuery,
    useLazyQuery,
    useMutation,
    ApolloError,
} from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Alert } from '@common/Alert';
import { EducationPeriodParametersAndDates } from './EducationPeriodParametersAndDates';
import {
    Space,
    DaySchedule,
    StudentWorkload,
    CreateSpaceBaseTimeIntervalParameters,
    UpdateSpaceBaseTimeIntervalParameters,
    Mode,
} from './EducationPeriodParametersAndDatesInterfaces';

const GET_SPACES = gql`
    query getSpaces {
        spaces {
          id
          name
          baseTimeIntervalType {
            id
            name
          }
          spaceBaseTimeIntervals {
            id
            order
            baseTimeIntervalInstances {
                id
                selectionStatus
            }
          }
          spaceParameters {
            daySchedules {
              weekDay
              workingIntervals {
                id
                start
                end
              }
            }
            studentWorkload {
              maxHoursPerDay
              maxDaysPerWeek
              maxHoursPerSpaceBaseTimeInterval
            }
          }
          spaceEducationPeriods {
            id
            name
            spaceBaseTimeIntervalParameters {
              spaceBaseTimeIntervalId
              baseTimeIntervalInstanceId
              subspaceSelectionStartDate
              subspaceSelectionEndDate
              moduleSelectionStartDate
              moduleSelectionEndDate
              spaceBaseTimeIntervalStart
              spaceBaseTimeIntervalEnd
              moduleAssessment {
                  id
                  isDynamicStartDate
                  startDate
                  endDate
              }
            }
          }
        }
      }
`;

const UPDATE_PARAMETERS = gql`
    mutation updateSpaceParameters($updateSpaceParametersInput: UpdateSpaceParametersInput!) {
        updateSpaceParameters(updateSpaceParametersInput: $updateSpaceParametersInput) {
            daySchedules {
                weekDay
            }
        }
    }
`;

const UPDATE_PERIOD = gql`
    mutation updateSpaceEducationPeriod($updateSpaceEducationPeriodInput: UpdateSpaceEducationPeriodInput!) {
        updateSpaceEducationPeriod(updateSpaceEducationPeriodInput: $updateSpaceEducationPeriodInput) {
            id,
            name
        }
    }
`;
const CREATE_PERIOD = gql`
    mutation createSpaceEducationPeriod($createSpaceEducationPeriodInput: CreateSpaceEducationPeriodInput!) {
        createSpaceEducationPeriod(createSpaceEducationPeriodInput: $createSpaceEducationPeriodInput) {
            id,
            name
        }
    }
`;

const GET_AVTOR_SCHEDULE = gql`
    query GetAvtorSchedule($weekNumber: Int!) {
        avtorSchedule(weekNumber: $weekNumber) {
            schedule
            weekNumber
        }
    }
`;

export function EducationPeriodParametersAndDatesApollo(
    { isSecret = false }: { isSecret?: boolean },
) {
    const [datesMode, setDatesMode] = useState<Mode>(Mode.VIEW);
    const [paratmetersMode, setParametersMode] = useState(Mode.VIEW);
    const [redirect, setRedirect] = useState<JSX.Element | null>(null);

    const [
        spaceParametersErrorAlerts,
        setSpaceParametersErrorAlerts,
    ] = useState<JSX.Element[]>([]);
    const addError = (message: string) => setSpaceParametersErrorAlerts((arr) => [...arr, (<Alert
        key={nanoid()}
        message={message}
    />)]);

    const redirectToSpaces = () => setTimeout(
        () => setRedirect((<Redirect to="/educational-space/new" />)),
        2500,
    );

    const isGetSpacesCompleted = () => {
        if (!(data?.spaces?.length)) {
            addError('Нужно создать пространства');
            redirectToSpaces();
        }
    };

    const { data, refetch: refetchGetSpaces } = useQuery<{
        spaces: Space[];
    }>(GET_SPACES, {
        fetchPolicy: 'cache-and-network',
        onError: (error) => addError(error.message),
        onCompleted: isGetSpacesCompleted,
    });

    const apolloParamatersMutationProps = {
        refetchQueries: [{ query: GET_SPACES }],
        onError: (error: ApolloError) => addError(error.message),
        onCompleted: () => setParametersMode(Mode.VIEW),
    };

    const apolloDatesMutationProps = {
        ...apolloParamatersMutationProps,
        onCompleted: () => setDatesMode(Mode.VIEW),
    };

    const [updateParametersMutation] = useMutation(
        UPDATE_PARAMETERS,
        apolloParamatersMutationProps,
    );

    const [updatePeriodMutation] = useMutation(UPDATE_PERIOD, apolloDatesMutationProps);
    const [createPeriodMutation] = useMutation(CREATE_PERIOD, apolloDatesMutationProps);
    const [getAvtorSchedule, {
        data: avtorSchedule,
        error: avtorScheduleError,
    }] = useLazyQuery(
        GET_AVTOR_SCHEDULE, { fetchPolicy: 'network-only' },
    );

    const updateParameters = (
        spaceId: string,
        daySchedules: DaySchedule[],
        studentWorkload: StudentWorkload,
    ) => updateParametersMutation({
        variables: {
            updateSpaceParametersInput: {
                spaceId,
                daySchedules,
                studentWorkload,
            },
        },
    }).catch((error) => addError(JSON.stringify(error)));

    const updatePeriod = (
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: UpdateSpaceBaseTimeIntervalParameters[],
    ) => updatePeriodMutation({
        variables: {
            updateSpaceEducationPeriodInput: {
                id,
                spaceId,
                name,
                spaceBaseTimeIntervalParameters,
            },
        },
    }).catch((error) => addError(JSON.stringify(error)));

    const createPeriod = (
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: CreateSpaceBaseTimeIntervalParameters[],
    ) => createPeriodMutation({
        variables: {
            createSpaceEducationPeriodInput: {
                id,
                spaceId,
                name,
                spaceBaseTimeIntervalParameters,
            },
        },
    }).catch((error) => addError(JSON.stringify(error)));

    if (!data) {
        return null;
    }

    if (!data?.spaces?.length) {
        return (
            <div>
                Пространства не заданы
            </div>
        );
    }

    return (
        <>
            <EducationPeriodParametersAndDates
                spaces={data.spaces}
                datesMode={datesMode}
                parametersMode={paratmetersMode}
                avtorSchedule={avtorSchedule?.avtorSchedule}
                isSecret={isSecret}
                updateParameters={updateParameters}
                createPeriod={createPeriod}
                updatePeriod={updatePeriod}
                setDatesMode={setDatesMode}
                setParametersMode={setParametersMode}
                getAvtorSchedule={weekNumber => getAvtorSchedule({ variables: { weekNumber } })}
                refetchGetSpaces={refetchGetSpaces}
            />

            {spaceParametersErrorAlerts}
            {redirect}

            {avtorScheduleError?.graphQLErrors?.[0].message
                && (
                    <Alert message={avtorScheduleError?.graphQLErrors?.[0].message} time={5000} />
                )}
        </>
    );
}
