import React from 'react';

import classes from './ModuleMainCharactersDescription.module.scss';

import {
    Module as ModuleType,
} from '../../subSpaceTypes';

interface Props {
    currentModule: ModuleType;
}

export function ModuleMainCharactersDescription({ currentModule }: Props):JSX.Element {
    return (
        <div className={classes.moduleMainCharactersDescription}>
            <div className={classes.moduleMainCharactersDescription__title}>
                Описание:
            </div>
            {currentModule.description ? (
                <div
                    className={classes.moduleMainCharactersDescription__text}
                    dangerouslySetInnerHTML={{ __html: currentModule.description }}
                />
            ) : (
                <div className={classes.moduleMainCharactersDescription__empty}>
                    Описание отсутствует
                </div>
            )}
        </div>
    );
}
