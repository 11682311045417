import React from 'react';

import { SpaceSubspaceDiagram } from '@common/SpaceSubspaceDiagram';

import classes from './TittleAndSettings.module.scss';

import {
    Space,
    Subspace,
    SubspaceType,
} from '../../subSpaceTypes';

interface Props {
    editState: boolean;
    minStudentCount: number;
    maxStudentCount:number;
    setMinStudentCount: (int: number) => void;
    setMaxStudentCount: (int: number) => void;
    description: string | number | readonly string[] | undefined;
    setDescription: (str: string) => void;
    space: Space;
    subspace: Subspace;
    subspaceType: SubspaceType;
}

export function TittleAndSettings({
    editState,
    minStudentCount,
    maxStudentCount,
    setMinStudentCount,
    setMaxStudentCount,
    description,
    setDescription,
    space,
    subspace,
    subspaceType,
}: Props): JSX.Element {
    return (
        <div className={classes.subSpaceTittleAndSettings}>
            <div className={classes.subSpaceTittleAndSettings__tittle}>
                {`Образовательное подпространство
                ${subspaceType.name} “${subspace.name}”`}
            </div>
            {/* <ul className={classes.subSpaceTittleAndSettings__settingsList}>
                <li className={classes.subSpaceTittleAndSettings__settingsItem}>
                    Этому подпространству доступно <b>32 ЗЕТ (20/12)</b>
                </li>
                <li className={classes.subSpaceTittleAndSettings__settingsItem}>
                    Момент выбора подпространства: <b>после 2 семестра</b>
                </li>
                <li className={classes.subSpaceTittleAndSettings__settingsItem}>
                    Временной отрезок подпространства: <b>3 и 4 семестр</b>
                </li>
                <li className={classes.subSpaceTittleAndSettings__settingsItem}>
                    Подпространству доступны <b>2 точка выбора</b>
                </li>
            </ul> */}
            {editState && (
                <>
                    <div className={classes.subSpaceTittleAndSettings__diagram}>
                        <SpaceSubspaceDiagram
                            space={space}
                            targetSubspaceType={subspaceType}
                        />
                    </div>
                    <div className={classes.subSpaceTittleAndSettings__roominess}>
                        <div className={classes.subSpaceTittleAndSettings__roominessTittle}>
                            Укажите вместимость подпространства:
                        </div>
                        <ul className={classes.subSpaceTittleAndSettings__roominessList}>
                            <li className={classes.subSpaceTittleAndSettings__roominessItem}>
                                min:
                                <input
                                    defaultValue={minStudentCount}
                                    onChange={({
                                        currentTarget,
                                    }) => setMinStudentCount(Number(currentTarget.value))}
                                    type="text"
                                    className={classes.subSpaceTittleAndSettings__roominessInput}
                                    placeholder="кол-во"
                                />
                            </li>
                            <li className={classes.subSpaceTittleAndSettings__roominessItem}>
                                max:
                                <input
                                    defaultValue={maxStudentCount}
                                    onChange={({
                                        currentTarget,
                                    }) => setMaxStudentCount(Number(currentTarget.value))}
                                    type="text"
                                    className={classes.subSpaceTittleAndSettings__roominessInput}
                                    placeholder="кол-во"
                                />
                            </li>
                        </ul>
                    </div>
                    {subspace.type.isDescriptionEnabled && (
                        <div className={classes.subSpaceTittleAndSettings__textareaContainer}>
                            Описание профиля выпускника подпространства:
                            <textarea
                                defaultValue={description}
                                onChange={({
                                    currentTarget,
                                }) => setDescription(currentTarget.value)}
                                name="subspace-description"
                                className={classes.subSpaceTittleAndSettings__textarea}
                                placeholder="Введите описание профиля выпускника подпространства, укажите потенциальные сферы трудоустройства и востребованность таких специалистов"
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
