import React from 'react';

import { Tool, ToolBar } from '@admin/Users/ToolBar';

import { Table, columnConfigs } from './Table';
import { Type } from './apollo-types';
import { useUserList } from './useUserList';
import { FullName } from './Table/userCellInfo';
import { UserRow } from './Table/UserRow';

import classes from './Table/table.module.scss';

export function TeachersTable() {
    const {
        loading,
        isAllSelected,
        isMoreButtonVisible,
        error,
        users,
        selectedUsers,
        onMoreButtonClick,
        onChangeSearchParams,
        onSelect,
        isUserSelected,
        refetchUserList,
        onSelectAll,
        clearPreviousSelected,
    } = useUserList(Type.teacher);

    if (error) {
        return (
            <div>Произошла ошибка: {error.graphQLErrors[0]?.message || error.message}</div>
        );
    }

    return (
        <>
            <div className={classes.toolBar}>
                <ToolBar
                    tools={[Tool.USER_IMPORT]}
                    selectedUsers={selectedUsers}
                    refetchUserList={refetchUserList}
                />
            </div>

            <Table
                setSearchParams={onChangeSearchParams}
                columnConfigs={[
                    columnConfigs.fullName,
                    columnConfigs.status,
                ]}
                onShowMore={onMoreButtonClick}
                isMoreButtonVisible={isMoreButtonVisible}
                clearPreviousSelected={clearPreviousSelected}
                onSelectAll={onSelectAll}
                isAllSelected={isAllSelected}
                loading={loading}
            >
                {
                    users.map(user => {
                        const isEnabled = user.roles?.find(role => role?.name === 'Teacher')?.enabled;

                        return (
                            <UserRow
                                key={user.id}
                                userId={user.id}
                                onChange={() => onSelect(user)}
                                checked={isUserSelected(user)}
                            >
                                <FullName user={user} />
                                <td>{isEnabled ? 'Активен' : 'Неактивен'}</td>
                            </UserRow>
                        );
                    })
                }
            </Table>
        </>
    );
}
