import React, { useState } from 'react';

import { groupBy } from 'lodash';

import classes from './ModuleSkillsList.module.scss';
import { ModuleSkillsListSkillTypes } from './ModuleSkillsListSkillTypes';
import { ModuleSkillsListSpecificSkills } from './ModuleSkillsListSpecificSkills';
import { ModuleSkillsListSwitchParams } from './ModuleSkillsListSwitchParams';

import {
    Module as ModuleType,
} from '../../subSpaceTypes';
import { skills_skills, skillTypesData_skillTypes } from '../../../graphql-query-types';

interface Props {
    currentModule: ModuleType;
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
}

export function ModuleSkillsList({
    currentModule,
    skillTypes,
    allSkillsList,
}: Props):JSX.Element {
    const [paramsState, setParamsState] = useState(true);
    const [skillType, setSkillType] = useState('1');

    const sortedSkills: any = skillTypes
        .filter((skill: skillTypesData_skillTypes) => skill.parentId === null);

    const getFinalSkillTypeId = (typeId: string): string => {
        const skillTypeGroups = groupBy(skillTypes, 'parentId');
        let finalSkillTypeId = typeId;
        while (skillTypeGroups[finalSkillTypeId]) {
            const [child] = skillTypeGroups[finalSkillTypeId];
            finalSkillTypeId = child.id;
        }

        return finalSkillTypeId;
    };

    function isParentSkill(parent: any, child: any): boolean {
        return !!parent.children?.filter((item: any) => item.id !== parent.id)
            .some((item: any) => item.id === child.id);
    }

    function findParentSkill(skill: any): any {
        return allSkillsList.find((item: any) => isParentSkill(item, skill));
    }

    function formatSkillName(skill: any): string {
        let { name } = skill.skill;
        let parent = findParentSkill(skill.skill);

        while (parent) {
            name = `${name}. ${parent.name}`;
            parent = findParentSkill(parent);
        }

        return name;
    }

    const currenSkillTypeParams: any = [];
    const paramsType = paramsState
        ? currentModule.prerequisiteSkills
        : currentModule.outputSkills;
    paramsType.forEach((item: any) => {
        if (skillType === item.skill.typeId) {
            currenSkillTypeParams.push({
                id: item.skill.id,
                name: item.skill.name,
                level: item.level,
            });
        }
        if (getFinalSkillTypeId(skillType) > sortedSkills.length
            && getFinalSkillTypeId(skillType) <= item.skill.typeId) {
            currenSkillTypeParams.push({
                id: item.skill.id,
                name: formatSkillName(item),
                level: null,
            });
        }
    });

    return (
        <div className={classes.moduleSkillsList}>
            <ModuleSkillsListSwitchParams
                paramsState={paramsState}
                setParamsState={setParamsState}
            />
            <div className={classes.moduleSkillsList__content}>
                <ModuleSkillsListSkillTypes
                    skillType={skillType}
                    sortedSkillTypes={sortedSkills}
                    setSkillType={setSkillType}
                />
                <ModuleSkillsListSpecificSkills
                    skillList={currenSkillTypeParams}
                />
            </div>
        </div>
    );
}
