import { useLazyQuery } from '@apollo/client';
import { Room } from '../../ScheduleGenerationPageInterfaces';
import { GET_AVAILABLE_ROOMS } from '../../scheduleQueries';

export const useLoadAvailableRoomsLazyQuery = () => {
    const [
        getAvailableRoomsQuery, { data },
    ] = useLazyQuery<{ availableRooms: Room[] }>(GET_AVAILABLE_ROOMS);

    return {
        loadAvailableRooms: (
            from: string,
            to: string,
            isAvailable: boolean,
            doesEquipmentMatch: boolean,
            equipments: {
                id: string,
                count: number,
            }[],
        ) => {
            getAvailableRoomsQuery(
                {
                    variables: {
                        availableRoomInput: {
                            from,
                            to,
                            isAvailable,
                            doesEquipmentMatch,
                            equipments,
                        },
                    },
                },
            );
        },
        availableRooms: data?.availableRooms,
    };
};
