import React from 'react';
import { observer } from 'mobx-react-lite';
import { flatten, map } from 'lodash';

import { ActionButton } from '@common/ActionButton';
import { ModuleContent } from './ModuleContent';
import { ModuleHeader } from './ModuleHeader';

import classes from './Module.module.scss';

import Diagram from '../store/Diagram';

import {
    Module as ModuleType,
    UpdateModuleInput,
    UpdateSlotDiagramInput,
} from '../subSpaceTypes';
import { skills_skills, skillTypesData_skillTypes } from '../../graphql-query-types';
import { updateForkSettingInput } from '../SubSpaceSlotDiagram/utilities';

interface Props {
    subspaceId: string
    slotId: string
    currentModule: ModuleType;
    skillTypes: skillTypesData_skillTypes[];
    allSkillsList: skills_skills[];
    selectedModule?: ModuleType;
    updateSlotModule: (item: UpdateModuleInput) => void;
    setModuleState: any;

    updateSlotDiagram(updateSlotDiagramInput: UpdateSlotDiagramInput): void
}

export const Module = observer(({
    subspaceId,
    slotId,
    currentModule,
    skillTypes,
    allSkillsList,
    selectedModule,
    updateSlotModule,
    setModuleState,
    updateSlotDiagram,
}: Props): JSX.Element => {
    const { btiSettings } = Diagram;
    const moduleId = currentModule.id;
    const slots = flatten(map(btiSettings, bti => (bti.moduleSlots))) || [];
    const groups = flatten(map(btiSettings, bti => (bti.groupSlots))) || [];
    const forks = flatten(map(btiSettings, bti => (bti.forks))) || [];
    return (
        <div className={classes.module}>
            <ModuleHeader
                currentModule={currentModule}
            />
            <ModuleContent
                currentModule={currentModule}
                skillTypes={skillTypes}
                allSkillsList={allSkillsList}
            />
            <div
                className={classes.module__button}
                onClick={
                    selectedModule
                        ? () => setModuleState(false)
                        : () => {
                            Diagram.updateModule(slotId, currentModule);
                            updateSlotDiagram({
                                subspaceId,
                                slots: slots.map(
                                    slot => ({
                                        id: slot.id,
                                        spaceBaseTimeIntervalId: slot.spaceBaseTimeIntervalId,
                                        row: slot.row,
                                        column: slot.column,
                                        nextSlots: slot.nextSlots.map(
                                            nextSlot => ({
                                                id: nextSlot.id,
                                                spaceBaseTimeIntervalId:
                                                    nextSlot.spaceBaseTimeIntervalId,
                                                row: nextSlot.row,
                                                column: nextSlot.column,
                                            }),
                                        ) || [],
                                        nextForks: slot.nextForks.map(
                                            nextFork => ({
                                                id: nextFork.id,
                                                spaceBaseTimeIntervalId:
                                                    nextFork.spaceBaseTimeIntervalId,
                                                row: nextFork.row,
                                                column: nextFork.column,
                                            }),
                                        ) || [],
                                        nextGroupSlots: slot.nextGroupSlots.map(
                                            nextGroupSlot => ({
                                                id: nextGroupSlot.id,
                                                spaceBaseTimeIntervalId:
                                                    nextGroupSlot.spaceBaseTimeIntervalId,
                                                row: nextGroupSlot.row,
                                                column: nextGroupSlot.column,
                                            }),
                                        ) || [],
                                    }),
                                ),
                                forks: updateForkSettingInput(forks),
                                groupSlots: map(groups, group => ({
                                    id: group.id,
                                    spaceBaseTimeIntervalId: group.spaceBaseTimeIntervalId,
                                    row: group.row,
                                    column: group.column,
                                    nextSlots: group.nextSlots.map(
                                        nextSlot => ({
                                            id: nextSlot.id,
                                            spaceBaseTimeIntervalId:
                                                nextSlot.spaceBaseTimeIntervalId,
                                            row: nextSlot.row,
                                            column: nextSlot.column,
                                        }),
                                    ) || [],
                                    nextForks: group.nextForks.map(
                                        nextFork => ({
                                            id: nextFork.id,
                                            spaceBaseTimeIntervalId:
                                                nextFork.spaceBaseTimeIntervalId,
                                            row: nextFork.row,
                                            column: nextFork.column,
                                        }),
                                    ) || [],
                                    nextGroupSlots: group.nextGroupSlots.map(
                                        nextGroupSlot => ({
                                            id: nextGroupSlot.id,
                                            spaceBaseTimeIntervalId:
                                                nextGroupSlot.spaceBaseTimeIntervalId,
                                            row: nextGroupSlot.row,
                                            column: nextGroupSlot.column,
                                        }),
                                    ) || [],
                                })),
                            });
                            updateSlotModule({ slotId, moduleId });
                            setModuleState(true);
                        }
                }
            >
                <ActionButton>
                    {selectedModule ? 'Заменить модуль' : 'Выбрать модуль'}
                </ActionButton>
            </div>
        </div>
    );
});
