import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { CommonTargetName, useSendMetriks } from '@common/hooks';
import { FilterButton } from './FilterButton';
import { FilterOption } from './FilterOption';
import { FilterType } from './filterType';
import { eventsStore } from '../../Store';
import { accountStore } from '../../../Store';
import { ViewType } from '../../../../graphql-query-types';

import classes from './EventFilterMenu.module.scss';

export const EventFilterMenu = observer(() => {
    const refMeetingFilter = useRef<null | HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { sendYandexMetrika } = useSendMetriks([ViewType.Student, ViewType.Teacher]);

    function closeFilterMenu(): void {
        setIsOpen(false);
    }

    function handleToggleFilterMenu(): void {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    }

    function handleChangeFilterType(filterType: FilterType): void {
        eventsStore.setFilterType(filterType);
        closeFilterMenu();
        const isCommonFilterType = (
            (filterType === FilterType.ALL) || (filterType === FilterType.MEETING));
        if (isCommonFilterType) {
            sendYandexMetrika(
                CommonTargetName.schedule_filter_common,
                { [CommonTargetName.schedule_filter_common]: filterType },
            );
        } else {
            sendYandexMetrika(
                CommonTargetName.schedule_filter_common,
                {
                    [CommonTargetName.schedule_filter_common]: filterType,
                    role: ViewType.Student,
                },
            );
            sendYandexMetrika(
                CommonTargetName.schedule_filter_common,
                {
                    [CommonTargetName.schedule_filter_common]: filterType,
                    role: ViewType.Teacher,
                },
            );
        }
    }

    // useOnClickOutside(refMeetingFilter, closeFilterMenu);

    return (
        <div className={classes.meetingFilter} ref={refMeetingFilter}>
            <FilterButton
                isOpen={isOpen}
                handleToggleFilterMenu={handleToggleFilterMenu}
            />

            {isOpen && (
                <div className={classes.menu}>
                    <FilterOption
                        filterType={FilterType.ALL}
                        handleChangeFilterType={handleChangeFilterType}
                    />
                    <FilterOption
                        filterType={FilterType.MEETING}
                        handleChangeFilterType={handleChangeFilterType}
                    />
                    {accountStore.isTeacher() && (
                        <FilterOption
                            filterType={FilterType.EVALUATION_POINT}
                            handleChangeFilterType={handleChangeFilterType}
                        />
                    )}
                    {accountStore.isStudent() && (
                        <FilterOption
                            filterType={FilterType.ASSIGNMENT}
                            handleChangeFilterType={handleChangeFilterType}
                        />
                    )}
                </div>
            )}
        </div>
    );
});
