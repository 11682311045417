import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';

import { BorderCrossIcon, BorderPlusIcon } from '@common/svg';
import { Alert } from '@common/Alert';
import { SkillLevelEditing } from '@admin/SkillPage/SkillLevelEditing';

import NestedSkill from '../store/NestedSkill';
import { checkChild, getSkillTypeById } from './SkillAddFunctions';

import classes from './SkillAddWithNest.module.scss';

import { skillTypesData_skillTypes } from '../../../../graphql-query-types';

interface IProps {
    skillTypes: skillTypesData_skillTypes[];
    currentSkillType: skillTypesData_skillTypes;
    skill: any;
}

export const SkillAddWithNest = observer(({ skillTypes, skill, currentSkillType }: IProps) => {
    const [active, setActive] = useState(0);
    const [error, setError] = useState('');

    const hasLevel = currentSkillType.properties?.maxLevel !== null;
    const nameError = 'Напишите название образовательного результата';
    const { hasChild, skillChild } = checkChild(skillTypes, currentSkillType.id);
    const { skillType } = getSkillTypeById(skillTypes, skill.typeId);
    let str = '';
    if (!hasChild && skill.children?.length > 0) {
        str = `${currentSkillType.name.nominativePlural} ${skillType.name.genitiveSingular.toLowerCase()}:`;
    }

    const blurHandler = (item: any) => {
        if (item.name === '') {
            NestedSkill.notValid.set(item.id, true);
        }
    };

    const onChangeName = (currentTarget: any, item: any) => {
        if (!currentTarget.value) {
            NestedSkill.notValid.set(item.id, true);
        } else {
            NestedSkill.notValid.set(item.id, false);
        }

        NestedSkill.update(item.id, {
            name: currentTarget.value,
            typeId: currentSkillType.id,
        });
    };

    const addChild = () => {
        if (!hasChild || !skill.children || skill.children[active]?.name !== '') {
            NestedSkill.addSkill(skill.id, {
                id: nanoid(),
                name: '',
                typeId: currentSkillType.id,
                maxLevel: hasLevel ? 1 : undefined,
            });

            setActive(skill.children?.length - 1);
        } else {
            setError(`Сначала введите имя текущего ${currentSkillType.name.genitiveSingular}!`);
        }
    };

    const deleteChild = (id: string) => {
        NestedSkill.removeSkill(id, skill.id);

        if (active !== 0) {
            setActive(active - 1);
        }
    };

    return (
        <div className={classes['skill-add-with-nest']}>
            <div className={classes['skill-add-with-nest__text-separator']}>{str}</div>

            {skill.children?.map((item: any, index: number) => (
                <div key={item.id} className={classes['skill-add-with-nest__item']}>
                    <div className={classes['skill-with-nest__head']}>
                        <div className={classes['skill-add-with-nest__head-number']}>
                            {index + 1}
                        </div>

                        <div
                            className={
                                active === index || !hasChild
                                    ? classes['skill-add-with-nest__head-title--hidden']
                                    : classes['skill-add-with-nest__head-title']
                            }
                            onClick={() => {
                                if (skill.children?.[active].name === '') {
                                    setError(`Для начала введите имя текущего ${currentSkillType.name.genitiveSingular}!`);
                                } else {
                                    setActive(index);
                                }
                            }}
                        >
                            {item.name}
                        </div>

                        <div className={(skill.children?.length > 1 || !hasChild)
                            ? classes['skill-with-neat__button-delete']
                            : classes['skill-with-nest__button-delete--hidden']}
                        >

                            <BorderCrossIcon handler={() => deleteChild(item.id)} />
                        </div>
                    </div>

                    <div className={classes['skill-add-with-nest__input-block']}>
                        <div
                            className={
                                active === index || !hasChild
                                    ? classes.item__input__group
                                    : classes['skill-with-nest__hidden']
                            }
                        >
                            <div className={classes['skill-add-with-nest__section-wrapper']}>
                                <div className={classes.item__input__group}>
                                    <label className={classes['skill-add-with-nest__label']}>
                                        {!NestedSkill.notValid.get(item.id)
                                            ? (
                                                <>
                                                    Название {(
                                                        currentSkillType.name.genitiveSingular
                                                    )?.toLowerCase()}:
                                                </>
                                            )
                                            : (
                                                <div style={{ color: 'red', width: '130%' }}>
                                                    {nameError}
                                                </div>
                                            )
                                        }

                                        <input
                                            id="nest-name"
                                            className={!NestedSkill.notValid.get(item.id)
                                                ? classes['skill-add-with-nest__input']
                                                : classes['skill-add-with-nest__input--red-border']
                                            }
                                            value={item.name}
                                            onBlur={() => blurHandler(item)}
                                            onChange={
                                                ({
                                                    currentTarget,
                                                }) => onChangeName(currentTarget, item)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>

                            {hasLevel && (
                                <SkillLevelEditing
                                    currentLevel={item.maxLevel}
                                    maxLevel={Number(currentSkillType.properties!.maxLevel)}
                                    onChange={maxLevel => NestedSkill.update(item.id, { maxLevel })}
                                />
                            )}

                            {hasChild ? (
                                <SkillAddWithNest
                                    skillTypes={skillTypes}
                                    skill={item}
                                    currentSkillType={skillChild}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            ))}

            <div className={classes['skill-with-nest__bottom-icon-container']} onClick={() => addChild()}>
                <BorderPlusIcon />

                <div className={classes['skill-with-nest__icon-text']}>
                    {`${currentSkillType.name.nominativeSingular}`}
                </div>
            </div>

            {error && <Alert message={error} onTimeout={() => setError('')} />}
        </div>
    );
});
