import React, { useState } from 'react';
import cn from 'classnames';

import { PointIcon } from '@common/svg/PointIcon';
import { PointDisabledIcon } from '@common/svg/PointDisabledIcon';
import { StudentTargetName, useSendMetriks } from '@common/hooks';

import { ModuleSkill, Skill, StudentSkill } from '../../profileModels';
import { ShowNestSkillArrow } from './ShowNestSkillArrow';
import { ViewType } from '../../../graphql-query-types';

import classes from '../StudentProfile.module.scss';

interface Props {
    skill?: Skill;
    allStudentSkills: Skill[];
    allModulesSkillWhithParent: Skill[];
    maxSkills: StudentSkill[];
    targetSkills: ModuleSkill[];
}

export const StudentSkillsWhithNest = React.memo(({
    skill,
    allStudentSkills,
    allModulesSkillWhithParent,
    maxSkills,
    targetSkills,
}: Props) => {
    const [isShow, setIsShow] = useState(false);

    const { sendYandexMetrika } = useSendMetriks([ViewType.Student]);

    function getSkillChild(id: string) {
        return allModulesSkillWhithParent.find(item => item.id === id);
    }
    if (!skill) {
        return null;
    }

    const isDisableSkill = !allStudentSkills.includes(skill);
    const { children, name, id } = skill;
    const sortChildren = [
        ...children.filter(child => allStudentSkills.map((item) => item.id).includes(child.id)),
        ...children.filter(child => !allStudentSkills.map((item) => item.id).includes(child.id)),
    ];

    function getMaxStudentLevel() {
        return maxSkills.find(item => item.skill.id === id)?.level ?? 0;
    }
    function getTargetMaxlevel() {
        return targetSkills.find(item => item.skill.id === id)?.level ?? 0;
    }
    return (children.length > 0) ? (
        <div key={id}>
            <div
                className={cn({
                    [classes.student__skill_fost]: !skill.parent,
                    [classes.student__skill_middle]: skill.parent,
                    [classes.student__skill_disable]: isDisableSkill,
                })}
                onClick={() => {
                    setIsShow(!isShow);
                    sendYandexMetrika(StudentTargetName.expand_knowledge);
                }}
            >
                <ShowNestSkillArrow isOpen={isShow} />
                {name}
            </div>
            {isShow && sortChildren.map(child => (
                <StudentSkillsWhithNest
                    key={child.id}
                    skill={getSkillChild(child.id)}
                    allStudentSkills={allStudentSkills}
                    allModulesSkillWhithParent={allModulesSkillWhithParent}
                    maxSkills={maxSkills}
                    targetSkills={targetSkills}
                />
            ))}
        </div>
    ) : (
        <div className={cn(classes.student__skill_terminal,
            { [classes.student__skill_disable]: isDisableSkill })}
        >
            {isDisableSkill ? (
                <PointDisabledIcon className={classes.student__skill_point} />
            ) : (
                <PointIcon className={classes.student__skill_point} />
            )}
            <div className={cn(classes.student__skill,
                { [classes.student__skill_disable]: isDisableSkill })}
            >
                {name}
                {skill.maxLevel ? (
                    <div className={classes.student__skill_level}>
                        Уровень:
                        <div className={cn(classes.student__skill_level_num, {
                            [classes.student__module_skill_level]: isDisableSkill,
                        })}
                        >
                            {getMaxStudentLevel()}
                            /
                            <div className={classes.student__module_skill_level}>
                                {getTargetMaxlevel()}
                            </div>
                        </div>
                    </div>
                ) : ''}
            </div>
        </div>
    );
});
