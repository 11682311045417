import { sortBy } from 'lodash';

import { ScheduleEvent } from '../types';
import { CellData, ITableModule } from './ScheduleTableInterfaces';

type Module = {
    id: string;
    name: string;
};

type Meeting = {
    id: string;
    topic: string;
    order: number;
};

type Wave = {
    id: string;
    index: number;
};

type PlanningMeetingInstance = {
    id: string;
    waveId: string;
    meetingId: string;
    scheduleGenerationProcessId: string;
};

function buildScheduleTable(
    module: Module,
    meetings: Meeting[],
    waves: Wave[],
    meetingInstances: ScheduleEvent[],
    planningMeetingInstances: PlanningMeetingInstance[],
): ITableModule {
    const findEvent = (waveId: string, meetingId: string): CellData => {
        let result: CellData | undefined = planningMeetingInstances
            .find(item => item.waveId === waveId && item.meetingId === meetingId);

        if (!result) {
            result = meetingInstances.find(item => item.waves.some(wave => wave.id === waveId)
                && item.meeting.id === meetingId);
        }

        return result ?? null;
    };

    const meetingsSortByOrder = sortBy(meetings, 'order');
    const wavesSortByIndex = sortBy(waves, 'index');
    const tableModule: ITableModule = {
        id: module.id,
        name: module.name,
        waves: wavesSortByIndex,
        meetings: meetingsSortByOrder,
        waveMeetingMatrix: meetingsSortByOrder
            .map(meeting => wavesSortByIndex.map(wave => findEvent(wave.id, meeting.id))),
    };

    return tableModule;
}

export { buildScheduleTable };
