import { gql } from '@apollo/client';

export interface CurrentUserData {
    getCurrentUser: {
        studentId: string;
    };
}

export const GET_ROLES = gql`
    query getRoles {
        roles {
            id
            name
        }
    }
`;

export const GET_CURRENT_USER = gql`
    query getCurrentUser {
        getCurrentUser {
            id
            firstName
            lastName

            roles {
                roleId
                name
                text
                enabled
            }

            availableInterfaceSections

            student{
                notifications
            }

            studentId
            teacherId
            acceptedAgreement
        }
    }
`;

export const GET_SKILLS_TYPE = gql`
query skillTypesData {
    skillTypes {
        id

        name {
            english
            nominativeSingular
            nominativePlural
            genitiveSingular
            genitivePlural
        }

        parentId

        properties{
            maxLevel
            description
        }

        possibleDependencies
    }
}`;

export const GET_USER_INFO = gql`
    query getUserById($id: String!) {
        user(id: $id) {
            id
            lastName
            firstName
            patronymic
            birthday
            email
            status
            teacherId
            studentId

            roles {
                name
                text
                enabled
            }

            isAdmin

            teacher {
                id
                status

                nonWorkingHours {
                    id
                    reason
                    from
                    to
                }

                workingHours {
                    id
                    to
                    from
                    day
                    weekType
                }
            }

            student {
                id
                status

                targetSkills {
                    level

                    skill {
                        id
                        name
                        fullName
                        typeId
                        maxLevel
                        description

                        parent {
                            id
                            name
                            fullName
                            typeId
                            maxLevel
                            description

                            parent {
                                id
                                name
                                fullName
                                typeId
                                maxLevel
                                description

                                parent {
                                    id
                                    name
                                    fullName
                                    typeId
                                    maxLevel
                                    description

                                    parent {
                                        id
                                    }

                                    children {
                                        id
                                        name
                                        fullName
                                        typeId
                                        maxLevel
                                        description
                                    }
                                }

                                children {
                                    id
                                    name
                                    fullName
                                    typeId
                                    maxLevel
                                    description
                                }
                            }

                            children {
                                id
                                name
                                fullName
                                typeId
                                maxLevel
                                description
                            }

                        }

                        children {
                            id
                            name
                            fullName
                            typeId
                            maxLevel
                            description
                        }
                    }
                }

                space {
                    name

                    baseTimeIntervalType {
                        name
                    }
                }

                baseTimeIntervalInstances {
                    baseTimeIntervalInstance {
                        spaceBaseTimeInterval {
                            order

                            space {
                                id
                                name

                                baseTimeIntervalType {
                                    name
                                }
                            }
                        }
                    }
                }

                maxSkills {
                    id
                    isCredited
                    level

                    student {
                        id
                    }

                    skill {
                        id
                        name
                        fullName
                        typeId
                        maxLevel
                        description

                        parent {
                            id
                            name
                            fullName
                            typeId
                            maxLevel
                            description

                            parent {
                                id
                                name
                                fullName
                                typeId
                                maxLevel
                                description

                                parent {
                                    id
                                    name
                                    fullName
                                    typeId
                                    maxLevel
                                    description

                                    parent {
                                        id
                                    }

                                    children {
                                        id
                                        name
                                        fullName
                                        typeId
                                        maxLevel
                                        description
                                    }
                                }

                                children {
                                    id
                                    name
                                    fullName
                                    typeId
                                    maxLevel
                                    description
                                }
                            }

                            children {
                                id
                                name
                                fullName
                                typeId
                                maxLevel
                                description
                            }
                        }

                        children {
                            id
                            name
                            fullName
                            typeId
                            maxLevel
                            description
                        }
                    }
                }

                activeWaves {
                    id
                    index
                    module {
                        id
                        name

                        waves {
                            id
                            index
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ADMIN_ROLES = gql`
query getAdminRoles($userId: String!) {
    adminRoles(userId: $userId){
        roleId
        name
        text
        enabled
    }
}`;

export const GET_SPACES = gql`
    query spaces {
        spaces {
            id
            name

            spaceBaseTimeIntervals {
                id
                order

                baseTimeIntervalInstances {
                    id
                    endDate
                    startDate
                }

                subspaceTypeLayouts {
                    subspaceType {
                        id
                        name
                        isDescriptionEnabled
                        isSelective

                        subspaces {
                            id
                            name
                            description
                        }
                    }
                }
            }

            baseTimeIntervalType{
                id
                name
            }
        }
    }
`;

export const GET_SUBSPACE_TYPES = gql`
    query subspaceTypes($subspaceTypesInput: SubspaceTypesInput!) {
        subspaceTypes(subspaceTypesInput: $subspaceTypesInput) {
            id
            name
            isDescriptionEnabled
            isSelective

            subspaces {
                id
                name
                description

                type {
                    name
                }
            }
        }
    }
`;

export const GET_TRAJECTORIES = gql`
    query trajectories($spaceId: String!) {
        trajectories(spaceId: $spaceId) {
            id
            name
            status
            description

            subspaces {
                id
                name
                description

                type {
                    name
                    isSelective
                }
            }
        }
    }
`;
