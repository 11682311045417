import React from 'react';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Space } from '../../EducationPeriodParametersAndDatesInterfaces';
import { EducationPeriodParametersDaySchedules } from '../EducationPeriodParametersDaySchedules';
import classes from './EducationPeriodParametersView.module.scss';

interface Props {
    space: Space,
    toggleMode(): void,
}

export function EducationPeriodParametersView(
    {
        space: {
            spaceParameters: {
                daySchedules,
                studentWorkload: {
                    maxHoursPerDay,
                    maxDaysPerWeek,
                    maxHoursPerSpaceBaseTimeInterval,
                },
            },
        },
        toggleMode,
    }: Props,
) {
    return (
        <div className={classes.educationPeriodParametersView}>
            <div className={classes.educationPeriodParametersView__schedules}>
                <div className={classes.educationPeriodParametersView__title}>
                    График работы:
                </div>
                <EducationPeriodParametersDaySchedules
                    daySchedules={daySchedules}
                />
            </div>
            <div className={classes.educationPeriodParametersView__workload}>
                <div className={classes.educationPeriodParametersView__title}>
                    Занятость студента:
                </div>
                <div className={classes.educationPeriodParametersView__paragraph}>
                    Часы в день: {maxHoursPerDay}<br />
                    Дни в неделю: {maxDaysPerWeek}<br />
                    Часы в семестр: {maxHoursPerSpaceBaseTimeInterval}<br />
                </div>
            </div>
            <div className={classes.educationPeriodParametersView__buttonPanel}>
                <ActionButton
                    actionType={ActionTypeOfButton.SECONDARY}
                    className={classes.newModule__buttons_margin_right}
                    onClick={toggleMode}
                >
                    Редактировать
                </ActionButton>
            </div>
        </div>
    );
}
