import React from 'react';
import classNames from 'classnames';

import { ActionButton } from '@common/ActionButton';

import classes from './UploadButton.module.scss';

interface Props {
    className?: string;
    children?: React.ReactChild;
    disabled?: boolean;
    onLoad(content: string): void;
}

export const UploadButton = ({
    className,
    children = 'Загрузить файл',
    onLoad,
    disabled = false,
}: Props): JSX.Element => (
    <div className={classNames(classes.uploadButton, className)}>
        <ActionButton>
            {children}
        </ActionButton>
        <input
            disabled={disabled}
            className={classes.uploadButton__input}
            type="file"
            onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
                const reader = new FileReader();

                reader.onload = (event) => {
                    const result = (event.target?.result ?? '') as string;

                    onLoad(result);
                };

                if (changeEvent.target?.files) {
                    const file = changeEvent.target.files[0];

                    if (file instanceof Blob) {
                        reader.readAsText(file);
                    }
                }
            }}
        />
    </div>
);
