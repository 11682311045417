import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { AuthRoute } from '@common/AuthRoute/AuthRoute';
import { Loader } from '@common/Loader';
import { GET_CURRENT_USER } from '@common/qraphql/queries';
import { getCurrentUser } from '@common/qraphql/graphql-types';

import UserView from '../../store/UserView';
import { InterfaceSection, ViewType } from '../../graphql-query-types';

interface Props {
    path: string;
    exact?: boolean;
    children: JSX.Element;
    requiredUserTypes: ViewType[];
    requiredSections?: InterfaceSection[] | null;
}

export const AuthRouteApollo = ({
    children,
    path,
    exact = false,
    requiredUserTypes,
    requiredSections,
}: Props): JSX.Element => {
    const isAuth = !!localStorage.getItem('token');

    if (!isAuth) {
        UserView.clearViewType();
        // TODO: хорошо бы возвращать полный путь а не только pathname
        const uri = encodeURI(window.location.href);
        // const uri = encodeURI(window.location.pathname);
        return <Redirect to={`/auth?redirect=${uri}`} />;
    }

    const {
        data,
        error,
        loading,
    } = useQuery<getCurrentUser>(GET_CURRENT_USER, {
        skip: !isAuth,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (isAuth && data?.getCurrentUser) {
            UserView.checkAndUpdateCurrentViewType(data.getCurrentUser);
            UserView.setStudentId(data.getCurrentUser.studentId);
        }
    }, [isAuth, data]);

    if (loading) return <Loader />;
    if (error) return <>`Error! ${error.message}`</>;

    return (
        // eslint-disable-next-line react/no-children-prop,max-len
        <AuthRoute path={path} exact={exact} children={children} requiredUserTypes={requiredUserTypes} requiredSections={requiredSections} />
    );
};
