import { gql, useQuery } from '@apollo/client';
import { ScheduleGenerationProcess } from '@admin/ScheduleGenerationPage/store/BaseTimeIntervalInstanceModel';

const ADDITIONAL_GENERATION_PROCESSES = gql`
    query AdditionalGenerationProcesses($baseTimeIntervalInstanceId: String!) {
        additionalScheduleGenerationProcesses(baseTimeIntervalInstanceId: $baseTimeIntervalInstanceId) {
            id
            name
            from
            to
            status
            constraintMatch {
                constraintMatchCountList {
                    constraintName
                    scoreType
                    matchCount
                }
            }
        }
    }
`;

export function useAdditionalGenerationsQuery(baseTimeIntervalInstanceId: string) {
    const { data, loading, refetch } = useQuery<{
        additionalScheduleGenerationProcesses: ScheduleGenerationProcess[];
    }>(ADDITIONAL_GENERATION_PROCESSES, {
        variables: { baseTimeIntervalInstanceId },
        fetchPolicy: 'network-only',
        pollInterval: 2500,
    });
    const { additionalScheduleGenerationProcesses } = data ?? {};
    return { additionalScheduleGenerationProcesses, loading, refetch };
}
