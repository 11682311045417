import { nanoid } from 'nanoid';
import React from 'react';
import { WorkingInterval, WeekDay } from '../../../../EducationPeriodParametersAndDatesInterfaces';
import classes from './Interval.module.scss';

interface Props {
    weekDay: WeekDay,
}

export function getFormatedTime(date: string) {
    if (date === '') {
        return date;
    }

    const splittedTime = date.split(':');
    const minutes = splittedTime.length === 3 ? `:${splittedTime[1]}` : '';
    const hours = splittedTime[0];

    return `${hours}${minutes}`;
}

function getIntervalString(interval: WorkingInterval, index: number, array: WorkingInterval[]) {
    return `${getFormatedTime(interval.start)} - ${getFormatedTime(interval.end)}${index === array.length - 1 ? '' : ','}`;
}

export function Interval(
    {
        weekDay,
    }: Props,
) {
    return weekDay[1].length > 0
        ? (
            <>
                {
                    weekDay[1].map(
                        (interval: WorkingInterval, i:number, arr:WorkingInterval[]) => (
                            // eslint-disable-next-line max-len
                            <div className={classes.interval} key={nanoid()}>
                                {
                                    getIntervalString(interval, i, arr)
                                }
                            </div>
                        ),
                    )
                }
            </>
        )
        : (<div>Выходной</div>);
}
