import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';

import { Alert } from '@common/Alert';
import { Loader } from '@common/Loader';
import { MODULE_INFO } from '@admin/NewModule';
import { ModulesPage as ModulesPageComponent } from './ModulesPage';

const GET_ALL_SKILLS = gql`
    query getAllSkills {
        skills {
            id
            name
            typeId
            maxLevel
            children {
                id
                name
                typeId
                maxLevel
                children {
                    id
                    name
                    typeId
                }
            }
        }
        skillTypes {
            id
            name {
                nominativeSingular
                genitiveSingular
                nominativePlural
                genitivePlural
                english
            }
            properties {
                maxLevel
            }
            parentId
        }
    }
`;

export const GET_MODULES = gql`
    query GetModules {
        modules(ids: null) {
            ${MODULE_INFO}
        }
    }
`;

export const GET_MODULE_DRAFTS = gql`
    query GetModuleDrafts {
        moduleDrafts(ids: null) {
            ${MODULE_INFO}
        }
    }
`;

export const REMOVE_MODULE = gql`
    mutation RemoveModule($id: String!){
        removeModule(id: $id){
            id
        }
    }
`;

const GetModules = () => {
    const [stateModules, setStateModules] = React.useState<any>();
    const [stateModuleDrafts, setStateModuleDrafts] = React.useState<any>();
    const [stateSkills, setStateSkills] = React.useState<any>();
    const { data: modules, loading: modulesLoading, error: modulesError } = useQuery(
        GET_MODULES,
    );
    const { data: moduleDrafts, loading: moduleDraftsLoading, error: moduleDraftsError } = useQuery(
        GET_MODULE_DRAFTS, { fetchPolicy: 'cache-and-network' },
    );
    const { data: skills, loading: skillsLoading, error: skillsError } = useQuery(
        GET_ALL_SKILLS, { fetchPolicy: 'cache-and-network' },
    );
    React.useEffect(() => setStateModules(modules), [modules]);
    React.useEffect(() => setStateModuleDrafts(moduleDrafts), [moduleDrafts]);
    React.useEffect(() => setStateSkills(skills), [skills]);
    const [removeModule, { error: errorRemove, data: dataRemove }] = useMutation(REMOVE_MODULE);
    if ((modulesLoading || moduleDraftsLoading || skillsLoading)
        && (!modules || !moduleDrafts || !skills)) return <Loader />;
    if (modulesError || moduleDraftsError || skillsError) return <>{`Error! ${modulesError?.message || moduleDraftsError?.message}`}</>;

    return (
        <>
            <ModulesPageComponent
                publicModules={stateModules?.modules ?? []}
                moduleDrafts={stateModuleDrafts?.moduleDrafts ?? []}
                removeModule={(id: string) => removeModule({ variables: { id } }).catch(() => { })}
                removeId={dataRemove?.removeModule?.id}
                skills={stateSkills?.skills ?? []}
                skillTypes={stateSkills?.skillTypes ?? []}
            />
            {errorRemove && <Alert message={errorRemove.message} time={5000} />}
        </>
    );
};

export { GetModules as ModulesPage };
