import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { GradientText } from '@common/GradientText';
import { ActionButton } from '@common/ActionButton';
import { BorderPlusIcon } from '@common/svg';
import { EquipmentForm } from '../EquipmentForm';

import classes from '../EquipmentPage.module.scss';

interface Equipment {
    [index: string]: any;
    id: string;
    name: string;
    count: number;
    isMovable?: boolean;
    territorialZoneId?: string | null;
}

interface TerritorialZone {
    id: string;
    name: string;
}

interface EquipmentState extends Equipment {
    warn: boolean;
}

interface Props {
    add: (equipments: Equipment) => void;
    territorialZones: TerritorialZone[];
    isAddFormClearable: boolean;
    setIsAddFormClearable: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AddEquipments({
    add,
    territorialZones,
    isAddFormClearable = false,
    setIsAddFormClearable,
}: Props): JSX.Element {
    const [equipmentArray, setEquipmentArray] = useState<EquipmentState[]>([{
        id: nanoid(),
        name: '',
        count: -1,
        isMovable: false,
        territorialZoneId: null,
        warn: false,
    }]);
    useEffect(() => {
        setIsAddFormClearable(false);
    }, []);
    // const [equipmentIsMovable, setEquipmentIsMovable] = useState(false);
    useEffect(() => {
        if (isAddFormClearable) {
            setEquipmentArray([{
                id: nanoid(),
                name: '',
                count: -1,
                isMovable: false,
                territorialZoneId: null,
                warn: false,
            }]);
            setIsAddFormClearable(false);
        }
    }, [isAddFormClearable]);
    const addEquipment = (): void => {
        let shouldWarn = false;
        const copyEquipmentArray = [...equipmentArray];
        copyEquipmentArray.forEach(
            ({ name, count }, index) => {
                if (name === '' || !(count > 0)) {
                    copyEquipmentArray[index].warn = true;
                    shouldWarn = true;
                } else {
                    copyEquipmentArray[index].warn = false;
                }
            },
        );
        if (!shouldWarn) {
            equipmentArray.forEach(({
                id,
                name,
                isMovable,
                territorialZoneId,
                count,
            }) => count > -1 && name !== '' && add({ id, name, isMovable, territorialZoneId, count }));
        } else {
            setEquipmentArray(copyEquipmentArray);
        }
    };

    const handleInputChange = (index: number, name: any, value: string): void => {
        setEquipmentArray((oldEquipmentArray) => {
            const copyOldEquipmentArray = [...oldEquipmentArray];
            copyOldEquipmentArray[index][name] = name === 'name' ? value : parseInt(value === '' ? '-1' : value, 10);

            return copyOldEquipmentArray;
        });
    };

    const handleIsMovableChange = (index: number) => {
        setEquipmentArray((oldEquipmentArray) => {
            const copyOldEquipmentArray = [...oldEquipmentArray];
            copyOldEquipmentArray[index].isMovable = !copyOldEquipmentArray[index].isMovable;

            return copyOldEquipmentArray;
        });
    };

    const handleZoneSelectChange = (index: number, territorialZoneId: string|null) => {
        setEquipmentArray((oldEquipmentArray) => {
            const copyOldEquipmentArray = [...oldEquipmentArray];
            copyOldEquipmentArray[index].territorialZoneId = territorialZoneId;
            return copyOldEquipmentArray;
        });
    };

    const handleNewInput = (): void => {
        setEquipmentArray((oldEquipmentArray) => [...oldEquipmentArray, {
            id: nanoid(),
            name: '',
            count: -1,
            isMovable: false,
            territorialZoneId: null,
            warn: false,
        }]);
    };
    const handleDeleteInput = (index: number): void => {
        setEquipmentArray((oldEquipmentArray) => {
            const copyOldEquipmentArray: EquipmentState[] = [...oldEquipmentArray];

            copyOldEquipmentArray.splice(index, 1);

            return copyOldEquipmentArray;
        });
    };
    return (
        <div className={classes.equipment}>
            <h2>Добавить оборудование</h2>
            <p className={classes.equipment__nameEquipment}>
                Название оборудования
            </p>
            {
                equipmentArray.map(
                    (element: Equipment, index: number) => (
                        <div className={classes.equipment__item} key={element.id}>
                            <EquipmentForm
                                equipmentIndex={index}
                                equipment={element}
                                handleInputChange={handleInputChange}
                                handleDeleteInput={handleDeleteInput}
                                handleIsMovableChange={handleIsMovableChange}
                                handleZoneSelectChange={handleZoneSelectChange}
                                warn={element.warn}
                                territorialZones={territorialZones}
                            />
                        </div>
                    ),
                )
            }
            <div
                className={classes.equipment__more}
                onClick={handleNewInput}
            >
                <BorderPlusIcon size={22} />
                <GradientText text="eще оборудование" className={classes.equipment__moreLabel} />
            </div>
            <ActionButton
                className={classes.equipment__button}
                onClick={() => addEquipment()}
            >
                Добавить
            </ActionButton>
        </div>
    );
}
