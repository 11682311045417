import React, { useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import format from 'date-fns/format';
import { observer } from 'mobx-react';
import { ApolloError } from '@apollo/client';

import { scheduleGenerationStore } from '@admin/ScheduleGenerationPage/store';
import { PublishEvents } from '@admin/ScheduleGenerationPage/PublishEvents';
import { useUrlQuery } from '@common/hooks';
import { ActionButton } from '@common/ActionButton';
import { Loader } from '@common/Loader';
import { Dialog } from '@common/Dialog';
import { AdditionalGeneration } from '@admin/ScheduleGenerationPage/AdditionalGeneration';
import { Alert } from '@common/Alert';

import { ScheduleEvent } from './types';
import { useApproveGeneratedEventsMutation, useImportResultsMutation } from './graphql';
import { FiltrationComponents } from './FiltrationComponents';
import {
    ScheduleGenerationConflicts,
    ValidationDates,
} from './ScheduleGenerationConflicts/scheduleViolationInterface';
import { MeetingDetails } from './MeetingDetails/index';
import { LoadFilteredSchedule } from './ScheduleGenerationPageInterfaces';
import { ScheduleGenerationTabs, ViewMode } from './ScheduleGenerationTabs';
import { ScheduleTable } from './ScheduleTable';
import { ScheduleGenerationConflict } from './ScheduleGenerationConflicts';
import { ValidationPopup } from './ValidationPopup';
import { UploadButton } from './UploadButton';
import { ShowEventCards } from './ShowEventCards';
import { ProcessChooser } from './ProcessChooser';
import { Statistics } from './Statistics';
import { useUpdateReservation } from './graphql/useUpdateReservation';

import classes from './ScheduleGenerationPage.module.scss';

interface Props {
    meetingsLoader: boolean;
    events: ScheduleEvent[];
    isSecret?: boolean;
    validateScheduleDataLoading?: boolean,
    pagesCount?: number,
    baseTimeIntervalInstanceId: string;
    studentsAttendance?: string;
    studentsAttendanceLoading: boolean;
    studentsAttendanceError: ApolloError | undefined;
    getConfig(forProcess?: boolean): Promise<void>;
    refetchPagesCount(): void,
    loadFilteredSchedule: LoadFilteredSchedule;
    checkSchedule(dates: ValidationDates): Promise<ScheduleGenerationConflicts>;
    refetchFilteredSchedule(): void;
    getLazyStudentsAttendance: (fromDate: Date, toDate: Date) => void;
}

function formatTimeInterval({ startTime }: ScheduleEvent): string {
    return format(new Date(startTime), 'HH:mm');
}

export const ScheduleGenerationPage = React.memo(observer(({
    events,
    meetingsLoader,
    baseTimeIntervalInstanceId,
    isSecret,
    validateScheduleDataLoading,
    pagesCount,
    studentsAttendance,
    studentsAttendanceLoading,
    studentsAttendanceError,
    refetchPagesCount,
    getConfig,
    checkSchedule,
    loadFilteredSchedule,
    refetchFilteredSchedule,
    getLazyStudentsAttendance,
}: Props): JSX.Element => {
    const [eventInstances, setEventInstances] = useState(events);
    const groups = groupBy(sortBy(eventInstances, ['startTime', 'id']), meeting => format(new Date(meeting.startTime), 'dd.MM.yyyy'));
    const dateMap = new Map(map(groups, (items, key) => [
        key,
        groupBy(items, item => formatTimeInterval(item)),
    ]));
    const [isOpenCheckSchedulePopUp, setIsOpenCheckSchedulePop] = useState(false);
    const [validateDates, setValidateDates] = useState<ValidationDates>({
        startDay: format(new Date(), 'yyyy-MM-dd'),
        endDay: '',
    });
    const [conflicts, setConflicts] = useState<ScheduleGenerationConflicts>();
    const [actionLoader, setActionLoader] = useState(false);
    const [viewMode, setViewMode] = useState(ViewMode.Common);
    const [
        updateReservationResponse,
        updateReservationError,
        updateReservation,
    ] = useUpdateReservation();

    const { getUrlQuery } = useUrlQuery();
    const [dialog, setDialog] = useState(false);
    const selectEventId = getUrlQuery('detailedEventId');

    const importResults = useImportResultsMutation();
    const { approveGeneratedEvents, loading: approveLoading } = useApproveGeneratedEventsMutation(
        baseTimeIntervalInstanceId,
    );

    useEffect(() => {
        if (selectEventId !== 'null' && selectEventId) {
            setDialog(true);
        }
    }, [selectEventId]);

    useEffect(() => {
        if (studentsAttendance !== undefined) {
            const blob = new Blob([studentsAttendance], {
                type: 'text/csv;charset=utf-8',
            });
            saveAs(blob, 'Отчет о посещаемости');
        }
    }, [studentsAttendance]);

    useEffect(() => setEventInstances(events), [events]);
    const onLoad = async (data: string) => {
        setActionLoader(true);
        const result = JSON.parse(data);
        result.scheduleGenerationProcessId = scheduleGenerationStore.baseTimeIntervalInstance
            ?.selectedProcess?.id;
        await importResults(JSON.stringify(result));
        setActionLoader(false);
    };

    return (
        <div className={classes.schedule}>
            <>
                {isSecret && (
                    <div className={classes.schedule__secretSection}>
                        <div className={classes.schedule__secretSubsection}>
                            <div className={classes.schedule__secretTitle}>
                                Генерация расписания
                            </div>

                            <ul className={classes.schedule__secretList}>
                                <li className={classes.schedule__secretItem}>
                                    <div className={classes.schedule__secretDescription}>
                                        Скачать конфиг для генерации расписания
                                        на выбранный период
                                    </div>

                                    <div className={classes.schedule__secretDescription}>
                                        <ProcessChooser />
                                    </div>

                                    <div style={{ display: 'flex', gap: 24 }}>
                                        <ActionButton
                                            className={classes.schedule__button}
                                            onClick={async () => {
                                                setActionLoader(true);
                                                await getConfig();
                                                setActionLoader(false);
                                            }}
                                        >
                                            Скачать конфиг для процесса
                                        </ActionButton>
                                        <ActionButton
                                            className={classes.schedule__button}
                                            onClick={async () => {
                                                setActionLoader(true);
                                                await getConfig(false);
                                                setActionLoader(false);
                                            }}
                                        >
                                            Скачать конфиг для БВИ
                                        </ActionButton>
                                    </div>
                                </li>

                                <li className={classes.schedule__secretItem}>
                                    <div className={classes.schedule__secretDescription}>
                                        Загрузить результаты генерации расписания.
                                        Новые встречи будут видны только из
                                        интерфейса администратора расписания
                                    </div>

                                    <div className={classes.schedule__secretDescription}>
                                        <ProcessChooser />
                                    </div>

                                    <UploadButton
                                        className={classes.schedule__button}
                                        onLoad={onLoad}
                                    >
                                        Загрузить
                                    </UploadButton>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </>

            <ScheduleGenerationTabs
                viewMode={viewMode}
                setViewMode={setViewMode}
                isSecret={isSecret}
            />

            {actionLoader
                ? (
                    <div className={classes.schedule__loader}>
                        <Loader />
                    </div>
                ) : (
                    <div className={classes.schedule__conflict_button}>
                        {viewMode === ViewMode.Common && (
                            <ActionButton
                                onClick={() => setIsOpenCheckSchedulePop(!isOpenCheckSchedulePopUp)}
                            >
                                Проверить конфликты
                            </ActionButton>
                        )}

                        {isOpenCheckSchedulePopUp ? (
                            <ValidationPopup
                                title="Вывести все конфликты за период:"
                                isOpen={isOpenCheckSchedulePopUp}
                                onClose={() => setIsOpenCheckSchedulePop(!isOpenCheckSchedulePopUp)}
                            >
                                <div className={classes.schedule__validate}>
                                    <input
                                        className={classes.schedule__popup_calendare}
                                        type="date"
                                        value={validateDates.startDay}
                                        onChange={({ target }) => setValidateDates({
                                            ...validateDates,
                                            startDay: target.value,
                                        })}
                                    />
                                    -
                                    <input
                                        className={classes.schedule__popup_calendare}
                                        type="date"
                                        data-date-format="DD-MMMM-YY"
                                        value={validateDates.endDay}
                                        onChange={({ target }) => setValidateDates({
                                            ...validateDates,
                                            endDay: target.value,
                                        })}
                                    />
                                </div>

                                <div className={classes.schedule__controls}>
                                    <ActionButton
                                        className={classes.schedule__setConflictButton}
                                        onClick={async () => {
                                            setActionLoader(true);
                                            setIsOpenCheckSchedulePop(!isOpenCheckSchedulePopUp);
                                            const data = await checkSchedule(validateDates);
                                            setConflicts(data);
                                            setActionLoader(false);
                                            setValidateDates({
                                                startDay: format(new Date(), 'yyyy-MM-dd'),
                                                endDay: '',
                                            });
                                        }}
                                    >
                                        Проверить
                                    </ActionButton>
                                </div>
                            </ValidationPopup>
                        ) : ''}
                    </div>
                )
            }

            {viewMode === ViewMode.Common && (
                <>
                    {
                        conflicts && (
                            <ScheduleGenerationConflict
                                resetConflicts={() => setConflicts(undefined)}
                                conflicts={conflicts}
                            />
                        )
                    }

                    <FiltrationComponents
                        pagesCount={pagesCount}
                        baseTimeIntervalInstanceId={baseTimeIntervalInstanceId}
                        loadFilteredSchedule={loadFilteredSchedule}
                        refetchPagesCount={refetchPagesCount}
                    />

                    <ShowEventCards dateMap={dateMap} setDialog={setDialog} />
                </>
            )}

            {dialog && (
                <Dialog
                    id="portal-edit-root"
                    dom={(
                        <MeetingDetails
                            setDialog={() => {
                                refetchFilteredSchedule();
                                setDialog(!dialog);
                            }}
                        />
                    )
                    }
                />
            )}

            {meetingsLoader && <Loader />}

            {viewMode === ViewMode.Table && !meetingsLoader && (
                <>
                    <div className={classes.schedule__secretItem}>
                        {!updateReservationResponse ? (
                            <>
                                <div className={classes.schedule__secretDescription}>
                                    Актуализировать данные о забронированных
                                    преподавателях и аудиториях в классическом
                                    университете
                                </div>

                                <ActionButton
                                    onClick={() => {
                                        updateReservation(baseTimeIntervalInstanceId);
                                    }}
                                >
                                    Обновить данные
                                </ActionButton>
                            </>
                        ) : 'Мы уже начали обновление данных. Данная операция занимает около 7 минут. После окончания операции, вы можете продолжить работу с расписанием.'}
                    </div>
                    <div className={classes.schedule__secretItem}>
                        <div className={classes.schedule__secretDescription}>
                            Для того, чтобы результаты генерации расписания
                            стали доступны всем пользователям, их нужно
                            опубликовать. Укажите временные интервалы встреч,
                            которые необходимо опубликовать
                        </div>
                        <PublishEvents
                            title="Опубликовать"
                            loading={approveLoading}
                            onButtonClick={approveGeneratedEvents}
                        />
                    </div>

                    {validateScheduleDataLoading && <Loader />}
                    <ScheduleTable isSecret={isSecret} />
                    {updateReservationError?.graphQLErrors?.[0].message && (
                        <Alert
                            message={updateReservationError?.graphQLErrors?.[0].message}
                            time={5000}
                        />
                    )}
                </>
            )}

            {viewMode === ViewMode.Statistics && !meetingsLoader && (
                <Statistics
                    studentsAttendanceLoading={studentsAttendanceLoading}
                    studentsAttendanceError={studentsAttendanceError}
                    getLazyStudentsAttendance={getLazyStudentsAttendance}
                />
            )}
            {viewMode === ViewMode.AdditionalGeneration && (
                <AdditionalGeneration
                    baseTimeIntervalInstanceId={baseTimeIntervalInstanceId}
                    isSecret={!!isSecret}
                />
            )}
        </div>
    );
}));
