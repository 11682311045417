import React, { useEffect, useState } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';

import { Tabs } from '@common/Tabs';
import { SubspacesConnectionsDiagram } from '@admin/SubspacesConnectionsDiagram';

import { AuthRoute } from '@common/AuthRoute';
import { EducationSpace } from './EducationSpace';
import { EducationSubspaceType } from './EducationSubspace';

import { Space } from '../../subSpaceAdmin/subSpaceTypes';
import { ViewType } from '../../graphql-query-types';

interface Props {
    spaces: Space[];
    routerPath: string;
    refetch: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>;
    removeSpace: (id: string,) => void;
}

export const EducationSpacePage = ({
    routerPath,
    spaces,
    removeSpace,
    refetch,
}: Props) => {
    const [tabOptions, setTabOptions] = useState([
        ['Новое пространство', 'new'],
    ]);

    const [isEdit, setIsEdit] = useState('');
    const { path } = useRouteMatch();
    const fullTabs = (newSpaces: Space[]) => {
        const tabs = newSpaces.map(space => [space.name, space.id]);
        setTabOptions([...tabs, ...tabOptions]);
    };

    const clearTabs = (spaceIds: string[]) => {
        const clearedTabs = tabOptions.filter(tab => (
            spaceIds.includes(tab[1])
            || tab[1] === 'new'
        ));
        setTabOptions(clearedTabs);
    };

    useEffect(() => {
        const plainTabs = tabOptions.map(tab => tab[1]);
        const newSpaces = spaces.filter(({ id }) => !plainTabs.includes(id));
        const spaceIds = spaces.map(({ id }) => id);
        const removedTabs = tabOptions.filter(tab => (
            !spaceIds.includes(tab[1])
            && tab[1] !== 'new'
        ));

        if (newSpaces.length > 0) {
            fullTabs(newSpaces);
        }

        if (removedTabs.length > 0) {
            clearTabs(spaceIds);
        }
    });

    const educationContentRouters = tabOptions.map((_, index) => (
        <AuthRoute
            key={tabOptions[index][1]}
            exact
            path={`${path}/${tabOptions[index][1]}`}
            requiredUserTypes={[ViewType.Admin]}
        >
            {index + 1 < tabOptions.length
                ? (
                    <div key={tabOptions[index][1]}>
                        <EducationSubspaceType
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                            educationSpacePath={path}
                            tabOptions={tabOptions}
                            setTabOptions={setTabOptions}
                            spaceRefetch={refetch}
                            removeSpace={removeSpace}
                            routerPath={routerPath}
                        />
                    </div>
                ) : (
                    <div key={tabOptions[index][1]}>
                        <EducationSpace
                            space={undefined}
                            path={path}
                            tabOptions={tabOptions}
                            setTabOptions={setTabOptions}
                            refetch={refetch}
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                            removeSpace={removeSpace}
                        />
                    </div>
                )
            }
        </AuthRoute>
    ));

    const subspacesConnectionsDiagramRouters = tabOptions
        .filter(tabOption => tabOption[1] !== 'new')
        .map(tabOption => {
            const spaceId = tabOption[1];

            return (
                <AuthRoute
                    key={spaceId}
                    exact
                    path={`${path}/${spaceId}/connections`}
                    requiredUserTypes={[ViewType.Admin]}
                >
                    <SubspacesConnectionsDiagram spaceId={spaceId} />
                </AuthRoute>
            );
        });
    return (
        <>
            <Tabs tabsOptions={tabOptions} />

            <Switch>
                <AuthRoute path={path} requiredUserTypes={[ViewType.Admin]} exact>
                    <Redirect to={`${path}/${tabOptions[0][1]}`} />
                </AuthRoute>

                {subspacesConnectionsDiagramRouters}

                {educationContentRouters}
            </Switch>
        </>
    );
};
