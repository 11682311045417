import { makeAutoObservable } from 'mobx';

import { AnyEvent, SelectionStatus, StudentPriority } from './Config/interfaces';

export interface InputFork {
    forkId: string;
    slots: {
        slotId: string;
        priority: number;
    }[];
}

interface ForkPriority {
    forkId: string;
    priorityCount: number;
}

class EventStore {
    baseTimeIntervalSelectionStatus: SelectionStatus = SelectionStatus.SELECTIONPENDING;

    selectedEvent: AnyEvent = null;

    forks: InputFork[] = [];

    forkPriorityCount: ForkPriority[] = [];

    studentPriorities: StudentPriority[] = [];

    selectSlotId = '';

    constructor() {
        makeAutoObservable(this);
    }

    addFork(fork: InputFork) {
        if (!this.getFork(fork.forkId)) {
            this.forks.push(fork);
        }
    }

    getForkPriorityCount(id = '') {
        return this.forkPriorityCount.find(({ forkId }) => forkId === id)?.priorityCount;
    }

    sortForkSlotsByPriorities() {
        this.forks.forEach(({ forkId }) => this.getFork(forkId)?.slots.sort((a, b) => {
            if (a.priority === 0) {
                return 0;
            }
            if (b.priority === 0) {
                return -1;
            }
            return a.priority - b.priority;
        }));
    }

    getRemainingPriorities(id = '') {
        const forkSetingCount = this.getForkPriorityCount(id) ?? 0;
        const forkSelectedSlota = this.getFork(id)?.slots
            .filter(slot => slot.priority > 0).length ?? 0;
        return forkSetingCount - forkSelectedSlota;
    }

    getFork(id: string) {
        return this.forks.find(({ forkId }) => forkId === id);
    }

    getFilteredForks() {
        return this.forks.map(item => ({
            ...item,
            slots: item.slots.filter(slot => slot.priority !== 0),
        }));
    }

    isChocePointFinished() {
        const forksWhithPriority = this.forks.map(fork => ({
            ...fork,
            slots: fork.slots.filter(slot => slot.priority > 0),
        }));
        return !forksWhithPriority
            .map(fork => fork.slots.length === this.getForkPriorityCount(fork.forkId))
            .includes(false);
    }

    setStudentPriorities(studentPriorities: StudentPriority[]) {
        this.studentPriorities = studentPriorities;
    }

    setSelectedEvent(event: AnyEvent): void {
        this.selectedEvent = event;
    }

    setSelectSlotId(id = '') {
        this.selectSlotId = id;
    }

    setPriority(forkId = '', slotId = '', priority: number) {
        const slot = this.getSlot(forkId, slotId)!;
        if (slot?.priority >= 0) {
            slot.priority = priority;
        }
    }

    setBaseTimeIntervalSelectionStatus(status: SelectionStatus) {
        this.baseTimeIntervalSelectionStatus = status;
    }

    updateForkPriorityCount(fork: ForkPriority) {
        if (!this.forkPriorityCount.find(({ forkId }) => forkId === fork.forkId)) {
            this.forkPriorityCount.push(fork);
        }
    }

    updateForksOnStudentPriorities() {
        if (this.studentPriorities.length > 0) {
            this.studentPriorities
                .forEach(({ forkId, slotId, priority }) => this
                    .setPriority(forkId, slotId, priority));
        }
    }

    isPrioritySelected(forkId = '', slotId = '', priority: number) {
        return !!this.getFork(forkId)?.slots
            .find(slot => slot.slotId !== slotId && priority === slot.priority);
    }

    getSlot(forkId = '', slotId = '') {
        return this.getFork(forkId)?.slots.find(slot => slot.slotId === slotId);
    }
}

export default new EventStore();
