/* eslint-disable react/prop-types */
import React from 'react';

import { RoomZones } from '../RoomForm/RoomZones';
import { RoomTitle } from '../RoomForm/RoomTitle';
import { RoomSpace } from '../RoomForm/RoomSpace';
import { RoomTypes } from '../RoomForm/RoomTypes';
import { RoomAvailable } from '../RoomForm/RoomItems/RoomAvailable';
import { AddItemsContainer } from '../RoomForm/RoomItems/AddItemsContainer';

import {
    RoomCreationEquipment,
    RoomMeetingFormat,
    Building,
} from '../RoomTypes';

interface RoomCreationProps {
    id?: string;
    types: RoomMeetingFormat[];
    title: string | number;
    buildings: Building[];
    defaultAddress: string;
    capacity: number;
    storeEquipment?: RoomCreationEquipment[] | undefined;
    equipmentsRoom: RoomCreationEquipment[];
    isAvailable: boolean;
    availableIntervals: {
        id: string;
        from: Date;
        to: Date;
    }[];
    chooseZone(value: string): void;
    handleRoomTitle(event: React.ChangeEvent<HTMLInputElement>): void;
    handleRoomCapacity(event: React.ChangeEvent<HTMLInputElement>): void;
    handleRoomType(event: RoomMeetingFormat[]): void;
    addRoomItems({ id, count, name }: RoomCreationEquipment): void;
    removeRoomItems(id: string): void;
    handlerAvailable: React.Dispatch<React.SetStateAction<boolean>>;
    handlerAvailableIntervals: React.Dispatch<React.SetStateAction<{
        id: string;
        from: Date;
        to: Date;
    }[]>>
}

export function RoomCreation({
    id,
    title,
    types,
    capacity,
    buildings,
    defaultAddress,
    storeEquipment,
    equipmentsRoom,
    isAvailable,
    availableIntervals,
    handlerAvailable,
    chooseZone,
    addRoomItems,
    handleRoomType,
    handleRoomTitle,
    handleRoomCapacity,
    removeRoomItems,
    handlerAvailableIntervals,
}: RoomCreationProps): JSX.Element {
    return (
        <>
            <RoomZones
                chooseZone={chooseZone}
                buildings={buildings}
                defaultAddress={defaultAddress}
                id={id}
            />
            <RoomTitle
                title={title}
                handleRoomTitle={handleRoomTitle}
            />
            <RoomSpace
                capacity={capacity}
                handleRoomCapacity={handleRoomCapacity}
            />
            <RoomTypes
                types={types}
                handleRoomType={handleRoomType}
            />
            <AddItemsContainer
                addRoomItems={addRoomItems}
                removeRoomItems={removeRoomItems}
                storeEquipment={storeEquipment}
                equipmentsRoom={equipmentsRoom}
            />
            <RoomAvailable
                isAvailable={isAvailable}
                availableIntervals={availableIntervals}
                handlerAvailableIntervals={handlerAvailableIntervals}
                handlerAvailable={handlerAvailable}

            />
        </>
    );
}
