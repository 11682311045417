import React, { useEffect, useState } from 'react';

import { IconDeprecated, Switcher } from '@common';
import { Autosuggest } from '@common/Autosuggest';

import { Meeting, RoomType, ScheduleEvent } from '@admin/ScheduleGenerationPage/types';
import {
    UpdateRoomFunc,
    useLoadAvailableRoomsLazyQuery,
} from '../apolloHooks';

import { Room as RoomInterface } from '../../ScheduleGenerationPageInterfaces';

import classes from './Room.module.scss';

interface Props {
    event: ScheduleEvent;
    edibleRoom: boolean;
    setEdibleRoom: React.Dispatch<React.SetStateAction<boolean>>;

    updateRoom: UpdateRoomFunc;
    ViewMode(input: { name: string; link: string }): JSX.Element;
}

export function IndoorRoom({
    event,
    edibleRoom,
    setEdibleRoom,
    ViewMode,
    updateRoom,
}: Props): JSX.Element {
    const { room, startTime, endTime } = event;
    const roomNameSeparator = ' по адресу: ';
    const name = formatRoomName(roomNameSeparator, room);
    const isEdible = edibleRoom || !room;
    const roomOption = room?.id ? { id: room?.id, name } : null;

    const { loadAvailableRooms, availableRooms = [] } = useLoadAvailableRoomsLazyQuery();
    const [newRoom, setNewRoom] = useState(roomOption);
    const roomId = newRoom?.id;
    const [isOnlyAvailableRoom, setOnlyAvailableRoom] = useState(true);
    const [isEquipmentMatch, setEquipmentMatch] = useState(true);
    const equipments = getEquipmentsOfMeeting(event.students.length, event.meeting);

    const options = availableRooms.map(certainRoom => ({
        id: certainRoom.id,
        name: formatRoomName(roomNameSeparator, certainRoom),
    }));

    useEffect(() => {
        if (isEdible) {
            loadAvailableRooms(
                startTime,
                endTime,
                isOnlyAvailableRoom,
                isEquipmentMatch,
                equipments,
            );
        }
    }, [isEdible]);

    return (
        <>
            {!isEdible
                ? <ViewMode name={name} link={`/university/room/${roomId}?`} />
                : (
                    <div className={classes.room__name}>
                        <Switcher
                            label="Только свободные помещения"
                            checked={isOnlyAvailableRoom}
                            onClick={() => {
                                loadAvailableRooms(
                                    startTime,
                                    endTime,
                                    !isOnlyAvailableRoom,
                                    isEquipmentMatch,
                                    equipments,
                                );
                                setOnlyAvailableRoom((oldState) => !oldState);
                            }}
                        />
                        <br />
                        <Switcher
                            label="Только помещения с оборудованием"
                            checked={isEquipmentMatch}
                            onClick={() => {
                                loadAvailableRooms(
                                    startTime,
                                    endTime,
                                    isOnlyAvailableRoom,
                                    !isEquipmentMatch,
                                    equipments,
                                );
                                setEquipmentMatch((oldState) => !oldState);
                            }}
                        />
                        <div className={classes.room__suggestion}>
                            <Autosuggest
                                options={options}
                                onChange={item => setNewRoom(item)}
                                selectedOptions={newRoom ? [newRoom] : []}
                                formatOption={item => item.name}
                                isSuggestableOnFocus
                            />
                            <IconDeprecated
                                id="check"
                                click={() => {
                                    setEdibleRoom(false);
                                    updateRoom({
                                        roomId: newRoom!.id,
                                        meetingInstanceId: event.id,
                                        roomType: RoomType.Indoor,
                                    });
                                }}
                                className={classes.room__penIcon}
                            />
                        </div>
                        {newRoom && (
                            <div className={classes.room__suggestion__list}>
                                {newRoom.name}
                            </div>
                        )}
                    </div>
                )
            }
        </>
    );
}

function formatRoomName(separator: string, room?: RoomInterface) {
    if (!room) return '';
    return `${room.title}${separator}${room.location?.location}`;
}

function getEquipmentsOfMeeting(studentsCount: number, meeting?: Meeting) {
    const result: { id: string, count: number }[] = [];
    meeting?.equipmentPerMeeting?.forEach(({ equipment, count }) => {
        result.push({ id: equipment.id, count });
    });
    meeting?.equipmentPerStudent?.forEach(({ equipment, count }) => {
        result.push({ id: equipment.id, count: count * studentsCount });
    });
    return result;
}
