export interface Space {
    id: string,
    name: string,
    baseTimeIntervalType: BaseTimeIntervalType,
    spaceParameters: SpaceParameters,
    spaceEducationPeriods: SpaceEducationPeriod[],
    spaceBaseTimeIntervals: SpaceBaseTimeInterval[],
}

export interface SpaceEducationPeriod {
    id: string,
    name: string,
    spaceBaseTimeIntervalParameters: SpaceBaseTimeIntervalParameters[],
}

export interface SpaceBaseTimeIntervalParameters {
    baseTimeIntervalInstanceId: string,
    spaceBaseTimeIntervalId: string,
    subspaceSelectionStartDate?: string,
    subspaceSelectionEndDate?: string,
    moduleSelectionStartDate?: string,
    moduleSelectionEndDate?: string,
    spaceBaseTimeIntervalStart?: string,
    spaceBaseTimeIntervalEnd?: string,
    moduleAssessment?: ModuleAssessment;
}

export interface ModuleAssessment {
    id: string;
    isDynamicStartDate: boolean;
    startDate?: string;
    endDate?: string;
}

export interface CreateSpaceBaseTimeIntervalParameters {
    baseTimeIntervalInstanceId: string;
    spaceBaseTimeIntervalId: string;
    subspaceSelectionStartDate?: string;
    subspaceSelectionEndDate?: string;
    moduleSelectionStartDate?: string;
    moduleSelectionEndDate?: string;
    spaceBaseTimeIntervalStart?: string;
    spaceBaseTimeIntervalEnd?: string;
}

export interface UpdateSpaceBaseTimeIntervalParameters {
    baseTimeIntervalInstanceId: string;
    spaceBaseTimeIntervalId: string;
    subspaceSelectionStartDate?: string;
    subspaceSelectionEndDate?: string;
    moduleSelectionStartDate?: string;
    moduleSelectionEndDate?: string;
    spaceBaseTimeIntervalStart?: string;
    spaceBaseTimeIntervalEnd?: string;
}

export interface SpaceBaseTimeInterval {
    id: string;
    order: number;
    baseTimeIntervalInstances: BaseTimeIntervalInstance[];
}

export interface BaseTimeIntervalInstance {
    id: string;
    selectionStatus: SelectionStatus;
}

export interface BaseTimeIntervalType {
    id: string,
    name: string,
}

export interface SpaceParameters {
    daySchedules: DaySchedule[],
    studentWorkload: StudentWorkload,
    // selectionPointParameters: SelectionPointParameters,
}

export interface DaySchedule {
    weekDay: string,
    workingIntervals: WorkingInterval[],
}

export interface WorkingInterval {
    id: string,
    start: string,
    end: string,
}

export interface StudentWorkload {
    maxHoursPerDay: number,
    maxDaysPerWeek: number,
    maxHoursPerSpaceBaseTimeInterval: number,
}

// export interface SelectionPointParameters {
//     daysForSubspaceSelection: number,
//     daysForModuleSelection: number,
// }

export enum WeekDaysRussian {
    Monday = 'ПН',
    Tuesday = 'ВТ',
    Wednesday = 'СР',
    Thursday = 'ЧТ',
    Friday = 'ПТ',
    Saturday = 'СБ',
    Sunday = 'ВС',
}

export type WeekDays = ([(keyof typeof WeekDaysRussian), WorkingInterval[]])[];

export type WeekDay = ([(keyof typeof WeekDaysRussian), WorkingInterval[]]);

export enum Mode {
    VIEW,
    CREATE,
    EDIT,
    DELETE,
}

export enum SelectionStatus {
    SELECTIONPENDING = 'selectionPending',
    SUBSPACESELECTIONENABLED = 'subspaceSelectionEnabled',
    SUBSPACESELECTIONINPROCESS = 'subspaceSelectionInProcess',
    SUBSPACEDISTRIBUTIONENABLED = 'subspaceDistributionEnabled',
    SUBSPACEDISTRIBUTIONINPROCESS = 'subspaceDistributioninProcess',
    SUBSPACEDISTRIBUTIONPENDING = 'subspaceDistributionPending',
    MODULESELECTIONENABLED = 'moduleSelectionEnabled',
    MODULESELECTIONINPROCESS = 'moduleSelectionInProcess',
    MODULEDISTRIBUTIONENABLED = 'moduleDistributionEnabled',
    MODULEDISTRIBUTIONINPROCESS = 'moduleDistributioninProcess',
    MODULEDISTRIBUTIONPENDING = 'moduleDistributionPending',
    SELECTIONFINISHED = 'selectionFinished',
}

export const russianSelectionStatus = {
    [SelectionStatus.SELECTIONPENDING]: 'выборность не запущена',
    [SelectionStatus.SUBSPACESELECTIONENABLED]: 'начало выборности подпространств',
    [SelectionStatus.SUBSPACESELECTIONINPROCESS]: 'идет процесс выбора подпространств',
    [SelectionStatus.SUBSPACEDISTRIBUTIONENABLED]: 'завершен процесс выбора подпространств',
    [SelectionStatus.SUBSPACEDISTRIBUTIONINPROCESS]: 'запущен процесс распределения по подпространствам',
    [SelectionStatus.SUBSPACEDISTRIBUTIONPENDING]: 'завершен процесс предварительного распределения по подпространствам',
    [SelectionStatus.MODULESELECTIONENABLED]: 'начало выборности модулей',
    [SelectionStatus.MODULESELECTIONINPROCESS]: 'идет процесс выбора модулей',
    [SelectionStatus.MODULEDISTRIBUTIONENABLED]: 'завершен процесс выбора модулей',
    [SelectionStatus.MODULEDISTRIBUTIONINPROCESS]: 'идет процесс распределения по модулям',
    [SelectionStatus.MODULEDISTRIBUTIONPENDING]: 'завершен процесс предварительного распределения по модулям',
    [SelectionStatus.SELECTIONFINISHED]: 'выборность завершена',
};
