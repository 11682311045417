import React, { Fragment, useState } from 'react';
import { format } from 'date-fns';

import { IconDeprecated } from '@common';
import { useUrlQuery } from '@common/hooks';
import { RoomType } from '@admin/ScheduleGenerationPage/types';
import { ArrowInShowViolation } from '../ArrowInShowViolation';
import { MeetingViolation } from '../../scheduleViolationInterface';

import classes from '../scheduleViolation.module.scss';

interface Props {
    meetingViolations: MeetingViolation;
}

export const ShowMeetingViolation = ({ meetingViolations }: Props) => {
    const { setUrlQuery } = useUrlQuery();
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={classes.schedule__violation_container}>
            <div
                className={classes.schedule__violation_title}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div
                    className={classes.schedule__violation_count}
                >
                    {`${meetingViolations.meetings.length} `}
                </div>
                {meetingViolations.violationName}
                <ArrowInShowViolation isOpen={isOpen} />
            </div>
            {isOpen && meetingViolations.meetings
                .map((violationsMeetings, index) => (
                    <Fragment key={`${violationsMeetings.map(ell => ell.id)}`}>
                        <div className={classes.schedule__violation_conflict_title}>
                            Конфликт {`${index + 1}: `}
                        </div>
                        {violationsMeetings
                            .map((meetingInstance) => {
                                const teachers = meetingInstance.meetingInstanceToTeachers
                                    .map(instanceToTeacher => {
                                        const { firstName } = instanceToTeacher.teacher.user;
                                        const { lastName } = instanceToTeacher.teacher.user;
                                        const { patronymic } = instanceToTeacher.teacher.user;
                                        return `${lastName} ${firstName} ${patronymic}`;
                                    });
                                return (
                                    <div
                                        key={meetingInstance.id}
                                        className={classes.schedule__violation_conflict}
                                    >
                                        <div>Модуль: {`${meetingInstance.meeting.module.name} `}</div>
                                        <div className={classes.schedule__violation_meeting}>
                                            Встреча:
                                            <div
                                                onClick={() => {
                                                    setUrlQuery({ detailedEventId: meetingInstance.id, detailedEventType: 'meeting' });
                                                }}
                                                className={classes.schedule__violation_meeting_name}
                                            >{` ${meetingInstance.meeting.topic}`}
                                                <IconDeprecated
                                                    id="editPenBlack"
                                                    className={classes.violation__meeting_icon}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.schedule__violation_value}>Формат: {`${meetingInstance.meeting.format.name} `}</div>
                                        <div className={classes.schedule__violation_value}>Потоки: {`${meetingInstance.waves.map(wave => `${wave.index + 1}`)} `}</div>
                                        <div className={classes.schedule__violation_value}>Преподаватель: {`${teachers} `}</div>
                                        <div className={classes.schedule__violation_value}>
                                            {meetingInstance.roomType === RoomType.Indoor
                                                ? `Помещение: ${meetingInstance.room?.title}`
                                                : `Ссылка: ${meetingInstance.onlineRoom?.url}`}
                                        </div>
                                        <div className={classes.schedule__violation_value}>
                                            Время проведения: {format(new Date(Number(meetingInstance.startDate)), ' HH:mm dd.MM.yy -')}
                                            {format(new Date(Number(meetingInstance.endDate)), ' HH:mm dd.MM.yy ')}
                                        </div>
                                    </div>
                                );
                            })}
                    </Fragment>
                ))}
        </div>
    );
};
