import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Loader } from '@common/Loader';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Alert } from '@common/Alert';
import { EducationSubspaceType } from './EducationSubspaceType';

interface SpaceBaseTimeInterval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
}

interface SubspaceTypeLayout {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    spaceBaseTimeInterval: SpaceBaseTimeInterval;
}

interface SubspaceType {
    id: string;
    name: string;
    subspaceTypeLayouts: SubspaceTypeLayout[];
}

interface SubspaceTypeLayoutsSimple {
    subspaceType: SubspaceType;
}

interface SpaceBaseTimeIntervalSimple {
    id: string;
    order: string;
    subspaceTypeLayouts: SubspaceTypeLayoutsSimple[];
}

interface SpaceBaseTimeInterval {
    id: string;
    minCreditCount: number;
    maxCreditCount: number;
    order: number;
}

interface SubspaceTypeLayoutType {
    id: string;
    spaceBaseTimeInterval: SpaceBaseTimeInterval;
    minCreditCount: number;
    maxCreditCount: number;
}

interface SubspaceTypeFromBack {
    id: string;
    name: string;
    isSelective: boolean;
    isDescriptionEnabled: boolean;
    subspaceTypeLayouts: SubspaceTypeLayoutType[];
    subspaces: { id: string; }[];
}

interface Props {
    educationSpacePath: string;
    tabOptions: string[][];
    setTabOptions(tabOptions: string[][]): void;
    spaceRefetch: any;
    isEdit: string;
    setIsEdit(value: string): void;
    removeSpace: (
        id: string,
    ) => void;
    routerPath: string;
}

export const GET_SUBSPACE_TYPES = gql`
query space($id: String!) {
    space(id: $id) {
        id
        name
        minCreditCount
        maxCreditCount
        baseTimeIntervalType {
            id
            name
        }
        spaceBaseTimeIntervals {
            id
            minCreditCount
            maxCreditCount
            order
            subspaceTypeLayouts {
                id
                minCreditCount
                maxCreditCount
                spaceBaseTimeInterval {
                    id
                    minCreditCount
                    maxCreditCount
                }
                subspaceType {
                    id
                    name
                    isDescriptionEnabled
                    isSelective
                    subspaceTypeLayouts {
                        id
                        minCreditCount
                        maxCreditCount
                        spaceBaseTimeInterval {
                            id
                            minCreditCount
                            maxCreditCount
                            order
                        }
                    }
                    subspaces {
                        id
                    }
                }
            }
        }
    }
}
`;

const CREATE_SUBSPACE_TYPE = gql`
    mutation createSubspaceType(
        $id: String!
        $name: String!
        $isSelective: Boolean!
        $isDescriptionEnabled: Boolean!
        $subspaceTypeLayouts: [CreateSubspaceTypeLayout!]!
  ) {
        createSubspaceType (
            createSubspaceTypeInput: {
                id: $id
                name: $name
                isSelective: $isSelective
                isDescriptionEnabled: $isDescriptionEnabled
                subspaceTypeLayouts: $subspaceTypeLayouts
            }
        ) {
            id
        }
    }
`;

const UPDATE_SUBSPACE_TYPE = gql`
    mutation updateSubspaceType(
        $id: String!
        $name: String!
        $isSelective: Boolean!
        $isDescriptionEnabled: Boolean!
        $subspaceTypeLayouts: [UpdateSubspaceTypeLayout!]!
    ) {
        updateSubspaceType (
            updateSubspaceTypeInput: {
                id: $id
                name: $name
                isSelective: $isSelective
                isDescriptionEnabled: $isDescriptionEnabled
                subspaceTypeLayouts: $subspaceTypeLayouts
            }
        ) {
            id
        }
    }
`;

const REMOVE_SUBSPACE_TYPE = gql`
    mutation removeSubspaceType($id: String!) {
        removeSubspaceType(id: $id) {
            status
        }
    }
`;

const UPDATE_SPACE = gql`
mutation updateSpace(
    $id: String!
    $name: String!
    $minCreditCount: Int!
    $maxCreditCount: Int!
    $isDifferentSpaceBaseTimeIntervals: Boolean!
    $baseTimeIntervalTypeId: String!
    $spaceBaseTimeIntervals: [UpdateSpaceBaseTimeInterval!]!
    ) {
        updateSpace(
            updateSpaceInput: {
                id: $id
                name: $name
                minCreditCount: $minCreditCount
                maxCreditCount: $maxCreditCount
                isDifferentSpaceBaseTimeIntervals: $isDifferentSpaceBaseTimeIntervals
                baseTimeIntervalTypeId: $baseTimeIntervalTypeId
                spaceBaseTimeIntervals: $spaceBaseTimeIntervals
            }
        ) {
            id
        }
    }
`;

export const EducationSubspaceTypeApollo = ({
    educationSpacePath,
    tabOptions,
    setTabOptions,
    spaceRefetch,
    isEdit,
    setIsEdit,
    removeSpace,
    routerPath,
}: Props) => {
    const { path } = useRouteMatch();
    const splitRoute = path.split('/');
    const spaceId = splitRoute[splitRoute.length - 1];
    const [removeSubspaceTypeError, setRemoveSubspaceTypeError] = useState(undefined);

    const [
        createSubspaceTypeInput,
        { error: maxLimitExceeded },
    ] = useMutation(CREATE_SUBSPACE_TYPE, {
        refetchQueries: [{ query: GET_SUBSPACE_TYPES, variables: { id: spaceId } }],
        onError: (subspaceTypeError: any) => {
            setRemoveSubspaceTypeError(subspaceTypeError.message);
        },
    });

    const [
        updateSubspaceTypeInput,
        { error: updateSubspaceTypeError },
    ] = useMutation(UPDATE_SUBSPACE_TYPE, {
        refetchQueries: [{ query: GET_SUBSPACE_TYPES, variables: { id: spaceId } }],
        onError: (updateError: any) => {
            setRemoveSubspaceTypeError(updateError.message);
        },
    });

    const [updateSpaceInput, { error: updateError }] = useMutation(UPDATE_SPACE, {
        refetchQueries: [{ query: GET_SUBSPACE_TYPES, variables: { id: spaceId } }],
        onError: (updateSpaceError: any) => {
            setRemoveSubspaceTypeError(updateSpaceError.message);
        },
    });

    const [
        removeSubspaceType,
        { error: gotError },
    ] = useMutation(REMOVE_SUBSPACE_TYPE, {
        refetchQueries: [{ query: GET_SUBSPACE_TYPES, variables: { id: spaceId } }],
        onError: (deleteError: any) => {
            setRemoveSubspaceTypeError(deleteError.message);
        },
    });

    const {
        error,
        loading,
        data,
        refetch: refetchSubspaceTypes,
    } = useQuery(GET_SUBSPACE_TYPES, { variables: { id: spaceId } });

    if (loading) return <Loader />;
    if (error) return <>`Error! ${error.message}`</>;

    const repeatSubspaceTypes: any = [];
    (data.space.spaceBaseTimeIntervals.map(
        (spaceBTI: SpaceBaseTimeIntervalSimple) => (spaceBTI.subspaceTypeLayouts.map(
            (subspaceTypeLayout: SubspaceTypeLayoutsSimple) => (
                repeatSubspaceTypes.push(subspaceTypeLayout.subspaceType)
            ),
        )),
    ));

    const subspaceTypes: SubspaceTypeFromBack[] = [];
    const fullSubspaceType = (index: number) => {
        if (!subspaceTypes.find(type => type.id === repeatSubspaceTypes[index].id)) {
            subspaceTypes.push(repeatSubspaceTypes[index]);
        }
    };

    let index = 0;
    while (index < repeatSubspaceTypes.length) {
        fullSubspaceType(index);
        index += 1;
    }

    const spaceBTIName = data.space.baseTimeIntervalType.name;
    const currentSpaceBaseTimeIntervals = data.space.spaceBaseTimeIntervals.map(
        (spaceBTI: SpaceBaseTimeIntervalSimple) => (
            { id: spaceBTI.id, name: `${spaceBTIName} ${spaceBTI.order}` }
        ),
    );

    currentSpaceBaseTimeIntervals.splice(0, 0, { id: '0', name: '' });

    function hasSubspaces(): boolean {
        const { spaceBaseTimeIntervals } = data.space;

        if (spaceBaseTimeIntervals.length > 0) {
            for (let i = 0; i < spaceBaseTimeIntervals.length; i += 1) {
                const { subspaceTypeLayouts } = spaceBaseTimeIntervals[i];

                if (subspaceTypeLayouts.length > 0) {
                    for (let j = 0; j < subspaceTypeLayouts.length; j += 1) {
                        const { subspaces } = subspaceTypeLayouts[j].subspaceType;

                        if (subspaces.length > 0) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    return (
        <>
            <EducationSubspaceType
                space={data.space}
                hasSubspaces={hasSubspaces()}
                defaultSubspaceTypes={subspaceTypes}
                spaceBaseTimeIntervals={currentSpaceBaseTimeIntervals}
                educationSpacePath={educationSpacePath}
                tabOptions={tabOptions}
                setTabOptions={setTabOptions}
                spaceRefetch={spaceRefetch}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                createSubspaceType={(
                    id,
                    name,
                    isSelective,
                    isDescriptionEnabled,
                    subspaceTypeLayouts,
                ) => createSubspaceTypeInput({
                    variables: {
                        id,
                        name,
                        isSelective,
                        isDescriptionEnabled,
                        subspaceTypeLayouts,
                    },
                })}
                updateSubspaceType={(
                    id,
                    name,
                    isSelective,
                    isDescriptionEnabled,
                    subspaceTypeLayouts,
                ) => updateSubspaceTypeInput({
                    variables: {
                        id,
                        name,
                        isSelective,
                        isDescriptionEnabled,
                        subspaceTypeLayouts,
                    },
                })}
                removeSubspaceType={(
                    id,
                ) => removeSubspaceType({
                    variables: {
                        id,
                    },
                })}
                updateSpace={(
                    id,
                    name,
                    minCreditCount,
                    maxCreditCount,
                    isDifferentSpaceBaseTimeIntervals,
                    baseTimeIntervalTypeId,
                    spaceBaseTimeIntervals,
                ) => updateSpaceInput({
                    variables: {
                        id,
                        name,
                        minCreditCount,
                        maxCreditCount,
                        isDifferentSpaceBaseTimeIntervals,
                        baseTimeIntervalTypeId,
                        spaceBaseTimeIntervals,
                    },
                })}
                removeSpace={removeSpace}
                refetchSubspaceTypes={refetchSubspaceTypes}
                routerPath={routerPath}
            />

            {
                gotError
                && removeSubspaceTypeError
                && <Alert message={gotError.message} time={3000} />
            }

            {
                updateError
                && <Alert message={updateError.message} time={3000} />
            }

            {
                updateSubspaceTypeError
                && <Alert message={updateSubspaceTypeError.message} time={3000} />
            }

            {
                maxLimitExceeded
                && <Alert message={maxLimitExceeded.message} time={3000} />
            }
        </>
    );
};
