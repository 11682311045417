/* eslint-disable max-len */
import React, { useState } from 'react';
import { format } from 'date-fns';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import {
    PREPARING_STATUSES,
    GENERATED_STATUSES,
    ScheduleGenerationProcessStatus,
    ScheduleGenerationProcess,
    ConstraintMatchCount,
} from '@admin/ScheduleGenerationPage/store/BaseTimeIntervalInstanceModel';
import {
    useBeginAdditionalGeneration,
    useDownloadConfigForAdditionalGenerationQuery,
    useImportAdditionalGenerationResult,
} from '@admin/ScheduleGenerationPage/graphql';
import { HorizontalArrowIcon, HorizontalVector } from '@common/svg';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Loader } from '@common/Loader';
import { UploadButton } from '@admin/ScheduleGenerationPage/UploadButton';

import classes from './AdditionalGeneration.module.scss';

type Props = {
    process: ScheduleGenerationProcess;
    back(): void;
    refetchProcesses(): void;
    isSecret: boolean;
};

enum IgnoreFlag {
    ignoreTeachers = 'преподаватели',
    ignoreStudents = 'студенты',
    ignoreEquipments = 'оборудование',
    ignoreRooms = 'помещения',
}

type IgnoreFlagCheckbox = {
    [Key in keyof typeof IgnoreFlag]: boolean;
};

const VALIDATE_QUERY = gql`
    query validateSchedule($validateScheduleInput: ValidateScheduleInput!) {
        validateSchedule(validateScheduleInput: $validateScheduleInput)
    }
`;

const FINISH_ADDITIONAL_GENERATION = gql`
    mutation finishScheduleGeneration($finishScheduleGenerationInput: FinishScheduleGeneration!) {
        finishScheduleGeneration(finishScheduleGenerationInput: $finishScheduleGenerationInput) {
            id
            name
            from
            to
            status
            constraintMatch {
                constraintMatchCountList {
                    constraintName
                    scoreType
                    matchCount
                }
            }
        }
    }
`;

export function AdditionalGeneration({ process, isSecret, back, refetchProcesses }: Props) {
    const beginAdditionalGeneration = useBeginAdditionalGeneration(refetchProcesses);
    const {
        downloadConfig, loading: configLoading,
    } = useDownloadConfigForAdditionalGenerationQuery(process.id);
    const {
        importResult, called: resultImported,
    } = useImportAdditionalGenerationResult(process.id);
    const [ignoreFlags, setIgnoreFlags] = useState<IgnoreFlagCheckbox>(
        {
            ignoreTeachers: false,
            ignoreStudents: false,
            ignoreEquipments: false,
            ignoreRooms: false,
        },
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleIgnoreFlagsChange = (ignoreFlag: keyof typeof IgnoreFlag) => () => setIgnoreFlags(
        (oldConflictTypes: IgnoreFlagCheckbox) => (
            { ...oldConflictTypes, [ignoreFlag]: !oldConflictTypes[ignoreFlag] }
        ),
    );
    const [finishAdditionGenerationMutation] = useMutation(FINISH_ADDITIONAL_GENERATION);
    function finishAdditionGeneration(doImport: boolean = false) {
        finishAdditionGenerationMutation(
            {
                variables: {
                    finishScheduleGenerationInput: {
                        generationProcessId: process.id,
                        doImport,
                    },
                },
            },
        );
    }
    const [
        validateQuery,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        { data: validationResult, loading: isValidationInProcess },
    ] = useLazyQuery(
        VALIDATE_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );
    function validate() {
        validateQuery(
            {
                variables: {
                    validateScheduleInput: {
                        generationProcessId: process.id,
                        ignoreFlags: getTrueKeysFromObject(ignoreFlags),
                    },
                },
            },
        );
    }
    function isAdditionalGenerationAvailable() {
        if (validationResult?.validateSchedule.length === 0
            && process.status === ScheduleGenerationProcessStatus.Correction) {
            return false;
        }
        return true;
    }
    return (
        <div>
            <div onClick={back} className={classes.additionalGeneration_back}>
                <HorizontalArrowIcon horizontalVector={HorizontalVector.LEFT} width={20} />
                Список периодов генерации
            </div>
            <div className={classes.additionalGeneration}>
                <div>
                    <span className={classes.additionalGeneration_name}>
                        {process.name}
                    </span>
                    <br />
                    {formatDate(process.from)} - {formatDate(process.to)}
                    <br />
                    Статус: {getStatusName(process.status)}
                </div>
                {
                    isSecret ? (
                        <div className={classes.additionalGeneration_actions}>
                            {PREPARING_STATUSES.includes(process.status) && (
                                <>
                                    <ActionButton
                                        onClick={() => beginAdditionalGeneration(process.id)}
                                        className={classes.button}
                                    >
                                        Начать догенерацию
                                    </ActionButton>
                                    <br />
                                </>
                            )}
                            {!GENERATED_STATUSES.includes(process.status) && (
                                <>
                                    <ActionButton
                                        onClick={downloadConfig}
                                        disabled={configLoading}
                                        className={classes.button}
                                    >
                                        Загрузить конфиг
                                    </ActionButton>
                                    <br />
                                    {!resultImported && (
                                        <UploadButton
                                            onLoad={importResult}
                                            className={classes.button}
                                        >
                                            Загрузить результат догенерации на сайт
                                        </UploadButton>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <div className={classes.additionalGeneration_actions}>
                            {
                                !!validationResult?.validateSchedule?.length && (
                                    <div className={classes.filters__conflictTypes}>
                                        <div
                                            className={classes.filters__itemName}
                                        >
                                            <b>
                                                {/* {
                                                    getTrueKeysFromObject(ignoreFlags).length === 4 && (
                                                        <>
                                                            Данные ошибки валидации невозможно проигнорировать. <br />
                                                        </>
                                                    )
                                                } */}
                                                Процесс догенерации невозможен без решения текущих ошибок. <br />
                                                Пожалуйста, решите указанные ошибки и повторите запрос. <br />
                                            </b>
                                        </div>
                                        <ul className={classes.conflicts}>
                                            {
                                                validationResult?.validateSchedule.map(
                                                    (item: string) => (
                                                        <li>
                                                            {item}
                                                        </li>
                                                    ),
                                                )
                                            }
                                        </ul>
                                        <div
                                            className={classes.filters__itemName}
                                        >
                                            Ресурсов недостаточно, это может привести
                                            к нарушениям в догенерации расписания.
                                            <br />
                                            Можно проигнорировать отсутствие следующих ресурсов:
                                        </div>
                                        <div className={classes.filters__checkbox}>
                                            <input
                                                type="checkbox"
                                                id="ignoreTeachers"
                                                name="ignoreTeachers"
                                                value="ignoreTeachers"
                                                checked={ignoreFlags.ignoreTeachers}
                                                onChange={handleIgnoreFlagsChange('ignoreTeachers')}
                                            />
                                            <div onClick={handleIgnoreFlagsChange('ignoreTeachers')}>
                                                {IgnoreFlag.ignoreTeachers}
                                            </div>
                                        </div>
                                        <div className={classes.filters__checkbox}>
                                            <input
                                                type="checkbox"
                                                id="ignoreStudents"
                                                name="ignoreStudents"
                                                value="ignoreStudents"
                                                checked={ignoreFlags.ignoreStudents}
                                                onChange={handleIgnoreFlagsChange('ignoreStudents')}
                                            />
                                            <div onClick={handleIgnoreFlagsChange('ignoreStudents')}>
                                                {IgnoreFlag.ignoreStudents}
                                            </div>
                                        </div>
                                        <div className={classes.filters__checkbox}>
                                            <input
                                                type="checkbox"
                                                id="ignoreEquipments"
                                                name="ignoreEquipments"
                                                value="ignoreEquipments"
                                                checked={ignoreFlags.ignoreEquipments}
                                                onChange={handleIgnoreFlagsChange('ignoreEquipments')}
                                            />
                                            <div onClick={handleIgnoreFlagsChange('ignoreEquipments')}>
                                                {IgnoreFlag.ignoreEquipments}
                                            </div>
                                        </div>
                                        <div className={classes.filters__checkbox}>
                                            <input
                                                type="checkbox"
                                                id="ignoreRooms"
                                                name="ignoreRooms"
                                                value="ignoreRooms"
                                                checked={ignoreFlags.ignoreRooms}
                                                onChange={handleIgnoreFlagsChange('ignoreRooms')}
                                            />
                                            <div onClick={handleIgnoreFlagsChange('ignoreRooms')}>
                                                {IgnoreFlag.ignoreRooms}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                process.status === ScheduleGenerationProcessStatus.Correction
                                && (
                                    <div>
                                        Перед запуском догенерации необходимо произвести валидацию
                                        ошибок. <br />
                                        Пожалуйста, нажмите на кнопку &quot;Валидация&quot;
                                        <br />
                                        {
                                            isValidationInProcess && (
                                                <>
                                                    <br />
                                                    <div>
                                                        Идёт процесс валидации...
                                                        <Loader />
                                                    </div>
                                                    <br />
                                                </>
                                            )
                                        }
                                        <br />
                                    </div>
                                )
                            }
                            {
                                getTrueKeysFromObject(ignoreFlags).length > 0
                                && process.status !== ScheduleGenerationProcessStatus.Generation
                                && process.status !== ScheduleGenerationProcessStatus.Preparing
                                && (
                                    <div className={classes.warning}>
                                        <div>
                                            Были проигнорированы следующие
                                            ресурсы при валидации расписания:
                                        </div>
                                        <ul>{
                                            getTrueKeysFromObject(ignoreFlags).map(
                                                (flag) => (
                                                    <li key={flag}>{
                                                        // eslint-disable-next-line max-len
                                                        Object.getOwnPropertyDescriptor(IgnoreFlag, flag)?.value
                                                    }
                                                    </li>
                                                ),
                                            )
                                        }
                                        </ul>
                                        <div>
                                            Игнорирование данных ресурсов может
                                            привести к конфликтам в догенерации расписания
                                        </div>
                                        <br />
                                    </div>
                                )
                            }
                            {
                                !isAdditionalGenerationAvailable() && (
                                    <>
                                        Валидация прошла успешно, вы можете начать
                                        процесс догенерации. Нажмите на кнопку «Начать догенерацию»
                                        <br />
                                        <br />
                                    </>
                                )
                            }
                            <div className={classes.additionalGeneration__mainActions}>
                                {
                                    process.status === ScheduleGenerationProcessStatus.Correction
                                    && (
                                        <>
                                            <ActionButton
                                                onClick={validate}
                                                className={classes.button}
                                            >
                                                Валидация
                                            </ActionButton>
                                            <br />
                                        </>
                                    )
                                }
                                {
                                    isSaveButtonVisible(process)
                                        ? (
                                            <div className={classes.additionalGeneration__finish}>
                                                <ActionButton
                                                    actionType={ActionTypeOfButton.SECONDARY}
                                                    onClick={
                                                        () => finishAdditionGeneration(false)
                                                    }
                                                    className={classes.button}
                                                >
                                                    Отменить догенерацию
                                                </ActionButton>
                                                <ActionButton
                                                    actionType={ActionTypeOfButton.PRIMARY}
                                                    onClick={
                                                        () => finishAdditionGeneration(true)
                                                    }
                                                    className={classes.button}
                                                >
                                                    Загрузить расписание
                                                </ActionButton>
                                            </div>
                                        )
                                        // не рендерить кнопку когда статус preparing
                                        // eslint-disable-next-line max-len
                                        : process.status === ScheduleGenerationProcessStatus.Correction && (
                                            <>
                                                <ActionButton
                                                    onClick={
                                                        () => beginAdditionalGeneration(
                                                            process.id,
                                                            getTrueKeysFromObject(ignoreFlags),
                                                        )
                                                    }
                                                    disabled={isAdditionalGenerationAvailable()}
                                                    className={classes.button}
                                                >
                                                    Начать догенерацию
                                                </ActionButton>
                                                <br />
                                            </>
                                        )
                                }
                            </div>
                            <>
                                {
                                    (process.status === ScheduleGenerationProcessStatus.Uploading
                                    || process.status === ScheduleGenerationProcessStatus.Preparing)
                                    && (<Loader />)
                                }
                                {
                                    isScoreVisible(process) && (
                                        <>
                                            <br />
                                            <ul className={classes.score}>
                                                {
                                                    // eslint-disable-next-line max-len
                                                    process?.constraintMatch?.constraintMatchCountList.map(
                                                        (item: ConstraintMatchCount) => item.scoreType === 'HARD' && (
                                                            <li>
                                                                {item.constraintName}&nbsp;&nbsp;&nbsp;
                                                                {item.matchCount}
                                                            </li>
                                                        ),
                                                    )
                                                }
                                            </ul>
                                            <br />
                                        </>
                                    )
                                }
                            </>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

function getTrueKeysFromObject(obj: { [index: string]: boolean }) {
    return Object.entries(obj).filter(([, value]) => value).map(([key]) => key);
}

export function formatDate(date: Date | string | number) {
    return format(new Date(date), 'dd.MM.yyyy');
}

function getStatusName(status: ScheduleGenerationProcessStatus) {
    switch (status) {
        case ScheduleGenerationProcessStatus.Correction:
            return 'Запуск валидации';
        case ScheduleGenerationProcessStatus.Preparing:
            return 'Подготовка данных';
        case ScheduleGenerationProcessStatus.Generation:
            return 'Идет процесс догенерации';
        case ScheduleGenerationProcessStatus.GenerationReady:
            return 'Расписание сгенерировано';
        case ScheduleGenerationProcessStatus.GenerationFailed:
            return 'Ошибки догенерации';
        case ScheduleGenerationProcessStatus.Uploading:
            return 'Процесс загрузки расписания';
        case ScheduleGenerationProcessStatus.Uploaded:
            return 'Догенерации расписания завершена';
        case ScheduleGenerationProcessStatus.Generated:
            return 'Догенерации расписания завершена';
        default:
            return '';
    }
}

function isScoreVisible(process: ScheduleGenerationProcess) {
    return (process.status === ScheduleGenerationProcessStatus.Generation
    || process.status === ScheduleGenerationProcessStatus.GenerationReady)
    && !!process.constraintMatch?.constraintMatchCountList?.length;
}

function isSaveButtonVisible(process: ScheduleGenerationProcess) {
    return process.status === ScheduleGenerationProcessStatus.GenerationReady;
}
