import { useLazyQuery } from '@apollo/client';

import { GET_VALIDATE_SCHEDULE } from '../scheduleQueries';

export function useValidationForProcessQuery() {
    const [
        getValidateScheduleQuery,
        {
            data: validateScheduleData,
            loading: validateScheduleDataLoading,
        },
    ] = useLazyQuery<{ validateSchedule: string[] }>(GET_VALIDATE_SCHEDULE, {
        fetchPolicy: 'no-cache',
    });

    return {
        getValidateScheduleQuery: (
            generationProcessId: string,
        ) => {
            getValidateScheduleQuery({
                variables: { generationProcessId },
            });
        },
        validateScheduleData,
        validateScheduleDataLoading,
    };
}
