import React, { ChangeEvent, useState } from 'react';
import { nanoid } from 'nanoid';
import { FetchResult } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Alert } from '@common/Alert';
import {
    Space,
    UpdateSpaceBaseTimeIntervalParameters,
    CreateSpaceBaseTimeIntervalParameters,
    SpaceEducationPeriod,
    SpaceBaseTimeIntervalParameters,
    Mode,
    SpaceBaseTimeInterval,
} from '../../EducationPeriodParametersAndDatesInterfaces';
import classes from './EducationPeriodDatesEdit.module.scss';
import { PeriodTitleEdit } from './PeriodTitleEdit';
import { Interval } from './Interval';

type ApolloPromise = Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>>;

type SetCurrentPeriodFunction =
React.Dispatch<React.SetStateAction<SpaceEducationPeriod | undefined>>;
type SetModeFunction = React.Dispatch<React.SetStateAction<Mode>>;

interface Props {
    space: Space,
    period: SpaceEducationPeriod | undefined,
    setCurrentPeriod: SetCurrentPeriodFunction | undefined,
    setMode: SetModeFunction,
    updatePeriod(
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: UpdateSpaceBaseTimeIntervalParameters[],
    ): ApolloPromise,
    createPeriod(
        id: string,
        spaceId: string,
        name: string,
        spaceBaseTimeIntervalParameters: CreateSpaceBaseTimeIntervalParameters[],
    ): ApolloPromise,
    mode: Mode,
}

export function EducationPeriodDatesEdit({
    space,
    period: periodProps,
    setCurrentPeriod,
    setMode,
    updatePeriod,
    createPeriod,
    mode,
}: Props) {
    const [
        errorAlerts,
        setErrorAlerts] = useState<JSX.Element[] | []>([]);
    const showError = (text: string) => setErrorAlerts((arr) => [...arr, (<Alert
        key={nanoid()}
        message={text}
        time={5000}
    />)]);
    const getNewPeriod = (): SpaceEducationPeriod => ({ id: nanoid(), name: '', spaceBaseTimeIntervalParameters: [] });
    const [period, setPeriod] = useState<SpaceEducationPeriod>(
        (periodProps && mode !== Mode.CREATE ? cloneDeep(periodProps) : getNewPeriod()),
    );

    const setName = (e: ChangeEvent<HTMLInputElement>) => {
        const periodCopy = cloneDeep(period);
        if (periodCopy) {
            periodCopy.name = e.target.value;
        }
        setPeriod(periodCopy);
    };
    const setParameters = (btiId: string, parameters: SpaceBaseTimeIntervalParameters) => {
        const periodCopy = cloneDeep(period);
        const parametersCopy = cloneDeep(parameters);
        const parameterIndex = periodCopy.spaceBaseTimeIntervalParameters.findIndex(
            (element) => (element.spaceBaseTimeIntervalId === btiId),
        );
        if (periodCopy && (parameterIndex > -1)) {
            periodCopy.spaceBaseTimeIntervalParameters[parameterIndex] = parametersCopy;
        } else {
            periodCopy.spaceBaseTimeIntervalParameters.push(parametersCopy);
        }
        setPeriod(periodCopy);
    };
    const savePeriod = () => {
        const parameters = [...period.spaceBaseTimeIntervalParameters];
        if (isValidSchedulePeriod()) {
            if (mode === Mode.CREATE) {
                createPeriod(
                    period.id,
                    space.id,
                    period.name,
                    parameters,
                ).then(() => {
                });
                if (setCurrentPeriod) {
                    setCurrentPeriod(period);
                }
            } else if (mode === Mode.EDIT) {
                updatePeriod(
                    period.id,
                    space.id,
                    period.name,
                    parameters,
                ).then(() => {
                    // TODO нужно подумать можно ли сделать лучше
                    if (setCurrentPeriod) {
                        setCurrentPeriod(period);
                    }
                });
            }
        }
    };
    const cancel = () => {
        setMode(Mode.VIEW);
    };
    const getParameters = (spaceBaseTimeInterval: SpaceBaseTimeInterval) => (
        period?.spaceBaseTimeIntervalParameters.find(
            (parameters) => parameters.spaceBaseTimeIntervalId === spaceBaseTimeInterval.id,
        )
    );

    function isValidSchedulePeriod() {
        let isValidPeriod = true;
        if (period.name.length === 0) {
            showError('Укажите название учебного периода');
            isValidPeriod = false;
        }
        if (period.spaceBaseTimeIntervalParameters.length === 0) {
            showError('Заполните как минимум один период перед сохранением');
            isValidPeriod = false;
        }
        period.spaceBaseTimeIntervalParameters.forEach(({ moduleAssessment }) => {
            if (moduleAssessment) {
                const { isDynamicStartDate, startDate, endDate } = moduleAssessment;
                if (!isDynamicStartDate && !startDate) {
                    showError('Укажите дату начала проведения СОП');
                    isValidPeriod = false;
                }
                if (!endDate) {
                    showError('Укажите дату окончания проведения СОП');
                    isValidPeriod = false;
                }
            }
        });
        return isValidPeriod;
    }

    return (
        <div className={classes.educationPeriodDatesEdit}>
            <PeriodTitleEdit
                value={period?.name}
                onChange={setName}
            />

            {space.spaceBaseTimeIntervals.map(spaceBaseTimeInterval => (
                <Interval
                    key={spaceBaseTimeInterval.id}
                    spaceBaseTimeInterval={spaceBaseTimeInterval}
                    baseTimeIntervalType={space.baseTimeIntervalType}
                    spaceBaseTimeIntervalParameters={getParameters(spaceBaseTimeInterval)}
                    setParameters={setParameters}
                />
            ))}

            <div className={classes.educationPeriodDatesEdit__buttonPanel}>
                <ActionButton
                    actionType={ActionTypeOfButton.SECONDARY}
                    onClick={cancel}
                >
                    Отменить
                </ActionButton>

                <ActionButton
                    className={classes.educationPeriodDatesEdit__saveButton}
                    onClick={savePeriod}
                >
                    Сохранить
                </ActionButton>
            </div>

            {errorAlerts}
        </div>
    );
}
