import React, { Fragment, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { Alert } from '@common/Alert';
import { Loader, LoaderSizes } from '@common/Loader';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { russianSelectionStatus, SelectionStatus, Space } from '@admin/EducationPeriodParametersAndDates/EducationPeriodParametersAndDatesInterfaces';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
/* eslint-disable import/no-cycle */
import { ButtonName, buttonHooks } from './buttonHooks';

import classes from './Parameters.module.scss';

interface Props {
    status: SelectionStatus;
    BTIId: string;
    isSecret: boolean;
    refetchGetSpaces: (variables?:
    Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{
        spaces: Space[];
    }>>
}

export const SelectionPointsButtons = ({
    status,
    isSecret,
    BTIId,
    refetchGetSpaces,
}: Props) => {
    const [file, setFile] = useState<{ value: string, name: string }>({ value: '', name: '' });
    const {
        error,
        loading,
        ...heandlers
    } = buttonHooks(BTIId, setFile);
    useEffect(() => {
        if (file.value) {
            const blob = new Blob([file.value], {
                type: 'text/csv;charset=utf-8',
            });
            saveAs(blob, `${file.name}`);
        }
    }, [file.value, file.name]);
    const buttonsKey = Object.keys(ButtonName);

    return (
        <div
            className={classes.paramaters__section_button}
        >
            {!loading ? buttonsKey.map((key) => {
                const typedKey = key as keyof typeof ButtonName;
                return (
                    <Fragment key={key}>
                        {getButtonStatus(ButtonName[typedKey], status, isSecret)
                            && (
                                <ActionButton
                                    actionType={ActionTypeOfButton.SECONDARY}
                                    onClick={async () => {
                                        await heandlers[ButtonName[typedKey]]();
                                        await refetchGetSpaces();
                                    }}
                                    className={classes.paramaters__selection_button}
                                >
                                    {ButtonName[typedKey]}
                                </ActionButton>
                            )}

                    </Fragment>
                );
            }) : (<Loader size={LoaderSizes.medium} />)}
            {error && (
                <Alert
                    message={error.message}
                    time={8000}
                />
            )}
            <div className={classes.paramaters__selectionStatus}>
                Текущий статус: {
                    russianSelectionStatus[status]
                }
            </div>
        </div>
    );
};

function getButtonStatus(name: ButtonName, status: SelectionStatus, isSecret?: boolean) {
    switch (name) {
        case ButtonName.STARTSUBSECTIONSELECTION:
            return (status === SelectionStatus.SUBSPACESELECTIONENABLED);
        case ButtonName.ENDSUBSECTIONSELECTION:
            return (status === SelectionStatus.SUBSPACESELECTIONINPROCESS);
        case ButtonName.STARTSUBSPACEDISTRIBUTION:
            return (status === SelectionStatus.SUBSPACEDISTRIBUTIONENABLED);
        case ButtonName.STARTMODULESELECTION:
            return (status === SelectionStatus.MODULESELECTIONENABLED);
        case ButtonName.ENDMODULESELECTION:
            return (status === SelectionStatus.MODULESELECTIONINPROCESS);
        case ButtonName.STARTMODULEDISTRIBUTION:
            return (status === SelectionStatus.MODULEDISTRIBUTIONENABLED);
        case ButtonName.LOADSTUDENTPRIORITY:
            return (status === SelectionStatus.MODULESELECTIONINPROCESS
                || status === SelectionStatus.MODULEDISTRIBUTIONENABLED
                || status === SelectionStatus.SELECTIONFINISHED);
        case ButtonName.LOADSUBSPACEDISTRIBUTION:
            return (status === SelectionStatus.SUBSPACEDISTRIBUTIONPENDING
                || status === SelectionStatus.MODULESELECTIONENABLED);
        case ButtonName.LOADMODULEDISTRIBUTION:
            return (status === SelectionStatus.MODULEDISTRIBUTIONPENDING
                || status === SelectionStatus.SELECTIONFINISHED);
        case ButtonName.APPROVESTUDENTMODULEDISTRIBUTION:
            return (isSecret && status === SelectionStatus.MODULEDISTRIBUTIONPENDING);
        case ButtonName.APPROVESTUDENTSUBSPACEDISTRIBUTION:
            return (isSecret && status === SelectionStatus.SUBSPACEDISTRIBUTIONPENDING);
        default:
            return false;
    }
}
