import React from 'react';

import classes from './ListItems.module.scss';

import closeBtn from '../../../source/CloseBTN.png';

import { RoomCreationEquipment } from '../../../RoomTypes';

interface ListEquipmentProps {
    equipments: RoomCreationEquipment[];
    removeItems(id: string): void;
    removeRoomItems(id: string): void;
}

export function ListItems({
    removeItems,
    removeRoomItems,
    equipments,
}: ListEquipmentProps): JSX.Element {
    function deleteItem(id: string) {
        removeItems(id);
        removeRoomItems(id);
    }
    return (
        <div className={classes.formEquip_container}>
            <div className={classes.formEquip_header}>
                <div className={classes.formEquip_subtitle}>Наименование:</div>
                <div className={classes.formEquip_subtitle}>Количество:</div>
            </div>
            <ul className={classes.formEquip_list}>
                {
                    equipments.map((element: RoomCreationEquipment) => (
                        <li
                            className={classes.formEquip_listBlock}
                            key={element.id}
                        >
                            <div className={classes.formEquip_nameGroup}>
                                <div className={classes.formEquip_text}>
                                    {element.name}
                                </div>
                                <div className={classes.formEquip_text}>
                                    ({element.count})
                                </div>
                            </div>

                            <button
                                type="button"
                                className={classes.formEquip_btn}
                                onClick={() => deleteItem(element.id)}
                            >
                                <img className={classes.formEquip_img} src={closeBtn} alt="X" />
                            </button>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}
