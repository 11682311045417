import React, { useEffect, useState } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import { NewModule } from '@admin/NewModule';
import { Tabs } from '@common/Tabs';
import UserView from '@store/UserView';
import { AuthRoute } from '@common/AuthRoute';

import { ModulesList } from './ModulesList';
import { Module as ModuleType, SkillType } from '../../../subSpaceAdmin/subSpaceTypes';
import { InterfaceSection, skills_skills, ViewType } from '../../../graphql-query-types';
import { External } from '../External';
import { ExternalModule } from '../External/ExternalModule';

import classes from './ModulesPage.module.scss';

interface Props {
    publicModules: ModuleType[];
    moduleDrafts: ModuleType[];
    removeId?: string | null;
    skillTypes: SkillType[];
    skills: skills_skills[];
    removeModule(id: string): void;
}

export const ModulesPage = ({
    publicModules,
    removeModule,
    moduleDrafts,
    removeId,
    skills,
    skillTypes,
}: Props) => {
    const [modules, setModules] = useState(publicModules);
    const [drafts, setDrafts] = useState(moduleDrafts);

    useEffect(() => {
        setModules(modules.filter(({ id }) => id !== removeId));
        setDrafts(drafts.filter(({ id }) => id !== removeId));
    }, [removeId]);

    useEffect(() => setModules(publicModules), [publicModules]);

    useEffect(() => setDrafts(moduleDrafts), [moduleDrafts]);

    const { path } = useRouteMatch();

    const tabs: string[][] = [];

    const sections = UserView.user?.availableInterfaceSections;

    sections?.forEach(section => {
        if (section === InterfaceSection.ActiveModules) {
            tabs.push(['Публичные модули', 'public']);
        }

        if (section === InterfaceSection.ModuleDrafts) {
            tabs.push(['Черновики модулей', 'drafts']);
        }

        if (section === InterfaceSection.ExternalModules) {
            tabs.push(['Внешние модули', 'external']);
        }
    });

    tabs.push(['Новый модуль', 'new']);

    return (
        <>
            <div className={classes.modulesPage__tabs}>
                <Tabs tabsOptions={tabs} />
            </div>

            <Switch>
                <AuthRoute exact path={`${path}`} requiredUserTypes={[ViewType.Admin]}>
                    <Redirect to={`${path}/public`} />
                </AuthRoute>

                <AuthRoute path={`${path}/public/:id/:eventId?`} requiredUserTypes={[ViewType.Admin]}>
                    <NewModule path={path} isDraft={false} />
                </AuthRoute>

                <AuthRoute path={`${path}/public`} requiredUserTypes={[ViewType.Admin]}>
                    <ModulesList
                        modules={modules}
                        path={`${path}/public`}
                        removeModule={removeModule}
                        skills={skills}
                        skillTypes={skillTypes}
                    />
                </AuthRoute>

                <AuthRoute path={`${path}/new`} requiredUserTypes={[ViewType.Admin]}>
                    <NewModule path={path} isDraft={false} />
                </AuthRoute>

                <AuthRoute path={`${path}/drafts/:id`} requiredUserTypes={[ViewType.Admin]}>
                    <NewModule path={path} isDraft />
                </AuthRoute>

                <AuthRoute path={`${path}/drafts`} requiredUserTypes={[ViewType.Admin]}>
                    <ModulesList
                        modules={drafts}
                        path={`${path}/drafts`}
                        removeModule={removeModule}
                        skills={skills}
                        skillTypes={skillTypes}
                    />
                </AuthRoute>
                <AuthRoute path={`${path}/external/:externalModuleId`} requiredUserTypes={[ViewType.Admin]}>
                    <ExternalModule />
                </AuthRoute>
                <AuthRoute path={`${path}/external`} requiredUserTypes={[ViewType.Admin]}>
                    <External />
                </AuthRoute>
            </Switch>
        </>
    );
};
