import React from 'react';
import { upperFirst } from 'lodash';

import classes from './SkillTab.module.scss';

import {
    SkillWhithLevel as Skills,
} from '../Config/interfaces';

interface Props {
    skills: Skills[];
}

export function SkillTab({
    skills,
}: Props) {
    return (
        <div className={classes.skillTab}>
            {skills.map(({ skill, level }) => (
                <div key={skill.id} className={classes.skillItem}>
                    <span className={classes.skillItem__name}>
                        {upperFirst(skill.fullName)}
                    </span>
                    {level && (
                        <span className={classes.skillProgress}>
                            <span className={classes.skillProgress__level}>
                                {level}
                            </span>
                        </span>
                    )}
                </div>
            ))}
        </div>
    );
}
