import React, { Fragment } from 'react';
import range from 'lodash/range';
import { observer } from 'mobx-react';

import { Title, TitleTypes } from '@common';
import { getSuggestFormProps } from '@admin/NewModule';

import { moduleStore, dictionaryStore, PointSkill } from '../Store';
import { Select } from '../Select';
import { Section, SuggestForm, SuggestFormInitialData } from '../Section';

import styles from './EvaluationPoint.module.scss';

interface Props {
    id: string;
}

export const EvaluationPointSkills = observer(({ id }: Props): JSX.Element => {
    const normalizeSkills = (skills: PointSkill[]) => skills.map(skill => ({
        ...skill.skill, level: skill.level,
    }));
    const module = moduleStore.moduleModel;
    const evaluationPoint = module.getEvaluationPoint(id);

    const eventsOptions = module.eventsWithoutTest
        .map(event => {
            const name = moduleStore.isAssignment(event) ? `Сам. работа ${event.assignmentNumber} ${event.topic ?? ''}` : `Встреча ${event.meetingNumber} ${event.topic ?? ''}`;
            return { id: event.id, name, order: event.order };
        })
        .sort((a, b) => a.order - b.order);

    const { meetingSkillTypes, moduleSkillTypes } = dictionaryStore.moduleSettings;
    const skillTypes = [...meetingSkillTypes, ...moduleSkillTypes];

    return (
        <>
            <div className={styles.evaluationPoint__container}>
                <Select
                    value={evaluationPoint?.previousEvent?.id}
                    options={eventsOptions}
                    name="previousEventId"
                    label="Проходит после встречи:"
                    onChange={({ target }) => module.evaluationPointList
                        .updateEvaluationPointPreviousEvent(
                            id, target.options[target.options.selectedIndex].value,
                        )}
                />
            </div>
            {skillTypes.map(skillType => {
                const type = dictionaryStore.getSkillType(skillType.id);
                const name = type!.name?.nominativePlural;
                const finalTypeId = dictionaryStore.getFinalSkillTypeId(skillType.id);
                const { isLevelIncreased } = type;
                const maxLevel = type.properties?.maxLevel;
                const skills = evaluationPoint?.evaluationPointSkills
                    ?.filter((skill: PointSkill) => skill.skill.typeId === finalTypeId) ?? [];
                const skillsForPoint = module.getSkillsSuggestForEvaluationPoint(
                    evaluationPoint?.id ?? '', finalTypeId,
                );
                const formProps = getSuggestFormProps(normalizeSkills(skillsForPoint));
                const typeId = finalTypeId;
                return {
                    ...skillType, id: typeId, name, isLevelIncreased, maxLevel, skills, formProps,
                };
            })
                .map(skillType => {
                    const possibleSkillLevelOptions = skillType.skills
                        .map(skill => {
                            const maxLevel = skill.skill.maxLevel ?? 1;
                            const options = skillType.isLevelIncreased
                                ? range(1, maxLevel + 1).map(item => ({
                                    id: String(item), name: String(item),
                                })) : [];
                            return { name: skill.skill.id, options };
                        }) ?? [];
                    const currentSkillLevels = evaluationPoint?.evaluationPointSkills
                        ?.filter(({ skill }) => skill.typeId === skillType.id)
                        .map(skill => String(skill.level));
                    const onChoseSkillLevel = ({
                        target,
                    }: React.ChangeEvent<HTMLSelectElement>) => {
                        const { options } = target;
                        module.evaluationPointList
                            .updateEvaluationPointSkillLevel(
                                id,
                                skillType.id,
                                target.name,
                                Number(options[options.selectedIndex].text),
                            );
                    };
                    const buttonInfo = {
                        down: false,
                        name: '',
                        itemWithLevel: skillType.isLevelIncreased,
                        selectedOptions: possibleSkillLevelOptions,
                        values: currentSkillLevels,
                        onChangeSelected: onChoseSkillLevel,
                    };
                    return (
                        <Fragment key={skillType.id}>
                            <div className={styles.evaluationPoint__container}>
                                <Title type={TitleTypes.Subtitle}>
                                    {`${skillType.name}, которые оцениваются в этой точке:`}
                                </Title>
                                {evaluationPoint?.previousEvent?.id && (
                                    <Section
                                        list={normalizeSkills(skillType.skills)}
                                        formatItem={({ name }: any) => <>{name}</>}
                                        initialData={SuggestFormInitialData}
                                        form={SuggestForm}
                                        formProps={skillType.formProps}
                                        onChange={items => {
                                            module.evaluationPointList.updateEvaluationPointSkills(
                                                id,
                                                dictionaryStore.getFinalSkillTypeId(skillType.id),
                                                items.map(item => ({
                                                    skill: item,
                                                    level: item.level,
                                                })),
                                            );
                                        }}
                                        buttonInfo={buttonInfo}
                                    />
                                )}
                            </div>
                        </Fragment>
                    );
                })}
        </>
    );
});
