import React, { Fragment } from 'react';

import classes from './ModuleCard.module.scss';

import { ModuleContent } from '../ModuleContent';

import { ModuleSettings, StudentModule, StudentSchedule } from '../Config/interfaces';

interface Props {
    isElectiveFork?: boolean;
    modules?: StudentModule[];
    studentSchedule?: StudentSchedule;
    moduleSettings?: ModuleSettings;
    forkId?: string;
}

export function ModuleCards({
    isElectiveFork = false,
    forkId,
    modules,
    studentSchedule,
    moduleSettings,
}: Props): JSX.Element {
    const sortedStudentModules = modules?.sort((a, b) => (
        Number(b.isStudentModuleAssessmentActive) - Number(a.isStudentModuleAssessmentActive)
    ));
    return (
        <ul className={classes.moduleCards__list}>
            {sortedStudentModules?.map(({
                module,
                slotId,
                isOpen,
                isStudentModuleAssessmentActive,
                id,
            }) => (
                <Fragment key={module.id}>
                    {module && (
                        <li
                            key={module.id}
                            className={classes.moduleCards__item}
                        >
                            <ModuleContent
                                slotId={slotId}
                                forkId={forkId}
                                isElectiveFork={isElectiveFork}
                                module={module}
                                studentSchedule={studentSchedule}
                                moduleSettings={moduleSettings}
                                isOpen={isOpen}
                                isStudentModuleAssessmentActive={isStudentModuleAssessmentActive}
                                studentModuleId={id}
                            />
                        </li>
                    )}
                </Fragment>
            ))}
        </ul>
    );
}
