import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import cn from 'classnames';
import classes from './EventItem.module.scss';

import EventStore from '../store';

import {
    EventType,
    EvaluationPoint,
    AttendanceStudentSchedule,
} from '../Config/interfaces';

enum EventTypeEnum {
    Meeting = 'Встреча',
    Assignments = 'Самостоятельная работа',
    EvaluationPoints = 'Точка оценки',
}

interface Props {
    moduleEventId?: string;
    event: EventType | EvaluationPoint;
    attendanceStudentSchedule: AttendanceStudentSchedule[] | undefined;
    checkEvent(event: any): boolean;
}
function EventItem({
    moduleEventId,
    event,
    attendanceStudentSchedule,
    checkEvent,
}: Props): JSX.Element {
    const { url } = useRouteMatch();
    return (
        <>
            <Link to={`${url}/${event.id}`}>
                <div
                    className={cn(classes.eventItem, {
                        [classes.eventItem_status_active]: moduleEventId === event.id,
                        [classes.eventItem_status_passed]: checkEvent(event),
                    })}
                    onClick={() => EventStore.setSelectedEvent(event)}
                >
                    {attendanceStudentSchedule?.map((item) => (
                        (item.meetingId === event.id && !item.attendance) && (
                            <div
                                className={classes.eventItem__attendanceSign}
                                key={item.meetingId}
                            />
                        )
                    ))}
                    <span className={classes.eventItem__title}>
                        {(event.eventName === 'meetings') && (`${EventTypeEnum.Meeting} ${event.eventOrder}: `)}
                        {(event.eventName === 'assignments') && (`${EventTypeEnum.Assignments} ${event.eventOrder}: `)}
                        {(event.eventName === 'evaluationPoints') && (`${EventTypeEnum.EvaluationPoints} ${event.eventOrder}: `)}
                    </span>
                    <span className={classes.eventItem__description}>
                        {event.topic}
                    </span>
                </div>
            </Link>
        </>
    );
}

export default React.memo(EventItem);
