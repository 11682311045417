import React, { ChangeEvent } from 'react';
import { IconDeprecated } from '@common';
import { WorkingInterval } from '../../../../../../EducationPeriodParametersAndDatesInterfaces';
import { getFormatedTime } from '../../../../../EducationPeriodParametersDaySchedules';
import classes from './Interval.module.scss';

interface Props {
    interval : WorkingInterval,
    onChangeStart(e: ChangeEvent<HTMLInputElement>): void,
    onChangeEnd(e: ChangeEvent<HTMLInputElement>): void,
    deleteInterval():void,
}

export function Interval({
    interval,
    onChangeStart,
    onChangeEnd,
    deleteInterval,
}: Props) {
    return (
        <div className={classes.interval}>
            <div className={classes.interval__grayBox}>
                <input
                    type="text"
                    value={getFormatedTime(interval.start)}
                    onChange={onChangeStart}
                    className={classes.interval__startTextInput}
                />
            </div>
            <div className={classes.interval__grayBox}>
                <IconDeprecated id="clock" className={classes.interval__clock} />
            </div>
            <IconDeprecated id="longDash" className={classes.interval__longDash} />
            <div className={classes.interval__grayBox}>
                <input
                    type="text"
                    value={getFormatedTime(interval.end)}
                    onChange={onChangeEnd}
                    className={classes.interval__endTextInput}
                />
            </div>
            <div className={classes.interval__grayBox}>
                <IconDeprecated id="clock" className={classes.interval__clock} />
            </div>
            <IconDeprecated
                id="Cross"
                className={classes.interval__delete}
                click={deleteInterval}
            />
        </div>
    );
}
