import { gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

const IMPORT_RESULTS = gql`
    mutation import($importScheduleResultInput: JSON!) {
        importScheduleResult(importScheduleResultInput: $importScheduleResultInput)
    }
`;

export function useImportResultsMutation() {
    const [importResults] = useMutationWithErrorHandling(IMPORT_RESULTS);

    return (importScheduleResultInput: string) => importResults({
        variables: { importScheduleResultInput },
    });
}
