/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Alert } from '@common/Alert';
import classes from './AddItems.module.scss';

import { AddItems } from './AddItems';
import { RoomCreationEquipment, AlertMessage } from '../../RoomTypes';

interface AddItemsProps {
    storeEquipment?: RoomCreationEquipment[];
    equipmentsRoom: RoomCreationEquipment[];
    addRoomItems(obj: RoomCreationEquipment): void;
    removeRoomItems(id: string): void;
}

interface Suggestion {
    activeSuggestion: number;
    filteredSuggestions: RoomCreationEquipment[];
    showSuggestions: boolean;
}

export const AddItemsContainer = (
    {
        addRoomItems,
        equipmentsRoom: equipments,
        removeRoomItems,
        storeEquipment,
    }: AddItemsProps,
) => {
    const [suggestion, setSuggestion] = useState<Suggestion>({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
    });

    const [item, setItem] = useState<RoomCreationEquipment>({ id: '', name: '', count: 0 });

    const [alert, setAlert] = useState<AlertMessage>({
        alert: false,
        message: '',
    });

    const addItemsInRoom = () => {
        if (item.name.length > 0 && item.count > 0 && storeEquipment !== undefined) {
            const equipment = storeEquipment
                .find(element => element.name === item.name && element.id === item.id);
            if (equipment && equipment.count >= item.count) {
                addRoomItems(item);
                setItem({
                    ...item,
                    id: '',
                    name: '',
                    count: 0,
                });
                setSuggestion({
                    ...suggestion, showSuggestions: false,
                });
            } else if (!equipment) {
                setAlert({ alert: true, message: 'Выберите предмет из списка' });
            } else if (equipment && equipment.count < item.count) {
                setAlert({ alert: true, message: 'Извините, предмет уже добавлен или такого количества на складе нет!' });
            } else {
                setAlert({ alert: true, message: 'Извините, но такого предмета на складе нет!' });
            }
        }
    };

    const handleChangeEquip = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = target;

        setItem({
            ...item,
            name: `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
        });

        if (storeEquipment !== undefined) {
            const filteredSuggestionsArray: RoomCreationEquipment[] | [] = storeEquipment
                .filter(({ name }) => name.toLowerCase().includes(value.toLowerCase()));
            setSuggestion({
                ...suggestion,
                activeSuggestion: 0,
                filteredSuggestions: filteredSuggestionsArray,
                showSuggestions: (Boolean(value)),
            });
        }
    };

    const handleChangeCount = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = currentTarget;

        setItem({
            ...item,
            count: Math.round(Number(value)),
            id: item.id,
        });
    };

    const addItemOnClick = ({ currentTarget }: any) => {
        setSuggestion({
            ...suggestion,
            filteredSuggestions: [],
            showSuggestions: false,
        });
        setItem({
            ...item,
            name: currentTarget.firstChild.textContent,
            id: currentTarget.id,
        });
    };

    const removeItemsFromList = (id: string) => {
        removeRoomItems(id);
    };

    // const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>): void => {
    //     const { filteredSuggestions, activeSuggestion } = suggestion;

    //     if (key === 'Enter') {
    //         setSuggestion({
    //             ...suggestion,
    //             activeSuggestion: 0,
    //             showSuggestions: false,
    //         });
    //         if (filteredSuggestions.length > 0) {
    //             setItem({
    //                 ...item,
    //                 name: filteredSuggestions[activeSuggestion].name,
    //             });
    //         }
    //     } else if (key === 'ArrowUp') {
    //         if (activeSuggestion > 0) {
    //             setSuggestion({
    //                 ...suggestion,
    //                 activeSuggestion: activeSuggestion - 1,
    //             });
    //         }
    //     } else if (key === 'ArrowDown') {
    //         if (activeSuggestion < filteredSuggestions.length - 1) {
    //             setSuggestion({
    //                 ...suggestion,
    //                 activeSuggestion: activeSuggestion + 1,
    //             });
    //         }
    //     }
    // };

    const closeSuggestionList = () => {
        setSuggestion({ ...suggestion, showSuggestions: false });
    };

    useEffect(() => {
        document.addEventListener('click', closeSuggestionList);
        return () => document.removeEventListener('click', closeSuggestionList);
    }, []);

    useEffect(() => {
        const alertTimeout = setTimeout(() => setAlert({ alert: false, message: '' }), 2000);
        return () => {
            clearTimeout(alertTimeout);
        };
    }, [alert]);
    // eslint-disable-next-line consistent-return
    const showListItems = () => {
        const { showSuggestions, activeSuggestion, filteredSuggestions } = suggestion;

        if (showSuggestions) {
            if (filteredSuggestions.length > 0) {
                let className: string;
                return (
                    <ul
                        className={classes.suggestions}
                    >
                        {
                            filteredSuggestions.map((element, index) => {
                                if (index === activeSuggestion) {
                                    className = `${classes.suggestionActive}`;
                                }
                                return (
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                    <li
                                        className={className}
                                        key={element.id}
                                        onClick={(e) => addItemOnClick(e)}
                                        id={element.id}
                                    >
                                        <span>{element.name}</span>
                                        <span className={classes.item__count}>({element.count})</span>
                                    </li>
                                );
                            })
                        }
                    </ul>
                );
            } if (filteredSuggestions.length === 0 && item.name.length > 0) {
                return (
                    <ul className={classes.noSuggestions}>
                        <li>нет такого оборудования, но <a href="/university/equipment/all">можно создать!</a></li>
                    </ul>
                );
            }
        }
    };
    return (
        <>
            <AddItems
                item={item}
                handleChangeEquip={handleChangeEquip}
                handleChangeCount={handleChangeCount}
                // handleKeyDown={handleKeyDown}
                addItemsInRoom={addItemsInRoom}
                showListItems={showListItems}
                removeItems={removeItemsFromList}
                removeRoomItems={removeRoomItems}
                equipmentList={equipments}
            />
            {
                alert.alert && <Alert message={alert.message} time={2500} />
            }
        </>
    );
};
