import React, { ChangeEvent } from 'react';
import classes from './Parameter.module.scss';

interface Props {
    onChange(e: ChangeEvent<HTMLInputElement>): void,
    title: string,
    value: string|number,
}

export function Parameter({
    onChange,
    title,
    value,
}: Props) {
    return (
        <div className={classes.parameter}>
            <div className={classes.parameter__box}>
                <input
                    type="text"
                    className={classes.parameter__input}
                    onChange={onChange}
                    value={value}
                />
            </div>
            <div className={classes.parameter__description}>
                {title}
            </div>
        </div>
    );
}
