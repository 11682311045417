import React, { useState } from 'react';
import { ArrowIcon } from '@common/svg/ArrowIcon';
import { ModuleSettings, StudentSchedule, StudentSubspace } from '../Config/interfaces';
import { ModuleCards } from '../ModuleCard';

import classes from './Subspaces.module.scss';

interface Props {
    studentSchedule?: StudentSchedule;
    moduleSettings?: ModuleSettings;
    studentSubspace?: StudentSubspace;
}

export function CurrentSubspace({
    studentSubspace,
    studentSchedule,
    moduleSettings,
}: Props) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            {studentSubspace && (
                <div
                    className={classes.subspace}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <ArrowIcon
                        isOpen={isOpen}
                    />
                    <div className={classes.subspace__title}>
                        {`${studentSubspace?.subspace.name}, ${getModuleDeclension(studentSubspace?.studentModules.length)}`}
                    </div>
                </div>
            )}
            {isOpen && (
                <ModuleCards
                    modules={studentSubspace?.studentModules}
                    studentSchedule={studentSchedule}
                    moduleSettings={moduleSettings}
                />
            )}
        </>

    );
}

function getModuleDeclension(count: number) {
    if (count === 1) {
        return `${count} модуль`;
    }
    if ((count > 1) && (count < 5)) {
        return `${count} модуля`;
    }

    return `${count} модулей`;
}
