import React from 'react';

import classes from './ModuleSkillsListSpecificSkills.module.scss';

interface Props {
    skillListIntro: any;
    skillListAutro: any;
}

export function ModuleSkillsListSpecificSkills({
    skillListIntro,
    skillListAutro,
}: Props):JSX.Element {
    return (
        <div className={classes.moduleSkillsListSpecificSkillsWrapper}>
            <div className={classes.moduleSkillsListSpecificSkills}>
                <ul className={classes.moduleSkillsListSpecificSkills__list}>
                    {skillListIntro.map((skill: any) => (
                        <li
                            key={skill.id}
                            className={classes.moduleSkillsListSpecificSkills__item}
                        >
                            <div
                                className={classes.moduleSkillsListSpecificSkills__currentSkillName}
                            >
                                {skill.name}
                            </div>
                            <div
                                className={
                                    classes.moduleSkillsListSpecificSkills__currentSkillLevel
                                }
                            >
                                {skill.level}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={classes.moduleSkillsListSpecificSkills}>
                <ul className={classes.moduleSkillsListSpecificSkills__list}>
                    {skillListAutro.map((skill: any) => (
                        <li
                            key={skill.id}
                            className={classes.moduleSkillsListSpecificSkills__item}
                        >
                            <div
                                className={classes.moduleSkillsListSpecificSkills__currentSkillName}
                            >
                                {skill.name}
                            </div>
                            <div
                                className={
                                    classes.moduleSkillsListSpecificSkills__currentSkillLevel
                                }
                            >
                                {skill.level}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
