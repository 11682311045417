import React from 'react';
import cn from 'classnames';
import {
    WeekDay,
    WeekDaysRussian,
} from '../../../EducationPeriodParametersAndDatesInterfaces';
import { Interval } from './Interval';
import classes from './WeekDayIntervals.module.scss';

interface Props {
    weekDay: WeekDay,
}

export function WeekDayIntervals(
    {
        weekDay,
    }: Props,
) {
    return (
        <div className={cn(classes.weekDayIntervals__day, {
            [classes.weekDayIntervals__day_disable]: (
                weekDay[1].length === 0
            ),
        })}
        >
            <div
                className={classes.weekDayIntervals__dayTitle}
            >
                {`${WeekDaysRussian[weekDay[0]]}:`}
            </div>
            <div
                className={
                    classes.weekDayIntervals__intervals
                }
            >
                <Interval weekDay={weekDay} />
            </div>
        </div>
    );
}
