import { format } from 'date-fns';
import React, { useState } from 'react';

import { EquipmentShortageViolation } from '../../scheduleViolationInterface';
import { ArrowInShowViolation } from '../ArrowInShowViolation';

import classes from '../scheduleViolation.module.scss';

interface Props {
    equipmentShortageViolation: EquipmentShortageViolation;
}

export const ShowEquipmentShortageViolation = ({ equipmentShortageViolation }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    return equipmentShortageViolation && (
        <div className={classes.schedule__violation_container}>
            <div
                className={classes.schedule__violation_title}
                onClick={() => setIsOpen(!isOpen)}
            >
                {equipmentShortageViolation?.violationName}
                <ArrowInShowViolation isOpen={isOpen} />
            </div>
            {isOpen && equipmentShortageViolation?.equipmentShortagesByDate
                .map((violationsByDate) => (
                    <div
                        className={classes.schedule__violation_conflict_title}
                        key={`${violationsByDate.date}${violationsByDate.startTime}${violationsByDate.equipmentShortages}`}
                    >
                        Время: {`${violationsByDate.startTime} ${format(new Date(violationsByDate.date), 'dd-MM-yy ')} `}
                        {violationsByDate.equipmentShortages.map(equipmentShortage => (
                            <div
                                key={`${equipmentShortage.equipment.id} ${violationsByDate.date}`}
                                className={classes.schedule__violation_conflict}
                            >
                                <div>Оборудование: {`${equipmentShortage.equipment.name} `}</div>
                                <div>Не хватает: {`${equipmentShortage.count}шт `}</div>
                            </div>
                        ))}
                    </div>
                ))}
        </div>
    );
};
