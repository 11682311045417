import React from 'react';
import { observer } from 'mobx-react';

import { Title } from '@common';
import { Dialog } from '@common/Dialog';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { Section, CheckboxForm } from '../Section';
import { normalBoldJSX } from '../utils';
import { PopUp } from './PopUp';
import { SuggestInputForm, SuggestInputFormInitialData } from '../Section/SuggestInputForm';

import styles from './Meeting.module.scss';

import {
    ModuleEquipment, ModuleConsumableEquipment, moduleStore, dictionaryStore, Meeting,
} from '../Store';

interface EquipmentView {
    id: string;
    name: string;
    inputValue?: string;
}

interface Props {
    meeting: Meeting;

    setRef(ref: React.MutableRefObject<HTMLDivElement | null>): void;
}

const getUnit = (equipmentId: string) => dictionaryStore.consumableEquipment
    .find(({ id }) => id === equipmentId)?.unit ?? '';

export const MeetingEquipments = observer(({ meeting, setRef }: Props) => {
    const [isDialog, setDialog] = React.useState(false);
    const validateCount = (item: EquipmentView) => item.id !== '' && item.inputValue !== ''
        && Number(item.inputValue) > 0;

    const validateEquipmentCountPerMeeting = (equipmentId: string, count: number): number => {
        const maxCount = dictionaryStore.equipments
            .find(equipment => equipment.id === equipmentId)!.count!;
        return count > maxCount ? maxCount : count;
    };

    const validateEquipmentCountPerStudent = (equipmentId: string, count: number): number => {
        const maxCount = 10000;
        return count > maxCount ? maxCount : count;
    };

    const normalEquipmentsDataPerMeeting = (equipmentViews: EquipmentView[]) => equipmentViews
        .map(item => {
            const count = validateEquipmentCountPerMeeting(item.id, Number(item.inputValue));
            return { equipment: { id: item.id, name: item.name }, count };
        });

    const normalEquipmentsDataPerStudent = (equipmentViews: EquipmentView[]) => equipmentViews
        .map(item => {
            const count = validateEquipmentCountPerStudent(item.id, Number(item.inputValue));
            return { equipment: { id: item.id, name: item.name }, count };
        });

    const normalConsumableEquipments = (
        equipmentViews: EquipmentView[],
    ): ModuleConsumableEquipment[] => equipmentViews
        .map(item => {
            const count = Number(item.inputValue);
            return {
                consumableEquipment: { id: item.id, name: item.name, unit: getUnit(item.id) },
                count,
            };
        });

    const normalListData = (equipments: ModuleEquipment[]) => equipments?.map(item => ({
        id: item.equipment.id, name: item.equipment.name, inputValue: String(item.count),
    })) ?? [];

    const normalConsumableListData = (equipments: ModuleConsumableEquipment[]) => equipments
        ?.map(item => ({
            id: item.consumableEquipment.id,
            name: item.consumableEquipment.name,
            inputValue: String(item.count),
        })) ?? [];

    const module = moduleStore.moduleModel;

    const dataEquipmentPerMeetingList = normalListData(meeting?.equipmentPerMeeting!);
    const dataEquipmentPerStudent = normalListData(meeting?.equipmentPerStudent!);
    const dataConsumableEquipmentPerMeeting = normalConsumableListData(
        meeting?.consumableEquipmentPerMeeting!,
    );
    const dataConsumableEquipmentPerStudent = normalConsumableListData(
        meeting?.consumableEquipmentPerStudent!,
    );

    const sortEquipments = [...dictionaryStore.equipments].sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    const sortConsumableEquipments = [...dictionaryStore.consumableEquipment].sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    const ref = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => setRef(ref), []);

    const [unitOfEquipmentPerMeeting, setUnitOfEquipmentPerMeeting] = React
        .useState<string | null | undefined>();
    const [unitOfEquipmentPerStudent, setUnitOfEquipmentPerStudent] = React
        .useState<string | null | undefined>();

    return (
        <div className={styles.form__equipments} ref={ref}>
            <div className={styles.form__title}>
                <Title>Оборудование и расходные материалы для проведения встречи:</Title>
            </div>
            <CheckboxForm
                checked={dataEquipmentPerMeetingList.length > 0}
                onChangeCheck={() => module.updateMeeting(meeting.id, { equipmentPerMeeting: [] })}
                checkboxLabel="Оборудование, не зависящее от количества студентов"
                title="Укажите оборудование и его количество необходимое для проведения встречи:"
            >
                <Section
                    initialData={SuggestInputFormInitialData}
                    list={dataEquipmentPerMeetingList}
                    form={SuggestInputForm}
                    formProps={{
                        mainOptions: sortEquipments,
                        placeholderSuggest: 'Укажите наименование оборудования',
                        placeholderInput: 'Кол-во',
                    }}
                    onChange={equpments => {
                        const equipmentPerMeeting = normalEquipmentsDataPerMeeting(equpments);
                        module.updateMeeting(meeting.id, { equipmentPerMeeting });
                    }}
                    formatItem={item => normalBoldJSX(item.name, item.inputValue)}
                    validate={validateCount}
                />
            </CheckboxForm>
            <div className={styles.form__line} />
            <CheckboxForm
                checked={dataEquipmentPerStudent.length > 0}
                onChangeCheck={() => module.updateMeeting(meeting.id, { equipmentPerStudent: [] })}
                checkboxLabel="Оборудование, зависящее от количества студентов"
                title="Укажите оборудование и количество студентов на 1 единицу этого оборудования:"
            >
                <Section
                    initialData={SuggestInputFormInitialData}
                    list={dataEquipmentPerStudent}
                    form={SuggestInputForm}
                    formProps={{
                        mainOptions: sortEquipments
                            .map(equipment => ({ ...equipment, count: 10000 })),
                        placeholderSuggest: 'Укажите наименование оборудования',
                        placeholderInput: 'Кол-во',
                        labelInput: 'на',
                        extraText: 'cтудентов',
                    }}
                    onChange={equipments => {
                        const equipmentPerStudent = normalEquipmentsDataPerStudent(equipments);
                        module.updateMeeting(meeting.id, { equipmentPerStudent });
                    }}
                    formatItem={item => normalBoldJSX(`${item.name} на`, `${item.inputValue} студентов`)}
                    validate={validateCount}
                />
            </CheckboxForm>
            <div className={styles.form__lineAndButton}>
                <div className={styles.form__line} />
                <div className={styles.form__lineAndButton__button}>
                    <ActionButton
                        actionType={ActionTypeOfButton.SECONDARY}
                        className={styles.form__lineAndButton__button_position_left}
                        onClick={() => setDialog(true)}
                    >
                        Запрос<br />оборудования
                    </ActionButton>
                </div>
                {isDialog && (
                    <Dialog
                        id="equipmentDialogId"
                        dom={(
                            <PopUp
                                text={dictionaryStore.equipmentRequestMessage?.message}
                                closePortal={() => setDialog(false)}
                            />
                        )}
                    />
                )}
            </div>
            <CheckboxForm
                checked={dataConsumableEquipmentPerMeeting.length > 0}
                onChangeCheck={() => module.updateMeeting(
                    meeting.id, { consumableEquipmentPerMeeting: [] },
                )}
                checkboxLabel="Расходные материалы, не зависящее от количества студентов"
                title="Укажите название расходного материала и количество необходимое для проведения встречи:"
            >
                <Section
                    initialData={SuggestInputFormInitialData}
                    list={dataConsumableEquipmentPerMeeting}
                    form={SuggestInputForm}
                    formProps={{
                        mainOptions: sortConsumableEquipments
                            .map(equip => ({ ...equip, count: Number.MAX_SAFE_INTEGER })),
                        placeholderSuggest: 'Укажите наименование оборудования',
                        placeholderInput: 'Кол-во',
                        extraText: unitOfEquipmentPerMeeting ?? '',
                        onChange: (equipmentId: string) => {
                            setUnitOfEquipmentPerMeeting(getUnit(equipmentId));
                        },
                    }}
                    onChange={equipments => {
                        const consumableEquipmentPerMeeting = normalConsumableEquipments(
                            equipments,
                        );
                        module.updateMeeting(meeting.id, { consumableEquipmentPerMeeting });
                    }}
                    formatItem={item => normalBoldJSX(item.name, `${item.inputValue} ${getUnit(item.id)}`)}
                    validate={validateCount}
                />
            </CheckboxForm>
            <div className={styles.form__line} />
            <CheckboxForm
                checked={dataConsumableEquipmentPerStudent.length > 0}
                onChangeCheck={() => module.updateMeeting(
                    meeting.id, { consumableEquipmentPerStudent: [] },
                )}
                checkboxLabel="Расходные материалы, зависящие от количества студентов"
                title="Укажите название расходного материала и количество студентов на 1 единицу этого расходного материала:"
            >
                <Section
                    initialData={SuggestInputFormInitialData}
                    list={dataConsumableEquipmentPerStudent}
                    form={SuggestInputForm}
                    formProps={{
                        mainOptions: sortConsumableEquipments
                            .map(equipment => ({ ...equipment, count: 10000 })),
                        placeholderSuggest: 'Укажите наименование оборудования',
                        placeholderInput: 'Кол-во',
                        labelInput: `${unitOfEquipmentPerStudent ?? ''} на`,
                        extraText: 'cтудентов',
                        onChange: (equipmentId: string) => {
                            setUnitOfEquipmentPerStudent(getUnit(equipmentId));
                        },
                    }}
                    onChange={equipments => {
                        const consumableEquipmentPerStudent = normalConsumableEquipments(
                            equipments,
                        );
                        module.updateMeeting(meeting.id, { consumableEquipmentPerStudent });
                    }}
                    formatItem={item => normalBoldJSX(`${item.name} ${getUnit(item.id)} на`, `${item.inputValue} студентов`)}
                    validate={validateCount}
                />
            </CheckboxForm>
        </div>
    );
});
