import React from 'react';
import { nanoid } from 'nanoid';
import { cloneDeep } from 'lodash';
// import { format } from 'date-fns';
import {
    SpaceBaseTimeInterval,
    BaseTimeIntervalType,
    SpaceBaseTimeIntervalParameters,
    ModuleAssessment as IModuleAssessment,
} from '../../../EducationPeriodParametersAndDatesInterfaces';

import classes from './Interval.module.scss';
import { DateSelector } from './DateSelector';
import { ModuleAssessment } from './ModuleAssessment';

interface Props {
    spaceBaseTimeInterval: SpaceBaseTimeInterval,
    baseTimeIntervalType: BaseTimeIntervalType,
    spaceBaseTimeIntervalParameters?:SpaceBaseTimeIntervalParameters,
    setParameters(btiId: string, parameters: SpaceBaseTimeIntervalParameters): void,
}

function getYearString(date: Date) {
    return `${date.getFullYear()}`;
}

function getMonthString(date: Date) {
    const monthIndex = date.getMonth() + 1;
    return `${monthIndex < 10 ? `0${monthIndex}` : monthIndex}`;
}

function getDayString(date: Date) {
    const dayIndex = date.getDate();
    return `${dayIndex < 10 ? `0${dayIndex}` : dayIndex}`;
}

function getFormatedDate(date: (Date | null)) {
    return date ? `${getYearString(date)}-${getMonthString(date)}-${getDayString(date)}` : undefined;
}

export function Interval({
    spaceBaseTimeInterval,
    baseTimeIntervalType,
    spaceBaseTimeIntervalParameters: spaceBaseTimeIntervalParametersProps,
    setParameters,
}: Props) {
    const getNewIntervalParameters = (): SpaceBaseTimeIntervalParameters => ({
        baseTimeIntervalInstanceId: nanoid(),
        spaceBaseTimeIntervalId: spaceBaseTimeInterval.id,
        subspaceSelectionStartDate: '',
        subspaceSelectionEndDate: '',
        moduleSelectionStartDate: '',
        moduleSelectionEndDate: '',
        spaceBaseTimeIntervalStart: '',
        spaceBaseTimeIntervalEnd: '',
    });
    // TODO отоDRYить на досуге две нижние функции
    const onChangeSubSpaceInterval = (data: (Date|null)[]) => {
        const [start, end] = data;
        const spaceBaseTimeIntervalParameters: SpaceBaseTimeIntervalParameters = cloneDeep(
            spaceBaseTimeIntervalParametersProps,
        ) || getNewIntervalParameters();
        if (spaceBaseTimeIntervalParameters) {
            spaceBaseTimeIntervalParameters.subspaceSelectionStartDate = getFormatedDate(start);
            spaceBaseTimeIntervalParameters.subspaceSelectionEndDate = getFormatedDate(end);
            setParameters(
                spaceBaseTimeInterval.id,
                spaceBaseTimeIntervalParameters,
            );
        }
    };
    const onChangeModuleInterval = (data: (Date|null)[]) => {
        const [start, end] = data;
        const spaceBaseTimeIntervalParameters: SpaceBaseTimeIntervalParameters = cloneDeep(
            spaceBaseTimeIntervalParametersProps,
        ) || getNewIntervalParameters();
        if (spaceBaseTimeIntervalParameters) {
            spaceBaseTimeIntervalParameters.moduleSelectionStartDate = getFormatedDate(start);
            spaceBaseTimeIntervalParameters.moduleSelectionEndDate = getFormatedDate(end);
            setParameters(
                spaceBaseTimeInterval.id,
                spaceBaseTimeIntervalParameters,
            );
        }
    };
    const onChangeBaseTimeInterval = (data: (Date|null)[]) => {
        const [start, end] = data;
        const spaceBaseTimeIntervalParameters = cloneDeep(
            spaceBaseTimeIntervalParametersProps,
        ) || getNewIntervalParameters();
        if (spaceBaseTimeIntervalParameters) {
            spaceBaseTimeIntervalParameters.spaceBaseTimeIntervalStart = getFormatedDate(start);
            spaceBaseTimeIntervalParameters.spaceBaseTimeIntervalEnd = getFormatedDate(end);
        }
        if (spaceBaseTimeIntervalParameters) {
            setParameters(
                spaceBaseTimeInterval.id,
                spaceBaseTimeIntervalParameters,
            );
        }
    };

    function onModuleAssessmentChange(moduleAssessment: IModuleAssessment | undefined) {
        const spaceBaseTimeIntervalParameters = cloneDeep(
            spaceBaseTimeIntervalParametersProps,
        ) || getNewIntervalParameters();
        setParameters(
            spaceBaseTimeInterval.id,
            {
                ...spaceBaseTimeIntervalParameters,
                moduleAssessment,
            },
        );
    }

    return (
        <div className={classes['base-time-interval-instance']}>
            <div className={classes['base-time-interval-instance__title']}>
                Настройки {spaceBaseTimeInterval.order} {baseTimeIntervalType.name}
            </div>
            <div className={classes['base-time-interval-instance__subtitle']}>
                Задайте параметры временных интервалов:
            </div>
            <DateSelector
                start={spaceBaseTimeIntervalParametersProps?.spaceBaseTimeIntervalStart}
                end={spaceBaseTimeIntervalParametersProps?.spaceBaseTimeIntervalEnd}
                description={`${spaceBaseTimeInterval.order} ${baseTimeIntervalType.name}`}
                onChange={onChangeBaseTimeInterval}
            />
            <DateSelector
                start={spaceBaseTimeIntervalParametersProps?.subspaceSelectionStartDate}
                end={spaceBaseTimeIntervalParametersProps?.subspaceSelectionEndDate}
                description={`выбора подпространств ${spaceBaseTimeInterval.order}`}
                onChange={onChangeSubSpaceInterval}
            />
            <DateSelector
                start={spaceBaseTimeIntervalParametersProps?.moduleSelectionStartDate}
                end={spaceBaseTimeIntervalParametersProps?.moduleSelectionEndDate}
                description={`выбора модулей ${spaceBaseTimeInterval.order}`}
                onChange={onChangeModuleInterval}
            />
            <ModuleAssessment
                spaceBaseTimeInterval={spaceBaseTimeInterval}
                baseTimeIntervalType={baseTimeIntervalType}
                moduleAssessment={spaceBaseTimeIntervalParametersProps?.moduleAssessment}
                onModuleAssessmentChange={onModuleAssessmentChange}
            />
        </div>
    );
}
