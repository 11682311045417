import { makeAutoObservable } from 'mobx';
import { AdminRole, NonWorkingHours, Role } from '../UserCard/models';
import { toLowerCaseFirstChar } from '../UserCard/TeacherInfo/TeacherInfo';
import { DaySchedule, WeekType } from '../UserCard/TimePicker/modelTimePicker';

export enum DayIntervals {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday',
}
export interface DayScheduleWhithWeekType {
    even?: DaySchedule[];
    odd?: DaySchedule[];
    every?: DaySchedule[];
}

interface Wave {
    id: string;
    index: number;
    module: {
        id: string;
        name: string;
        waves: {
            id: string;
            index: number;
        }[];
    }
}

interface Module {
    name: string;
    id: string;
    waveId: string;
    waveIndex: number;
    waves: {
        id: string,
        index: number,
    }[];
}

class UserCardStore {
    defaultWorkingIntervals: DaySchedule[] = [
        { weekDay: DayIntervals.Monday, workingIntervals: [] },
        { weekDay: DayIntervals.Tuesday, workingIntervals: [] },
        { weekDay: DayIntervals.Wednesday, workingIntervals: [] },
        { weekDay: DayIntervals.Thursday, workingIntervals: [] },
        { weekDay: DayIntervals.Friday, workingIntervals: [] },
        { weekDay: DayIntervals.Saturday, workingIntervals: [] },
        { weekDay: DayIntervals.Sunday, workingIntervals: [] },
    ];

    mobXuserId: string = '';

    studentModules: Module[] = [];

    mobXroles: Role[] = [];

    mobXoriginalRoles: Role[] = [];

    mobXadminRoles: AdminRole[] = [];

    workingIntervals: DayScheduleWhithWeekType = {
        odd: [], even: [], every: [],
    };

    isEveryWeek: boolean = true;

    nonWorkingHours: NonWorkingHours[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    get selectedWaveIds(): string[] {
        return this.studentModules.map(wave => wave.waveId);
    }

    set userId(id: string) { this.mobXuserId = id; }

    get userId() { return this.mobXuserId; }

    set roles(roles: Role[]) { this.mobXroles = roles; }

    get roles() { return this.mobXroles; }

    set originalRoles(originalRoles: Role[]) {
        this.mobXoriginalRoles = originalRoles;
        this.mobXroles = originalRoles;
    }

    get originalRoles() { return this.mobXoriginalRoles; }

    set adminRoles(adminRoles: AdminRole[]) { this.mobXadminRoles = adminRoles; }

    get adminRoles() { return this.mobXadminRoles; }

    resetAllRoles = () => {
        this.roles = [];
        this.originalRoles = [];
        this.adminRoles = [];
    };

    findDefaultStateIntervals = (weekDay: string) => this.defaultWorkingIntervals
        .find(item => item.weekDay === weekDay);

    addNonWorkingHours = (nonWorkingHours: NonWorkingHours) => {
        this.nonWorkingHours = [...this.nonWorkingHours ?? [], nonWorkingHours];
    };

    updateNonWorkingHours = (nonWorkingHours: NonWorkingHours[]) => {
        this.nonWorkingHours = nonWorkingHours;
    };

    removeNonWorkingHours = (id: string) => {
        const newHours = this.nonWorkingHours.filter(item => item.id !== id);
        this.nonWorkingHours = newHours;
    };

    setSelectWeekType = (isEveryWeek: boolean) => {
        this.isEveryWeek = isEveryWeek;
    };

    updateWorkingInterval = (
        weekType: WeekType,
        daySchedules: DaySchedule[],
    ): void => {
        this.workingIntervals[weekType] = daySchedules.map(day => ({
            ...day,
            workingIntervals: day.workingIntervals
                .filter(interval => interval.weekType === weekType),
        }));
    };

    createDefaultWorkingInterval = (
        daySchedule: DaySchedule,
    ): void => {
        this.findDefaultStateIntervals(daySchedule.weekDay)
            ?.workingIntervals.push(daySchedule.workingIntervals[0]);
        this.updateWorkingInterval(daySchedule.workingIntervals[0].weekType,
            this.defaultWorkingIntervals);
    };

    get DispatchedWorkingHours() {
        const workingIntervalsIds: string[] = [];
        const normalizWeekDay = (item: DaySchedule, typeWeek: WeekType) => item.workingIntervals
            .map(interval => {
                if (workingIntervalsIds.includes(interval.id)) {
                    return [];
                }
                workingIntervalsIds.push(interval.id);
                return {
                    id: interval.id,
                    from: interval.start,
                    to: interval.end,
                    day: toLowerCaseFirstChar(item.weekDay),
                    weekType: typeWeek,
                };
            });
        if (this.isEveryWeek) {
            return this.workingIntervals.every!
                .map(item => normalizWeekDay(item, WeekType.every)).flat();
        }
        return [
            ...this.workingIntervals.odd!.map(item => normalizWeekDay(item, WeekType.odd)),
            ...this.workingIntervals.even!.map(item => normalizWeekDay(item, WeekType.even)),
        ].flat();
    }

    resetWorkingIntervals = () => {
        this.defaultWorkingIntervals = [
            { weekDay: DayIntervals.Monday, workingIntervals: [] },
            { weekDay: DayIntervals.Tuesday, workingIntervals: [] },
            { weekDay: DayIntervals.Wednesday, workingIntervals: [] },
            { weekDay: DayIntervals.Thursday, workingIntervals: [] },
            { weekDay: DayIntervals.Friday, workingIntervals: [] },
            { weekDay: DayIntervals.Saturday, workingIntervals: [] },
            { weekDay: DayIntervals.Sunday, workingIntervals: [] },
        ];
        this.workingIntervals = {
            odd: [], even: [], every: [],
        };
    };

    updateIsEveryWeek = (isEvwry: boolean) => {
        this.isEveryWeek = isEvwry;
    };

    updateStudenModules = (actiweWaves: Wave[]) => {
        this.studentModules = actiweWaves.map(wave => ({
            name: wave.module.name,
            id: wave.module.id,
            waveId: wave.id,
            waveIndex: wave.index + 1,
            waves: wave.module.waves.map(moduleWave => ({
                index: moduleWave.index + 1,
                id: moduleWave.id,
            })),
        }));
        this.studentModules = this.studentModules.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });
    };

    updateStudentModule = (id: string, newWaveid: string) => {
        if (id && newWaveid) {
            const newModule = this.studentModules.find(module => module.id === id)!;
            this.studentModules = this.studentModules.filter(module => module.id !== id);
            newModule.waveId = newWaveid;
            this.studentModules.push(newModule);
            this.studentModules = this.studentModules.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
        }
    };

    resetStudentsModule = () => {
        this.studentModules = [];
    };
}

export const userCardStore = new UserCardStore();
