import React, { useState } from 'react';
import cn from 'classnames';

import { TerritorialZone, UpdateTerritorialZoneInput } from '@admin/UniversityPage/types';
import { ApolloError } from '@apollo/client';
import { Alert } from '@common/Alert';
import { EditIcon, FilledEditIcon } from '@common/svg';
import { Confirm } from '@common/Confirm';
import { UniversityZonesForm } from '../UniversityZonesForm';

import classes from './UniversityZones.module.scss';

interface Props {
    editBuildingsMode: boolean;
    addBuildingsMode: boolean;
    editZonesMode: boolean;
    territorialZones?: TerritorialZone[];
    updateTerritorialZonesError?: ApolloError;
    handleSetEditZonesMode(): void;
    updateTerritorialZones(zones: UpdateTerritorialZoneInput[]): void;
}

export function UniversityZones({
    editBuildingsMode, addBuildingsMode,
    editZonesMode,
    territorialZones = [],
    updateTerritorialZonesError,
    handleSetEditZonesMode,
    updateTerritorialZones,
}: Props): JSX.Element {
    const [showWarning, setShowWarning] = useState(false);

    const handleSetShowWarning = (): void => {
        setShowWarning(!showWarning);
    };

    const zonesCount = territorialZones.length;

    return (
        <>
            {showWarning && (
                <Confirm
                    headerText="Предупреждение"
                    descriptionText="Завершите редактирование корпусов либо нажмите кнопку 'Отменить'"
                    primaryBtnText="Ок"
                    onPrimaryBtnClick={handleSetShowWarning}
                    onOutClick={handleSetShowWarning}
                    onSecondaryBtnClick={handleSetShowWarning}
                />
            )}

            {
                updateTerritorialZonesError?.message
                && <Alert message={updateTerritorialZonesError?.message} />
            }

            <div className={classes.universityZones}>
                {zonesCount <= 0 && (
                    <button
                        type="button"
                        className={cn({
                            [classes.universityZones__SetBtn]: editZonesMode,
                            [classes.universityZones__SetBtn_visited]: !editZonesMode,
                        })}
                        onClick={handleSetEditZonesMode}
                    >
                        Задать территориальные зоны
                    </button>
                )}

                {zonesCount > 0 && (
                    <div className={classes.zonesAmount}>
                        <button
                            type="button"
                            className={classes.universityZones__editBtn}
                            onClick={editBuildingsMode || addBuildingsMode
                                ? handleSetShowWarning
                                : handleSetEditZonesMode
                            }
                        >
                            {editZonesMode ? <FilledEditIcon /> : <EditIcon size={20} />}
                        </button>
                        <p className={classes.zonesAmount__zonesAmount}>
                            {getZoneDeclination(zonesCount)}
                        </p>
                    </div>
                )}

                {editZonesMode && (
                    <UniversityZonesForm
                        territorialZones={territorialZones}
                        handleSetEditZonesMode={handleSetEditZonesMode}
                        updateTerritorialZones={updateTerritorialZones}
                    />
                )}
            </div>
        </>

    );
}

function getZoneDeclination(zonesCount: number) {
    if (zonesCount > 1 && zonesCount < 5) {
        return `Задано ${zonesCount} территориальные зоны`;
    }
    if (zonesCount > 4) {
        return `Задано ${zonesCount} территориальных зон`;
    }
    return `Задана ${zonesCount} территориальная зона`;
}
