import React from 'react';
import { Link } from 'react-router-dom';

import { Dialog } from '@common/Dialog';
import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { PopUp } from './PopUp';
import { ModuleContent } from './ModuleContent';
import { ModuleHeader } from './ModuleHeader';

import classes from './ModuleCard.module.scss';

import { SkillType, Module as ModuleType } from '../../subSpaceAdmin/subSpaceTypes';
import { skills_skills } from '../../graphql-query-types';

interface Props {
    currentModule: ModuleType;
    skillTypes: SkillType[];
    allSkillsList: skills_skills[];
    path: string;
    handleRemoveModule?(id: string): void;
}

export const ModuleCard = React.memo(({
    currentModule,
    skillTypes,
    allSkillsList,
    path,
    handleRemoveModule,
}: Props): JSX.Element => {
    const [visiblePortal, setVisiblePortal] = React.useState(false);

    return (
        <div className={classes.module}>
            <ModuleHeader currentModule={currentModule} />
            <ModuleContent
                currentModule={currentModule}
                skillTypes={skillTypes}
                allSkillsList={allSkillsList}
            />
            <div className={classes.module__buttons}>
                {handleRemoveModule && (
                    <div className={classes.module__button} onClick={() => setVisiblePortal(true)}>
                        <ActionButton type="button" actionType={ActionTypeOfButton.SECONDARY}>
                            Удалить
                        </ActionButton>
                    </div>
                )}
                <Link to={`${path}/${currentModule.id}`}>
                    <div className={classes.module__button}>
                        <ActionButton type="button">
                            Редактировать
                        </ActionButton>
                    </div>
                </Link>
            </div>
            {visiblePortal && (
                <Dialog
                    id="deleteModuleId"
                    dom={(
                        <PopUp
                            text="Вы уверены, что хотите удалить этот модуль?"
                            closePortal={() => setVisiblePortal(false)}
                            deleteAction={
                                () => handleRemoveModule && handleRemoveModule(currentModule.id)
                            }
                        />
                    )}
                />
            )}
        </div>
    );
});
