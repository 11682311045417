import React from 'react';
import { observer } from 'mobx-react';

import { Title } from '@common/Title';
import { Pendant, PendantType } from '@admin/NewModule/Pendant';
import { CheckboxForm } from '@admin/NewModule/Section';
import {
    moduleStore,
    Event,
    Validator,
    EventWithoutTest,
} from '@admin/NewModule/Store';
import { MinMax } from '@admin/NewModule/MinMax';

import classes from './Meeting.module.scss';

interface Props {
    event: EventWithoutTest;
    disableCheckbox?: boolean;

    updateEvent(id: string, data: Partial<Event>): void;

    setRef?(ref: React.MutableRefObject<HTMLDivElement | null>): void;
}

const validateNumber = (value: string) => (value === '' ? undefined : Math.max(Math.min(Number(value), 100000), 0));

export const DaysNextEvent = observer(({
    event, updateEvent, setRef = () => {}, disableCheckbox = false,
}: Props) => {
    const module = moduleStore.moduleModel;
    const {
        isPreviousEventInRow,
        isNextEventInRow,
        minDaysToNextEvent,
        maxDaysToNextEvent,
        minDaysToPreviousEvent,
        maxDaysToPreviousEvent,
    } = event;

    const ref = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => setRef(ref), []);

    const mainEvents = module.eventsWithoutTest
        .filter(item => item.dependentEvents.some(({ id }) => id === event.id));
    const dependentEvents = module.eventsWithoutTest
        .filter(item => event.dependentEvents.some(({ id }) => item.id === id));
    const previousEventError = mainEvents.reduce((acc, cur) => {
        const error = Validator.validateInterval(cur, event);
        return { error: acc.error || error.error, valid: acc.valid && error.valid };
    }, Validator.validatePreviousEvent(event));
    const nextEventError = dependentEvents.reduce((acc, cur) => {
        const error = Validator.validateInterval(event, cur);
        return { error: acc.error || error.error, valid: acc.valid && error.valid };
    }, Validator.validateNextEvent(event));

    const PreviousError = () => (!previousEventError.valid ? (
        <div className={classes.form__options__warning}>
            <Pendant type={PendantType.Danger}>
                <div className={classes.form__options__warningText}>
                    {previousEventError.error}
                </div>
            </Pendant>
        </div>
    ) : <></>);
    const NextError = () => (!nextEventError.valid ? (
        <div className={classes.form__options__warning}>
            <Pendant type={PendantType.Danger}>
                <div className={classes.form__options__warningText}>
                    {nextEventError.error}
                </div>
            </Pendant>
        </div>
    ) : <></>);

    return (
        <div ref={ref}>
            <Title>Интервалы до и после встречи встречи:</Title>
            {disableCheckbox
                ? (
                    <>
                        <div className={classes.form__checkBoxTitle}>
                            Время от предыдущего события до текущего
                        </div>
                        <MinMax
                            title="Укажите количество рабочих дней до предыдущей встречи или самостоятельной работы:"
                            min={minDaysToPreviousEvent}
                            max={maxDaysToPreviousEvent}
                            onChangeMin={({ target }) => updateEvent(event.id, {
                                minDaysToPreviousEvent: validateNumber(target.value),
                            })}
                            onChangeMax={({ target }) => updateEvent(event.id, {
                                maxDaysToPreviousEvent: validateNumber(target.value),
                            })}
                            minName="minDaysToPreviousEvent"
                            maxName="maxDaysToPreviousEvent"
                            placeholder="неважно"
                            canBeZero
                            validate={false}
                        />
                        <PreviousError />
                        <div className={classes.form__checkBoxTitle}>
                            Время от текущего события до следующего
                        </div>
                        <MinMax
                            title="Укажите количество рабочих дней до следующей встречи или самостоятельной работы:"
                            min={minDaysToNextEvent}
                            max={maxDaysToNextEvent ?? ''}
                            onChangeMin={({ target }) => updateEvent(event.id, {
                                minDaysToNextEvent: validateNumber(target.value),
                            })}
                            onChangeMax={({ target }) => updateEvent(event.id, {
                                maxDaysToNextEvent: validateNumber(target.value),
                            })}
                            minName="minDaysToNextEvent"
                            maxName="maxDaysToNextEvent"
                            placeholder="неважно"
                            canBeZero
                            validate={false}
                        />
                        <NextError />
                    </>
                )
                : (
                    <>
                        <CheckboxForm
                            checked={isPreviousEventInRow}
                            onChangeCheck={() => updateEvent(event.id, {
                                minDaysToPreviousEvent: undefined,
                                maxDaysToPreviousEvent: undefined,
                                isPreviousEventInRow: !isPreviousEventInRow,
                            })}
                            checkboxLabel="Предыдущая встреча идет встык с текущей"
                            uncheckedView={(
                                <MinMax
                                    title="Укажите количество рабочих дней до предыдущей встречи или самостоятельной работы:"
                                    min={minDaysToPreviousEvent}
                                    max={maxDaysToPreviousEvent}
                                    onChangeMin={({ target }) => updateEvent(event.id, {
                                        minDaysToPreviousEvent: validateNumber(target.value),
                                    })}
                                    onChangeMax={({ target }) => updateEvent(event.id, {
                                        maxDaysToPreviousEvent: validateNumber(target.value),
                                    })}
                                    minName="minDaysToPreviousEvent"
                                    maxName="maxDaysToPreviousEvent"
                                    placeholder="неважно"
                                    canBeZero
                                    validate={false}
                                />
                            )}
                        />
                        <PreviousError />
                        <CheckboxForm
                            checked={isNextEventInRow}
                            onChangeCheck={() => updateEvent(event.id, {
                                minDaysToNextEvent: undefined,
                                maxDaysToNextEvent: undefined,
                                isNextEventInRow: !isNextEventInRow,
                            })}
                            checkboxLabel="Следующая встреча идет встык с текущей"
                            uncheckedView={(
                                <MinMax
                                    title="Укажите количество рабочих дней до следующей встречи или самостоятельной работы:"
                                    min={minDaysToNextEvent}
                                    max={maxDaysToNextEvent ?? ''}
                                    onChangeMin={({ target }) => updateEvent(event.id, {
                                        minDaysToNextEvent: validateNumber(target.value),
                                    })}
                                    onChangeMax={({ target }) => updateEvent(event.id, {
                                        maxDaysToNextEvent: validateNumber(target.value),
                                    })}
                                    minName="minDaysToNextEvent"
                                    maxName="maxDaysToNextEvent"
                                    placeholder="неважно"
                                    canBeZero
                                    validate={false}
                                />
                            )}
                        />
                        <NextError />
                    </>
                )
            }
        </div>
    );
});
