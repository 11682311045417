import { useLazyQuery } from '@apollo/client';
import { Teacher } from '../../ScheduleGenerationPageInterfaces';
import { GET_AVAILABLE_TEACHERS } from '../../scheduleQueries';

export const useLoadAvailableTeachersLazyQuery = () => {
    const [
        getAvailableTeachersQuery,
        { data: availableTeachers },
    ] = useLazyQuery<{ availableTeachers: Teacher[] }>(
        GET_AVAILABLE_TEACHERS,
    );

    return {
        loadAvailableTeachers: (
            from: string,
            to: string,
            isAvailable: boolean = false,
        ) => getAvailableTeachersQuery(
            {
                variables: {
                    availableTeacherInput: {
                        from,
                        to,
                        isAvailable,
                    },
                },
            },
        ),
        availableTeachers,
    };
};
