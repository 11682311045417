import React from 'react';
import { DocumentNode } from '@apollo/client';

import { MultiAutosuggest } from '@common/MultiAutosuggest';

import classes from './FiltrationComponents.module.scss';

interface Props<SourceType extends { id: string }> {
    filterName: string,
    placeholder: string,
    query?: DocumentNode,
    selectedOptions?: SourceType[],
    formatOption?: (item: SourceType) => string,
    formatSendingValue?: (value: string) => any,
    transformResponse?: (response:any) => SourceType[],
    setState: (value: SourceType[]) => void,
    isSuggestableOnFocus?: boolean,
    options?: SourceType[];
}

export function FilterSelect<SourceType extends { id: string }>({
    filterName,
    placeholder,
    query,
    selectedOptions = [],
    isSuggestableOnFocus = true,
    setState,
    formatOption,
    transformResponse,
    formatSendingValue = (value) => value,
    options,
}: Props<SourceType>): JSX.Element {
    return (
        <div className={classes.filters__item}>
            <div
                className={classes.filters__itemName}
            >
                {filterName}
            </div>
            <MultiAutosuggest
                placeholder={placeholder}
                query={query}
                formatOption={formatOption}
                transformResponse={transformResponse}
                onChange={setState}
                selectedOptions={selectedOptions}
                isSuggestableOnFocus={isSuggestableOnFocus}
                formatSendingValue={formatSendingValue}
                options={options}
            />
        </div>
    );
}
