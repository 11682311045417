import { ApolloError, useMutation } from '@apollo/client';
import { GET_EVENT_DATA, UPDATE_EVALUATION_POINT_INSTANCE } from '../../scheduleQueries';

export const useEvaluationPointDurationMutation = (
    id: string,
    type: string,
    setErrorsFunction: (error: ApolloError) => void,
) => {
    const [
        updateEvaluationPointInstance,
    ] = useMutation(UPDATE_EVALUATION_POINT_INSTANCE, {
        refetchQueries: [{
            query: GET_EVENT_DATA,
            variables: {
                tmpMeetingInstanceInput: { id, type },
            },
        }],
        onError: (error) => setErrorsFunction(error),
    });

    return {
        updateEvaluationPointDuration: (duration: number) => updateEvaluationPointInstance({
            variables: {
                updateEvaluationPointInstanceInput: {
                    id,
                    duration,
                },
            },
        }),
    };
};
