import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { nanoid } from 'nanoid';

import { ActionButton, ActionTypeOfButton } from '@common/ActionButton';
import { BorderCrossIcon, BorderPlusIcon } from '@common/svg';
import { Alert } from '@common/Alert';
import { Switcher } from '@common';
import { IconDeprecated } from '@common/IconDeprecated';

import { EducationSubspaceTypeFormIntervals } from './EdicationSubspaceTypeFormIntervals';
import classes from '../EducationSpacePage.module.scss';

interface SubspaceType {
    id: string;
    name: string;
    isSelective: boolean;
    isDescriptionEnabled: boolean;
    subspaceTypeLayouts: SubspaceTypeLayout[];
}

interface IntervalTypes {
    id: string;
    name: string;
}

interface SubspaceTypeLayout {
    id: string;
    spaceBaseTimeIntervalId: string;
    minCreditCount: number;
    maxCreditCount: number;
}

interface Props {
    index: number;
    setActiveIndex(index: number): void;
    subspaceTypes: SubspaceType[];
    spaceBTI: IntervalTypes[];
    createSubspaceType: (
        id: string,
        name: string,
        isSelective: boolean,
        isDescriptionEnabled: boolean,
        subspaceTypeLayout: SubspaceTypeLayout[],
    ) => void;
    updateSubspaceType: (
        id: string,
        name: string,
        isSelective: boolean,
        isDescriptionEnabled: boolean,
        subspaceTypeLayout: SubspaceTypeLayout[],
    ) => void;
    removeSubspaceType: (
        id: string,
    ) => void;
}

const SubspaceTypeValidator = (subspaceType: SubspaceType) => {
    if (subspaceType.name.trim() === '') {
        return 'Имя типа подпространства обязательное для запонения поле';
    }

    if (subspaceType.subspaceTypeLayouts.length === 0) {
        return 'Тип подпространства должен содержать хотя бы один временной интервал';
    }

    if (subspaceType.subspaceTypeLayouts.find(interval => (
        interval.maxCreditCount < interval.minCreditCount
    ))) {
        return 'Минимальное значение не должно превышать максимальное значение';
    }

    if (subspaceType.subspaceTypeLayouts.find(interval => (
        interval.maxCreditCount < 0 || interval.minCreditCount < 0
    ))) {
        return 'Минимальное и макимальное значение должны быть неотрицательны';
    }

    if (subspaceType.subspaceTypeLayouts.find(interval => (
        interval.minCreditCount === 0 || interval.maxCreditCount === 0
    ))) {
        return 'Минимум и максимум обязательные для заполнения поля';
    }
    return '';
};

export const EducationSubspaceTypeForm = ({
    index,
    setActiveIndex,
    subspaceTypes,
    spaceBTI,
    createSubspaceType,
    updateSubspaceType,
    removeSubspaceType,
}: Props) => {
    const [isSelective, setSelective] = useState(subspaceTypes[index]?.isSelective || false);
    const [isDescriptionEnabled, setDescriptionEnabled] = useState(
        subspaceTypes[index]?.isDescriptionEnabled || false,
    );
    const name = (index > -1) ? subspaceTypes[index].name : '';
    const subspaceTL: SubspaceTypeLayout[] = (index > -1)
        ? (
            subspaceTypes[index].subspaceTypeLayouts
        ) : [];
    const [subspaceName, setSubspaceName] = useState(name);
    const [subspaceTypeLayouts, setSubspaceTypeLayout] = useState<SubspaceTypeLayout[]>(
        subspaceTL,
    );
    const [timeInterval, setTimeInterval] = useState<SubspaceTypeLayout>({
        id: nanoid(),
        spaceBaseTimeIntervalId: spaceBTI[0].id,
        minCreditCount: 0,
        maxCreditCount: 0,
    });
    const [error, setError] = useState('');

    const getIntervalById = (id: string) => {
        const interval: IntervalTypes | undefined = spaceBTI.find(
            spaceBaseTineInterval => spaceBaseTineInterval.id === id,
        );
        return {
            id,
            spaceBaseTimeIntervalId: interval!.id,
            minCreditCount: 0,
            maxCreditCount: 0,
        };
    };
    const getSpaceBTIById = (id: string) => (
        spaceBTI.find(spaceBaseTineInterval => spaceBaseTineInterval.id === id)
    );

    const addSubspaceTypeLayout = (subspaceTypeLayout: SubspaceTypeLayout) => {
        if (subspaceTypeLayout.spaceBaseTimeIntervalId !== '0') {
            let indexForNewSTL = 0;
            while (indexForNewSTL < subspaceTypeLayouts.length
                && getSpaceBTIById(
                    subspaceTypeLayouts[indexForNewSTL].spaceBaseTimeIntervalId,
                )!.name < getSpaceBTIById(
                    subspaceTypeLayout.spaceBaseTimeIntervalId,
                )!.name
            ) {
                indexForNewSTL += 1;
            }
            const newSTL = { ...subspaceTypeLayout, id: nanoid() };
            subspaceTypeLayouts.splice(indexForNewSTL, 0, newSTL);
            setSubspaceTypeLayout(subspaceTypeLayouts);
        }
        setTimeInterval(getIntervalById('0'));
    };

    const deleteSubspaceTypeLayout = (subspaceTypeLayout: SubspaceTypeLayout) => {
        setSubspaceTypeLayout(
            subspaceTypeLayouts.filter(({ id }) => id !== subspaceTypeLayout.id),
        );
    };

    const editSubspaceType = () => {
        const updatedSubspaceType = {
            ...subspaceTypes[index],
            name: subspaceName.trim(),
            isSelective,
            isDescriptionEnabled,
            subspaceTypeLayouts: [...subspaceTypeLayouts],
        };
        updateSubspaceType(
            updatedSubspaceType.id,
            updatedSubspaceType.name.trim(),
            isSelective,
            isDescriptionEnabled,
            subspaceTypeLayouts,
        );
    };

    const createSubspace = () => {
        const subspaceTypeForValidation = {
            id: nanoid(),
            name: subspaceName.trim(),
            isSelective,
            isDescriptionEnabled,
            subspaceTypeLayouts: [...subspaceTypeLayouts],
        };

        const message = SubspaceTypeValidator(subspaceTypeForValidation);
        setError(message);

        if (message === '') {
            if (index > -1) {
                editSubspaceType();
            } else {
                const newId = nanoid();
                createSubspaceType(
                    newId,
                    subspaceName.trim(),
                    isSelective,
                    isDescriptionEnabled,
                    subspaceTypeLayouts,
                );
            }
            setActiveIndex(-1);
        }
    };

    const availableIntervals = spaceBTI.filter((item) => (
        !subspaceTypeLayouts.some(({ spaceBaseTimeIntervalId }) => (
            spaceBaseTimeIntervalId === item.id
        ))
    ));

    const closeSubspaceType = () => {
        setActiveIndex(-1);
    };

    const deleteSubspace = () => {
        if (index > -1) {
            removeSubspaceType(
                subspaceTypes[index].id,
            );
        }
        setActiveIndex(-1);
    };

    useEffect(() => {
        setTimeout(() => {
            if (error !== '') {
                setError('');
            }
        }, 2000);
    });

    return (
        <div className={classes.educationSubspaceTypeForm}>
            <div className={classes.educationSubspaceTypeForm__border}>
                <div className={classes.educationSubspaceTypeForm__container}>
                    <div className={classes.educationSubspaceTypeForm__header}>
                        <BorderCrossIcon
                            size={26}
                            handler={() => closeSubspaceType()}
                        />

                        <div className={classes.educationSubspaceTypeForm__head}>
                            Новый тип образовательных подпространств:
                        </div>
                    </div>

                    <label className={classes.educationSubspaceTypeForm__label}>
                        Название типа:

                        <input
                            className={classes.educationSubspaceTypeForm__input}
                            value={subspaceName}
                            onChange={({ currentTarget }) => setSubspaceName(currentTarget.value)}
                        />
                    </label>

                    <label className={classes.educationSubspaceTypeForm__label}>
                        Добавить интервал времени для типа:

                        <div className={classes.educationSubspaceTypeForm__containerTimeInterval}>
                            <div className={classes.educationSubspaceTypeForm__IconContainer}>
                                <BorderPlusIcon
                                    handler={() => addSubspaceTypeLayout(timeInterval)}
                                />
                            </div>

                            <select
                                className={classes.educationSubspaceTypeForm__selector}
                                value={timeInterval.id}
                                onChange={({
                                    currentTarget,
                                }) => setTimeInterval(getIntervalById(currentTarget.value))}
                            >
                                {availableIntervals.map((item) => (
                                    <option
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </label>

                    <ul>
                        {subspaceTypeLayouts.map((subspaceTypeLayout: SubspaceTypeLayout) => (
                            <li
                                key={subspaceTypeLayout.id}
                                className={classes.educationSubspaceTypeForm__listTimeInterval}
                            >

                                <IconDeprecated
                                    id="Cross"
                                    className={classes.icon__greyCrossSkillDependency}
                                    click={() => deleteSubspaceTypeLayout(subspaceTypeLayout)}
                                />

                                {
                                    spaceBTI.find((BTI) => (
                                        BTI.id === subspaceTypeLayout.spaceBaseTimeIntervalId
                                    ))?.name
                                }

                            </li>
                        ))}
                    </ul>

                    <EducationSubspaceTypeFormIntervals
                        spaceBaseTimeIntervals={spaceBTI}
                        subspaceTypeLayouts={subspaceTypeLayouts}
                        setSubspaceTypeLayouts={setSubspaceTypeLayout}
                    />

                    <div className={classes.educationSubspaceTypeForm__switcher}>
                        <Switcher
                            label="Тип подпространств является выборным"
                            onClick={() => setSelective(!isSelective)}
                            checked={isSelective}
                        />
                    </div>

                    <div className={classes.educationSubspaceTypeForm__switcher}>
                        <Switcher
                            label="Есть описание профиля выпускника подпространства"
                            onClick={() => setDescriptionEnabled(!isDescriptionEnabled)}
                            checked={isDescriptionEnabled}
                        />
                    </div>
                </div>

                <div className={classes.educationSubspaceTypeForm__buttonCreate}>
                    <ActionButton
                        className={cn(classes.educationButton, {
                            [classes.educationButton__bgButton]: true,
                            [classes.educationButton_width_fix]: true,
                        })}
                        onClick={() => createSubspace()}
                    >
                        {
                            index > -1
                                ? 'Обновить тип подпространства'
                                : 'Создать тип подпространства'
                        }
                    </ActionButton>

                    <ActionButton
                        actionType={ActionTypeOfButton.SECONDARY}
                        className={cn(classes.educationButton, {
                            [classes.educationButton_width_fix]: true,
                        })}
                        onClick={() => deleteSubspace()}
                    >
                        Удалить тип подпространства
                    </ActionButton>
                </div>
            </div>

            {error !== '' && <Alert message={error} />}
        </div>
    );
};
