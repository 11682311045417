import {
    compact, filter, find, map, range,
} from 'lodash';
import { SpecializedSkillsInput, SubspaceSkill } from '../../subSpaceTypes';
import { skillTypesData_skillTypes } from '../../../graphql-query-types';

export const parseSpecializedSkills = (specializedSkills: SubspaceSkill[]): SubspaceSkill[] => {
    const parsedSkills = map(
        specializedSkills,
        skill => {
            if (skill.level && skill.level > 1) {
                return map(range(1, skill.level + 1), skillIndex => ({
                    ...skill,
                    level: skillIndex,
                }));
            }

            return skill;
        },
    );

    return parsedSkills.flat();
};

export const filterSkillTypesForSlots = (
    skillsWithTypes: skillTypesData_skillTypes[],
): skillTypesData_skillTypes[] => compact(
    map(skillsWithTypes, type => (type.parentId === null ? type : undefined)),
);

export const filterSubspaceSpecializedSkillsBySkillType = (
    specializedSkills: SubspaceSkill[],
    skillType: skillTypesData_skillTypes,
    skillTypes: skillTypesData_skillTypes[],
): SubspaceSkill[] => {
    let filteredSkills: SubspaceSkill[] = [];

    specializedSkills.forEach(skill => {
        if (skill.skill.typeId === skillType.id) {
            filteredSkills = [...filteredSkills, skill];
        }
    });

    const nextSkillType = find(
        skillTypes, type => type.parentId === skillType.id,
    );

    if (nextSkillType && nextSkillType.id !== skillType.id) {
        filteredSkills = [...filteredSkills, ...filterSubspaceSpecializedSkillsBySkillType(
            specializedSkills,
            nextSkillType,
            skillTypes,
        )];
    }
    return filteredSkills;
};

export const transformSpecializedSkillsToSuggestionSkills = (
    specializedSkills: SubspaceSkill[],
): string[] => map(
    specializedSkills,
    skill => {
        if (skill.level) {
            if (skill.skill.name === skill.skill.fullName) return `${skill.skill.name} ${skill.level}`;
            return `${skill.skill.fullName} ${skill.level}`;
        }
        return skill.skill.name;
    },
);

export const filterSpecializedSkillsBySelectedSkill = (
    specializedSkills: SubspaceSkill[],
    skill: SubspaceSkill,
): SubspaceSkill[] => filter(
    specializedSkills,
    specializedSkill => specializedSkill.skill.id !== skill.skill.id,
);

export const filterSelectedSkillBySkillTypeName = (
    slotSkills: SubspaceSkill[],
    rootSkillTypes: skillTypesData_skillTypes[],
): SpecializedSkillsInput => {
    let skillsInput: any = {};

    rootSkillTypes.forEach(skillType => {
        skillsInput = {
            ...skillsInput,
            [skillType.name.english.toLocaleLowerCase()]:
                [
                    ...transformSpecializedSkillsToSuggestionSkills(
                        filterSubspaceSpecializedSkillsBySkillType(
                            slotSkills,
                            skillType,
                            rootSkillTypes,
                        ),
                    ),
                ]
            ,
        };
    });
    return skillsInput;
};
