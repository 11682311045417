import React, { useState } from 'react';

import cn from 'classnames';
import { BorderCrossIcon } from '@common/svg';
import { Dialog } from '@common/Dialog';
import { PortalSkillAddWithoutNest } from '../../SkillAddPage/SkillAddWithoutNest/PortalSkillAddWithoutNest';

import classes from './SkillCardWithoutNest.module.scss';

import {
    skillTypesData_skillTypes,
    skills_skills,
} from '../../../../graphql-query-types';

interface Props {
    skillId: string
    skillType: skillTypesData_skillTypes;
    item: skills_skills;
    editState: boolean;
    removeSkill(id: string): void;
    updateSkill: (
        id: string,
        name: string,
        typeId: string,
        description: string,
        maxLevel: number
    ) => void;
}

export function SkillCardSkillItem({
    skillId,
    skillType,
    item,
    editState,
    removeSkill,
    updateSkill,
}: Props): JSX.Element {
    const [height, setHeight] = useState(true);
    const [portal, togglePortal] = useState(false);

    const edit = () => {
        setHeight(!height);
    };

    const nonEdit = () => {
        togglePortal(!portal);
    };
    return (
        <div className={classes.skillCardContent__container}>
            <div className={cn(classes.skillCardContent__titleWrapper, {
                [classes.skillCardContent__titleWrapper_withDesc]: item.description,
                [classes.skillCardContent__titleWrapper_editHover]: !editState,
            })}
            >
                <div
                    className={cn(classes.skillCardContent__title, {
                        [classes.skillCardContent_checked]: !height,
                    })}
                    // eslint-disable-next-line
                    onClick={editState
                        ? (item.description ? edit : () => null)
                        : nonEdit
                    }
                >
                    {item.name}
                </div>
                {!editState && (
                    <BorderCrossIcon
                        handler={() => removeSkill(skillId)}
                        size={18}
                    />
                )}
            </div>
            <div
                className={cn(classes.skillCardContent__description, {
                    [classes.skillCardContent__description_height_null]: height,
                })}
            >
                <div className={classes.skillCardContent__descriptionText}>
                    {item.description}
                </div>
            </div>
            {portal && (
                <Dialog
                    id="portal-edit-root"
                    dom={(
                        <PortalSkillAddWithoutNest
                            skill={item}
                            closePortal={nonEdit}
                            updateSkill={updateSkill}
                            skillType={skillType}
                        />
                    )}
                />
            )}
        </div>
    );
}
