import { gql } from '@apollo/client';

import { useMutationWithErrorHandling } from './useMutationWithErrorHandling';

const BEGIN_ADDITIONAL_GENERATION = gql`
    mutation startAdditionalGeneration($startGenerationInput: StartGenerationInput!) {
        startAdditionalGeneration(startGenerationInput: $startGenerationInput) {
            id
        }
    }
`;

export function useBeginAdditionalGeneration(onCompleted?: () => void) {
    const [beginAdditionalGeneration] = useMutationWithErrorHandling(
        BEGIN_ADDITIONAL_GENERATION, { onCompleted },
    );

    return (
        generationProcessId: string,
        ignoreFlags: string[] = [],
    ) => beginAdditionalGeneration({ variables: {
        startGenerationInput: {
            generationProcessId,
            ignoreFlags,

        },
    } });
}
