import UserView from '../../store/UserView';
import { ViewType } from '../../graphql-query-types';

export enum MetrikaType {
    REACH_GOAL = 'reachGoal',
}

export enum CommonTargetName {
    avatar = 'avatar',
    schedule_view = 'schedule_view',
    schedule_print = 'schedule_print',
    schedule_filter_common = 'schedule_filter_common',
}

export enum StudentTargetName {
    expand_knowledge = 'expand_knowledge',
    disclosure_block = 'disclosure_block',
    modules = 'modules',
}

export enum CounterId {
    qa = 87439619,
    prod = 87425716,
}

declare global {
    interface Window {
        ym: (counterId: number, type: string, value: string, params?: Params) => void;
    }
}

type Param = {
    role?: ViewType | string;
};

type Params = Record<string, string | undefined>;

type TargetName = CommonTargetName | StudentTargetName;

export function useSendMetriks(role: ViewType[]) {
    const { userViewType } = UserView;

    const sendYandexMetrika = (
        value: TargetName,
        param?: Params,
    ) => {
        if (process.env.NODE_ENV === 'production') {
            const activeRole: ViewType[] | string | undefined = param?.role || role;
            const isCheckRoleArray = (Array.isArray(activeRole)
                && userViewType?.name
                && activeRole.includes(userViewType?.name));
            const isCheckRoleString = (!Array.isArray(activeRole)
                && userViewType?.name === activeRole);

            if (isCheckRoleArray || isCheckRoleString) {
                const params: Params & Param = {
                    role: userViewType?.name,
                    ...param,
                };

                Object.keys(CounterId)
                    .map(counterId => window.ym(
                        CounterId[counterId as keyof typeof CounterId],
                        MetrikaType.REACH_GOAL,
                        value,
                        params,
                    ));
            }
        }
    };

    return { sendYandexMetrika };
}
