import React from 'react';
import cn from 'classnames';
import { IconDeprecated } from '@common';
import {
    Space,
} from '../../../EducationPeriodParametersAndDatesInterfaces';
import classes from './Navigation.module.scss';

interface Props {
    currentPeriodName?: string,
    goForward(): void,
    goBackward(): void,
    space: Space,
}

export function Navigation(
    {
        currentPeriodName,
        goForward,
        goBackward,
        space,
    }: Props,
) {
    return (
        <div className={classes.navigation}>
            <IconDeprecated
                id="navigationLeftArrow"
                click={goBackward}
                className={cn(
                    classes.navigation__arrow,
                    {
                        // eslint-disable-next-line max-len
                        [classes.navigation__arrow_inactive]: !(space?.spaceEducationPeriods?.length > 1),
                    },
                )}
            />
            <div className={classes.navigation__periodName}>
                {currentPeriodName}
            </div>
            <IconDeprecated
                id="navigationRightArrow"
                click={goForward}
                className={cn(
                    classes.navigation__arrow,
                    {
                        // eslint-disable-next-line max-len
                        [classes.navigation__arrow_inactive]: !(space?.spaceEducationPeriods?.length > 1),
                    },
                )}
            />
        </div>
    );
}
