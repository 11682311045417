import React from 'react';
import cn from 'classnames';

import { Event, EventType, moduleStore } from '@admin/NewModule/Store';
import { ModuleSkills } from './ModuleSkills';
import { SkillType } from '../../../subSpaceAdmin/subSpaceTypes';
import { skills_skills } from '../../../graphql-query-types';

import classes from './Event.module.scss';

interface Props {
    openedMeeting: null | number;
    setOpenedMeeting: (id: null | number) => void;
    meetingIndex: number;
    event: Partial<Event> & { type: EventType; numberOfEvent: number; };
    skillTypes: SkillType[];
    allSkillsList: skills_skills[];
}

function EventComponent({
    openedMeeting,
    setOpenedMeeting,
    meetingIndex,
    event,
    skillTypes,
    allSkillsList,
}: Props): JSX.Element {
    return (
        <div className={classes.moduleSpecificMeeting}>
            <div
                className={classes.moduleSpecificMeeting__title}
                onClick={() => setOpenedMeeting(
                    openedMeeting === meetingIndex ? null : meetingIndex,
                )}
            >
                <div className={classes.moduleSpecificMeeting__name}>
                    {moduleStore.isMeeting(event) && (
                        <p>
                            <b>Встреча {event.numberOfEvent}: </b>
                            {`${event.topic ?? ''}`}
                        </p>
                    )}
                    {moduleStore.isAssignment(event) && (
                        <p>
                            <b>Самостоятельная работа {event.numberOfEvent}: </b>
                            {`${event.topic ?? ''}`}
                        </p>
                    )}
                    {moduleStore.isEvaluationPoint(event) && (
                        <p>Точка оценки {event.numberOfEvent}</p>
                    )}
                </div>
            </div>
            <div className={cn(classes.moduleSpecificMeeting__options, {
                [classes.moduleSpecificMeeting__options_height_null]:
                openedMeeting !== meetingIndex,
            })}
            >
                <div className={classes.moduleSpecificMeeting__formatAndDuration}>
                    <div className={classes.moduleSpecificMeeting__duration}>
                        <span><b>Продолжительность:</b></span>
                        <span>{event.duration?.name ? `${event.duration?.name ?? ''} мин` : 'не указана'}</span>
                    </div>
                    <div className={classes.moduleSpecificMeeting__format}>
                        {moduleStore.isMeeting(event) && (
                            <>
                                <span><b>Формат:</b></span>
                                <span>{event.format?.name ?? 'не указан'}</span>
                            </>
                        )}
                        {moduleStore.isEvaluationPoint(event) && (
                            <>
                                <span><b>Название:</b></span>
                                <span>{event.topic ?? ''}</span>
                            </>
                        )}
                    </div>
                </div>
                <div className={classes.moduleSpecificMeeting__skillsList}>
                    {moduleStore.isMeeting(event) || moduleStore.isAssignment(event) ? (
                        <>
                            <ModuleSkills
                                text="необходимые для встречи:"
                                // @ts-ignore
                                skills={event.prerequisiteSkills ?? []}
                                skillTypes={skillTypes}
                                allSkillsList={allSkillsList}
                            />
                            <ModuleSkills
                                text="получаемые после встречи:"
                                // @ts-ignore
                                skills={event.outputSkills ?? []}
                                skillTypes={skillTypes}
                                allSkillsList={allSkillsList}
                            />
                        </>
                    ) : (
                        <ModuleSkills
                            text="оценивающиеся в этой точке:"
                            // @ts-ignore
                            skills={event.evaluationPointSkills ?? []}
                            skillTypes={skillTypes}
                            allSkillsList={allSkillsList}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export { EventComponent as Event };
